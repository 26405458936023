import React from 'react';
import ModalComponent from '../ModalComponent';
import {Button, Row, Col} from 'react-bootstrap';


const FullCommentModal = ({ comment, showModal, setShowModal }) => {
  return (
    <ModalComponent
      header="Comentario"
      body={
        <Row>
          <Col>
            <div style={{ overflowY: 'auto', maxHeight: '65vh' }}>
              <div className="scroll">
                {comment}
              </div>
            </div>
          </Col>
        </Row>
      }
      footer={<Button onClick={() => setShowModal(false)}>Cerrar</Button>}
      show={showModal}
      canClose={true}
      onHide={setShowModal}
    />
  );
}

export default FullCommentModal;
