import {RequestNewVersion} from "../../../classes/RequestNewVersion";

export const assignedUsersTableColumns = [
  {
    dataField: "name",
    text: "Nombre",
    sort: true,
    style: { maxWidth:"250px", minWidth:"180px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "email",
    text: "Correo electrónico",
    sort: true,
    style: { maxWidth:"250px", minWidth:"180px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "actions",
    text: "",
    sort: false,
    style: { maxWidth:"50px", minWidth:"50px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
];

export const searchUsersTableColumns = [
  {
    dataField: "fullname",
    text: "Nombre",
    sort: true,
    style: { maxWidth:"250px", minWidth:"180px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "email",
    text: "Correo electrónico",
    sort: true,
    style: { maxWidth:"250px", minWidth:"180px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
  {
    dataField: "actions",
    text: "",
    sort: false,
    style: { maxWidth:"50px", minWidth:"50px", textAlign:"center" },
    headerStyle: { textAlign:"center" }
  },
];

export const getUsersWithPermissionsToSeeExpedient = async (expedientId, setCollection, loader) => {
  loader.show(true);

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/get_user_permissions/${expedientId}`,
    "get",
    null,
    {}
  );

  const { code, response } = await request.executeRequest();

  if (code !== 200) {
    setCollection([]);
    loader.show(false);
    return;
  }

  const { expedient, permissions } = response;
  const users = [...expedient, ...permissions];
  const mappedUsers = users.map(user => ({...user, idreact: user.email.concat(user.id)}));

  setCollection(mappedUsers);
  loader.show(false);
}

export const handleSubmitAssignUserSearch = async (e, search, setCollection, loader) => {
  e.preventDefault();
  loader.show(true);

  search = search === '' ? '%20' : search;

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/search_users/${search}`,
    "get",
    null,
    {}
  );

  const { code, response } = await request.executeRequest();

  if (code !== 200) {
    loader.show(false);
    setCollection([]);
    return;
  }

  const { users } = response;
  const usersFound = users || [];
  const mappedUsers = usersFound.map(user => ({...user, idreact: user.email.concat(user.id)}));

  setCollection(mappedUsers);
  loader.show(false);
}

export const addUserToExpedientPermission = async (id, expedientId, setUsersWithPermission, loader) => {
  loader.show(true);

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/add_users_to_expedient_permissions/${expedientId}`,
    "post",
    null,
    {
      users: [id]
    }
  );

  const { code } = await request.executeRequest();

  if (code !== 200) {
    loader.show(false);
    return;
  }

  getUsersWithPermissionsToSeeExpedient(expedientId, setUsersWithPermission, loader);
  loader.show(false);
}

export const removeUserToExpedientPermission = async (id, expedientId, setUsersWithPermission, loader) => {
  loader.show(true);

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/remove_users_to_expedient_permissions/${expedientId}`,
    "post",
    null,
    {
      users: [id]
    }
  );

  const { code } = await request.executeRequest();

  if (code !== 200) {
    loader.show(false);
    return;
  }

  getUsersWithPermissionsToSeeExpedient(expedientId, setUsersWithPermission, loader);
  loader.show(false);
}
