import _ from "lodash";

export const getTemplateImagesWithCorsError = async (template) => {
  const container = document.createElement('div');
  container.insertAdjacentHTML("beforeend", template);
  const imageNodeList = Array.from(container.querySelectorAll('img'));
  const externalProtocol = 'http';
  const externalImageNodeList = imageNodeList.filter((imageNode) => imageNode.src.startsWith(externalProtocol));
  const externalSrcImageList = externalImageNodeList.map((imageNode) => imageNode.src);
  const externalImageRequestList = externalSrcImageList.map((imageSrc) => fetch(imageSrc));
  const externalImageResponseList = await Promise.allSettled(externalImageRequestList);

  const externalImageSrcWithResponseList = externalSrcImageList.map((imageSrc, index) => {
    const imageSrcWithResponse = {
      imageSrc,
      response: externalImageResponseList[index],
    }
    return imageSrcWithResponse
  })

  const externalImageSrcResponseWithErrorList = externalImageSrcWithResponseList.filter(({ response }) => {
    const isAFailedPromise = _.get(response, 'status') === 'rejected';
    const isARejectedRequest = _.get(response, 'reason.message') === 'Failed to fetch';
    const isAResponseWithCorsError = isAFailedPromise && isARejectedRequest;
    return isAResponseWithCorsError;
  });

  const externalImageSrcResponseWithErrorFlattenedList = externalImageSrcResponseWithErrorList.map(({ imageSrc }) => imageSrc);
  return externalImageSrcResponseWithErrorFlattenedList
}