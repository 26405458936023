import _ from "lodash";
import { RequestNewVersion } from "../../../components/classes/RequestNewVersion";

export const onGetSignatureList = async (documentId) => {
  const signatureListRequestURL = `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/signers/${documentId}`;
  const signatureListRequest = new RequestNewVersion(signatureListRequestURL, "get", null);

  try {
    const signatureListResponse = await signatureListRequest.executeRequest();
    const signatureListResponseCode = _.get(signatureListResponse, 'code');
    const signatureListResponseSuccessful = signatureListResponseCode === 200;

    if (!signatureListResponseSuccessful) {
      throw new Error('Ocurrió un error al obtener listado de firmantes');
    }

    return signatureListResponse

  } catch (error) {
    throw error;
  }
}