import { createContext, useContext } from "react";

export const IndicatorsContext = createContext();
IndicatorsContext.displayName = 'IndicatorsContext';

export const useIndicatorsContext = () => {
  const context = useContext(IndicatorsContext);

  if (typeof context === 'undefined')
    throw new Error('To use useIndicatorsContext you have to wrap your component inside a IndicatorsContextProvider component.');
  return context;
}