import _ from "lodash";

export const getPartyDataWithFormattedPersonBirthDate = (partyData) => {
  if (_.isEmpty(partyData)) {
    return partyData
  }

  let {
    birthDate,
    ...restOfPartyData
  } = partyData;

  const formattedBirthDate = typeof birthDate === 'string' ? birthDate.trim() : birthDate;

  const formattedPartyData = {
    ...restOfPartyData,
    birthDate: formattedBirthDate,
  }

  return formattedPartyData;
}