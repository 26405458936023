import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Badge, Form, Button, Modal, ListGroup, Dropdown } from "react-bootstrap";
import ModalComponent from "../helper_components/ModalComponent";
import Select from "react-select";
import { selectStylesDefault, fieldsValidation } from "./my_expedients/expedients_list_logic";
import Cookies from "js-cookie";
import { 
  getTemplatesPresaved, getUserTemplateById, getAgreementTypes, 
  requestDeleteTemplate, getPromotionsWithoutAgreement, renderFieldsPromotionTemplate,
  requestSendUpdateTemplate, bodyDeleteTemplate, getCommentsByTemplate,
  requestCreatePDFTemplate, requestCommentAndRelease, getEmailsTemplates,
  getExpedientStakeHolders, footerNotifyEmail, bodyNotifyEmail, requestCreatePDFTemplateOther,
  getStringIdFromASelectArray, getAgreementsToNotifyByExpedientId,
  getSelectedOptionsById, getIsCommentFieldShowingInTemplate, requestUnsignPDFTemplate
} from "./details_expedient/expedient_details_logic";
import { renderErrorsList, renderClassesToErrors } from "../edit_profile/edit_profile_logic";
import LoaderContext from "../layout/shared/loader_context";
import { NotificationManager } from "react-notifications";
import _, { set } from "lodash";
import ConfirmModal from "../helper_components/confirm_modal/ConfirmModal";
import TextTooltip from "../tootip/TextTooltip";
import ElectronicSignatureTemplatesModal from "./details_expedient/modals/ElectronicSignatureTemplatesModal";
import ModalComponentPortal from "../helper_components/modals/ModalComponentPortal";
import AgreementWithoutPromotionModal from "./details_expedient/modals/AgreementWithoutPromotionModal";
import { templatesTypesToAskIfIsAPublishBulletin } from "../../services/dataFormatting/templatesTypesToAskIfIsAPublishBulletin";
import ElectronicSignatureProcessProvider from "../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext";
import { useTemplatesPresavedContextContext } from "./details_expedient/context/templatesPresavedContext";
import useImagesWithCorsErrorState from "../richTextEditor/hooks/imagesWithCorsErrorState/useImagesWithCorsErrorState";
import RichTextEditor from "../richTextEditor/RichTextEditor";
import { getLabelFromDictionary } from "../../config/dictionary";
import { onGetTemplateFile } from "./details_expedient/services/onGetTemplateFile";
import { getFormData } from "../../services/dataFormatting/getFormData";

const stylesFormatted = {
  ...selectStylesDefault,
  menu: (provided) => ({
    ...provided,
    zIndex:100
  })
};

const getObjectWithoutEmailTemplateId = (object) => {
  const {email_template_id, ...restOfData} = object;
  return restOfData;
}

const selectStylesError = {
  control: (provided,) => {
    return {
      ...provided,
      border: '1px solid red'
    };
  }
};

const electronicNotificationInitialState = {
  isAnElectronicNotification: false,
  isAnAgreementType: true,
  isAnJudgmentType: false,
}

const TemplatesPresaved = ({ 
  expedientNumber, reloadData, setReloadData, reloadParentComponent, props, dataExpedient, reloadExpedientDetailsComponent, expedientId
}) => {
  const specialPermissions = Cookies.getJSON('special_permissions_03');
  const { userId_03 } = Cookies.get();
  const canUseAgreementTemplate = specialPermissions[0]['agreement_template'] === '1';
  const canUseNotificationsTemplate = specialPermissions[0]['notifications_template'] === '1';
  const canUsePromotionsTemplate = specialPermissions[0]['promotions_template'] === '1';
  const canUseJudgmentsTemplate = specialPermissions[0]['judgments_template'] === '1';
  const canUseEmailsTemplate = specialPermissions[0]['emails_template'] === '1';
  const canSaveTemplates= specialPermissions[0]['documents_without_sing'] === '1';
  const renderTemplates = canUseAgreementTemplate || canUseNotificationsTemplate ||
    canUsePromotionsTemplate || canUseJudgmentsTemplate || canUseEmailsTemplate;
  const [showModalTiny, setShowModalTiny] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [templateHere, setTemplateHere] = useState({});
  const [templatesAgreement, setTemplatesAgreement] = useState([]);
  const [templatesNotification, setTemplatesNotification] = useState([]);
  const [templatesPromotion, setTemplatesPromotion] = useState([]);
  const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false);
  const [templatesEmail, setTemplatesEmail] = useState([]);
  const [templatesJudgment, setTemplatesJudgment] = useState([]);
  const [errors, setErrors] = useState({});
  const loader = useContext(LoaderContext);
  const [templateIdDelete, setTemplateIdDelete] = useState('');
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
  const [comments, setComments] = useState([]);
  const [openComments, setOpenComments] = useState(false);
  const [templatesType, setTemplatesType] = useState('');
  const [emailsOptions, setEmailsOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentTemplateId, setCurrentTemplateId] = useState(null);
  const [showUsers, setShowUsers] = useState(false);
  const [newUser, setNewUser] = useState({
    email: '',
    fullname: '',
    id: null,
    canDelete: true
  });
  const [dataUploadEvidences, setDataUploadEvidences] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isAgreementWithoutPromotionModalShowing, setIsAgreementWithoutPromotionModalShowing] = useState(false);
  const shouldTemplateBeSigned = useRef(false);
  const [electronicNotificationState, setElectronicNotificationState] = useState(electronicNotificationInitialState);
  const shouldAskIfIsAPublishBulletin = useMemo(() => templatesTypesToAskIfIsAPublishBulletin.includes(templateHere.type), [templateHere.type]);
  const [agreementsOrJudgmentsToNotifyOptions, setAgreementsOrJudgmentsToNotifyOptions] = useState([]);
  const [selectedAgreementsOrJudgmentsToNotify, setSelectedAgreementsOrJudgmentsToNotify] = useState([]);
  const richTextPreviousValueRef = useRef('');
  const imagesWithCorsErrorState = useImagesWithCorsErrorState();

  const onIsAPublishBulletinChange = ({ target: { value } }) => {
    const isIncomingValueTrue = value === 'true';
    setErrors(({ ifexcerpt, ...restOfProperties }) => ({ ...restOfProperties }));
    setTemplateHere({ ...templateHere, ifexcerpt: isIncomingValueTrue });
  }
  
  useEffect(() => {
    if (!agreementsOrJudgmentsToNotifyOptions.length || !templateHere.agreementsOrJudgmentsToNotify)
      return

    const _selectedAgreementsOrJudgmentsToNotify = getSelectedOptionsById(agreementsOrJudgmentsToNotifyOptions, templateHere.agreementsOrJudgmentsToNotify)
    setSelectedAgreementsOrJudgmentsToNotify(_selectedAgreementsOrJudgmentsToNotify);
  }, [agreementsOrJudgmentsToNotifyOptions]) 

  const onNotifycationTypeChange = ({ target }) => {
    setElectronicNotificationState((previousState) => ({
      ...previousState,
      isAnAgreementType: target.value === 'agreement',
      isAnJudgmentType: target.value === 'judgment',
    }));
  }

  useEffect(() => {
    const getAndSetAgreementsOrJudgmentsToNotifyOptions = async () => {
      setSelectedAgreementsOrJudgmentsToNotify([]);
      setTemplateHere({ ...templateHere, agreementsOrJudgmentsToNotify: [] })
      if (electronicNotificationState.isAnAgreementType) {
        loader.show(true);
        const _agreementsOrJudgmentsToNotifyOptions = await getAgreementsToNotifyByExpedientId(
          dataExpedient.id,
          electronicNotificationState.isAnAgreementType ? 'agreement' : 'judgment'
        );
        loader.show(false);
        setAgreementsOrJudgmentsToNotifyOptions(_agreementsOrJudgmentsToNotifyOptions);
      }
    }

    if (templateHere.type === 'notification' && (electronicNotificationState.isAnAgreementType || electronicNotificationState.isAnJudgmentType)) {
      getAndSetAgreementsOrJudgmentsToNotifyOptions();
      setTemplateHere({ ...templateHere, notificationType: electronicNotificationState.isAnAgreementType ? 'agreement' : 'judgment' });
    }
  }, [
    electronicNotificationState.isAnAgreementType,
    electronicNotificationState.isAnJudgmentType,
    templateHere.type
  ]);

  const { isElectronicSignatureModalOpen, onOpenElectronicSignatureModal } = useTemplatesPresavedContextContext();
  
  useEffect(() => {
    (async function(){
      loader.show(true);
      await getTemplatesPresaved(
        expedientId,
        setTemplatesAgreement,
        setTemplatesNotification,
        setTemplatesPromotion,
        setTemplatesEmail,
        setTemplatesJudgment,
        setTemplatesType
      );
      await getAgreementTypes(
        setAgreementTypes
      );
      await getPromotionsWithoutAgreement(
        expedientId,
        setPromotions
      );
      loader.show(false);
    })();

    const detectClickOutside = (e) => {
      if (e.target.id !== 'delete-template-button') setTemplateIdDelete('');
    };

    window.addEventListener('click', detectClickOutside);
    return () => window.removeEventListener('click', detectClickOutside);
  }, [reloadData]);

  const onSignTemplateClick = async() => {
    loader.show(true);
    const areThereTemplateImagesWithCorsError = await imagesWithCorsErrorState.getAreThereTemplateImagesWithCorsError(templateHere.body);
    loader.show(false);

    if (areThereTemplateImagesWithCorsError) {
      return
    };

    const areTemplateFieldsValid = validateTemplateFields();
    if (!areTemplateFieldsValid) {
      return
    }
    if (!templateHere.all_promotion && templateHere.answer_promotion) {
      if(templateHere.type === 'agreement') {
        shouldTemplateBeSigned.current = true;
        setIsAgreementWithoutPromotionModalShowing(true);
        return;
      }
    }
    signTemplate();
  }

  useEffect(() => {
    if (templateHere.type !== 'agreement') {
      return
    }
    if ((promotions && !promotions.length) || !promotions || !templateHere.promotions) {
      return
    }
    setTemplateHere({ ...templateHere, all_promotion: promotions.length === templateHere.promotions.length })
  }, [promotions, templateHere.promotions])

  const signTemplate = () => {
    if (!templateHere.ifexcerpt && templateHere.excerpt) {
      setTemplateHere({ ...templateHere, excerpt: '' });
    }
    onOpenElectronicSignatureModal();
  }

  const handleEditorChange = (e) => {
    delete errors['body'];
    delete errors['html'];
    const richTextCurrentValue = e.content;
    const hasRichTextValueChanged = richTextPreviousValueRef.current !== richTextCurrentValue;

    if (!hasRichTextValueChanged) {
      return
    }

    richTextPreviousValueRef.current = richTextCurrentValue;
    setTemplateHere({ ...templateHere, body: richTextCurrentValue, });
  };

  const sendCommentAndRelease = async (templateId) => {
    const data = {
      expedient_number: expedientNumber.replace(/-/g, '/'),
      comment: templateHere['comments'],
      expedient_id: expedientId,
    };
    const responseValidation = fieldsValidation(
      ['expedient_number', 'comment'], 
      data
    );
    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
      NotificationManager.error('Faltan campos requeridos, revisa los campos marcados en rojo');
    } else {
      loader.show(true);
      await requestCommentAndRelease(
        templateId,
        data,
        props
      );
      loader.show(false);
    }
  };

  const onCloseTemplatesPresavedModal = () => {
    setShowModalTiny(false);
    setSelectedAgreementsOrJudgmentsToNotify([]);
    setAgreementsOrJudgmentsToNotifyOptions([]);
    setTemplateHere({});
    setErrors({});
  }

  const reloadDataTemplates = async () => {
    await getTemplatesPresaved(
      expedientId,
      setTemplatesAgreement,
      setTemplatesNotification,
      setTemplatesPromotion,
      setTemplatesEmail,
      setTemplatesJudgment,
      setTemplatesType
    );
    await getAgreementTypes(
      setAgreementTypes
    );
    await getPromotionsWithoutAgreement(
      expedientId,
      setPromotions
    );
    setUsers([]);
    setShowUsers(false);
  };

  const setIsAnElectronicNotificationToTrueWhenIsATemplatesTypeNotifications = () => {
    if (templatesType === 'Notifications') {
      const isAnElectronicNotification = true;
      setElectronicNotificationState({ ...electronicNotificationInitialState, isAnElectronicNotification });
      setTemplateHere({ ...templateHere, checkNotification: true });
      return
    }
    setElectronicNotificationState(electronicNotificationInitialState);
    setTemplateHere({ ...templateHere, checkNotification: false });
    return
  }

  const reloadParentAndTemplateComponents = async () => {
    loader.show(true);
    const message = templateHere['hasProofs'] === 'si' ? 
      'La inserción de la promoción y sus archivos de prueba ha sido exitosa' :
      'La inserción de la promoción ha sido exitosa';

      await reloadParentComponent(
      message,
      'Excelente:',
      5000
    );
    await reloadDataTemplates();
    setShowModalTiny(false);
    loader.show(false);
  };

  const isCommentFieldShowing = getIsCommentFieldShowingInTemplate(templateHere.type);

  const onSelectRubroChange = (selected) => {
    const agreementsFormatted = selected ? selected.map((p) => p.value) : [];
    const agreementsFormattedLabel = selected ? selected.map((p) => p.label) : [];
    const _alias = agreementsFormattedLabel.toString();
    setTemplateHere({
      ...templateHere,
      alias: _alias,
      headings: agreementsFormatted,
      filename: _alias
    });
  }
  const tinyEditor = (
    <div style={{ margin: '0 auto', width: '80%' }}>
      <div style={{margin:'0 auto', width:'80%'}}>
        <Form>
          <Form.Group>
            <Form.Label>{templateHere['type'] === 'agreement' ? '* Rubro' : '* Nombre'}</Form.Label>
            {
              templateHere['type'] === 'agreement' && (templateHere.defaultAgreements && templateHere.defaultAgreements.length > 0)
                ?
                <Select id='selectRubro'
                  defaultValue={templateHere.defaultAgreements}
                  onChange={onSelectRubroChange}
                  isClearable={false}
                  isMulti
                  options={agreementTypes}
                  placeholder="Selecciona rubros"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={
                    errors['alias'] ?
                      selectStylesError :
                      stylesFormatted
                  }
                />
                : 
              <Form.Control
                onChange={(e) => {
                  delete errors['alias'];
                  setTemplateHere({...templateHere, alias: e.target.value});
                }}
                className={renderClassesToErrors(errors, 'alias')}
                placeholder='Agrega un nombre'
                value={templateHere['alias']}
                readOnly
              /> }
            {renderErrorsList(errors, 'alias')}
          </Form.Group>
          
          {templateHere['last_comment'] ? 
          <>
            <hr/>
            <Button 
              className='fl-r btn-add-comments-template' 
              variant='link'
              onClick={() => handleOpenComments()}
            >
              Ver historial de observaciones
            </Button>
            <Form.Group>
              <Form.Label>
                Última observación: 
              </Form.Label>
              <Form.Control
                as="textarea"
                aria-label="Última observación"
                defaultValue={templateHere['last_comment']}
                readOnly
                disabled
              />
            </Form.Group>
          </> : ''}

          <hr/>
          {
            templateHere['type'] === 'notification'
            &&
            <>
              <Form.Group className="mb-3">
                <Form.Label>¿Desea hacer una notificación electrónica?</Form.Label>
                <Form.Check
                  type="checkbox"
                  label={"Si"}
                  checked={electronicNotificationState.isAnElectronicNotification}
                  id={"check_isAnElectronicNotification"}
                  onChange={({ target }) => {
                    if (target.checked) {
                      setElectronicNotificationState({ ...electronicNotificationInitialState, isAnElectronicNotification: target.checked });
                      setTemplateHere({...templateHere, checkNotification: true});
                    } else {
                      setElectronicNotificationState({ ...electronicNotificationInitialState, isAnElectronicNotification: target.checked });
                      setTemplateHere({...templateHere, email_template_id: null, checkNotification: false });
                    }
                  }}
                />
              </Form.Group>
              {
                electronicNotificationState.isAnElectronicNotification
                &&
                <>
                <Form.Group>
                  <Form.Label>* Plantillas de correo electrónico</Form.Label>
                  <Select
                    options={emailsOptions}
                    value={emailsOptions.find(option => option.value === templateHere.email_template_id) || null}
                    onChange={(selected) => {
                      delete errors['email_template_id'];
                      setTemplateHere({...templateHere, email_template_id: selected.value});
                    }}
                    placeholder="Selecciona la plantilla para notificar vía correo electrónico"
                    styles={errors['email_template_id'] ? selectStylesError : stylesFormatted}
                    noOptionsMessage={emailsOptions.length > 0 ? () => "Sin opciones disponibles" : () => "Da de alta primero plantillas de correo"}
                    />
                  {renderErrorsList(errors, 'email_template_id')}
                </Form.Group>
                <hr />
                </>
              }
              <hr />

              <Form.Group onChange={onNotifycationTypeChange}>
                <Form.Label>* Tipo de Notificación</Form.Label>
                <Form.Check
                  type={"radio"}
                  label={"Acuerdo"}
                  value={"agreement"}
                  name={"notificationType"}
                  defaultChecked={electronicNotificationState.isAnAgreementType}
                />
                <Form.Check
                  type={"radio"}
                  label={"Sentencia"}
                  value={"judgment"}
                  name={"notificationType"}
                  defaultChecked={electronicNotificationState.isAnJudgmentType}
                />
                {renderErrorsList(errors, 'notificationType')}
              </Form.Group>
              <hr />

              {
                electronicNotificationState.isAnAgreementType
                &&
                <>
                  <Form.Group>
                    <Form.Label>* {electronicNotificationState.isAnAgreementType ? 'Acuerdos' : 'Sentecias'} a notificar:</Form.Label>
                    <Select
                      value={selectedAgreementsOrJudgmentsToNotify}
                      isClearable={false}
                      isMulti
                      options={agreementsOrJudgmentsToNotifyOptions}
                      placeholder={`Selecciona ${electronicNotificationState.isAnAgreementType ? 'los acuerdos a los' : 'las sentencias a las'} cuales quieres notificar`}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      noOptionsMessage={agreementsOrJudgmentsToNotifyOptions.length > 0 ? () => "Sin más opciones disponibles" : () => "Sin opciones disponibles"}
                      styles={stylesFormatted}
                      onChange={(selected) => {
                        setTemplateHere({ ...templateHere, agreementsOrJudgmentsToNotify: getStringIdFromASelectArray(selected) })
                        setSelectedAgreementsOrJudgmentsToNotify(selected);
                      }}
                    />
                    {renderErrorsList(errors, 'agreementsOrJudgmentsToNotify')}
                  </Form.Group>
                  <hr />
                </>
              }
            </>
          }

          { templateHere['type'] !== 'notification' &&
            <Form.Group >
              <Form.Label>Solicitar correcciones</Form.Label>
              <Form.Check
                type="checkbox"
                label={"Si"}
                checked={templateHere['add_comments'] ? true : false}
                id={"yes"}
                value={templateHere['add_comments'] ? 'false' : 'true'}
                onChange={(e) => {
                  if (e.target.value === 'true') {
                    setTemplateHere({...templateHere, add_comments: true})
                  } else {
                    setTemplateHere({...templateHere, add_comments: false, comments:''})
                  }
                }}
              />
            </Form.Group>
          }

          {templateHere['add_comments'] ? 
          <>
            <Form.Group>
              <Form.Label>Observaciones:</Form.Label>
              <Form.Control
                onChange={(e) => setTemplateHere({
                  ...templateHere,
                  comments:e.target.value
                })}
                as="textarea"
                rows="5"
                aria-label="Observaciones"
                defaultValue={templateHere['comments']}
                className={renderClassesToErrors(errors, errors['comment'] ? 'comment' : 'comments')}
              />
              {renderErrorsList(errors, errors['comment'] ? 'comment' : 'comments')}
              {templateHere && String(templateHere['user_id']) === userId_03 ? '' :
              <Button 
                onClick={() => sendCommentAndRelease(templateHere['id'])} 
                variant='danger' 
                className='fl-r mT-2 mB-10'
              >
                Solicitar corrección
              </Button>}
            </Form.Group>
          </> : ''}

          {
            isCommentFieldShowing
            &&
            <>
              <hr />
              <Form.Group>
                <Form.Label>Comentarios:</Form.Label>
                <Form.Control
                  onChange={(e) => setTemplateHere({ ...templateHere, comment: e.target.value })}
                  as="textarea"
                  rows="5"
                  aria-label="Comentarios"
                  defaultValue={templateHere['comment']}
                />
              </Form.Group>
            </>
          }

          {
            templateHere['type'] === 'judgment'
            &&
            <>
              <hr />
              <Form.Group>
                <Form.Label>* Selecciona el tipo de sentencia</Form.Label>
                <div>
                  <Form.Check
                    inline
                    type={'radio'}
                    label={`Interlocutoria`}
                    id={`interlocutory`}
                    className='pl-3'
                    value={'interlocutory'}
                    checked={templateHere && templateHere.judgment_type === 'interlocutory'}
                    onChange={({ target: { value } }) => setTemplateHere({ ...templateHere, judgment_type: value })}
                  />
                  <Form.Check
                    inline
                    type={'radio'}
                    label={`Definitiva`}
                    id={`definitive`}
                    value={'definitive'}
                    checked={templateHere && templateHere.judgment_type === 'definitive'}
                    onChange={({ target: { value } }) => setTemplateHere({ ...templateHere, judgment_type: value })}
                  />
                  <Form.Check
                    inline
                    type={'radio'}
                    label={`Segunda instancia`}
                    id={`second_instance_judgment_type_templates`}
                    value={'second_instance'}
                    checked={templateHere && templateHere.judgment_type === 'second_instance'}
                    onChange={({ target: { value } }) => setTemplateHere({ ...templateHere, judgment_type: value })}
                  />
                </div>
              </Form.Group>
              {renderErrorsList(errors, "judgment_type")}
            </>
          }

          {
            shouldAskIfIsAPublishBulletin
            &&
            <>
              <Form.Group >
                <Form.Label>* ¿Desea publicar en el boletín?</Form.Label>
                <section>
                  <Form.Check
                    checked={templateHere.ifexcerpt}
                    inline
                    label="Si"
                    name="publishBulletin"
                    type={'radio'}
                    id={'publish-bulletin'}
                    value={true}
                    onChange={onIsAPublishBulletinChange}
                  />
                  <Form.Check
                    checked={!templateHere.ifexcerpt}
                    inline
                    label="No"
                    name="publishBulletin"
                    type={'radio'}
                    id={'do-not-publish-bulletin'}
                    value={false}
                    onChange={onIsAPublishBulletinChange}
                  />
                </section>
                {renderErrorsList(errors, 'ifexcerpt')}
              </Form.Group>
              <hr />
              {
                ((templateHere.ifexcerpt) || (templateHere.excerpt && templateHere.ifexcerpt))
                &&
                <>
                  <Form.Group>
                    <Form.Label>
                      * Extracto para boletín:
                    </Form.Label>
                    {renderErrorsList(errors, 'excerpt')}
                    <Form.Control
                      as="textarea"
                      aria-label="Extracto para boletín"
                      defaultValue={templateHere.excerpt}
                      rows="5"
                      className={renderClassesToErrors(errors, 'excerpt')}
                      onChange={(e) => {
                        setErrors(({ excerpt, ...restOfProperties }) => ({ ...restOfProperties }))
                        setTemplateHere({ ...templateHere, excerpt: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <hr />
                </>
              }
            </>
          }

          { 
            templateHere['type'] === 'agreement'
            &&
            <>
              <Form.Group>
                <Form.Label>* ¿Deseas responder promociones?</Form.Label>
                <Form.Check
                  type="checkbox"
                  label={"Si"}
                  checked={templateHere['answer_promotion'] ? true : false}
                  id={"si"}
                  value={templateHere['answer_promotion'] ? 'false' : 'true'}
                  onChange={(e) => {
                    if (e.target.value === 'true') {
                      setTemplateHere({...templateHere, answer_promotion: true})
                    } else {
                      setTemplateHere({...templateHere, answer_promotion: false})
                    }
                  }}
                />
              </Form.Group>
              <hr/>
            </>
          }

          {
            templateHere['answer_promotion'] && templateHere['type'] === 'agreement' ?
            <>
              <Form.Group>
                <Form.Label>Respuesta a promociones:</Form.Label>
                <Select
                  defaultValue={templateHere['promotions']}
                  isClearable={false}
                  isMulti
                  options={promotions}
                  placeholder="Selecciona las promociones de este acuerdo"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  noOptionsMessage={promotions.length > 0 ? () => "Sin más opciones disponibles" : () => "Sin opciones disponibles"}
                  styles={stylesFormatted}
                  onChange={(selectedValues) => {
                    const promotionsFormatted = selectedValues ? selectedValues.map((p) => p.value) : [];
                    setTemplateHere({
                      ...templateHere,
                      answer_promotion: true,
                      promotions_ids: promotionsFormatted.toString(),
                      all_promotion: promotions.length === promotionsFormatted.length
                    });
                  }}
                />
              </Form.Group> 
              <hr/>
            </> : ""
          }

          { templateHere['type'] === 'promotion' ?
            renderFieldsPromotionTemplate(
              templateHere, 
              setTemplateHere, 
              errors, 
              setErrors, 
              dataExpedient,
              reloadParentAndTemplateComponents,
              dataUploadEvidences
            ) : '' }

          <Form.Group>
            <Form.Label>* Documento</Form.Label>
            {renderErrorsList(errors, errors['html'] ? 'html' : 'body')}
          </Form.Group>
        </Form>
      </div>

      <div className={"container-preview-templates" + (errors['body'] || errors['html'] ? '-error' : '')} style={{marginTop:'-10px'}}>
        <RichTextEditor
          value={templateHere.body}
          onChange={handleEditorChange}
          imagesWithCorsErrorState={imagesWithCorsErrorState}
        />
      </div>
    </div>
  );

  const setDataTemplateModal = async (template, isNotification) => {
    setIsAnElectronicNotificationToTrueWhenIsATemplatesTypeNotifications();
    loader.show(true);
    if (isNotification) await getEmailsTemplates(expedientId, setEmailsOptions);
    const _currentTemplateId = template['id']
    setCurrentTemplateId(_currentTemplateId);
    await getUserTemplateById(
      _currentTemplateId,
      setTemplateHere,
      setShowModalTiny,
      agreementTypes,
    );
    loader.show(false);
  };

  const onSaveTamplateClick = () => {
    if (!templateHere.all_promotion && templateHere.answer_promotion) {
      if(templateHere.type === 'agreement') {
        setIsAgreementWithoutPromotionModalShowing(true);
        return;
      }
    }
    handleRequestSave();
  }

  const getTemplateToSend = () => {
    let templateToSend = { ...templateHere };
    if (templateHere.type === 'notification') {
      templateToSend.notificationType = electronicNotificationState.isAnAgreementType ? 'agreement' : 'judgment';
    }
    if (!templateHere.ifexcerpt && templateHere.excerpt) {
      templateToSend.excerpt = '';
    }
    templateToSend['content'] = templateToSend['body'];
    templateToSend['templateId'] = templateToSend['id'];
    templateToSend['userId'] = Cookies.get('userId_03');
    return templateToSend;
  }

  const validateTemplateFields = () => {
    const requiredFields = templateHere['add_comments'] ?
      ['id', 'alias', 'filename', 'type', 'content', 'body', 'expedient', 'comments'] : 
      ['id', 'alias', 'filename', 'type', 'content', 'body', 'expedient'];

    if (shouldAskIfIsAPublishBulletin && templateHere.ifexcerpt === undefined) {
      requiredFields.push('ifexcerpt');
    }

    if (templateHere.ifexcerpt) {
      requiredFields.push('excerpt');
    }

    if (templateHere.type === 'judgment') {
      requiredFields.push('judgment_type');
    }

    if (templateHere.type === 'notification') {
      requiredFields.push('notificationType');
      if (electronicNotificationState.isAnAgreementType) {
        requiredFields.push('agreementsOrJudgmentsToNotify');
      }
    }

    let templateToValid = getTemplateToSend();
    const responseValidation = fieldsValidation(requiredFields, templateToValid);
    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
      NotificationManager.error('Faltan campos requeridos, revisa los campos marcados en rojo');
      return false;
    } 
    return true;
  }

  const handleRequestSave = async () => {
    loader.show(true);

    const areThereTemplateImagesWithCorsError = await imagesWithCorsErrorState.getAreThereTemplateImagesWithCorsError(templateHere.body);
    if (areThereTemplateImagesWithCorsError) {
      loader.show(false);
      return
    };

    const areTemplateFieldsValid = validateTemplateFields();
    if (areTemplateFieldsValid) {
      let templateToSend = getTemplateToSend();
      templateToSend = _.omit(
        templateToSend,
        'expedient', 'filename',
        'type',
        'id', 'body', 'defaultAgreements');
      await requestSendUpdateTemplate(
        templateToSend, setShowModalTiny,
        setReloadData, reloadData
      );
    }
    loader.show(false);
  };

  const showMenuDeleteTemplate = (e, template) => {
    e.preventDefault();
    setTemplateIdDelete(template['id']);
  };

  const handleRequestDelete = async () => {
    loader.show(true);
    await requestDeleteTemplate(
      templateIdDelete, setOpenDeleteTemplate,
      setReloadData, reloadData
    );
    loader.show(false);
  };

  const handleOpenComments = async () => {
    setShowModalTiny(false);
    await getCommentsByTemplate(
      templateHere['id'], 
      setComments,
      setOpenComments
    );
  };

  const handleCloseComments = () => {
    setOpenComments(false);
    setShowModalTiny(true);
  };

  const getFileTemplate = async (pdfTemplate) => {
    if(canSaveTemplates){
      try {
        const filetemplate = await onGetTemplateFile(pdfTemplate['expedient_template_id'], pdfTemplate['html'], pdfTemplate['filename']);
          pdfTemplate['file'] = filetemplate;
        return pdfTemplate;
        }
       catch (error) {
        NotificationManager.error(
          'Ha ocurrido un error desconocido, vuelve a intentarlo...',
          '',
          4500
        );
      }
    }
  }

  const handleUsingTemplate = async () => {

    const requiredFields = templateHere['type'] === 'notification' ? 
      ['expedient_template_id', 'html', 'user_id', 'email_template_id'] :
      templateHere['type'] === 'promotion' && templateHere['hasProofs'] !== 'si' ?
      ['expedient_template_id', 'html', 'user_id', 'amount_copies', 'amount_signatures', 'hasProofs'] :
      templateHere['type'] === 'promotion' && templateHere['hasProofs'] === 'si' ?
      ['expedient_template_id', 'html', 'user_id', 'amount_copies', 'amount_signatures', 'hasProofs', 'evidence_file'] :
      templateHere['type'] === 'agreement' ? ['expedient_template_id', 'html', 'user_id', 'answer_promotion', 'promotions','file'] :
      ['expedient_template_id', 'html', 'user_id']  

    let pdfToCreate = {
      expedient_template_id: templateHere.id || null,
      html: templateHere.body || '',
      user_id: userId_03 || null,
      comment: templateHere.comments || ''
    };

    if (templateHere['type'] === 'promotion') {
      pdfToCreate = {
        ...pdfToCreate, 
        ...templateHere
      };
    }

    if(templateHere['type'] === 'agreement') {
      pdfToCreate = {
        ...pdfToCreate,
        ...templateHere,
      };
    }

    pdfToCreate = await getFileTemplate(pdfToCreate);
    const responseValidation = fieldsValidation(requiredFields,pdfToCreate);
    
    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
      NotificationManager.error('Faltan campos requeridos, revisa los campos marcados en rojo');
      return responseValidation;
    } else {
      loader.show(true);
      if (templateHere['type'] === 'notification') {
        await insertToExpedientNotificationTemplate();
      } else {

        if (canSaveTemplates){
          pdfToCreate['FileName']= `${templateHere['filename']}.pdf`;
          pdfToCreate['UserName']= `${Cookies.get('names_03'), Cookies.get('paternal_surname_03')}`;
          pdfToCreate['email_template_id'] = templateHere.email_template_id || 0;
          pdfToCreate = getFormData(pdfToCreate);
        }
        await requestUnsignPDFTemplate(
          pdfToCreate, setShowModalTiny,
          setReloadData, reloadData,
          setErrors, reloadParentComponent, 
          setDataUploadEvidences,setShowConfirmUploadModal
        );
      }
      if (pdfToCreate['type'] !== 'promotion' && pdfToCreate['hasProofs'] !== 'si') loader.show(false);
    }

    return {};
  }

  const handleRequestCreate = async () => {
    const requiredFields = templateHere['type'] === 'notification' ? 
      ['expedient_template_id', 'html', 'user_id', 'email_template_id'] :
      templateHere['type'] === 'promotion' && templateHere['hasProofs'] !== 'si' ?
      ['expedient_template_id', 'html', 'user_id', 'amount_copies', 'amount_signatures', 'hasProofs'] :
      templateHere['type'] === 'promotion' && templateHere['hasProofs'] === 'si' ?
      ['expedient_template_id', 'html', 'user_id', 'amount_copies', 'amount_signatures', 'hasProofs', 'evidence_file']  :
      ['expedient_template_id', 'html', 'user_id']  

    let pdfToCreate = {
      expedient_template_id: templateHere.id || null,
      html: templateHere.body || '',
      user_id: userId_03 || null,
      comment: templateHere.comments || ''
    };

    if (templateHere['type'] === 'promotion') {
      pdfToCreate = {
        ...pdfToCreate, 
        ...templateHere
      };
    }

    if(templateHere['type'] === 'agreement') {
      pdfToCreate = {
        ...pdfToCreate,
        ...templateHere,
      };
    }

    const responseValidation = fieldsValidation(requiredFields,pdfToCreate);
    
    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
      NotificationManager.error('Faltan campos requeridos, revisa los campos marcados en rojo');
      return responseValidation;
    } else {
      loader.show(true);
      if (templateHere['type'] === 'notification') {
        await insertToExpedientNotificationTemplate();
      } else {
        await requestCreatePDFTemplate(
          pdfToCreate, setShowModalTiny,
          setReloadData, reloadData,
          setErrors, reloadParentComponent, 
          setDataUploadEvidences
        );
      }
      if (pdfToCreate['type'] !== 'promotion' && pdfToCreate['hasProofs'] !== 'si') loader.show(false);
    }

    return {};
  };

  const insertToExpedientNotificationTemplate = async () => {
    await getExpedientStakeHolders(expedientId, setUsers);
    setShowModalTiny(false);
    setShowUsers(true);
  };

  const handleCloseModalStakeHolders = () => {
    setShowModalTiny(true);
    setShowUsers(false);
  };

  const onClickTemplateBadge = (...args) => {
    setIsAnElectronicNotificationToTrueWhenIsATemplatesTypeNotifications();
    setElectronicNotificationState(electronicNotificationInitialState);
    setDataTemplateModal(...args);
  }

  const handleRequestInsertNotificationTemplate = async () => {
    loader.show(true);
    const pdfToCreate = {
      expedient_template_id: templateHere.id || null,
      html: templateHere.body || '',
      user_id: userId_03 || null,
      comment: templateHere.comments || '',
      email_template_id: templateHere.email_template_id 
    };
    const dataNotificationEmail = {
      expedient_id: expedientId,
      notification_template_id: templateHere.id,
      emails: users
    };
    await requestCreatePDFTemplateOther(
      pdfToCreate, 
      setErrors,
      dataNotificationEmail,
      reloadParentComponent,
      reloadDataTemplates
    );
    loader.show(false);
  };

  const renderTemplatesByType = () => {
    if (templatesType === 'Notifications' && templatesNotification.length > 0) return templatesNotification.map( (tem, i) => (
      <div 
        key={i} 
        style={{
          display:'flex',
          marginBottom:'5px',
          overflow: 'hidden',

        }}
      >
        <Badge 
          key={tem['id']} 
          variant="secondary" 
          className="badge-templates-presave"
          onClick={() => onClickTemplateBadge(tem, true)}
          onContextMenu={(e) => showMenuDeleteTemplate(e, tem)}
        >
          {tem['alias']}
        </Badge>
        <div 
          className={
            templateIdDelete === tem['id'] ? 'delete-template-presave-wrapper' : 'd-n'
          }
        >
          <div
            className='trash-button-template'
            title='Borrar plantilla'
            id='delete-template-button'
            onClick={() => setOpenDeleteTemplate(true)}
          >
            <i
              id='delete-template-button'
              className='ti-trash'
            />
          </div>
        </div>
      </div>
    ))
    else if (templatesType === 'Agreements' && templatesAgreement.length > 0) return templatesAgreement.map( (tem, i) => (
      <div 
        key={i} 
        style={{
          display:'flex',
          marginBottom:'5px',
          overflow: 'hidden',

        }}
      >
        <Badge 
          key={tem['id']} 
          variant="secondary" 
          className="badge-templates-presave"
          onClick={() => setDataTemplateModal(tem)}
          onContextMenu={(e) => showMenuDeleteTemplate(e, tem)}
        >
          {tem['alias']}
        </Badge>
        <div 
          className={
            templateIdDelete === tem['id'] ? 'delete-template-presave-wrapper' : 'd-n'
          }
        >
          <div
            className='trash-button-template'
            title='Borrar plantilla'
            id='delete-template-button'
            onClick={() => setOpenDeleteTemplate(true)}
          >
            <i
              id='delete-template-button'
              className='ti-trash'
            />
          </div>
        </div>
      </div>
    ))
    else if (templatesType === 'Emails' && templatesEmail.length > 0) return templatesEmail.map( (tem, i) => (
      <div 
        key={i} 
        style={{
          display:'flex',
          marginBottom:'5px',
          overflow: 'hidden',

        }}
      >
        <Badge 
          key={tem['id']} 
          variant="secondary" 
          className="badge-templates-presave"
          onClick={() => setDataTemplateModal(tem)}
          onContextMenu={(e) => showMenuDeleteTemplate(e, tem)}
        >
          {tem['alias']}
        </Badge>
        <div 
          className={
            templateIdDelete === tem['id'] ? 'delete-template-presave-wrapper' : 'd-n'
          }
        >
          <div
            className='trash-button-template'
            title='Borrar plantilla'
            id='delete-template-button'
            onClick={() => setOpenDeleteTemplate(true)}
          >
            <i
              id='delete-template-button'
              className='ti-trash'
            />
          </div>
        </div>
      </div>
    ))
    else if (templatesType === 'Judgments' && templatesJudgment.length > 0) return templatesJudgment.map( (tem, i) => (
      <div 
        key={i} 
        style={{
          display:'flex',
          marginBottom:'5px',
          overflow: 'hidden',

        }}
      >
        <Badge 
          key={tem['id']} 
          variant="secondary" 
          className="badge-templates-presave"
          onClick={() => setDataTemplateModal(tem)}
          onContextMenu={(e) => showMenuDeleteTemplate(e, tem)}
        >
          {tem['alias']}
        </Badge>
        <div 
          className={
            templateIdDelete === tem['id'] ? 'delete-template-presave-wrapper' : 'd-n'
          }
        >
          <div
            className='trash-button-template'
            title='Borrar plantilla'
            id='delete-template-button'
            onClick={() => setOpenDeleteTemplate(true)}
          >
            <i
              id='delete-template-button'
              className='ti-trash'
            />
          </div>
        </div>
      </div>
    ))
    else return (
      <h6>No existen registros que mostrar...</h6>
    );
  };

  const renderTitleConfirmModal = (documentType) => {
    switch (documentType) {
      case 'notification':
        return `¿Desea agregar notificación al último acuerdo del ${getLabelFromDictionary('global', 'lowerMainName')}?`;
  
      case 'promotion':
        return `¿Desea agregar promoción al ${getLabelFromDictionary('global', 'lowerMainName')}?`;
  
      default:
       return `¿Deseas publicar el documento en el boletín?`;
    }
  }
  
  const handleRequestPrintTemplate = async (documentKind = null) => {
    loader.show(true);

    const areThereTemplateImagesWithCorsError = await imagesWithCorsErrorState.getAreThereTemplateImagesWithCorsError(templateHere.body);
    if (areThereTemplateImagesWithCorsError) {
      loader.show(false);
      return
    };

    const { authentication_token_03 } = Cookies.get();
    let formData = new FormData();
    formData.append('user_id', userId_03);
    formData.append('template_id', templateHere['id']);
    formData.append('content', templateHere['body']);
    if (documentKind) { formData.append('document_kind', documentKind); }
    
    await fetch(`${process.env.REACT_APP_URL_API_LARAVEL}/update_download_content_expedient_template`, { 
      method:'POST',
      body: formData,
      headers: {
        Authorization: authentication_token_03
      } 
    }).then(response => response.blob().then(blob => {
      if (blob && blob['type'] && blob['type'] === 'application/pdf') {
        let url = URL.createObjectURL(blob);
        var wnd = window.open(url);
        if (wnd) wnd.print()
        else {
          NotificationManager.warning(
            'Por favor habilita el permiso de abrir ventanas emergentes en este sitio para poder imprimir el documento',
            '¡Necesitamos tu permiso!',
            8000
          );
        }
        setTimeout(() => URL.revokeObjectURL(url), 500);
      } else {
        NotificationManager.error(
          'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
          '¡Que mal!',
          5000
        );
      }
    })).catch(err => {
      NotificationManager.error(
        'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
        '¡Que mal!',
        5000
      );;
    });
    loader.show(false);
  };

  const isExpedientJudgmentActive = (Boolean(dataExpedient) && Boolean(dataExpedient.judgment_active));

  const handlePrintTemplateClick = () => {
    if (templateHere.type === 'agreement') {
      handleRequestPrintTemplate('private');
      return
    }
    handleRequestPrintTemplate();
  }

  return (
    <>
      <ListGroup horizontal className={renderTemplates ? '' : 'd-n'}>
        { canUseAgreementTemplate ? 
          <ListGroup.Item 
            style={{ width:'25%', cursor: 'pointer' }}
            onClick={() => setTemplatesType('Agreements')}
            active={templatesType === 'Agreements'}
          >
            Proyectos de acuerdos guardados:</ListGroup.Item> : '' }
        { canUseNotificationsTemplate ? 
          <ListGroup.Item 
            style={{ width:'25%', cursor: 'pointer' }}
            onClick={() => setTemplatesType('Notifications')}
            active={templatesType === 'Notifications'}
          >
            Proyectos de notificaciones guardados:</ListGroup.Item> : '' }
        { Boolean(canUseJudgmentsTemplate && isExpedientJudgmentActive) ?
          <ListGroup.Item 
            style={{ width:'25%', cursor: 'pointer' }}
            onClick={() => setTemplatesType('Judgments')}
            active={templatesType === 'Judgments'}
          >
            Proyectos de sentencias guardados:</ListGroup.Item> : '' }
        { canUseEmailsTemplate ? 
          <ListGroup.Item 
            style={{ width:'25%', cursor: 'pointer' }}
            onClick={() => setTemplatesType('Emails')}
            active={templatesType === 'Emails'}
          >
            Proyectos de correos guardados:</ListGroup.Item> : '' }
      </ListGroup>
      <div className={renderTemplates && templatesType !== '' ? 'render-templates-by-type' : 'd-n'}>
        {renderTemplatesByType()}
      </div>

      <ModalComponentPortal
        header={`Plantilla guardada de tipo ${
          templateHere['type'] === 'agreement' ? 'acuerdo:' : 
          templateHere['type'] === 'notification' ? 'notificación:' :
          templateHere['type'] === 'judgment' ? 'sentencia:' :
          templateHere['type'] === 'promotion' ? 'promoción:' :
          'correo electrónico:'}`
        }
        body={tinyEditor}
        footer={(    
          <>
            <Button 
              variant="secondary" 
              onClick={() => setShowModalTiny(false)}
            >
              Cerrar
          </Button>
            {
              templateHere["type"] !== "email" && canSaveTemplates == false &&
                <TextTooltip text={'Firma electrónica'}>
                  <Button
                    variant="warning"
                    onClick={onSignTemplateClick}
                  >
                    <i className="ti-pencil-alt" />
                  </Button>
                </TextTooltip>
            }

            {
              templateHere["type"] !== "email" && canSaveTemplates ? 
                <TextTooltip text={'Guardar'}>
                  <Button
                    variant="info"
                    onClick={() => setShowConfirmUploadModal(true)}
                  >
                    <i className="fas fa-file-upload" />
                  </Button>
                </TextTooltip>
              : ""
            }

            <TextTooltip text={'Guardar'}>
              <Button
                variant="success"
                onClick={onSaveTamplateClick}
              >
                <i className="fas fa-save" />
              </Button>
            </TextTooltip>
            { 
              templateHere["type"] === "judgment" ?
                <TextTooltip text={'Imprimir'} placement={'right'}>
                  <Dropdown>
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                      <i className="fas fa-print" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleRequestPrintTemplate('public')}
                      >
                        Publico
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleRequestPrintTemplate('private')}
                      >
                        Privado
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </TextTooltip>
              :
                <TextTooltip text={'Imprimir'}>
                  <Button
                    variant="dark"
                    onClick={handlePrintTemplateClick}
                  >
                    <i className="fas fa-print" />
                  </Button>
                </TextTooltip>
              }
          </>
        )}
        isModalShowing={showModalTiny}
        onHide={onCloseTemplatesPresavedModal}
      />

      {
        isElectronicSignatureModalOpen
        &&
        <ElectronicSignatureProcessProvider key={currentTemplateId} isOnlyOneSignaturePerUserActivated={true}>
          <ElectronicSignatureTemplatesModal
            htmlOfDocumentToSign={templateHere.body}
            expedientTemplateId={currentTemplateId}
            templateName={templateHere.filename}
            ExpedientId={expedientId}
            setReloadTemplatesData={setReloadData}
            onCloseTemplateModal={onCloseTemplatesPresavedModal}
            reloadExpedientDetailsComponent={reloadExpedientDetailsComponent}
            userId={templateHere.user_id}
            electronicNotificationState={electronicNotificationState}
            setShowModalTiny={setShowModalTiny}
            templateData={templateHere.email_template_id ? templateHere : getObjectWithoutEmailTemplateId(templateHere)}
            dataExpedient={dataExpedient}
            emailTemplateId={templateHere.email_template_id}
          />
        </ElectronicSignatureProcessProvider>
      }

      <ModalComponent
        header='¿Estás seguro de eliminar este elemento?'
        body={bodyDeleteTemplate}
        footer={(    
          <>
            <Button 
              variant="secondary" 
              onClick={() => setOpenDeleteTemplate(false)}
            >
              Cancelar
            </Button>
            <Button 
              variant="primary" 
              onClick={() => handleRequestDelete()}
              id='delete-template-button'
            >
              Eliminar
            </Button>
          </>
        )}
        show={openDeleteTemplate}
        canClose={true}
        onHide={setOpenDeleteTemplate}
      />

      <ModalComponent
        header='Notificar vía correo electrónico:'
        body={bodyNotifyEmail(
          users, setUsers, newUser, setNewUser
        )}
        footer={footerNotifyEmail(
          handleCloseModalStakeHolders,
          handleRequestInsertNotificationTemplate
        )}
        show={showUsers}
        canClose={true}
        onHide={handleCloseModalStakeHolders}
        scroll={true}
        dialogClassName="modal-notify-email"
      />

      <Modal 
        show={openComments} 
        onHide={() => handleCloseComments()} 
        animation={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Historial de comentarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table 
            className="table table-collapsed" 
            style={{ 
              border: '1px solid #a2a2a2',
              marginTop: '10px'
            }}>
            <tbody>
              <tr>
                <th className="text-center button-see-evidence-documents">Creado por:</th>
                <th className="text-center button-see-evidence-documents">Comentario:</th>
                <th className="text-center button-see-evidence-documents">Fecha:</th>
              </tr>
              {	
                comments.map( ( comment, index ) => (
                  <tr key={index}>
                    <td style={{paddingTop:"15px", maxWidth:"150px"}} className="text-center">
                      <small>{comment['user']}</small>
                    </td>
                    <td style={{paddingTop:"15px", maxWidth:"150px"}} className="text-justify">
                      {comment['comment']}
                    </td>
                    <td style={{paddingTop:"15px"}} className="text-center">
                      <small>{comment['createdAt']}</small>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseComments()}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        title={'Publicar documento'}
        bodyText={renderTitleConfirmModal(templateHere['type'])}
        confirmCallback={async () => {
          const responseCreate = await handleRequestCreate()
          if (!_.isEmpty(responseCreate)) {
            setShowModalTiny(true);
          }
          setShowConfirmModal(false);
        }}
        cancelCallback={() => {
          setShowConfirmModal(false);
          setShowModalTiny(true);
        }}
      />

      {
        showConfirmUploadModal
        &&
        <ConfirmModal
          showModal={showConfirmUploadModal}
          setShowModal={setShowConfirmUploadModal}
          title={'Guardar documento'}
          bodyText={'El documento será cargado al expediente y no se podrá modificar'}
          confirmCallback={async () => {
            await handleUsingTemplate();
          }}
          cancelCallback={() => 
            setShowConfirmUploadModal(false)}
        />
      }


      <AgreementWithoutPromotionModal
        onCancelClick={() => {
          shouldTemplateBeSigned.current = false;
          setIsAgreementWithoutPromotionModalShowing(false);
        }}
        onAcceptClick={async () => {
          if (shouldTemplateBeSigned.current) {
            signTemplate();
            shouldTemplateBeSigned.current = false;
          } else {
            await handleRequestSave();
          }
          setIsAgreementWithoutPromotionModalShowing(false);
        }}
        onOutsideClick={() => {
          shouldTemplateBeSigned.current = false;
          setIsAgreementWithoutPromotionModalShowing(false);
        }}
        isModalShowing={isAgreementWithoutPromotionModalShowing}
      />
    </>
  );
};

export default TemplatesPresaved;
