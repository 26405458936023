import React from "react";
import { Grid } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { getSpecialPermissions } from "../../../../services/getSpecialPermissions";
import { controlPanelTabNames } from "../constants/controlPanelTabNameList";

const TabMenu = ({ activeTabName, history }) => {

  const specialPermissions = getSpecialPermissions();

  const {
    expirationTabName,
    pendingSignatureTabName,
  } = controlPanelTabNames;

  const handleTabOnClick = (active) => {
    history.push(`/control_panel/${active}`);
  }

  return (
    <Grid fluid={true} className="principal-nav">
      <Grid>
        <ul className="nav nav-tabs">
          {
            specialPermissions.expiration_panel
            &&
            <li className="nav-item">
              <Link
                to="#"
                onClick={() => handleTabOnClick(expirationTabName)}
                className={
                  "nav-link " +
                  (activeTabName === expirationTabName ? "active" : "")
                }>
                Vencimiento
              </Link>
            </li>
          }

          {
            specialPermissions.pending_signatures_panel
            &&
            <li className="nav-item">
              <Link
                to="#"
                onClick={() => handleTabOnClick(pendingSignatureTabName)}
                className={
                  "nav-link " +
                  (activeTabName === pendingSignatureTabName ? "active" : "")
                }>
                Pendiente de firma
              </Link>
            </li>
          }
        </ul>
      </Grid>
    </Grid>
  )
};

export default TabMenu;
