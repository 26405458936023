import React from 'react';
import { Card, Container } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
// import EditAgreementModal from '../../../../modals/agreements/EditAgreementModal';
import moment from 'moment';
// import { deleteAgreement } from '../../../api/agreements';
// import styles from "../../../bulletin_results/BulletinResults.module.css";

const Agreements = ({ agreements }) => {
  // const [show, setShow] = useState(false);
  // const [agreementInfo, setAgreementInfo] = useState(null);

  // const handleEditAgreement = async (e, agreement) => {
  //   e.preventDefault();
  //   setAgreementInfo(agreement);
  //   setShow(true);
  // }

  // const handleDeleteAgreement = async (e, id) => {
  //   e.preventDefault();

  //   const confirmation = window.confirm('¿Estas seguro que deseas eliminar este acuerdo?');
  //   if (confirmation) {
  //     await deleteAgreement(id);
  //     window.location.reload();
  //   }
  // }

  const formatDate = (expedientDate) => {
    return moment(expedientDate).locale('es').format('LL');
  }

  return (
    <>
      <Card>
        <Card.Header>
          <h5>Acuerdos</h5>
        </Card.Header>
        <Card.Body>
          <Container>
            <table>
              <thead>
                <tr>
                  <th>Fecha del Acuerdo</th>
                  <th>Fecha de Promoción</th>
                  <th>Clasificación</th>
                  <th>Sintesis del Acuerdo</th>
                  {/* <th>Acciones</th> */}
                </tr>
              </thead>
              <tbody>
                {agreements.map((agreement) => {
                  return (
                    <tr key={agreement.id}>
                      <td>{formatDate(agreement.attributes.agreementDate)}</td>
                      <td>{formatDate(agreement.attributes.promotionDate)}</td>
                      <td>{agreement.attributes.agreementClasification.label}</td>
                      <td>{agreement.attributes.agreementExtract}</td>
                      {/* <td>
                        <div className={styles.actionElements}>
                          <NavLink>
                            <FontAwesomeIcon
                              icon={faPencil}
                              className={styles.editIconStyles}
                              onClick={(e) => handleEditAgreement(e, agreement)}
                            />
                          </NavLink>

                          <NavLink>
                            <FontAwesomeIcon
                              icon={faTrash}
                              className={styles.deleteIconStyles}
                              onClick={(e) => handleDeleteAgreement(e, agreement.id)}
                            />
                          </NavLink>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        </Card.Body>
      </Card >

      {/* <EditAgreementModal show={show} setShow={setShow} agreement={agreementInfo} /> */}
    </>
  );
}

export default Agreements;
