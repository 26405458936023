export const getSelectOptionsFromCodeAndNameProperties = (list) => {
  if (!Array.isArray(list)) {
    return [];
  }
  const selectOptions = list.map((listElement) => {
    const emptySelectOption = { value: '', label: '' };

    if (typeof listElement !== 'object') {
      return emptySelectOption
    }

    const {
      code = emptySelectOption.value,
      name = emptySelectOption.label,
      ...listElementRestOfProperties
    } = listElement;

    const selectOptionElement = {
      ...listElementRestOfProperties,
      value: code,
      label: name,
    }

    return selectOptionElement;
  })

  return selectOptions;
}