import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import ToggleButton from '../../../layout/shared/ToggleButton';
import TextTooltip from '../../../tootip/TextTooltip';
import ElectronicSignatureTable from './componets/ElectronicSignatureTable';
import { useElectronicSignatureProcessContext } from './context/ElectronicSignatureProcessContext';
import CredentialsModal, { SignatureInputFile } from './credentialsModal/CredentialsModal';
import { showAlertForDocumentToBeSignedNotAllowed } from '../../../government_book/details_expedient/services/showAlertForDocumentToBeSignedNotAllowed';
import { getIsAValidMaxFileSize } from '../../../../services/assert/getIsAValidMaxFileSize';
import { getFileWithExtensionInLowercase } from '../../../government_book/details_expedient/services/getFileWithExtensionInLowercase';
import { getCanTypeOfDocumentBeSigned } from '../../../government_book/details_expedient/services/getCanTypeOfDocumentBeSigned';

const ElectronicSignatureProcess = ({
  onPreviousToAddFileToSign = async () => true,
  onCreatePkcs7 = () => { },
  disableSingleSignature = false,
  disableMultiSignature = false,
}) => {

  const {
    isElectronicSignatureModalSowing,
    setIsElectronicSignatureModalSowing,
    electronicSignaturesList,
    onCloseModal,
    onAddElectronicSignature,
    isMultipleSignature,
    setIsMultipleSignature,
    isReadyToCreatePkcs7,
    documentToSign,
    onAddDocumentToSign,
    onDelteElectronicSignatureById,
    singleElectronicSignature,
    isThereASignature,
    elementId,
  } = useElectronicSignatureProcessContext();

  useEffect(() => {
    if (disableSingleSignature) {
      setIsMultipleSignature(true);
    } else if (disableMultiSignature) {
      setIsMultipleSignature(false);
    }
  }, [disableSingleSignature, disableMultiSignature]);

  const doesMultipleSignatureToogleEnabled = (!disableSingleSignature && !disableMultiSignature);

  const handleOnAddDocumentToSign = async (files) => {
    const canTypeOfDocumentBeSigned = getCanTypeOfDocumentBeSigned(files[0].type);
    if (!canTypeOfDocumentBeSigned) {
      showAlertForDocumentToBeSignedNotAllowed();
      return
    }

    const isAValidMaxFileSize = getIsAValidMaxFileSize(files[0]);
    if (!isAValidMaxFileSize) {
      NotificationManager.error("El archivo que intenta cargar al sistema, supera los 18MB de tamaño permitido. Intente con un documento de menor tamaño");
      return
    }

    const fileToSave = getFileWithExtensionInLowercase(files[0]);
    
    const hasPreviousActivityBeenCompleted = await onPreviousToAddFileToSign([fileToSave]);
    if (!hasPreviousActivityBeenCompleted) {
      return
    }

    onAddDocumentToSign([fileToSave])
  }

  return (
    <div>
      {
        doesMultipleSignatureToogleEnabled
        &&
        <ToggleButton
          onChange={(event) => setIsMultipleSignature(event.target.checked)}
          name='isMultipleSignature'
          size={'medium'}
          isCheckedControlled={isMultipleSignature}
          id={`toggleButton-${elementId}`}
        >
          Habilitar multifirma
        </ToggleButton>
      }
      <Button
        variant="success"
        onClick={() => setIsElectronicSignatureModalSowing(true)}
        className={'mb-3'}
        style={{ marginTop: '20px' }}
      >
        {(isThereASignature && !isMultipleSignature) ? 'Cambiar Firma' : 'Agregar Firma'}
      </Button>

      {
        (Boolean(electronicSignaturesList.length && isMultipleSignature) || (!isMultipleSignature && Boolean(singleElectronicSignature)))
        &&
        <>
          <p className='m-0'>
            {isMultipleSignature ? 'Lista de Firmantes' : 'Firmante'}
          </p>
          <ElectronicSignatureTable
            electronicSignaturesList={electronicSignaturesList}
            singleElectronicSignature={singleElectronicSignature}
            isMultipleSignature={isMultipleSignature}
            onDelteElectronicSignatureById={onDelteElectronicSignatureById}
          />
        </>
      }

      <div>
        <TextTooltip text={'El nombre del documento a firmar sólo debe incluir números, espacios o letras sin acentos'}>
          <SignatureInputFile
            label="* Documento a firmar"
            setFile={handleOnAddDocumentToSign}
            id={`documentToSign`}
            accept={['.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX']}
            fileName={documentToSign ? documentToSign.name : ''}
            isMultiFiles
          />
        </TextTooltip>
      </div>
      <Button
        onClick={onCreatePkcs7}
        disabled={!isReadyToCreatePkcs7}
      >
        Firmar
      </Button>
      {
        isElectronicSignatureModalSowing
        &&
        <CredentialsModal
          isModalSowing={isElectronicSignatureModalSowing}
          onCloseModal={onCloseModal}
          addElectronicSignatureHandle={onAddElectronicSignature}
          isMultipleSignature={isMultipleSignature}
        />
      }
    </div>
  )
}

export default ElectronicSignatureProcess
