import React from 'react';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import WrapperFormikField from './WrapperFormikField';
import styles from './ErrorMessagesShared.module.css';

export default function SelectField({ onChange = () => { }, ...restOfProps }) {
  const { setFieldValue, values, errors } = useFormikContext();

  const handleChange = (value) => {
    onChange(value);
    setFieldValue(restOfProps.name, value);
  }

  return (
    <WrapperFormikField {...restOfProps}>
      {({ currentValue, errorField }) => (
        <>
          <Select
            {...restOfProps}
            onChange={handleChange}
            value={currentValue}
          />
          {errorField &&
            <div className={styles.feedbackErrorMessages}>{errorField}</div>
          }
        </>
      )}
    </WrapperFormikField>
  )
}
