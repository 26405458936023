import { useState } from "react";
import { searchExpedient } from "../api/expedients";

const useJuditialBulletin = () => {
  const [records, setRecords] = useState(null);
  const [expedient, setExpedient] = useState("");
  const [expedientId, setExpedientId] = useState(null);

  const getExpedientRecords = async (expedient, expedientType) => {
    try {
      localStorage.removeItem('parts');
      setExpedient(expedient);

      const dataResponse = await searchExpedient(expedient, expedientType);
      const expedientIdSource = dataResponse.data.length && dataResponse.data[0] ?
        dataResponse.data[0].id : null;

      setExpedientId(expedientIdSource);
      setRecords(dataResponse.data);
    } catch (error) {
      console.error(error);
      console.log("Ocurrio un error al consultar la fuente de expedientes");
    }
  }

  return {
    getExpedientRecords,
    records,
    setRecords,
    expedient,
    expedientId
  }
}

export default useJuditialBulletin;