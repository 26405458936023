/*
    Find information related to allowed catalogs.

    Parameters:
    data ([obj]): The name of the catalog to search for. The permitted catalogs are:
        - "centuries"
        - "civil status"
        - "genders"
        - "studylevel"
        - "nationalities"
        - "party request"
        - "sex"
        - "transparency"
        - "parttype"
    key (str): The key to search within the catalog.

    Returns:
    The item found corresponding to the key or null.

    The allowed catalogs are located in the following paths:
    - src/components/government_book/details_expedient/modals/partiesModal/assets/ages.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/civilStatus.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/genders.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/levelOfStudies.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/nationalities.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/partiesRequest.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/sex.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/transparency.js
    - src/components/government_book/details_expedient/modals/partiesModal/assets/typeParties.js
*/

export const findDataInCatalogsByKey = (data, key) => {
    return data.find( item => item.codigo === key);
}


/*
 */

export const validateObjectEqualFields = ( original = {}, toCompare = {} ) => {
    if (original === toCompare) return true;

    if (original && toCompare && typeof original === 'object' && typeof toCompare === 'object') {
        if (Object.keys(original).length !== Object.keys(toCompare).length) return false;

        for (const key in original) {
            if (!toCompare.hasOwnProperty(key)) return false;
            if (!validateObjectEqualFields(original[key], toCompare[key])) return false;
        }

        return true;
    }

    return false;
}
