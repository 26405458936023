import React from 'react'
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext';
import FormTitle from '../../FormTitle';
import { Col, Form, Row } from 'react-bootstrap';

const RepresentationAdmissibleProceeding = () => {

  const { representationAdmissibleProceeding: { fieldGroups } } = useIndicatorsContext();

  return (
    <div>
      <FormTitle>
        Representacion de las partes en los procedimentos admitidos
      </FormTitle>

      <Form>
        <Row style={{ gap: '30px' }}>
          {
            fieldGroups.map((fieldGroupElement, fieldGroupIndex) => (
              <Col lg key={fieldGroupIndex}>
                <Form.Label className="text-muted">
                  {fieldGroupElement.label}
                </Form.Label>
                {
                  fieldGroupElement.checkFields.map((check, checkIndex) => (
                    <Form.Group controlId={`${check.value}_${fieldGroupElement.value}`} key={checkIndex}>
                      <Form.Check
                        type="checkbox"
                        label={check.label}
                        id={`${check.value}_${fieldGroupElement.value}`}
                        name={check.value}
                        checked={fieldGroupElement.formMethods.formValues[check.value] === true}
                        onChange={fieldGroupElement.formMethods.onFormCheckChange}
                      />
                    </Form.Group>
                  ))
                }
              </Col>
            ))
          }
        </Row>
      </Form>
    </div>
  )
}

export default RepresentationAdmissibleProceeding