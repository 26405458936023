import { NotificationManager } from 'react-notifications';
import { Request } from '../../../../../classes/Request';
import { useLoaderContext } from '../../../../../layout/shared/loader_context';
import { useFilesPendingSignatureContext } from '../context/FilesPendingSignatureContext';
import { useDeletePendingDocumentToBeSignedContext } from '../context/DeletePendingDocumentToBeSignedContext';

const useDeleteFileModal = ({ onDeleteDocumentPendingToBeSigned = () => { } }) => {

  const {
    isDeleteDocumentModalOpen,
    toggleIsDeleteDocumentModalOpen,
  } = useDeletePendingDocumentToBeSignedContext();

  const { documentParameters } = useFilesPendingSignatureContext();

  const loader = useLoaderContext();

  const handleDeleteDocumentPendingToBeSigned = async () => {
    const request = new Request(
      `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/delete/${documentParameters.documentId}`,
      "delete",
      null,
      null
    );

    try {
      loader.show(true);
      const response = await request.executeRequest();
      if (!response || response.code !== 200) {
        return false
      }
      if (response.data && response.data.data && response.data.data.data && response.data.data.data.status) {
        NotificationManager.success('Documento eliminado satisfactoriamente');
        onDeleteDocumentPendingToBeSigned();
        toggleIsDeleteDocumentModalOpen();
        return true
      }
      NotificationManager.error('Ocurrió un error al intentar eliminar el documento');
      return false
    } catch (error) {
      NotificationManager.error('Ocurrió un error al intentar eliminar el documento');
      console.error(error);
      return false
    } finally {
      loader.show(false);
    }
  }

  return (
    {
      isDeleteDocumentModalOpen,
      toggleIsDeleteDocumentModalOpen,
      handleDeleteDocumentPendingToBeSigned,
    }
  )
}

export default useDeleteFileModal