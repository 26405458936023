export const regimeCatalog = [
    {
	'codigo': 'fisica',
	'etiqueta': 'Persona Física'
    },
    {
	'codigo': 'moral',
	'etiqueta': 'Persona Moral'
    },
    
];
