import React, { useState, useEffect, useContext } from "react";
import NavBar from "../../layout/shared/NavBar";
import { RemotePagination } from '../../helper_components/remote_pagination/RemotePagination';
import { Grid } from 'react-flexbox-grid';
import {
  initColumnsTablePendingExpedientsToReceive,
  getPendingExpedientsToReceive,
  receiveExpedient,
  getResponseOfSearch,
  getPendingsSelected,
  renderInputOfComments,
  removeInputComments,
  sendMultiReceive,
  disabledButtonsToReceiveTransfer
} from './table_pending_expedients_to_receive_logic';
import ExpedientSearcher from '../../helper_components/expedients_searcher/ExpedientSearcher';
import { Button } from 'react-bootstrap';
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import Footer from "../../layout/shared/Footer";
import { getLabelFromDictionary } from "../../../config/dictionary";
import ReceiveElectronicExpedientModal from "../shared/ReceiveElectronicExpedientModal";


const TablePendingExpedientsToReceive = (props) => {
  const [data, setData] = useState([]);
  const [currentExpedientParts, setCurrentExpedientParts] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const [searching, setSearching] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [checked, setChecked] = useState(false);
  const [showCheckedError, setShowCheckedError] = useState(false);
  const loader = useContext(LoaderContext);
  const [textNotData,setTextNotData] = useState("");
  const [showComments,setShowComments] = useState(false);
  const columns = initColumnsTablePendingExpedientsToReceive( showComments );
  const currentExpedientPartsColumns = currentExpedientParts;

  useEffect(() => {
    const pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];
    (async function () {
      await getPendingExpedientsToReceive(
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        handleShowModal,
        setTextNotData
      );

      setShowComments( pendingsAddedStorage.length > 0 ? true : false );
      renderInputOfComments(pendingsAddedStorage,commentsOnChange);
      disabledButtonsToReceiveTransfer(pendingsAddedStorage, true);
    })();
  }, []);

  const handleShowModal = async (governmentBook, expedientParts) => {
    setCurrentExpedientParts(expedientParts);
    setModalData(governmentBook);
    setShowModal(true);
  }

  const handleTableChange = async (type, { page, sizePerPage }) => {
    const pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];
    setPage(page);
    setSizePerPage(sizePerPage);

    await getPendingExpedientsToReceive(
      setData,
      setTotalProcedures,
      page,
      sizePerPage,
      handleShowModal,
      setTextNotData
    );
    setShowComments( pendingsAddedStorage.length > 0 ? true : false );
    renderInputOfComments(pendingsAddedStorage,commentsOnChange);
    disabledButtonsToReceiveTransfer(pendingsAddedStorage, true);
  }

  const searchExpedients = async () => {
    const pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];

    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {
      
      setSearching(true);
      loader.show(true);
      await getResponseOfSearch(
        search.replace(/^\s+|\s+$/gm,''),
        10,
        1,
        setData,
        setTotalProcedures,
        handleShowModal,
        setTextNotData
      );

      setShowComments( pendingsAddedStorage.length > 0 ? true : false );
      renderInputOfComments(pendingsAddedStorage,commentsOnChange);
      disabledButtonsToReceiveTransfer(pendingsAddedStorage, true);

      setSearching(false);
      loader.show(false);
    }
  };

  const cleanSearch = async () => {
    const pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];
    setSearch("");

    loader.show(true);
    setCleaning(true);
    await getPendingExpedientsToReceive(
      setData,
      setTotalProcedures,
      page,
      sizePerPage,
      handleShowModal, 
      setTextNotData
    );

    setShowComments( pendingsAddedStorage.length > 0 ? true : false );
    renderInputOfComments(pendingsAddedStorage,commentsOnChange);
    disabledButtonsToReceiveTransfer(pendingsAddedStorage, true);

    setCleaning(false);
    loader.show(false);
  };

  const handleClose = () => {
    setChecked(false);
    setShowModal(false);
  }

  const handleReceive = async () => {
    if (!checked) {
      setShowCheckedError(true);
      return;
    }

    receiveExpedient(modalData,setShowComments)
      .then(async (response) => {
        setChecked(false);
        setShowModal(false);
        await getPendingExpedientsToReceive(
          setData,
          setTotalProcedures,
          page,
          sizePerPage,
          handleShowModal,
          setTextNotData
        );
      })
      .catch((error) => {
        // do something.
      });
  };

  const commentsOnChange = (e, expedientId) => {

    let pendingsAdded = JSON.parse(localStorage.getItem('pendingsAdded'));

    if ( pendingsAdded ){
      const index = pendingsAdded.map( (element,i) => element.id).indexOf(expedientId);
      pendingsAdded[index]['comments'] = e.target.value;

      localStorage.setItem('pendingsAdded', JSON.stringify(pendingsAdded));
    }

  };

  const onSelectRow = (row, isSelect, rowIndex, e) => {
    let pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];

    if ( isSelect ) {

      const pendingToAdd = {
        id:row['id'],
        expedient_id:row['expedient_id'],
        actor:row['actor'],
        defendant:row['defendant'],
        expedient_number:row['expedient_number'],
        kind_judgment:row['kind_judgment'],
        via:row['via'],
        comments:'',
        sign: true
      };

      const newArray = pendingsAddedStorage.concat( [pendingToAdd] );
      setShowComments(newArray.length > 0 ? true : false);
      localStorage.setItem('pendingsAdded', JSON.stringify( newArray ));
      renderInputOfComments([pendingToAdd], commentsOnChange);
      disabledButtonsToReceiveTransfer(newArray, true);
    }
    else {

      const pendingFilter = pendingsAddedStorage.filter( element => element.id !== row['id'] );
      removeInputComments([row]);
      setShowComments(pendingFilter.length > 0 ? true : false);
      disabledButtonsToReceiveTransfer([row], false);
      localStorage.setItem('pendingsAdded', JSON.stringify( pendingFilter ));
    }
  }; 

  const onSelectAllRows = (isSelect, rows, e) => {
    let pendingsAddedStorage = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];

    if ( isSelect ) {

      const rowsFiltered = rows.map( ({id,expedient_id,expedient_number,actor,defendant,kind_judgment,via}) => {

        const object = {
          id: id,
          expedient_id: expedient_id,
          expedient_number: expedient_number,
          comments:"",
          actor: actor,
          defendant: defendant,
          kind_judgment: kind_judgment,
          via: via,
          sign: true
        }

        return object;
      });

      const newArray = pendingsAddedStorage.concat(rowsFiltered);
      setShowComments(newArray.length > 0 ? true : false);
      localStorage.setItem('pendingsAdded', JSON.stringify( newArray ));
      renderInputOfComments(rowsFiltered, commentsOnChange);
      disabledButtonsToReceiveTransfer(newArray, true);
    }
    else {

      const pendingsFiltereds = pendingsAddedStorage.filter(expedientPendingAdded => 
        !rows.find(expedientToRemove => 
          (expedientToRemove.id === expedientPendingAdded.id && expedientPendingAdded.expedient_number === expedientToRemove.expedient_number) 
        )
      );
      removeInputComments(rows);
      setShowComments(pendingsFiltereds.length > 0 ? true : false);
      localStorage.setItem('pendingsAdded', JSON.stringify(pendingsFiltereds));
      disabledButtonsToReceiveTransfer(rows, false);
    }
  };
    
  const sendMultipleReceive = async () => {
    const expedientsToReceive = JSON.parse(localStorage.getItem('pendingsAdded')) ? JSON.parse(localStorage.getItem('pendingsAdded')) : [];

    if ( expedientsToReceive.length > 0 ) {

      loader.show(true);
      await sendMultiReceive(
        expedientsToReceive,
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        handleShowModal,
        setTextNotData,
        setShowComments,
        props
      );
      loader.show(false);
    }
  };

  return (
    <>
      <NavBar {...props} />

      <h3 className="mT-20 w-100 text-center">{ getLabelFromDictionary( 'expedientsToReceived', 'label' ) }</h3>

      <div style={{ width: "85%", margin: "0px auto", display: "block" }}>
        <ExpedientSearcher
          placeholderSearch={`Escribe el número de ${getLabelFromDictionary('global', 'sigleMainName')}`}
          placeholderSearchButton="Buscar"
          placeholderCleanButton="Limpiar"
          helperContextual={`Escribe el número de ${getLabelFromDictionary('global', 'sigleMainName')} y haz clic en el botón buscar.`}
          executeSearch={searchExpedients}
          cleanSearch={cleanSearch}
          isSearching={searching}
          isCleaning={cleaning}
          setSearch={setSearch}
          search={search}
          classNameButtonsSearch={"buttonsSearchExpedientsList"}
        />


        <Button 
          onClick={ () => sendMultipleReceive() }
          disabled={ !showComments }
        >
          <i className="fas fa-check-circle" />
          {'  '} Recibir {getLabelFromDictionary('global', 'lowerMainName')} seleccionados
        </Button> 
      </div>

      <Grid fluid={true} className={ data.length === 0 ? "d-n" : "no-padding"} style={{ minHeight: '76vh', width: "85%", margin: "0px auto" }}>
        <section className="procedures-table-container mT-30">

          <RemotePagination
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalProcedures}
            onTableChange={handleTableChange}
            columns={columns}
            selectRow={{ 
              mode:'checkbox', 
              selectionHeaderRenderer: ({ mode, checked}) =>  ( 
                <> 
                  <input id='all' type={ mode } checked={checked} onChange={()=>{}} /> 
                  {'  '}<label>Todo</label> 
                </> 
              ),
              headerColumnStyle: { textAlign: 'center', lineHeight:'.5', minWidth:'70px', maxWidth:'70px' },
              onSelect: (row, isSelect, rowIndex, e) => onSelectRow(row, isSelect, rowIndex, e), 
              onSelectAll: (isSelect, rows, e) => onSelectAllRows(isSelect, rows, e),
              selectColumnStyle: { textAlign:'center' },
              selected: getPendingsSelected()
            }}
          />

        </section>
      </Grid>

      <h4 className={ data.length === 0 ? "text-center clearFloat padding-footer-not-data" : "d-n"}>{textNotData}</h4>

      <Footer/>

      <ReceiveElectronicExpedientModal
        showModal={showModal}
        modalData={modalData}
        setModalData={setModalData}
        checked={checked}
        setChecked={setChecked}
        handleClose={handleClose}
        handleReceive={handleReceive}
        showCheckedError={showCheckedError}
        expedientParts={currentExpedientPartsColumns}
      />

      <Loader/>
    </>
  );
}

export default TablePendingExpedientsToReceive;