import React, { useEffect, useState } from "react";
import { Grid } from "react-flexbox-grid";
import { RemotePagination } from "../../../helper_components/remote_pagination/RemotePagination";
import { getExpedientsList, initColumns } from "./expiration_list_logic";
import ModalComponent from "../../../helper_components/ModalComponent";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { useLoaderContext } from "../../../layout/shared/loader_context";
import Loader from "../../../layout/shared/Loader";
import { getLabelFromDictionary } from "../../../../config/dictionary";


const ExpirationList = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const columns = initColumns;
  const [showExpiration, setShowExpiration] = useState(false);
  const [documentsState, setDocumentsData] = useState([]);

  const { special_permissions_03 } = Cookies.get();
  const arrayPermissionsParsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
  const keysPermissions = arrayPermissionsParsed.length > 0 ? Object.keys(arrayPermissionsParsed[0]) : [];
  const canTurnForeign = keysPermissions.includes('foreign') ? '1' : '0';
  const loader = useLoaderContext();

  useEffect(() => {
    (async function () {
      loader.show(true);
      await getExpedientsList(
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        setShowExpiration,
        setDocumentsData,
        canTurnForeign
      );
      loader.show(false);
    }());
  }, []);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);

    loader.show(true);
    await getExpedientsList(
      setData,
      setTotalProcedures,
      page,
      sizePerPage,
      setShowExpiration,
      setDocumentsData,
      canTurnForeign
    );
    loader.show(false);
  };

  return (
    <>
          <h4 className={"text-center pt-5 mt-5 mb-0"}>
            {getLabelFromDictionary('global', 'mainName')} con término de vencimiento
          </h4>

          <Grid fluid={true} className={"no-padding"} style={{ width: "65%", margin: "0px auto" }}>
            <section className="procedures-table-container mT-30">
              <RemotePagination
                data={data}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalProcedures}
                onTableChange={handleTableChange}
                columns={columns}
              />
            </section>
          </Grid>

          <h4 className={data.length === 0 ? "text-center clearFloat padding-footer-not-data" : "d-n"}>No se han encontrado {getLabelFromDictionary('global', 'lowerMainName')}</h4>

          <ExpirationModal
            showExpiration={showExpiration}
            setShowExpiration={setShowExpiration}
            documentsState={documentsState}
            setDocumentsData={setDocumentsData}
          />

          <Loader />
    </>
  );
};

const ExpirationModal = ({ showExpiration, setShowExpiration, documentsState, setDocumentsData }) => {

  const bodyModalExpiration = (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
        {
          documentsState.map((doc, index) => (
            <tbody key={index}>
              <tr className="table-documents-modal">
                <th colSpan="4" className="text-center" title={doc['filename']}>{doc['filename']}</th>
              </tr>
              <tr>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Nombre</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Etiqueta</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Vence</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", backgroundColor: '#dfe6e9' }} className="text-center">Indicador</td>
              </tr>
              {
                doc['expirations'].map((expiration, i) => (
                  <tr key={i}>
                    <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={expiration['name']}>{expiration['name']}</td>
                    <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={expiration['alias']}>{expiration['alias']}</td>
                    <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={expiration['expired_at']}>{expiration['expired_at']}</td>
                    <td style={{ paddingTop: "5px" }} className="text-center"><span className={"dot-" + expiration['warning_flag']} /></td>
                  </tr>
                ))
              }
            </tbody>
          ))
        }
      </table>
    </div>
  );

  const footerModalExpiration = (
    <>
      <Button
        variant="secondary"
        onClick={() => setCloseModalExpiration()}
      >
        Cerrar
      </Button>
    </>
  );

  const setCloseModalExpiration = () => {
    setDocumentsData([]);
    setShowExpiration(false);
  }

  return (
    <ModalComponent
      show={showExpiration}
      header={"Vencimiento"}
      body={bodyModalExpiration}
      footer={footerModalExpiration}
      canClose={true}
      onHide={setCloseModalExpiration}
    />
  );
}

export default ExpirationList;