import React, { useEffect ,useState } from 'react';
import { RequestNewVersion } from '../../classes/RequestNewVersion';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
const Cover = ({expedientId}) => {
    const [cover, setCover] = useState('');
    async function getCover() {
        const request = new RequestNewVersion(
            `${process.env.REACT_APP_URL_API_LARAVEL}/coverpage/translate/${expedientId}`,
            "get",
            null,
            {}
          );
        try {
            const { code, response } = _.defaultTo(await request.executeRequest(), {});
            if (code !== 200) {
                NotificationManager.error('Error al obtener la portada');
                return;
            }
            if(_.has(response, 'coverTemplate')){
                setCover(response.coverTemplate);
            }
        } catch (error) {
            NotificationManager.error('Error al obtener la portada');
        }
    }
    
    useEffect(() => {
        getCover();
    }, []);

    return (
        <div className="cover">
            <div className="cover__content">
                <div dangerouslySetInnerHTML={{ __html: cover}} />
            </div>
        </div>
    );
}
export default Cover;