import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import {
  PartsTypeCatalog,
  PartsClasificationCatalog,
  PartsSexCatalog,
  PartsGenderCatalog,
  RegimesCatalog
} from '../../../utils/BulletinCatalogs';
import Select from 'react-select';
import _, { set } from 'lodash';
import { editPart, addPartToExpedient, getAllPartsByExpedient } from '../../juditial_bulletin/api/parts';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import styles from '../../juditial_bulletin/expedient_form/ExpedientForm.module.css';
import { Separator } from '../../shared/Separator';

const { v4: uuidv4 } = require("uuid");
const PartsFormModal = ({ show, setShow, parts, setParts, id = null, expedientId = null }) => {
  const [partNames, setPartNames] = useState('');
  const [paternalSurname, setPaternalSurname] = useState('');
  const [maternalSurname, setMaternalSurname] = useState('');
  const [partSex, setPartSex] = useState();
  const [partGender, setPartGender] = useState();
  const [partType, setPartType] = useState();
  const [partClasification, setPartClasification] = useState();
  const [partAlias, setPartAlias] = useState('');
  const [partAge, setPartAge] = useState('');
  const [partEmail, setPartEmail] = useState('');
  const [partPhone, setPartPhone] = useState('');
  const [partBirthday, setPartBirthday] = useState(null);
  const [partBusinessName, setPartBusinessName] = useState('');
  const [partRegime, setPartRegime] = useState();

  let partData = null;
  if (id && !expedientId) {
    partData = parts.find(part => part.id === id);
  } else if (expedientId && id) {
    const foundPart = parts.find(part => part.id === id);

    if (foundPart) {
      partData = foundPart.attributes;
    }
  }

  const handleInput = (e) => {
    const { value, name } = e.target;

    switch (name) {
      case 'partNames':
        setPartNames(value);
        break;

      case 'paternalSurname':
        setPaternalSurname(value);
        break;

      case 'maternalSurname':
        setMaternalSurname(value);
        break;

      case 'partAlias':
        setPartAlias(value);
        break;

      case 'partAge':
        setPartAge(value);
        break;

      case 'partEmail':
        setPartEmail(value);
        break;

      case 'partPhone':
        setPartPhone(value);
        break;

      case 'partBusinessName':
        setPartBusinessName(value);
        break;
    }
  }

  const handleChangeType = (value) => {
    setPartType(value);
  }

  const handleChangeSex = (value) => {
    setPartSex(value);
  }

  const handleChangeRegime = (value) => {
    setPartRegime(value);
  }

  const handleChangeGender = (value) => {
    setPartGender(value);
  }

  const handleChangeClasification = (value) => {
    setPartClasification(value);
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleChangeBirthday = (date) => {
    setPartBirthday(date);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!expedientId) {
      if (!id) {
        setParts(
          [
            ...parts,
            {
              id: uuidv4(),
              partNames,
              paternalSurname,
              maternalSurname,
              partType,
              partClasification,
              partSex,
              partGender,
              partAlias,
              partAge,
              partEmail,
              partPhone,
              partBirthday,
              partRegime,
              partBusinessName
            }
          ]
        );

        setPartClasification('');
        setPartType('');
        setPartNames('');
        setPaternalSurname('');
        setMaternalSurname('');
        setPartSex('');
        setPartGender('');
        setPartAlias('');
        setPartAge('');
        setPartEmail('');
        setPartPhone('');
        setPartBirthday(null);
        setPartRegime('');
        setPartBusinessName('');
        setShow(false);
      } else {
        setParts(parts.map((part) => {
          if (part.id === id) {
            return {
              ...part,
              partType: partType ? partType : part.partType,
              partClasification: partClasification ? partClasification : part.partClasification,
              partNames: partNames ? partNames : part.partNames,
              paternalSurname: paternalSurname ? paternalSurname : part.paternalSurname,
              maternalSurname: maternalSurname ? maternalSurname : part.maternalSurname,
              partSex: partSex ? partSex : part.partSex,
              partGender: partGender ? partGender : part.partGender,
              partAlias: partAlias ? partAlias : part.partAlias,
              partAge: partAge ? partAge : part.partAge,
              partEmail: partEmail ? partEmail : part.partEmail,
              partPhone: partPhone ? partPhone : part.partPhone,
              partBirthday: partBirthday ? partBirthday : part.partBirthday,
              partRegime: partRegime ? partRegime : part.partRegime,
              partBusinessName: partBusinessName ? partBusinessName : part.partBusinessName
            }
          }
          return part;
        }));
        setShow(false);
      }
    } else {
      if (id) {
        await editPart(id, {
          partType: partType || partData.partType,
          partClasification: partClasification || partData.partClasification,
          partSex: partSex || partData.partSex,
          partGender: partGender || partData.partGender,
          partAlias: partAlias || partData.partAlias,
          partAge: partAge || partData.partAge,
          partEmail: partEmail || partData.partEmail,
          partPhone: partPhone || partData.partPhone,
          partNames: partNames || partData.partNames,
          paternalSurname: paternalSurname || partData.paternalSurname,
          maternalSurname: maternalSurname || partData.maternalSurname,
          partBirthday: partBirthday || partData.partBirthday,
          partRegime: partRegime || partData.partRegime,
          partBusinessName: partBusinessName || partData.partBusinessName
        });

        setShow(false);
      } else {
        try {
          await addPartToExpedient({
            partType,
            partClasification,
            partNames: partNames,
            paternalSurname: paternalSurname,
            maternalSurname: maternalSurname,
            partSex,
            partGender,
            partAlias: partAlias,
            partAge: partAge,
            partEmail: partEmail,
            partPhone: partPhone,
            partBirthday,
            partRegime,
            partBusinessName
          }, expedientId);

        } catch (error) {
          console.log('Error al agregar la parte', error);
        }

        setShow(false);
      }

      setParts(
        await getAllPartsByExpedient(expedientId)
      );
    }
  }

  useEffect(() => {
    if (partData) {
      setPartRegime(partData.partRegime);
    }
  }, []);

  return (
    <Modal show={show} onHide={handleClose} size="lx">
      <Modal.Header>
        <Modal.Title>{id ? 'Edición de parte' : 'Alta de nueva parte'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tabs className={styles.tabMenus}>
          <Tab eventKey="personal_data" title="Datos personales">
            <div className={styles.containerRowFields}>
              <div className={styles.largeSize}>
                <Form.Group>
                  <Form.Label>Regimen fiscal</Form.Label>
                  <Select
                    id="partRegime"
                    name='partRegime'
                    placeholder="Elige una opción"
                    options={RegimesCatalog}
                    onChange={(value) => handleChangeRegime(value)}
                    defaultValue={_.get(partData, 'partRegime', partRegime)}
                  />

                </Form.Group>
              </div>
            </div>

            {partRegime && partRegime.value === 'moral' &&
              <>
                <div className={styles.containerRowFields}>
                  <div className={styles.largeSize}>
                    <Form.Group>
                      <Form.Label>* Razón social</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Escribe la razón social"
                        name='partBusinessName'
                        onChange={handleInput}
                        defaultValue={_.get(partData, 'partBusinessName', partBusinessName)}
                        className={styles.input}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className={styles.legalRepresentantTitle}>Información del Representante Legal</div>
                <Separator />
              </>
            }

            <div className={styles.containerRowFields}>
              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>* Nombre(s)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Escribe el/los nombre(s)"
                    name='partNames'
                    onChange={handleInput}
                    defaultValue={_.get(partData, 'partNames', partNames)}
                    className={styles.input}
                  />
                </Form.Group>
              </div>

              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>* Primer apellido</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Escribe el primer apellido"
                    name='paternalSurname'
                    onChange={handleInput}
                    defaultValue={_.get(partData, 'paternalSurname', paternalSurname)}
                    className={styles.input}
                  />
                </Form.Group>
              </div>
            </div>

            <div className={styles.containerRowFields}>
              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>Segundo apellido</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Escribe el segundo apellido"
                    name='maternalSurname'
                    onChange={handleInput}
                    defaultValue={_.get(partData, 'maternalSurname', maternalSurname)}
                    className={styles.input}
                  />
                </Form.Group>
              </div>

              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>Alias</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Indique un alias"
                    name='partAlias'
                    onChange={handleInput}
                    defaultValue={_.get(partData, 'partAlias', partAlias)}
                    className={styles.input}
                  />
                </Form.Group>
              </div>
            </div>

            <div className={styles.containerRowFields}>
              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>* Edad</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa la edad"
                    name='partAge'
                    onChange={handleInput}
                    defaultValue={_.get(partData, 'partAge', partAge)}
                    className={styles.input}
                  />
                </Form.Group>
              </div>

              <div className={styles.mediumSize}>
                <Form.Group>
                  <div className={styles.fieldLabel}>
                    * Fecha de nacimiento
                  </div>
                  <div className={styles.calendarFieldApparience}>
                    <div className={styles.datePickerElement}>
                      <DatePicker
                        name='partBirthday'
                        id='partBirthday'
                        className={styles.inputDate}
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete='off'
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={handleChangeBirthday}
                        selected={_.get(partData, 'partBirthday', partBirthday) ?
                          new Date(_.defaultTo(partBirthday, _.get(partData, 'partBirthday', partBirthday))) :
                          partBirthday
                        }
                      />
                    </div>
                    <div className={styles.iconPicker}>
                      <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
                    </div>
                  </div>
                  {/* <small>Fecha de nacimiento</small> */}
                </Form.Group>
              </div>
            </div>

            <div className={styles.containerRowFields}>
              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>Sexo</Form.Label>
                  <Select
                    id="partSex"
                    name='partSex'
                    placeholder="Elige una opción"
                    options={PartsSexCatalog}
                    onChange={(value) => handleChangeSex(value)}
                    defaultValue={_.get(partData, 'partSex', partSex)}
                  />
                </Form.Group>
              </div>

              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>Género</Form.Label>
                  <Select
                    id="partGender"
                    name='partGender'
                    placeholder="Elige una opción"
                    options={PartsGenderCatalog}
                    onChange={(value) => handleChangeGender(value)}
                    defaultValue={_.get(partData, 'partGender', partGender)}
                  />
                </Form.Group>
              </div>
            </div>

            <div className={styles.containerRowFields}>
              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>* Tipo de Parte</Form.Label>
                  <Select
                    id="partType"
                    name='partType'
                    placeholder="Elige una opción"
                    options={PartsTypeCatalog}
                    onChange={(value) => handleChangeType(value)}
                    defaultValue={_.get(partData, 'partType', partType)}
                  />
                </Form.Group>
              </div>

              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>* Clasificación</Form.Label>
                  <Select
                    id="clasification"
                    name='clasification'
                    placeholder="Elige una opción"
                    options={PartsClasificationCatalog}
                    onChange={(value) => handleChangeClasification(value)}
                    defaultValue={_.get(partData, 'partClasification', partClasification)}
                  />
                </Form.Group>
              </div>
            </div>
          </Tab>

          <Tab eventKey="contact_info" title="Datos de contacto">
            <div className={styles.containerRowFields}>
              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa el correo electrónico"
                    onChange={handleInput}
                    name='partEmail'
                    defaultValue={_.get(partData, 'partEmail', partEmail)}
                    className={styles.input}
                  />
                </Form.Group>
              </div>

              <div className={styles.mediumSize}>
                <Form.Group>
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa el teléfono"
                    onChange={handleInput}
                    name='partPhone'
                    defaultValue={_.get(partData, 'partPhone', partPhone)}
                    className={styles.input}
                  />
                </Form.Group>
              </div>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          Cerrar
        </Button>

        <Button
          onClick={handleSubmit}
        >
          {id ? 'Actualizar' : 'Guardar'}
        </Button>
      </Modal.Footer>
    </Modal >
  );
}

export default PartsFormModal;