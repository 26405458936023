import React from 'react';
import ModalComponent from '../ModalComponent';

const ConfirmModal = ({ showModal, setShowModal, title, bodyText, confirmCallback, cancelCallback }) => {
  return (
    <ModalComponent
      header={title}
      body={
        <section>
          <div className="container" style={{ height: '60vh' }}>
            <div className="row" style={{ padding: '10%' }}>
              <div className="col-12 text-center" style={{ padding:'6%', boxShadow: '2px 6px 13px gray' }}>
                <p>{bodyText}</p>
                <button
                  className="btn btn-success mr-2"
                  onClick={async () => {
                    await confirmCallback();
                  }}>Confirmar
                </button>
                <button
                  className="btn btn-info"
                  onClick={async () => {
                    setShowModal(false);
                    await cancelCallback();
                  }}>Cancelar
                </button>
              </div>
            </div>
          </div>
        </section>
      }
      footer={<></>}
      show={showModal}
      canClose={false}
      onHide={setShowModal}
      scroll={false}
      dialogClassName="modal-templates-preview"
    />
  );
}


export default ConfirmModal;
