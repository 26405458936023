import React, { forwardRef } from 'react';

export const CustomInputDate = forwardRef((props, ref = null) => (
    <input
        ref={ref}
        onClick={props.onClick}
        value={props.value}
        type='text'
        onInput={(e) => e.preventDefault()}
        className={ `form-control ${ props.error ? 'datepicker-error' : '' }` }
        onChange={() => { }}
        placeholder='Da clic y elige la fecha correspondiente'
    />
));

export default CustomInputDate;