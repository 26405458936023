import React from 'react';
import { useFormikContext } from 'formik';
import DatePicker from "react-datepicker";
import WrapperFormikField from './WrapperFormikField';
import styles from './ErrorMessagesShared.module.css';

export default function DateField({ onChange = () => { }, ...restOfProps }) {
  const { setFieldValue } = useFormikContext();

  const handleChange = (value) => {
    onChange(value);
    setFieldValue(restOfProps.name, value);
  }

  return (
    <WrapperFormikField {...restOfProps}>
      {({ currentValue, errorField }) => (
        <>
          <DatePicker
            {...restOfProps}
            onChange={handleChange}
            value={currentValue}
            selected={currentValue}
            autoComplete='off'
            onKeyDown={(e) => e.preventDefault()}
          />

          {errorField && <div className={styles.feedbackErrorMessages}>{errorField}</div>}
        </>
      )}
    </WrapperFormikField>
  )
}