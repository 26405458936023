import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import GenerateQRModal from '../../modals/GenerateQRModal';


const GenerateQRButton = ({ electronicExpedientId }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {
        showModal
        &&
        <GenerateQRModal
          electronicExpedientId={electronicExpedientId}
          isShowingModal={showModal}
          onCloseModal={() => setShowModal(false)}
        />
      }
      <Button
        onClick={() => setShowModal(true)}
        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
      >
        <i className="fas fa-sharp fa-solid fa-qrcode"></i>
        <span>Generar código QR</span>
      </Button>
    </>
  );
};

export default GenerateQRButton;