import React from 'react'
import { Button } from 'react-bootstrap'

const FooterModal = ({
  onCloseButtonClick = () => { },
}) => {
  return (
    <Button
      variant="secondary"
      onClick={onCloseButtonClick}
    >
      Cerrar
    </Button>
  )
}

export default FooterModal