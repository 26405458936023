import React from 'react'
import { Button } from 'react-bootstrap';
import ModalComponent from '../../../helper_components/ModalComponent';

const AgreementWithoutPromotionModal = ({ onCancelClick, onAcceptClick, onOutsideClick, isModalShowing }) => {
  return (
    <ModalComponent
      header={(<h3 className="text-center">Advertencia</h3>)}
      body={(
        <div>
          <h4 className="text-center">¿Desea agregar un acuerdo sin contestar una promoción?</h4>
        </div>
      )}
      footer={(
        <>
          <Button
            variant="secondary"
            onClick={onCancelClick}
          >
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={onAcceptClick}
          >
            Aceptar
          </Button>
        </>
      )}
      show={isModalShowing}
      canClose={true}
      onHide={onOutsideClick}
    />
  )
}

export default AgreementWithoutPromotionModal