import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getLabelFromDictionary } from "../../../config/dictionary";

const actionRoutes = [
  'Mis expedientes',
  'Expedientes enviados',
  "Buscar expediente",
  "Expedientes recibidos",
  "Expedientes por recibir",
  "Detalles de expediente"
];


const actionRoutesDictionary = [
  {
    name: 'Mis expedientes',
    key: 'myExpedients'
  },

  {
    name: 'Expedientes enviados',
    key: 'sendedExpedients'
  },

  {
    name: 'Buscar expediente',
    key: 'searchExpedients'
  },

  {
    name: 'Expedientes recibidos',
    key: 'resivedExpedients'
  },

  {
    name: 'Expedientes por recibir',
    key: 'pendingExpedients'
  },

  {
    name: 'Detalles de expediente',
    key: 'detailExpedient'
  },
];

const findKey = (name) => {
  const key = actionRoutesDictionary.find( action => action.name === name );
  return key ? key.key : '';
}

const Breadcrumb = props => {
  const {
    open,
    onHide,
    name,
    path
  } = props;
  const inExpedientDetails = props && props.location && props.location.state && path === '/expedient_details' ?
    true : false;
  const previousPathData = inExpedientDetails ? 
    props.location.state : { prevPath: null, labelPath: null };
  return(
    <div className="sm-10 offset-1 mT-10">
      <p>
      <Link to="/"> <i className="fas fa-home"/> Inicio</Link>
        { actionRoutes.includes(name) && 
          previousPathData.labelPath !== `Modificar ${getLabelFromDictionary('global', 'mainName')}` &&
          previousPathData.labelPath !== `Turnado externo de ${getLabelFromDictionary('global', 'mainName')}` ? 
          (<span>&nbsp;\&nbsp;
            <Button
              className="remove-hover-link"
              variant="link" 
              onClick={() => onHide( !open )}
            > 
              { getLabelFromDictionary( 'lateralMenu', 'expiedients' ) }
            </Button></span>) :
          ''
        }
        { inExpedientDetails ? 
          (<span>&nbsp;\&nbsp;
            <Link
              to={previousPathData.prevPath}
            > 
              { getLabelFromDictionary( 'expedientsMenu', findKey(previousPathData.labelPath) ) }
            </Link></span>) : ''
        }
        &nbsp;\&nbsp;<Button 
          className="remove-hover-link" 
          variant="link" 
        > 
          { getLabelFromDictionary( 'expedientsMenu', name) }
        </Button>
        
      </p>
    </div>
  );
};
export default Breadcrumb;