import React from "react";
import styles from "./SectionTitle.module.css";

const SectionTitle = ({ text }) => {
  return (
    <div className={styles.titleContent}>
      <h3>{text}</h3>
    </div>
  );
}

export default SectionTitle;