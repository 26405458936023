import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import styles from '../../styles/styles.css'
import { usePartiesContext } from '../../context/PartiesContext';

export const PartiesFormBread = () => {
    const { values, setValues } = usePartiesContext();
    const { errorsByForm = [] } = values;

    const handleChangeActiveForm = (formActive) => {
        setValues({
            ...values,
            formActive
        });
    }
    
    return (
        <ButtonGroup aria-label='Button Parties Form' style={{ fontSize: '10px', width: '100%', zIndex: 1 }} size='lg'>
            <Button 
                variant='secondary' 
                className='reset-size-font'
                active={values.formActive === 'personal_info'}
                onClick={() => handleChangeActiveForm('personal_info')}
                style={ errorsByForm.includes('personal_info') ? {  border: '2px solid red' } : {}}
            >
                {errorsByForm.includes('personal_info') && <span color='red' className='error-span'>* </span>}
                Datos Personales
            </Button>
            <Button 
                variant='secondary' 
                className='reset-size-font'
                active={values.formActive === 'contact_info'}
                onClick={() => handleChangeActiveForm('contact_info')}
                style={ errorsByForm.includes('contact') ? {  border: '2px solid red' } : {}}
            >
                {errorsByForm.includes('contact') && <span color='red' className='error-span'>* </span>}
                Datos de Contacto
            </Button>
            <Button
                variant='secondary' 
                className='reset-size-font'
                active={values.formActive === 'transparency_info'}
                onClick={() => handleChangeActiveForm('transparency_info')}
                style={ errorsByForm.includes('transparency') ? {  border: '2px solid red' } : {}}
            >
                {errorsByForm.includes('transparency') && <span color='red' className='error-span'>* </span>}
                Transparencia
            </Button>
        </ButtonGroup>
    );
}