import React from 'react';
import ModalComponent from '../../../../../../helper_components/ModalComponent';
import ElectronicSignatureProcessProvider, { useElectronicSignatureProcessContext } from '../../../../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext';
import BodyModal from './components/bodyModal/BodyModal';
import FooterModal from './components/footerModal/FooterModal';
import { useFilesPendingSignatureContext } from '../../context/FilesPendingSignatureContext';
import useElectronicSignatureModalState from './hooks/useElectronicSignatureModalState';
import SignatureConfirmationModal from './components/signatureConfirmationModal/SignatureConfirmationModal';
import NotifyEmailModal from '../../../../modals/NotifyEmailModal';

const ElectronicSignatureModal = ({
  isShowing = false,
  onCloseModalClick = () => { },
  handleOpenTransfer = () => { },
  getDocumentsByExpedient = () => { },
}) => {
  if (!isShowing) {
    return <></>
  }

  const { documentParameters } = useFilesPendingSignatureContext();

  return (
    <ElectronicSignatureProcessProvider key={documentParameters.documentId} isOnlyOneSignaturePerUserActivated={true}>
      <ElectronicSignatureModalComponent
        onCloseModalClick={onCloseModalClick}
        handleOpenTransfer={handleOpenTransfer}
        getDocumentsByExpedient={getDocumentsByExpedient}
      />
    </ElectronicSignatureProcessProvider>
  )
}

const ElectronicSignatureModalComponent = ({
  onCloseModalClick = () => { },
  handleOpenTransfer = () => { },
  getDocumentsByExpedient = () => { },
}) => {

  const {
    expedientId,
    isNotifyEmailModalShowing,
    handleHideNotifyEmailModal,
  } = useFilesPendingSignatureContext();

  const {
    handleSaveSignatureAndTransfer,
    isElectronicSignatureTableShowing,
    handleSendNotifyEmailButtonClick,
    handleSignDocumentClick,
    isOnlyOneSignaturePerUserActivated,
    handleCloseSignatureConfirmationModal,
    handleOpenSignatureConfirmationModal,
    isSignatureConfirmationModalShowing,
  } = useElectronicSignatureModalState({
    onCloseModalClick,
    handleOpenTransfer,
    getDocumentsByExpedient,
  });

  const {
    electronicSignaturesRecentlyAddedList,
  } = useElectronicSignatureProcessContext();

  const canPerformAnAction = Boolean(electronicSignaturesRecentlyAddedList.length);

  const bodyModal = (
    <BodyModal
      isOnlyOneSignaturePerUserActivated={isOnlyOneSignaturePerUserActivated}
      isElectronicSignatureTableShowing={isElectronicSignatureTableShowing}
    />
  );

  const footerModal = (
    <FooterModal
      isTransferExpedinetButtonDisabled={!canPerformAnAction}
      isSignDocumentButtonDisabled={!canPerformAnAction}
      onTransferExpedinetClick={handleSaveSignatureAndTransfer}
      onSignDocumentClick={handleOpenSignatureConfirmationModal}
    />
  );

  return (
    <>
      <ModalComponent
        header='Firmar Documento'
        body={bodyModal}
        footer={footerModal}
        show={true}
        canClose={true}
        onHide={onCloseModalClick}
        scroll={true}
        dialogClassName="electronic-signature-modal"
      />

      <SignatureConfirmationModal
        onConfirmClick={handleSignDocumentClick}
        onCloseModalClick={handleCloseSignatureConfirmationModal}
        isModalShowing={isSignatureConfirmationModalShowing}
      />

      {
        isNotifyEmailModalShowing
        &&
        <NotifyEmailModal
          expedientId={expedientId}
          showModal={isNotifyEmailModalShowing}
          handleOnCancel={handleHideNotifyEmailModal}
          onAcceptClick={handleSendNotifyEmailButtonClick}
        />
      }
    </>
  )
}

export default ElectronicSignatureModal