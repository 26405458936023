export const levelOfStudies = [
    {
        'codigo': 'preescolar',
        'etiqueta': 'Preescolar'
    },
    {
        'codigo': 'primaria',
        'etiqueta': 'Primaria'
    },
    {
        'codigo': 'secundaria',
        'etiqueta': 'Secundaria'
    },
    {
        'codigo': 'bachillerato',
        'etiqueta': 'Bachillerato'
    },
    {
        'codigo': 'tecnico _superior',
        'etiqueta': 'Técnico Superior Universitario'
    },
    {
        'codigo': 'licenciatura',
        'etiqueta': 'Licenciatura '
    },
    {
        'codigo': 'maestria',
        'etiqueta': 'Maestría'
    },
    {
        'codigo': 'especializacion',
        'etiqueta': 'Especialización '
    },
    {
        'codigo': 'doctorado',
        'etiqueta': 'Doctorado'
    }
];