export const initialAgeOptions = [
  {
    label: '0 años',
    value: 0,
  },
  {
    label: '1 año',
    value: 1,
  },
  {
    label: '2 años',
    value: 2,
  },
  {
    label: '3 años',
    value: 3,
  },
  {
    label: '4 años',
    value: 4,
  },
  {
    label: '5 años',
    value: 5,
  },
  {
    label: '6 años',
    value: 6,
  },
  {
    label: '7 años',
    value: 7,
  },
  {
    label: '8 años',
    value: 8,
  },
  {
    label: '9 años',
    value: 9,
  },
  {
    label: '10 años',
    value: 10,
  },
  {
    label: '11 años',
    value: 11,
  },
  {
    label: '12 años',
    value: 12,
  },
  {
    label: '13 años',
    value: 13,
  },
  {
    label: '14 años',
    value: 14,
  },
  {
    label: '15 años',
    value: 15,
  },
  {
    label: '16 años',
    value: 16,
  },
  {
    label: '17 años',
    value: 17,
  },
  {
    label: '18 años',
    value: 18,
  }
]