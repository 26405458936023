import _ from "lodash";
import { RequestNewVersion } from "../../components/classes/RequestNewVersion";

export const handleRequest = async (...requestArguments) => {
  try {
    const request = new RequestNewVersion(...requestArguments);
    const requestResponse = await request.executeRequest();
    const responseCode = _.get(requestResponse, 'code');
    const isASuccessfulResponse = responseCode === 200;

    if (!isASuccessfulResponse) {
      const errorMessage = _.get(requestResponse, 'response.message', 'Error Without error message');
      throw new Error(errorMessage);
    }

    return requestResponse

  } catch (error) {
    throw error
  }
}