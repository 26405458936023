import _ from "lodash";
import { RequestNewVersion } from "../../../components/classes/RequestNewVersion";

export const onSaveSignatureToTheListOfSignatures = async (signature, documentId) => {
  const saveSignatureRequestURL = `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/multiple_signers/${documentId}`;
  const saveSignatureRequest = new RequestNewVersion(saveSignatureRequestURL, "post", null, signature);

  try {
    const saveSignatureResponse = await saveSignatureRequest.executeRequest();
    const saveSignatureResponseCode = _.get(saveSignatureResponse, 'code');
    const isSaveSignatureResponseSuccessful = saveSignatureResponseCode === 200;

    if (!isSaveSignatureResponseSuccessful) {
      throw new Error('No se pudo agregar la firma al documento');
    }

    return saveSignatureResponse

  } catch (error) {
    throw error;
  }
}