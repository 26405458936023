import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import ModalMultiFile from './ModalMultiFile';
import { bodyMultiFile, bodySeeFile, footerMultiFile, footerSeeFile } from './multifile_logic';
import { NotificationManager } from 'react-notifications';
import { fieldsValidation } from '../../government_book/my_expedients/expedients_list_logic';
import { saveFileServer } from '../../dynamic_form/dynamic_form_logic';
import LoaderContext from '../../layout/shared/loader_context';
import { getListWithADotAtBeginningOfEachElement } from '../../../services/dataFormatting/getListWithADotAtBeginningOfEachElement';

const MultiFile = ({ 
  el, onObservationHandler, disableField, onChangeHandler, history
}) => {
  const [open, setOpen] = useState(false);
  const [openSee, setOpenSee] = useState(false);
  const [fileState, setFileState] = useState({});
  const [errors, setErrors] = useState({});
  const [listOfFiles, setListOfFiles] = useState([]);
  const [seeFile, setSeeFile] = useState({});
  const loader = useContext(LoaderContext);
  const fileTypeToAccept = useMemo(() => getListWithADotAtBeginningOfEachElement(el.extra_attributes.allowed_files), [el.extra_attributes.allowed_files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [...fileTypeToAccept],
    onDrop: acceptedFiles => {
      acceptedFiles.forEach((file) => {
        let type = "";
        if (!file['type'].includes('image')) {
          Object.assign(file, {
            preview: process.env.PUBLIC_URL + '/img/document.svg'
          });
          type = "documento";
        }
        else {
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          });
          type = "imagen";
        }
        delete errors['file'];
        setFileState({ ...fileState, file: file, type: type });
      });
    }
  });

  useEffect(() => {
    if (el.value && el.value.value && el.value.value.length > 0) {
      setListOfFiles(el.value.value);
      onChangeHandler(
        el.value.value,
        el.name,
        'multifile'
      );
    }
  }, []);

  const handleOpenModal = () => {
    setFileState({});
    setErrors({});
    setOpen(true);
  };

  const handleCloseModal = () => {
    if (
      fileState && 
      fileState.file && 
      fileState.file.preview
    ) URL.revokeObjectURL(fileState.file.preview);
    setOpen(false);
  };

  const addFileToTable = (fileToAdd) => {
    const requiredFields = ['file_type', 'alias', 'file'];
    const responseValidation = fieldsValidation(requiredFields, fileState);

    if (typeof responseValidation === "object") {
      NotificationManager.error("Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo");
      setErrors(responseValidation);
    }
    else uploadFile(fileToAdd);
  };

  const uploadFile = async (fileToAdd) => {
    const { file } = fileToAdd;
    const reader = new FileReader();
    let newArr = [...listOfFiles];

    reader.onload = async event => {
      loader.show(true);
      const fileSaved = await saveFileServer(
        event.target.result,
        history.location.state.params.stage,
        el
      );
      loader.show(false);
      if (!fileSaved) {
        return
      }
      fileToAdd = { ...fileSaved, ...fileToAdd };
      newArr.push(fileToAdd);
      setFileState({});
      onChangeHandler(newArr, el.name, 'multifile');
      setListOfFiles(newArr);
      URL.revokeObjectURL(fileToAdd['file']['preview']);
      delete fileToAdd['file'];
      setOpen(false);
    };

    reader.readAsDataURL(file);
  };

  const handleSeeFile = (dataFile) => {
    setSeeFile(dataFile);
    setOpenSee(true);
  };

  const handleCloseModalSee = () => {
    URL.revokeObjectURL(seeFile['previewURL']);
    setOpenSee(false);
  };

  const handleDeleteFile = (index) => {
    let newFiles = [...listOfFiles];
    newFiles.splice(index, 1);
    onChangeHandler(newFiles, el.name, 'multifile');
    setListOfFiles(newFiles);
  };

  const validateCloud = el => {
    // if (el.is_validable && props.mode === 3) {
    //   if (el.observations) {
    //     return { display: "inline-block" };
    //   } else {
    //     return { display: "none" };
    //   }
    // } else {
      return { display: "none" };
    // }
  };

  return (
    <Col
      className={el.visibility["status"] ? "" : " d-none"}
      xs={12}
      md={el.width}
    >
      <Col xs={12}>
        <Form.Label style={validateCloud(el)}>
          <b className={el.observations ? "text-danger" : ""}>{el.label}</b>
          <span
            className="observation-input-icon"
            onClick={() => onObservationHandler(el)}
          >
            <i className={"far fa-comment-dots" + (el.observations ? " text-danger" : "")} />
          </span>
        </Form.Label>

        <Form.Group
          style={disableField(el) ? { display: "none" } : null}
          controlId="formBasicMultiFile"
        >
          <Button
            block
            onClick={() => handleOpenModal()}
          >
            <i
              className="fas fa-plus-square"
              style={{ fontSize: '20px', marginTop: '4px', marginRight: '5px' }}
            />
            <b>{' Agregar ' + el.label + (el.requiredSignal ? ' *' : '')}</b>
          </Button>
          <small
            id={`small${el.name}`}
            className="form-text text-muted mt-2"
          >
            {el.contextual_help ? el.contextual_help : ""}
          </small>
          <span
            style={
              el.error
                ? { display: "inline", color: "red" }
                : { display: "none" }
            }
          >
            {el.error ? "* " + el.error[0] : ""}
          </span>
        </Form.Group>
      </Col>

      <Col xs={12} style={listOfFiles.length > 0 ? { maxHeight: '300px', overflow: 'scroll', marginTop: '-20px' } : { display:'none' }}>
        {
          listOfFiles.length > 0 ?
            <table
              className="table table-collapsed"
              style={{
                border: '1px solid #a2a2a2',
                marginTop: '10px'
              }}>
              <tbody>
                <tr style={{ backgroundColor: '#02cc98' }}>
                  <th className="text-center button-see-evidence-documents" style={{width:'180px'}}>Tipo de documento:</th>
                  <th className="text-center button-see-evidence-documents">Etiqueta:</th>
                  <th className="text-center button-see-evidence-documents" style={{width:'160px'}}>Archivo de tipo:</th>
                  <th className="text-center button-see-evidence-documents" style={{width:'150px'}}>Acciones:</th>
                </tr>
                {
                  listOfFiles.map((file, index) => (
                    <tr key={index}>
                      <td style={{ paddingTop: "15px", maxWidth: "150px" }} className="text-center">
                        {file['file_type']}
                      </td>
                      <td style={{ paddingTop: "15px", maxWidth: "150px" }} className="text-center">
                        {file['alias']}
                      </td>
                      <td style={{ paddingTop: "15px" }} className="text-center">
                        {file['type']}
                      </td>
                      <td style={{ paddingTop: "10px" }} className="text-center">
                        <Button
                          size="sm"
                          variant="danger"
                          title="Cancelar"
                          onClick={() => handleDeleteFile(index)}
                        >
                          <i className="ti-trash" />
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          title="Ver archivo"
                          className="mL-5"
                          onClick={() => handleSeeFile(file)}
                        >
                          <i className="ti-eye" />
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            : ""
        }
      </Col>

      <ModalMultiFile
        header={'Agrega tu archivo:'}
        body={bodyMultiFile(
          setFileState, fileState, getRootProps,
          errors, setErrors, getInputProps
        )}
        footer={footerMultiFile(handleCloseModal, addFileToTable, fileState)}
        show={open}
        canClose={true}
        onHide={() => handleCloseModal()}
        scroll={true}
      />

      <ModalMultiFile
        header={'Visualización de archivo:'}
        body={bodySeeFile(seeFile)}
        footer={footerSeeFile(handleCloseModalSee)}
        show={openSee}
        canClose={true}
        onHide={() => handleCloseModalSee()}
        scroll={true}
        dialogClassName={'modal-templates-preview'}
      />
    </Col>
  );
};

export default MultiFile;