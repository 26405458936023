import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import DigitalSignatureModal from "../digital_signature/digitalSignatureModal";
import { Col } from "react-flexbox-grid";
import { getNodeToInsertCodeTag } from "../../../services/tinymce/getNodeToInsertCodeTag";
import { TINYMCE_API_KEY } from "../../../utils/constants";

const TinyEditor = props => {
  const [text, setText] = useState(props.state[props.el.name]);
  const signatureModal = props.el.extra_attributes.signature ? (
    <Col className="p-5" xs={12}>
      <DigitalSignatureModal {...props} htmlIntoBase64={text} />
    </Col>
  ) : (
    ""
  );

  useEffect(() => {
    if (props.state[props.el.name]) {
      setText(props.state[props.el.name]);
    } else {
      setText(props.el.extra_attributes.document_information.data);
    }
  }, []);

  const handleEditorChange = e => {
    setText(e.target.getContent());
    props.onChangeHandler(e.target.getContent(), props.el.name, props.el.type);
  };

  return (
    <Col xs={12} className="p-10" style={{minHeight:"1250px"}}>
      {signatureModal}
      <Editor
        apiKey={TINYMCE_API_KEY}
        initialValue={text}
        value={text}
        init={{
          plugins: "link image code",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright alignjustify | codeButton",
            setup: function (editor) {
              editor.ui.registry.addButton('codeButton', {
                icon: 'sourcecode',
                onAction: function (_) {
                  const nodeToinsert = getNodeToInsertCodeTag(editor);
                  editor.insertContent(nodeToinsert);
                }
              });
            },
          language: "es_MX",
          language_url: "/langs/es_MX.js"
        }}
        onChange={handleEditorChange}
      />
    </Col>
  );
};

export default TinyEditor;
