// Metodos para firma FIREL
import { SelectPfx, OpenPfx, PKCS7creado, extFileUpload, SelectFileSign, message } from './cliente';
import { saveAs } from 'file-saver';
//Metodos para e-firma
import { SelectFileSigneFirma, SelectKEY, SelectCER, validateKeyPairs, FirmarEfirma, messageeFirma, PKCS7creadoeFirma, extFileUploadeFirma } from './eFirma';

export var nombPKSC7completo = '';  //esta variable se comparte para los metodos de firma
var pkcs7 = null;              //esta variable se comparte para los dos metodos de firma
var extensionDelArchivo = '';
var messageHex = '';

var PFXFileSelected = '';
var isValidPFX = false;

/** variable s para firma e-firma (sat) **/
var messageHexEFirma = '';
var extensionDelArchivoEFirma = '';
var FileKey = null;
var FileCer = null;
var isValidCer = false;

export const SeleccionarArchivo = async (file) => {
  const isValidFile = await SelectFileSign(file);
  if (isValidFile) {
    messageHex = message;
    extensionDelArchivo = extFileUpload;
    return true
  }
  return false
};

export const SeleccionarPfx = filePFX => {
  PFXFileSelected = filePFX;
  isValidPFX = SelectPfx(filePFX);

  if (isValidPFX) {
    PFXFileSelected = filePFX;
  }

};

//por aqui deberia estar una funcion para validar que haya un archivo pfx valido
// y que la contraseña no este vacia
//y que exista un doucmento pdf o word en l input file

export const firmar = async (password) => {
  try {
    const isCreatePKCS7 = await OpenPfx(PFXFileSelected, password);
    if (isCreatePKCS7) {
      pkcs7 = PKCS7creado;
      let fecha = new Date();
      nombPKSC7completo = "pkcs7_" + fecha.valueOf() + "." + extensionDelArchivo;
      // saveTextAsFile(pkcs7)
      return pkcs7
    }
  } catch {
    return false
  }
};

const saveTextAsFile = file => {
  //UNICAMENTE NOS SIRVE PARA HACER LA PRUEBA DE QUE SE ESTA GENERANDO CORRECTAMENTE EL PKCS7 LO GUARDA
  //COMO TEXTO PLANO
  var fecha = new Date();
  let textToWrite = file;
  let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });
  let fileNameToSaveAs = nombPKSC7completo;
  saveAs(textFileAsBlob, fileNameToSaveAs);
};

/*************** METODOS PARA FIRMA CON SAT  *****************/

export const SeleccionarArchivoEFirma = async (file) => {
  const isValidFile = await SelectFileSigneFirma(file);
  if (isValidFile) {
    messageHexEFirma = messageeFirma;
    extensionDelArchivoEFirma = extFileUploadeFirma;
    return true
  }
  return false
};

export const SeleccionarKey = (fileKey) => {

  if (SelectKEY(fileKey)) {
    FileKey = fileKey;
  }
};

export const SeleccionarCer = (fileCER) => {
  isValidCer = SelectCER(fileCER);
  if (isValidCer) {
    FileCer = fileCER;
  }
};

export const getIsAValidEFirmaPassword = (password) => {
  return new Promise((resolve) => {
    validateKeyPairs(password, function (e) {
      if (e.state == 0) {
        resolve(true)
      }
      else {
        resolve(false)
      }
    });
  });
}

export const firmarSat = async (password) => {
  if (password != '') {
    //Validacion de llaves
    let validacion = false;
    let privateKeyPem = '';
    validateKeyPairs(password, function (e) {

      if (e.state == 0) {
        validacion = true;
        privateKeyPem = e.privateKey;
      }
      else {
        validacion = false;
      }
    });
    if (validacion) {

      const isCreatePKCS7 = await FirmarEfirma(privateKeyPem);
      if (isCreatePKCS7) {
        pkcs7 = PKCS7creadoeFirma;
        const fecha = new Date();
        nombPKSC7completo = '';
        nombPKSC7completo = "pkcs7_" + fecha.valueOf() + "." + extensionDelArchivoEFirma;
        // saveTextAsFile(pkcs7)
        return pkcs7
      }
    }
  }
};

