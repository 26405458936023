import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ show, setShow, handlePublishExpedient }) => {

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Esta acción requiere confirmación</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        ¿Estas seguro que deseas publicar este registro en el boletín judicial?
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          Cerrar
        </Button>

        <Button onClick={handlePublishExpedient} >
          Continuar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;