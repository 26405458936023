import React from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import PartsRelatedToTheExpedientTable from "../details_expedient/components/partsRelatedToTheExpedientTable/PartsRelatedToTheExpedientTable";
import { procedureTypeOptions } from "../details_expedient/constants/procedureTypeOptions";
import { getLabelFromDictionary } from "../../../config/dictionary";

const ReceiveElectronicExpedientModal = ({
  showModal,
  modalData,
  setModalData,
  checked,
  setChecked,
  handleClose,
  handleReceive,
  showCheckedError,
  expedientParts = [],
}) => {
  if (!modalData) return null;

  return (
      <Modal show={showModal} size={'xl'} onHide={() => handleClose()}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
            <p><b>{getLabelFromDictionary('global', 'sigleMainName')}: </b>{modalData.expedient_number}</p>
            </div>
            <div className="col-6">
              <p><b>Emisor: </b> {modalData.sender}</p>
            </div>
          </div>
          <hr/>

          {
            Boolean(expedientParts.length)
            &&
            <Form.Group>
              <PartsRelatedToTheExpedientTable expedientParts={expedientParts} />
            </Form.Group>
          }

          <hr/>
          <div className="row">
            <div className="col-6">
            <p><b>Tipo de Juicio: </b>{modalData.kind_judgment}</p>
            </div>
            <div className="col-6">
              <p><b>Via: </b> {modalData.via}</p>
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-12">
              <div className="text-center"><b>Observaciones del emisor</b>
                <div className="text-justify">
                  <span>{modalData.sender_observations}</span>
                </div>

                <br/>

              </div>
              {
                modalData.procedure_type && (
              <div className="">
                <label>
                 <b>Tipo de trámite: {" "}</b>
                </label>
                  <span>{" "}{procedureTypeOptions.find((option) => option.value === +modalData.procedure_type).label}</span>
              </div>
                )
              }

              {
                modalData.apellant_name && (
              <div>
                <label>
                 <b>Apelante: {" "}</b>
                </label>
                <span>{" "}{modalData.apellant_name}</span>
              </div>
                )
              }
              <Form.Label>Observaciones:</Form.Label>
              <FormControl
                as="textarea"
                rows="4"
                className="form-control"
                style={{ fontSize: "1rem", width: "100%" }}
                onChange={(e) => setModalData({ ...modalData, observation: e.target.value })}
              />
              <div className="mt-2 text-center">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    checked={checked}
                    label={`Recibí el ${getLabelFromDictionary('global', 'singleLowerMainName')}`}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                </Form.Group>
                {showCheckedError
                  ? <Form.Label
                    className={"font-weight-bold mb-0 mt-2 text-danger"}
                  >
                    <span>Debe seleccionar la casilla para confirmar la recepción</span>
                  </Form.Label>
                  : ''}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleReceive}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default ReceiveElectronicExpedientModal;