import React from 'react'
import NavBar from '../../../../../navBar/NavBar';
import { navItems } from '../services/navItems';
import { useIndicatorsContext } from '../context/indicatorsContext/IndicatorsContext';

const BodyModal = () => {

  const { currentNav, setCurrentNav, CurrentForm } = useIndicatorsContext();

  return (
    <>
      <NavBar
        currentNav={currentNav}
        setCurrentNav={setCurrentNav}
        navItems={navItems}
      />
      <CurrentForm />
    </>
  )
}

export default BodyModal