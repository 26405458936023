import React, { useEffect, useRef } from 'react'
import ModalComponent from '../../../../../../helper_components/ModalComponent'
import Comment from './componets/comment/Comment'
import useCommentsModal from './hooks/useCommentsModal'
import Cookies from "js-cookie";

const { userId_03 } = Cookies.get();

const CommentsDocumentsPendingSignatureModal = () => {

  const {
    isCommentsModalOpen,
    toggleCommentsModal,
    comments,
    handleOnFormChange,
    textComment,
    handleAddComment,
    isATurnedExpedient,
  } = useCommentsModal();

  const modalHeader = (
    <div style={{
      position: 'relative',
      width: '100%',
    }}>
      <>
        Comentarios
      </>
      <button style={{
        position: 'absolute',
        right: '0px',
        top: '0px',
        width: '35px',
        height: '35px',
        lineHeight: '35px',
        border: 'none',
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: '#F95D5D',
        color: '#FFF',
      }}
        data-toggle="tooltip"
        title="Cerrar"
        onClick={toggleCommentsModal}
      >
        <i className="fas fa-times fa-lg"></i>
      </button>
    </div>
  )

  const footerModal = (
    <div style={{
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      gap: '10px',
      width: '100%',
    }}>
      <textarea
        style={{
          border: 'none',
          borderRadius: '20px',
          backgroundColor: '#E9E9E9',
          padding: '10px 15px',
          resize: 'none',
          flexGrow: '1',
        }}
        name="comment"
        id="commentTextArea"
        rows="2"
        placeholder={isATurnedExpedient ? 'Sólo lectura' : "Comentar"}
        onChange={handleOnFormChange}
        value={textComment}
        disabled={isATurnedExpedient}
      />
      <button
        className="btn btn-primary"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          textAlign: 'center',
        }}
        data-toggle="tooltip"
        title="Comentar"
        onClick={handleAddComment}
        disabled={isATurnedExpedient}
      >
        <i className="fas fa-paper-plane"></i>
      </button>
    </div >
  )

  return (
    <>
      <ModalComponent
        header={modalHeader}
        body={<BodyModal comments={comments} />}
        footer={footerModal}
        show={isCommentsModalOpen}
        canClose={true}
        onHide={toggleCommentsModal}
        scroll={true}
        dialogClassName="Comments-modal"
      />
    </>
  )
}

function BodyModal({ comments }) {
  const bodyModalRef = useRef(null)

  useEffect(() => {
    if (comments.length > 0) {
      const yScroll = bodyModalRef.current.scrollHeight;
      bodyModalRef.current.scroll(0, yScroll)
    }
  }, [comments])

  return (
    <div

      ref={bodyModalRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        maxHeight: '500px',
        overflowY: 'scroll',
        paddingRight: '16px',
        scrollBehavior: 'smooth',
      }}
    >
      {
        comments.length > 0
          ?
          comments.map((comment) => {
            return (
              <Comment
                key={comment.id}
                userName={comment.fullname}
                comment={comment.comment}
                date={comment.localDate}
                isOwnComment={parseInt(comment.user_id) === parseInt(userId_03)}
              />
            )
          })
          :
          'Aún no hay comentarios'
      }
    </div >
  )
}

export default CommentsDocumentsPendingSignatureModal;