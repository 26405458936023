export const ages = [
    {
        'codigo': '1',
        'etiqueta': '1'
    },
    {
        'codigo': '2',
        'etiqueta': '2'
    },
    {
        'codigo': '3',
        'etiqueta': '3'
    },
    {
        'codigo': '4',
        'etiqueta': '4'
    },
    {
        'codigo': '5',
        'etiqueta': '5'
    },
    {
        'codigo': '6',
        'etiqueta': '6'
    },
    {
        'codigo': '7',
        'etiqueta': '7'
    },
    {
        'codigo': '8',
        'etiqueta': '8'
    },
    {
        'codigo': '9',
        'etiqueta': '9'
    },
    {
        'codigo': '10',
        'etiqueta': '10'
    },
    {
        'codigo': '11',
        'etiqueta': '11'
    },
    {
        'codigo': '12',
        'etiqueta': '12'
    },
    {
        'codigo': '13',
        'etiqueta': '13'
    },
    {
        'codigo': '14',
        'etiqueta': '14'
    },
    {
        'codigo': '15',
        'etiqueta': '15'
    },
    {
        'codigo': '16',
        'etiqueta': '16'
    },
    {
        'codigo': '17',
        'etiqueta': '17'
    },
    {
        'codigo': '18',
        'etiqueta': '18'
    },
    {
        'codigo': '19',
        'etiqueta': '19'
    },
    {
        'codigo': '20',
        'etiqueta': '20'
    },
    {
        'codigo': '21',
        'etiqueta': '21'
    },
    {
        'codigo': '22',
        'etiqueta': '22'
    },
    {
        'codigo': '23',
        'etiqueta': '23'
    },
    {
        'codigo': '24',
        'etiqueta': '24'
    },
    {
        'codigo': '25',
        'etiqueta': '25'
    },
    {
        'codigo': '26',
        'etiqueta': '26'
    },
    {
        'codigo': '27',
        'etiqueta': '27'
    },
    {
        'codigo': '28',
        'etiqueta': '28'
    },
    {
        'codigo': '29',
        'etiqueta': '29'
    },
    {
        'codigo': '30',
        'etiqueta': '30'
    },
    {
        'codigo': '31',
        'etiqueta': '31'
    },
    {
        'codigo': '32',
        'etiqueta': '32'
    },
    {
        'codigo': '33',
        'etiqueta': '33'
    },
    {
        'codigo': '34',
        'etiqueta': '34'
    },
    {
        'codigo': '35',
        'etiqueta': '35'
    },
    {
        'codigo': '36',
        'etiqueta': '36'
    },
    {
        'codigo': '37',
        'etiqueta': '37'
    },
    {
        'codigo': '38',
        'etiqueta': '38'
    },
    {
        'codigo': '39',
        'etiqueta': '39'
    },
    {
        'codigo': '40',
        'etiqueta': '40'
    },
    {
        'codigo': '41',
        'etiqueta': '41'
    },
    {
        'codigo': '42',
        'etiqueta': '42'
    },
    {
        'codigo': '43',
        'etiqueta': '43'
    },
    {
        'codigo': '44',
        'etiqueta': '44'
    },
    {
        'codigo': '45',
        'etiqueta': '45'
    },
    {
        'codigo': '46',
        'etiqueta': '46'
    },
    {
        'codigo': '47',
        'etiqueta': '47'
    },
    {
        'codigo': '48',
        'etiqueta': '48'
    },
    {
        'codigo': '49',
        'etiqueta': '49'
    },
    {
        'codigo': '50',
        'etiqueta': '50'
    },
    {
        'codigo': '51',
        'etiqueta': '51'
    },
    {
        'codigo': '52',
        'etiqueta': '52'
    },
    {
        'codigo': '53',
        'etiqueta': '53'
    },
    {
        'codigo': '54',
        'etiqueta': '54'
    },
    {
        'codigo': '55',
        'etiqueta': '55'
    },
    {
        'codigo': '56',
        'etiqueta': '56'
    },
    {
        'codigo': '57',
        'etiqueta': '57'
    },
    {
        'codigo': '58',
        'etiqueta': '58'
    },
    {
        'codigo': '59',
        'etiqueta': '59'
    },
    {
        'codigo': '60',
        'etiqueta': '60'
    },
    {
        'codigo': '61',
        'etiqueta': '61'
    },
    {
        'codigo': '62',
        'etiqueta': '62'
    },
    {
        'codigo': '63',
        'etiqueta': '63'
    },
    {
        'codigo': '64',
        'etiqueta': '64'
    },
    {
        'codigo': '65',
        'etiqueta': '65'
    },
    {
        'codigo': '66',
        'etiqueta': '66'
    },
    {
        'codigo': '67',
        'etiqueta': '67'
    },
    {
        'codigo': '68',
        'etiqueta': '68'
    },
    {
        'codigo': '69',
        'etiqueta': '69'
    },
    {
        'codigo': '70',
        'etiqueta': '70'
    },
    {
        'codigo': '71',
        'etiqueta': '71'
    },
    {
        'codigo': '72',
        'etiqueta': '72'
    },
    {
        'codigo': '73',
        'etiqueta': '73'
    },
    {
        'codigo': '74',
        'etiqueta': '74'
    },
    {
        'codigo': '75',
        'etiqueta': '75'
    },
    {
        'codigo': '76',
        'etiqueta': '76'
    },
    {
        'codigo': '77',
        'etiqueta': '77'
    },
    {
        'codigo': '78',
        'etiqueta': '78'
    },
    {
        'codigo': '79',
        'etiqueta': '79'
    },
    {
        'codigo': '80',
        'etiqueta': '80'
    },
    {
        'codigo': '81',
        'etiqueta': '81'
    },
    {
        'codigo': '82',
        'etiqueta': '82'
    },
    {
        'codigo': '83',
        'etiqueta': '83'
    },
    {
        'codigo': '84',
        'etiqueta': '84'
    },
    {
        'codigo': '85',
        'etiqueta': '85'
    },
    {
        'codigo': '86',
        'etiqueta': '86'
    },
    {
        'codigo': '87',
        'etiqueta': '87'
    },
    {
        'codigo': '88',
        'etiqueta': '88'
    },
    {
        'codigo': '89',
        'etiqueta': '89'
    },
    {
        'codigo': '90',
        'etiqueta': '90'
    },
    {
        'codigo': '91',
        'etiqueta': '91'
    },
    {
        'codigo': '92',
        'etiqueta': '92'
    },
    {
        'codigo': '93',
        'etiqueta': '93'
    },
    {
        'codigo': '94',
        'etiqueta': '94'
    },
    {
        'codigo': '95',
        'etiqueta': '95'
    },
    {
        'codigo': '96',
        'etiqueta': '96'
    },
    {
        'codigo': '97',
        'etiqueta': '97'
    },
    {
        'codigo': '98',
        'etiqueta': '98'
    },
    {
        'codigo': '99',
        'etiqueta': '99'
    },
    {
        'codigo': '100',
        'etiqueta': '100'
    },
    {
        'codigo': '101',
        'etiqueta': '101'
    },
    {
        'codigo': '102',
        'etiqueta': '102'
    },
    {
        'codigo': '103',
        'etiqueta': '103'
    },
    {
        'codigo': '104',
        'etiqueta': '104'
    },
    {
        'codigo': '105',
        'etiqueta': '105'
    },
    {
        'codigo': '106',
        'etiqueta': '106'
    },
    {
        'codigo': '107',
        'etiqueta': '107'
    },
    {
        'codigo': '108',
        'etiqueta': '108'
    },
    {
        'codigo': '109',
        'etiqueta': '109'
    },
    {
        'codigo': '110',
        'etiqueta': '110'
    },
    {
        'codigo': '111',
        'etiqueta': '111'
    },
    {
        'codigo': '112',
        'etiqueta': '112'
    },
    {
        'codigo': '113',
        'etiqueta': '113'
    },
    {
        'codigo': '114',
        'etiqueta': '114'
    },
    {
        'codigo': '115',
        'etiqueta': '115'
    },
    {
        'codigo': '116',
        'etiqueta': '116'
    },
    {
        'codigo': '117',
        'etiqueta': '117'
    },
    {
        'codigo': '118',
        'etiqueta': '118'
    },
    {
        'codigo': '119',
        'etiqueta': '119'
    },
    {
        'codigo': '120',
        'etiqueta': '120'
    },
    {
        'codigo': '121',
        'etiqueta': '121'
    },
    {
        'codigo': '122',
        'etiqueta': '122'
    },
    {
        'codigo': '123',
        'etiqueta': '123'
    },
    {
        'codigo': '124',
        'etiqueta': '124'
    },
    {
        'codigo': '125',
        'etiqueta': '125'
    },
    {
        'codigo': '126',
        'etiqueta': '126'
    },
    {
        'codigo': '127',
        'etiqueta': '127'
    },
    {
        'codigo': '128',
        'etiqueta': '128'
    },
    {
        'codigo': '129',
        'etiqueta': '129'
    },
    {
        'codigo': '130',
        'etiqueta': '130'
    },
    {
        'codigo': '131',
        'etiqueta': '131'
    },
    {
        'codigo': '132',
        'etiqueta': '132'
    },
    {
        'codigo': '133',
        'etiqueta': '133'
    },
    {
        'codigo': '134',
        'etiqueta': '134'
    },
    {
        'codigo': '135',
        'etiqueta': '135'
    },
    {
        'codigo': '136',
        'etiqueta': '136'
    },
    {
        'codigo': '137',
        'etiqueta': '137'
    },
    {
        'codigo': '138',
        'etiqueta': '138'
    },
    {
        'codigo': '139',
        'etiqueta': '139'
    },
    {
        'codigo': '140',
        'etiqueta': '140'
    },
    {
        'codigo': '141',
        'etiqueta': '141'
    },
    {
        'codigo': '142',
        'etiqueta': '142'
    },
    {
        'codigo': '143',
        'etiqueta': '143'
    },
    {
        'codigo': '144',
        'etiqueta': '144'
    },
    {
        'codigo': '145',
        'etiqueta': '145'
    },
    {
        'codigo': '146',
        'etiqueta': '146'
    },
    {
        'codigo': '147',
        'etiqueta': '147'
    },
    {
        'codigo': '148',
        'etiqueta': '148'
    },
    {
        'codigo': '149',
        'etiqueta': '149'
    },
    {
        'codigo': '150',
        'etiqueta': '150'
    }
];