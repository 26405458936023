import React from 'react';
import ModalComponent from '../../../../helper_components/ModalComponent';
import FooterModal from './components/footerModal/FooterModal';
import { getLabelFromDictionary } from '../../../../../config/dictionary';

export const SupremeCourtOfJusticeReceiverConfirmationModal = ({
  isShowing = false,
  onConfirmClick = () => { },
  onCancelClick = () => { },
  ...restOfProps
}) => {

  return (
    <ModalComponent
      header="Confirmar acción"
      body={`¿Estás seguro de enviar el ${getLabelFromDictionary('global', 'singleLowerMainName')} a la Secretaría General del Supremo Tribunal de Justicia?`}
      footer={<FooterModal onCancelClick={onCancelClick} onConfirmClick={onConfirmClick} />}
      show={isShowing}
      canClose={true}
      onHide={onCancelClick}
      scroll={true}
      {...restOfProps}
    />
  )
}

export default SupremeCourtOfJusticeReceiverConfirmationModal