const getAgreementsByExpedient = async (expedientId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    }

    const allAgreementsByExpedientResponse =
      await fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}` +
        `/api/agreements?filters[expedientId][id][$eq]=${expedientId}`,
        requestOptions);

    const agreements = await allAgreementsByExpedientResponse.json();
    return agreements.data;
  }
  catch (error) {
    console.error(error);
    return null;
  }
}

const deleteAgreement = async (agreementId) => {
  try {
    const agreementResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}` + `/api/agreements/${agreementId}`,
      { method: 'DELETE' }
    );

    return await agreementResponse.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

const editAgreement = async (agreementId, agreement) => {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          agreementDate: agreement.agreement_date,
          promotionDate: agreement.promotion_date,
          agreementExtract: agreement.extract,
          agreementJudgeName: agreement.judge_name,
          agreementSecretaryName: agreement.secretary_name,
          agreementItems: agreement.category.map(item => item),
          agreementClasification: agreement.clasification,
          publicationDate: agreement.publication_date,
          reserveDate: agreement.reserve_date,
        }
      }),
    }

    const editResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/agreements/${agreementId}`, requestOptions
    );
    const updatedAgreement = await editResponse.json();
    return updatedAgreement;
  } catch (error) {
    console.error(error);
    console.log("Ocurrio un error al editar el acuerdo");
  }
}

const addAgreementToExpedient = (agreement, expedientId, setRecords) => {
  return fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/agreements`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
    },
    body: JSON.stringify({
      data: {
        agreementDate: agreement.agreement_date,
        promotionDate: agreement.promotion_date,
        agreementClasification: agreement.clasification,
        agreementItems: agreement.category.map(item => item),
        agreementExtract: agreement.extract,
        agreementSecretaryName: agreement.secretary_name,
        agreementJudgeName: agreement.judge_name,
        expedientId: expedientId,
        publicationDate: agreement.clasification.value !== 4 ? agreement.publication_date : null,
        reserveDate: agreement.clasification.value === 4 ? agreement.reserve_date : null
      }
    }),
  })
    .then(response => response.json())
    .then(() => {
      setRecords(null);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

export {
  getAgreementsByExpedient,
  // deleteAgreement,
  editAgreement,
  addAgreementToExpedient
};