import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {initForm, SignUp} from "./login_logic";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import NotificationAlertListContext from "../alertNotificationTable/notification_alert_context";
import { useSessionManagerContext } from "../../context/sessionManagerContext/sessionManagerContext";
import { backgroundImageStyles } from "../../inLineStyles/backgroundImageStyles/backgroundImageStyles";

const Login = props => {
    const [form, setForm] = useState(initForm);
    const Loader = useContext(LoaderContext);
    const notificationsContext = useContext(NotificationAlertListContext);
    const { handleSessionManager, tokenExpirationTimeRef } = useSessionManagerContext();

    const onChangeHandler = event => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        if (form.visible === true) {
            setTimeout(() => {
                setForm({
                    email: [form.email],
                    password: [form.password],
                    errors: [form.errors],
                    styleClassAlert: [form.styleClassAlert],
                    visible: false
                });
            }, 3000);
        }
    }, [form.visible]);

    const onSubmitFormHandler = async e => {
        Loader.show(true);
        e.preventDefault();
        await SignUp(form, props, notificationsContext, handleSessionManager, tokenExpirationTimeRef);
        Loader.show(false);
    };

    return (
        <Plain {...props} noHeader={true} noFooter={true}>
            <Container
                fluid={true}
                className="min-height-100vh bg-size-cover"
                style={backgroundImageStyles}
            >
                <Container>
                    <Row style={{minHeight: "100vh"}}>
                        <Col sm={12} md={{span: 10, offset: 1}} lg={{span: 8, offset: 2}} xl={{span: 6, offset: 3}}
                            className="mY-30">
                            <Card className="mY-40@sm+">
                                <Card.Header>
                                    <Container>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={{span: 10, offset: 1}}
                                                className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                                            >
                                                <h4 className="mb-0 subtitle text-center">Bienvenido a la plataforma</h4>
                                                <h3 className="mb-0 text-center">Portal del Poder Judicial del Estado de Jalisco, Portal del funcionario</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Header>
                                <Card.Body>
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={{span: 10, offset: 1}}>
                                                <p>
                                                    Inicia sesión proporcionando los datos solicitados a
                                                    continuación
                                                </p>

                                                <Form onSubmit={onSubmitFormHandler}>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>
                                                            <b>* Email:</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Correo electrónico"
                                                            required
                                                            autoComplete="off"
                                                            onChange={onChangeHandler}
                                                            name="email"
                                                            value={form.email}
                                                        />
                                                        <Form.Text className="text-muted">
                                                            Nunca compartas tu correo electronico con alguién
                                                            más.
                                                        </Form.Text>
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicPassword">
                                                        <Form.Label>
                                                            <b>* Contraseña:</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Password"
                                                            required
                                                            minLength="8"
                                                            autoComplete="off"
                                                            onChange={onChangeHandler}
                                                            value={form.password}
                                                            name="password"
                                                        />
                                                    </Form.Group>

                                                    <Button
                                                        variant="primary"
                                                        type="submit"
                                                        className="px-4 mT-20 btn-responsive"
                                                    >
                                                        Ingresar
                                                    </Button>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                                <Card.Footer>
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={{span: 10, offset: 1}} className="pY-10">
                                                ¿Olvidaste tu contraseña? Para recuperarla haz clic
                                                <b>
                                                    <Link to="/forgot_password"> aquí</Link>
                                                </b>
                                                .
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Plain>
    );
};

export default Login;
