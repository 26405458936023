import { useState } from 'react'
import { getMattersListRequest } from '../../services/requests/matter/matterRequests';
import { getSelectOptionsFromCodeAndNameProperties } from '../../services/dataFormatting/getSelectOptionsFromCodeAndNameProperties';

const useMattersList = () => {

  const [mattersList, setMattersList] = useState([]);

  const getMattersList = async () => {
    if (mattersList.length) {
      return mattersList;
    }

    const _mattersList = await getMattersListRequest();
    if (!Array.isArray(_mattersList)) {
      return mattersList;
    }

    const formattedMattersList = getSelectOptionsFromCodeAndNameProperties(_mattersList);

    setMattersList(formattedMattersList);
    return formattedMattersList;
  }

  return {
    getMattersList,
    mattersList,
  }
}

export default useMattersList