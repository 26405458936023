import _ from 'lodash';
import useFormValues from '../../../../../../hooks/useFormValues';
import { testSectionDefaultFormValues } from '../constants/defaultValues';

const useTestSection = () => {
  const testSectionFormMethods = useFormValues(testSectionDefaultFormValues);

  const formValues = {
    ...testSectionFormMethods.formValues,
  }

  const setInitialState = (initialState) => {
    const doesInitialStateExist = _.isObject(initialState);
    if (!doesInitialStateExist) {
      return
    }

    testSectionFormMethods.setFormValues({ ...testSectionFormMethods.formValues, ...initialState });
  }

  return {
    testSectionFormMethods,
    formValues,
    setInitialState,
  }
}

export default useTestSection