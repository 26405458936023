import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_API_KEY } from '../../utils/constants';
import { imagesDataimgFilter } from '../../services/tinymce/imagesDataimgFilter';
import { imagesUploadHandler } from '../../services/tinymce/imagesUploadHandler';
import { setupEditorHandler } from '../../services/tinymce/setupEditorHandler';
import TemplateImagesWithCorsErrorModal from './components/templateImagesWithCorsErrorModal/TemplateImagesWithCorsErrorModal';

const RichTextEditor = ({
  value = '',
  onChange = () => { },
  imagesWithCorsErrorState = null,
}) => {

  return (
    <>
      <Editor
        apiKey={TINYMCE_API_KEY}
        value={value}
        onGetContent={onChange}
        init={{
          plugins: 'link image code',
          toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | codeButton',
          setup: setupEditorHandler,
          language: "es_MX",
          language_url: "/langs/es_MX.js",
          images_dataimg_filter: imagesDataimgFilter,
          images_upload_handler: imagesUploadHandler,
        }}
      />

      {
        Boolean(imagesWithCorsErrorState)
        &&
        <TemplateImagesWithCorsErrorModal
          isModalShowing={imagesWithCorsErrorState.isTemplateImagesWithCorsErrorModalShowing}
          imageWithCorsErrorList={imagesWithCorsErrorState.templateImageWithCorsErrorList}
          onCloseModal={imagesWithCorsErrorState.handleCloseTemplateImagesWithCorsErrorModal}
        />
      }
    </>
  )
}

export default RichTextEditor