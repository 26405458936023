import React from 'react'
import ModalComponent from '../../../helper_components/ModalComponent'
import { Button } from 'react-bootstrap'

const SimpleSignatureAlert = ({
  isModalShowing,
  setIsModalShowing = () => { },
  onCancel = () => { },
  onContinue = () => { },
}) => {

  const body = (
    <h4>
      Tu documento será firmado con firma simple. ¿Estás seguro que deseas continuar?
    </h4>
  );

  const footer = (
    <>
      <Button onClick={onCancel} variant="secondary">
        Cancelar
      </Button>
      <Button onClick={onContinue} >
        Continuar
      </Button>
    </>
  );

  if (!isModalShowing) {
    return <></>
  }

  return (
    <ModalComponent
      header="Atención"
      body={body}
      footer={footer}
      show={isModalShowing}
      canClose={true}
      onHide={setIsModalShowing}
      dialogClassName="modal-document-to-be-signed-with-a-simple-signature-alert"
    />
  )
}

export default SimpleSignatureAlert