import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TextTooltip = ({ children, text, style = {}, className = '', ...restOfProps }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>} {...restOfProps}>
      <div className={`d-inline-block ${className}`} style={style}>
        {children}
      </div>
    </OverlayTrigger>
  )
}

export default TextTooltip