import _ from 'lodash';
import React, { createContext, useContext, useState } from 'react'

const TemplatesPresavedContext = createContext();
TemplatesPresavedContext.displayName = 'TemplatesPresavedContext';

const TemplatesPresavedContextProvider = (props) => {

  const contextValueFromProps = _.get(props, 'value', {});

  const [isElectronicSignatureModalOpen, setIsElectronicSignatureModalOpen] = useState(false);

  const onCloseElectronicSignatureModal = () => {
    setIsElectronicSignatureModalOpen(false);
  }

  const onOpenElectronicSignatureModal = () => {
    setIsElectronicSignatureModalOpen(true);
  }

  const contextValue = {
    isElectronicSignatureModalOpen,
    onCloseElectronicSignatureModal,
    setIsElectronicSignatureModalOpen,
    onOpenElectronicSignatureModal,
    ...contextValueFromProps,
  }

  return (
    <TemplatesPresavedContext.Provider {...props} value={contextValue} />
  )
}

export const useTemplatesPresavedContextContext = () => {
  const context = useContext(TemplatesPresavedContext)
  if (typeof context === 'undefined') {
    throw new Error('To use useTemplatesPresavedContextContext you must wrap inside a TemplatesPresavedContextProvider')
  }
  return context
}

export default TemplatesPresavedContextProvider