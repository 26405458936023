import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PartsList from '../parts/PartsList';
import PartsFormModal from '../../modals/parts/PartsFormModal';
import EditExpedientModal from '../../modals/EditExpedientModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil } from '@fortawesome/free-solid-svg-icons';
import { findExpedient } from '../../juditial_bulletin/api/expedients';
import { Button, NavLink } from 'react-bootstrap';
import { getAllPartsByExpedient } from '../../juditial_bulletin/api/parts';
import styles from './InfoExistentExpedient.module.css';

const InfoExistentExpedient = ({ records, showParts = false }) => {
  const [parts, setParts] = useState([]);
  const [show, setShow] = useState(false);
  const [expedientInfo, setExpedientInfo] = useState(records[0].attributes);
  const [showExpedient, setShowExpedient] = useState(false);

  const formatDate = (expedientDate) => {
    return moment(expedientDate).locale('es').format('LL');
  }

  useEffect(() => {
    (
      async () => {
        const data = await getAllPartsByExpedient(records[0].id);
        setParts(data);
      }
    )();
  }, []);

  const handleAddPartModal = (e) => {
    e.preventDefault();
    setShow(true);
  }

  const handleEditExpedient = async (e, id) => {
    e.preventDefault();

    const expedient = await findExpedient(id);
    setExpedientInfo(expedient);
    setShowExpedient(true);
  }

  return (
    <>
      <div className={styles.infoContainer}>
        <h5 className="text-center">Datos generales del Expediente</h5>
        <div className={styles.infoTableContainer}>
          {showParts &&
            <div className={styles.actionElements}>
              <NavLink>
                <FontAwesomeIcon
                  icon={faPencil}
                  className={styles.editIconStyles}
                  onClick={(e) => handleEditExpedient(e, records[0].id)}
                />
              </NavLink>
            </div>
          }

          <table className={styles.generalInfoForExpedient}>
            <tbody>
              <tr>
                <th>Número de expediente</th>
                <td>{expedientInfo.expedientNumber}</td>
              </tr>
              <tr>
                <th>Tipo de expediente</th>
                <td>{expedientInfo.expedientType ? expedientInfo.expedientType.label : ''}</td>
              </tr>
              <tr>
                <th>Fecha de registro</th>
                <td>{formatDate(expedientInfo.recordDate)}</td>
              </tr>
              <tr>
                <th>Juicio</th>
                <td>{expedientInfo.judgment ? expedientInfo.judgment.label : ''}</td>
              </tr>
              <tr>
                <th>Origen</th>
                <td>{expedientInfo.origin ? expedientInfo.origin.label : ''}</td>
              </tr>
              <tr>
                <th>Expediente externo</th>
                <td>{expedientInfo.externalExpedient ? expedientInfo.externalExpedient : 'No aplica'}</td>
              </tr>
              <tr>
                <th>Dependencia externa</th>
                <td>{expedientInfo.externalDependence ? expedientInfo.externalDependence : 'No aplica'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {showParts &&
        <div className={styles.partsContainer}>
          <div className={styles.addPartsButton}>
            <Button
              onClick={(e) => handleAddPartModal(e)}
              className="formulario-tareas__btn"
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={styles.actionIconPart}
              /> Agregar parte
            </Button>
          </div>

          {parts.length > 0 ?
            <div className={styles.partsListingContainer}>
              <h4 className={styles.partsTableTitle}>Partes del expediente</h4>
              <PartsList parts={parts} setParts={setParts} expedientId={records[0].id} />
            </div>
            :
            <div>Sin partes</div>
          }
        </div>
      }

      <PartsFormModal
        show={show}
        setShow={setShow}
        parts={parts}
        setParts={setParts}
        expedientId={records[0].id}
      />

      <EditExpedientModal
        show={showExpedient}
        setShow={setShowExpedient}
        expedient={expedientInfo}
        setExpedientInfo={setExpedientInfo}
      />
    </>
  );
}

export default InfoExistentExpedient;