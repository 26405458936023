import _ from "lodash";
import { RequestNewVersion } from "../../../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";

export const getJudicialPartiesList = async () => {
  const judicialPartiesListRequest = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/judicial_parties/get_list`,
    "get",
    null,
    {},
  );

  const { code, response } = _.defaultTo(await judicialPartiesListRequest.executeRequest(), {});

  if (code !== 200) {
    NotificationManager.error('Ocurrió un problema al tratar de obtener el listado de salas');
    return;
  }

  return _.get(response, 'courts', []);
}