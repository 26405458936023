import React from 'react'
import FormTitle from '../../FormTitle';
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext';
import { Form } from 'react-bootstrap';
import { testSectionDefaultFormValues } from '../../../constants/defaultValues';
import _ from 'lodash';

const TestComponent = () => {

  const { testSection } = useIndicatorsContext();

  const {
    testSectionFormMethods: {
      onFormChange,
      formValues,
    },
  } = testSection;

  return (
    <div>
      <FormTitle>
        Pruebas en procedimientos admitidos
      </FormTitle>

      <Form>
        <Form.Group controlId="offered">
          <Form.Label>
            Ofertadas
          </Form.Label>
          <Form.Control
            type="text"
            name='offered'
            onChange={onFormChange}
            value={_.defaultTo(formValues.offered, testSectionDefaultFormValues.offered)}
          />
        </Form.Group>

        <Form.Group controlId="admitted">
          <Form.Label>
            Admitidas
          </Form.Label>
          <Form.Control
            type="text"
            name='admitted'
            onChange={onFormChange}
            value={_.defaultTo(formValues.admitted, testSectionDefaultFormValues.admitted)}
          />
        </Form.Group>

        <Form.Group controlId="unburdened">
          <Form.Label>
            Desahogadas
          </Form.Label>
          <Form.Control
            type="text"
            name='unburdened'
            onChange={onFormChange}
            value={_.defaultTo(formValues.unburdened, testSectionDefaultFormValues.unburdened)}
          />
        </Form.Group>
      </Form>
    </div>
  )
}

export default TestComponent