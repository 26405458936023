import React from 'react'
import { Button } from 'react-bootstrap';
import ModalComponent from '../../../helper_components/ModalComponent'
import RenderPdfDocument from '../../../helper_components/RenderDocumentPdf'
import { usePreviewPdfModalContext } from '../context/previewPdfModalContext';

const PreviewPdfModal = () => {

  const {
    isPreviewPdfModalShowing,
    documentDataToPreview,
    hidePreviewPdfModal,
  } = usePreviewPdfModalContext();

  return (
    <ModalComponent
      header={'Documento a firmar'}
      body={<PreviewPdfBodyModal documentPath={documentDataToPreview.filePath} documentName={documentDataToPreview.fileName} />}
      footer={<Button variant="secondary" onClick={hidePreviewPdfModal}>Cerrar</Button>}
      show={isPreviewPdfModalShowing}
      canClose={true}
      onHide={hidePreviewPdfModal}
      size="lg"
    />
  )
}

const PreviewPdfBodyModal = ({ documentPath, documentName }) => {
  return (
    <div className='d-flex flex-column align-items-center'>
      <h4 className='text-center'>{documentName}</h4>
      <RenderPdfDocument
        doc={documentPath}
        currentDocument={documentPath}
      />
    </div>

  )
}

export default PreviewPdfModal