export const civilStatus = [
    {
        'codigo': 'soltero',
        'etiqueta': 'Soltero'
    },
    {
        'codigo': 'casado',
        'etiqueta': 'Casado'
    },
    {
        'codigo': 'divorciado',
        'etiqueta': 'Divorciado'
    },
    {
        'codigo': 'separado',
        'etiqueta': 'Separado en proceso judicial'
    },
    {
        'codigo': 'viudo',
        'etiqueta': 'Viudo'
    },
    {
        'codigo': 'concubinato',
        'etiqueta': 'Concubinato'
    }
];