import React from 'react'
import { Button } from 'react-bootstrap'

const FooterModal = ({
  onCloseButtonClick = () => { },
  onReplicasButtonClick = () => { },
}) => {

  return (
    <>
      <Button
        variant={'secondary'}
        onClick={onCloseButtonClick}
      >
        Cerrar
      </Button>
      <Button onClick={onReplicasButtonClick} >
        Confirmar
      </Button>
    </>
  )
}

export default FooterModal
