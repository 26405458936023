import { NotificationManager } from "react-notifications";

const { RequestNewVersion } = require("../../../classes/RequestNewVersion");

export const getDocuments = async (electronicExpedientId, setDocuments) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/get_all_documents_for_qr/${electronicExpedientId}`,
    "get",
    null,
    {}
  );

  const { code, response } = await request.executeRequest();

  if (code !== 200) {
    setDocuments([]);
    return;
  }

  const { documents } = response;
  setDocuments(documents);
};

export const fieldsValidation = (requiredFields, objectToSend) => {
  const fieldsObjectToSend = Object.keys(objectToSend);
  let missingFields = {};

  requiredFields.forEach((field, index) => {
    if (!fieldsObjectToSend.includes(field) || !objectToSend[field]) {
      missingFields[field] = ["Este campo es requerido"];
    }

    if (Array.isArray(objectToSend[field]) && objectToSend[field].length === 0) {
      missingFields[field] = ["Este campo es requerido"];
    }
  });

  if (Object.keys(missingFields).length > 0) {
    return missingFields;
  }

  return true;
};

export const copyBase64QrToClipboard = async (qr) => {
      const base64Response = await fetch(`data:image/png;base64,${qr}`);
      const blob = await base64Response.blob();
			await navigator.clipboard.write(
        [
          new window.ClipboardItem({ 'image/png': blob })
        ]
      );
      NotificationManager.success('QR copiado al portapapeles');
}