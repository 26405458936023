import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Card, Container, Form } from "react-bootstrap";
import NavBar from "../../layout/shared/NavBar";
import Select from "react-select";
import {
  getOptionsKindJudgment,
  getOptionsLegalWays,
  selectStylesDefault,
  fieldsValidation,
  selectStylesError,
  renderErrorsByInputName
} from "../my_expedients/expedients_list_logic";
import { saveExpedientEdited, getDataExpedientToEdit } from "./modify_expedients_logic";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import useLegalRepresentativeText from "../my_expedients/hooks/useLegalRepresentativeText";
import { regimeSelectorOptions } from "../../../services/dataFormatting/selectInput";
import { getLabelFromDictionary } from "../../../config/dictionary";
import Cookies from "js-cookie";
import { getDecodedJwt } from "../../../services/dataFormatting/getDecodedJwt";
import { getCrimes } from "../my_expedients/expedients_list_logic";
const customStyles = {
  ...selectStylesDefault,
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    width: '99%',
    margin: '0 auto'
  })
};

const EditExpedient = (props) => {
  const initValues = {
    expedient_number: '',
    actor_regime: '',
    actor_business_name: '',
    actor_name: '',
    actor_firstname: '',
    actor_secondname: '',
    actor_email: '',
    lawyer_actor_name: '',
    lawyer_actor_firstname: '',
    lawyer_actor_secondname: '',
    lawyer_actor_email: '',
    defendant_regime: '',
    defendant_business_name: '',
    defendant_name: '',
    defendant_firstname: '',
    defendant_secondname: '',
    defendant_email: '',
    lawyer_defendant_name: '',
    lawyer_defendant_firstname: '',
    lawyer_defendant_secondname: '',
    lawyer_defendant_email: '',
    kind_judgment: null,
    via: null,
    justification: ''
  };
  const expedientId = props.match.params.expedientId;
  const [kindJudgmentOptions, setKindJudgmentOptions] = useState([]);
  const [legalWaysOptions, setLegalWaysOptions] = useState([]);
  const [expedientToEdit, setExpedientToEdit] = useState(initValues);
  const [errors, setErrors] = useState({});
  const [disableFields, setDisableFields] = useState(false);
  const loader = useContext(LoaderContext);
  const textForLegalActorRepresentative = useLegalRepresentativeText(expedientToEdit.actor_regime);
  const textForLegalDefendantRepresentative = useLegalRepresentativeText(expedientToEdit.defendant_regime);
  const [crimesSelectorOptions, setCrimesSelectorOptions] = useState([]);
  useEffect(() => {
    (async function () {
      loader.show(true);
      const optionsKJ = await getOptionsKindJudgment();
      const optionsLW = await getOptionsLegalWays();
      setKindJudgmentOptions(optionsKJ);
      setLegalWaysOptions(optionsLW);
      const expedient = await getDataExpedientToEdit(
        expedientId,
        optionsLW,
        optionsKJ
      );
      setExpedientToEdit(expedient ? expedient : initValues);
      loader.show(false);
    }())
    getAccountUser();
  }, []);

  useEffect(() => {
    if(expedientToEdit.matter === 'Penal'){
      getCrimesOptions();
    }
  }, [expedientToEdit.matter]);

  const getCrimesOptions = async () => {
    const crimes = await getCrimes((expedientToEdit.matter ).toLowerCase());
    setCrimesSelectorOptions(crimes);
  };

  const getAccountUser = () => {
    const { authentication_token_03 } = Cookies.get();

    if (!authentication_token_03) return '';
    let { user } = getDecodedJwt(authentication_token_03);
    if (!user) return '';
    let id_app = user.account_id ? user.account_id : '';
    if (id_app == 3) setDisableFields(true);

    return id_app;
  };

  const handleSubmit = async () => {
    const accountUser = getAccountUser();
    const requiredFields = accountUser!==3 ?['expedient_number', 'actor_name', 'actor_firstname', 'kindJudgmentOption', 'viaOption', 'reason']:
    ['expedient_number', 'kindJudgmentOption', 'viaOption', 'reason'];
    const responseValidation = fieldsValidation(requiredFields, expedientToEdit);

    if (typeof responseValidation === 'object') {
      setErrors(responseValidation);
    }
    else {
      loader.show(true);
      expedientToEdit['via'] = expedientToEdit['viaOption']['value'];
      expedientToEdit['kind_judgment'] = expedientToEdit['kindJudgmentOption']['value'];

      await saveExpedientEdited(
        props,
        expedientToEdit,
        setErrors
      );
      loader.show(false);
    }
  };

  const handleChange = (name, value) => {
    setExpedientToEdit({
      ...expedientToEdit,
      [name]: value
    });
    delete errors[name]
  };

  const matterSelectorOptions = [{ value: expedientToEdit.matter, label: expedientToEdit.matter }];
  return (
    <>
      <NavBar {...props} />

      <Row className="jc-c mT-25">
        <Card
          className="cardExpedientEdit"
        >
          <Card.Header
            className="cardHeaderExpedients"
          >
            <h4 className="text-center">{getLabelFromDictionary('editExpedient', 'editExpedientLabel') + expedientToEdit['expedient_number']} <i className="ti-pencil-alt" style={{ fontSize: '15px' }} /></h4>
          </Card.Header>
          <Card.Body>
            <Container>
              <div className="table-responsive">
                <Form>
                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'numberExpedient')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Edita el número de ${getLabelFromDictionary('global', 'sigleMainName')}`}
                      required
                      name="expedient_number"
                      value={expedientToEdit['expedient_number']}
                      onChange={(e) => {
                        if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
                          handleChange(
                            'expedient_number',
                            e.target.value
                          )
                        }
                      }}
                      className={Object.keys(errors).includes("expedient_number") ? "text-danger is-invalid" : ""}
                      onInput={(e) => e.target.value = e.target.value.replace(/\b0+/g, '')}
                    />
                    {renderErrorsByInputName(errors, "expedient_number")}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'actorRegime')}</Form.Label>
                    <Select
                      options={regimeSelectorOptions}
                      onChange={selected => handleChange('actor_regime', selected.value)}
                      placeholder="Edita el régimen del actor (promovente)"
                      value={regimeSelectorOptions.find((option) => option.value === expedientToEdit.actor_regime)}
                      styles={Object.keys(errors).includes("actor_regime") ? selectStylesError : selectStylesDefault}
                    />
                    {renderErrorsByInputName(errors, "actor_regime")}
                  </Form.Group>

                  {
                    (expedientToEdit.actor_regime && expedientToEdit.actor_regime === 'moral')
                    &&
                    <Form.Group>
                      <Form.Label>* Razón social del actor:</Form.Label>
                      <Form.Control
                        value={expedientToEdit.actor_business_name}
                        aria-label="actor_business_name"
                        className={Object.keys(errors).includes("actor_business_name") ? "text-danger is-invalid" : ""}
                        placeholder="Edita la razón social del actor (promovente)"
                        onChange={(e) => handleChange('actor_business_name', e.target.value)}
                      />
                      {renderErrorsByInputName(errors, "actor_business_name")}
                    </Form.Group>
                  }

                  {!disableFields &&
                    <>
                      <Form.Group>
                        <Form.Label>
                          * Nombre(s) del {textForLegalActorRepresentative} actor:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`Edita el nombre o nombres del ${textForLegalActorRepresentative} actor (promovente)`}
                          required
                          name="actor_name"
                          value={expedientToEdit['actor_name']}
                          onChange={(e) => handleChange(
                            'actor_name',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("actor_name") ? "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "actor_name")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          * Primer apellido del {textForLegalActorRepresentative} actor:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`Edita el primer apellido del ${textForLegalActorRepresentative} actor (promovente)`}
                          required
                          name="actor_firstname"
                          value={expedientToEdit['actor_firstname']}
                          onChange={(e) => handleChange(
                            'actor_firstname',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("actor_firstname") ? "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "actor_firstname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          Segundo apellido del {textForLegalActorRepresentative} actor:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`Edita el segundo apellido del ${textForLegalActorRepresentative} actor (promovente)`}
                          required
                          name="actor_secondname"
                          value={expedientToEdit['actor_secondname']}
                          onChange={(e) => handleChange(
                            'actor_secondname',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("actor_secondname") ? "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "actor_secondname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Correo electrónico del actor:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el correo electrónico del actor"
                          required
                          name="actor_email"
                          value={expedientToEdit['actor_email']}
                          onChange={(e) => handleChange(
                            'actor_email',
                            e.target.value.toLowerCase()
                          )}
                          className={Object.keys(errors).includes("actor_email") ? "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "actor_email")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>{getLabelFromDictionary('editExpedient', 'actorPatronLawyerNames')}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el nombre(s) del abogado patrono del actor"
                          required
                          name="lawyer_actor_name"
                          value={expedientToEdit['lawyer_actor_name'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_actor_name',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_actor_name") ?
                            "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "lawyer_actor_name")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>{getLabelFromDictionary('editExpedient', 'actorPatronLawyerFirstLastName')}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el primer apellido del abogado patrono del actor"
                          required
                          name="lawyer_actor_firstname"
                          value={expedientToEdit['lawyer_actor_firstname'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_actor_firstname',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_actor_firstname") ?
                            "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "lawyer_actor_firstname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>{getLabelFromDictionary('editExpedient', 'actorPatronLawyerSecondLastName')}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el segundo apellido del abogado patrono del actor"
                          required
                          name="lawyer_actor_secondname"
                          value={expedientToEdit['lawyer_actor_secondname'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_actor_secondname',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_actor_secondname") ?
                            "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "lawyer_actor_secondname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Notificaciones electrónicas:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Agrega el correo electrónico del abogado del actor"
                          name="lawyer_actor_email"
                          value={expedientToEdit['lawyer_actor_email'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_actor_email',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_actor_email") ?
                            "text-danger is-invalid" : ""}
                        />
                        <small>Ejemplo: ana123@gmail.com</small>
                        {renderErrorsByInputName(errors, "lawyer_actor_email")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>{getLabelFromDictionary('editExpedient', 'defendantRegime')}</Form.Label>
                        <Select
                          options={regimeSelectorOptions}
                          onChange={selected => handleChange('defendant_regime', selected.value)}
                          placeholder="Edita el régimen del demandado"
                          value={regimeSelectorOptions.find((option) => option.value === expedientToEdit.defendant_regime)}
                          styles={Object.keys(errors).includes("defendant_regime") ? selectStylesError : selectStylesDefault}
                        />
                        {renderErrorsByInputName(errors, "defendant_regime")}
                      </Form.Group>

                      {
                        (expedientToEdit.defendant_regime && expedientToEdit.defendant_regime === 'moral')
                        &&
                        <Form.Group>
                          <Form.Label>* Razón social del demandado:</Form.Label>
                          <Form.Control
                            value={expedientToEdit.defendant_business_name}
                            aria-label="defendant_business_name"
                            className={Object.keys(errors).includes("defendant_business_name") ? "text-danger is-invalid" : ""}
                            placeholder="Edita la razón social del demandado"
                            onChange={(e) => handleChange('defendant_business_name', e.target.value)}
                          />
                          {renderErrorsByInputName(errors, "defendant_business_name")}
                        </Form.Group>
                      }

                      <Form.Group>
                        <Form.Label>
                          * Nombre(s) del {textForLegalDefendantRepresentative} demandado:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`Edita el nombre(s) del ${textForLegalDefendantRepresentative} demandado`}
                          required
                          name="defendant_name"
                          defaultValue={expedientToEdit['defendant_name'] || ""}
                          onChange={(e) => handleChange(
                            'defendant_name',
                            e.target.value
                          )}
                        />
                        {renderErrorsByInputName(errors, "defendant_name")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          * Primer apellido del {textForLegalDefendantRepresentative} demandado:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`Edita el primer apellido del ${textForLegalDefendantRepresentative} demandado`}
                          required
                          name="defendant_firstname"
                          defaultValue={expedientToEdit['defendant_firstname'] || ""}
                          onChange={(e) => handleChange(
                            'defendant_firstname',
                            e.target.value
                          )}
                        />
                        {renderErrorsByInputName(errors, "defendant_firstname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          Segundo apellido del {textForLegalDefendantRepresentative} demandado:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`Edita el segundo apellido del ${textForLegalDefendantRepresentative} demandado`}
                          required
                          name="defendant_secondname"
                          defaultValue={expedientToEdit['defendant_secondname'] || ""}
                          onChange={(e) => handleChange(
                            'defendant_secondname',
                            e.target.value
                          )}
                        />
                        {renderErrorsByInputName(errors, "defendant_secondname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Correo electrónico del demandado:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el correo electrónico del demandado"
                          required
                          name="defendant_email"
                          value={expedientToEdit['defendant_email']}
                          onChange={(e) => handleChange(
                            'defendant_email',
                            e.target.value.toLowerCase()
                          )}
                          className={Object.keys(errors).includes("defendant_email") ? "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "defendant_email")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Nombre(s) del abogado patrono:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el nombre(s) del abogado patrono del demandado"
                          required
                          name="lawyer_defendant_name"
                          value={expedientToEdit['lawyer_defendant_name'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_defendant_name',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_defendant_name") ?
                            "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "lawyer_defendant_name")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Primer apellido del abogado patrono:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el primer apellido del abogado patrono del demandado"
                          required
                          name="lawyer_defendant_firstname"
                          value={expedientToEdit['lawyer_defendant_firstname'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_defendant_firstname',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_defendant_firstname") ?
                            "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "lawyer_defendant_firstname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Segundo apellido del abogado patrono:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Edita el segundo apellido del abogado patrono del demandado"
                          required
                          name="lawyer_defendant_secondname"
                          value={expedientToEdit['lawyer_defendant_secondname'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_defendant_secondname',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_defendant_secondname") ?
                            "text-danger is-invalid" : ""}
                        />
                        {renderErrorsByInputName(errors, "lawyer_defendant_secondname")}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Notificaciones electrónicas:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Agrega el correo electrónico del abogado patrono del demandado"
                          name="lawyer_defendant_email"
                          value={expedientToEdit['lawyer_defendant_email'] || ""}
                          onChange={(e) => handleChange(
                            'lawyer_defendant_email',
                            e.target.value
                          )}
                          className={Object.keys(errors).includes("lawyer_defendant_email") ?
                            "text-danger is-invalid" : ""}
                        />
                        <small>Ejemplo: ana123@gmail.com</small>
                        {renderErrorsByInputName(errors, "lawyer_defendant_email")}
                      </Form.Group>
                    </>
                  }

                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'trialType')}</Form.Label>
                    <Select
                      value={expedientToEdit['kindJudgmentOption']}
                      placeholder="Selecciona el tipo de juicio"
                      styles={Object.keys(errors).includes('kindJudgmentOption') ? selectStylesError : customStyles}
                      menuPlacement="bottom"
                      maxMenuHeight={150}
                      onChange={selected => handleChange(
                        'kindJudgmentOption',
                        selected
                      )}
                      options={kindJudgmentOptions}
                    />
                    {renderErrorsByInputName(errors, "kindJudgmentOption")}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'via')}</Form.Label>
                    <Select
                      options={legalWaysOptions}
                      placeholder="Selecciona la vía"
                      styles={Object.keys(errors).includes('viaOption') ? selectStylesError : customStyles}
                      menuPlacement="bottom"
                      maxMenuHeight={150}
                      value={expedientToEdit['viaOption']}
                      onChange={selected => handleChange(
                        'viaOption',
                        selected
                      )}
                    />
                    {renderErrorsByInputName(errors, "viaOption")}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>{getLabelFromDictionary('editExpedient', 'subject')}</Form.Label>
                    <Select
                      options={matterSelectorOptions}
                      onChange={selected => handleChange('matter', selected.value)}
                      placeholder="Selecciona la materia"
                      value={matterSelectorOptions.find((option) => option.value === expedientToEdit.matter)}
                      styles={Object.keys(errors).includes("matter") ? selectStylesError : selectStylesDefault}
                      isDisabled
                    />
                    {renderErrorsByInputName(errors, "matter")}
                  </Form.Group>

                  {expedientToEdit.matter === 'Penal' &&
                    <Form.Group>
                      <Form.Label>Delito:</Form.Label>
                      <Select
                        options={crimesSelectorOptions}
                        onChange={selected => handleChange('crimes', selected.value)}
                        placeholder="Selecciona el delito"
                        value={crimesSelectorOptions.find((option) => option.value == expedientToEdit.crimes)}
                        styles={Object.keys(errors).includes("crimes") ? selectStylesError : customStyles}
                      />
                      {renderErrorsByInputName(errors, "crime")}
                    </Form.Group> } 

                  {expedientToEdit.matter === 'Penal' &&
                    <Form.Group>
                      <Form.Label>Carpeta de investigación:</Form.Label>
                      <Form.Control
                        defaultValue={expedientToEdit["investigation_folder"]}
                        aria-label="investigation_folder"
                        onChange={(e) => handleChange( 'investigation_folder', e.target.value )}
                        className={Object.keys(errors).includes("investigation_folder") ? "text-danger is-invalid" : ""}
                      />
                      {renderErrorsByInputName(errors, "investigation_folder")}
                    </Form.Group> 
                  } 

                  <Form.Group>
                    <Form.Label>* Justificación:</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      placeholder="Agrega una justificación"
                      defaultValue={expedientToEdit['reason']}
                      onChange={(e) => handleChange(
                        'reason',
                        e.target.value
                      )}
                      className={Object.keys(errors).includes("reason") ? "text-danger is-invalid" : ""}
                    />
                    {renderErrorsByInputName(errors, "reason")}
                  </Form.Group>
                </Form>
              </div>
            </Container>
          </Card.Body>
        </Card>
      </Row>
      <Row className="jc-c mB-20">
        <div className="actions-expedient-edit">
          <div className="fl-r">
            <Button
              variant="secondary"
              className="mR-20"
              onClick={() => props.history.goBack()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleSubmit()}
            >
              Actualizar
            </Button>
          </div>
        </div>
      </Row>

      <Loader />
    </>
  );
};

export default EditExpedient;
