import React, { useState, useContext } from "react";
import { Row, Col } from "react-flexbox-grid";
import DynamicForm from "../dynamic_form/DynamicForm";
import { executeStage, previousHandler } from "./start_process_logic";
import OneColumn from "../layout/containers/OneColumn";
import NotificationAlertListContext from "../alertNotificationTable/notification_alert_context";
import {updateAllAlertNotifications} from "../alertNotificationTable/alert_notification_logic";
import LoaderContext from "../layout/shared/loader_context";

const StartProcess = props => {
  const loader = useContext(LoaderContext);
  const alertContext = useContext(NotificationAlertListContext);
  const [sequence, setSequence] = useState(
    props.history.location.state.params.sequence
  );
  const [form, setForm] = useState(props.history.location.state.params.form);
  const [lastStep, setLastStep] = useState(false);
  const [stage, setStage] = useState(props.history.location.state.params.stage);
  const [flagError, setFlagError] = useState(false);
  const [mode, setMode] = useState(props.history.location.state.params.mode);
  const [applicant, setApplicant] = useState(
    props.history.location.state.params.applicant
  );
  const [formTitle, setFormTitle] = useState(
    props.history.location.state.params.formTitle
  );
  const [previosUrl, setPreviousUrl] = useState(
    props.history.location.state.params.previous_url
      ? props.history.location.state.params.previous_url
      : ""
  );

  const previousUrlHandler = async url => {
    loader.show(true);
    await previousHandler(
      url,
      setFormTitle,
      setPreviousUrl,
      setForm,
      setSequence,
      setMode
    );
    loader.show(false);
    await updateAllAlertNotifications(alertContext);
  };

  const onSubmitHandler = async (form, partial = false) => {
    loader.show(true);
    await executeStage(
      form,
      partial,
      sequence,
      props,
      setFormTitle,
      setSequence,
      setStage,
      setPreviousUrl,
      setFlagError,
      setForm,
      setLastStep,
      setMode,
      setApplicant
    );
    loader.show(false);
    await updateAllAlertNotifications(alertContext);
  };

  let pageConfig = {
    active: formTitle,
    title: "Tramite"
  };

  return (
    <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} noTitle={true}>
      <section className="render_form mB-40">
        <Row>
          <Col xs={12}>
            <DynamicForm
              onSubmit={(models, partial) => {
                onSubmitHandler(models, partial);
              }}
              history={props.history}
              form={form}
              lastStep={lastStep}
              formTitle={formTitle}
              stage={stage}
              hasErrors={flagError}
              setHasErrors={setFlagError}
              previousUrl={previosUrl}
              previousUrlHandler={previousUrlHandler}
              setLastStep={setLastStep}
              mode={mode}
              applicant={applicant}
            />
          </Col>
        </Row>
      </section>
    </OneColumn>
  );
};

export default StartProcess;
