import _ from "lodash";
import { getFormattedPartyTypeCatalogCode } from "../utils/getFormattedPartyTypeCatalogCode";
import { getPartyDataWithFormattedPersonNames } from "../utils/getPartyDataWithFormattedPersonNames";
import { getPartyDataWithFormattedPersonBirthDate } from "../utils/getPartyDataWithFormattedPersonBirthDate";

export const getFormattedPartyList = (partyList = []) => {
  if (!Array.isArray(partyList)) {
    return [];
  }

  const formattedParties = partyList.map((party) => {

    if (_.isEmpty(party)) {
      return party;
    }

    let partyType = _.get(party, 'party_type', '');
    let partyData = _.get(party, 'party_data', {});

    partyType = getFormattedPartyTypeCatalogCode(partyType);
    partyData = getPartyDataWithFormattedPersonNames(partyData);
    partyData = getPartyDataWithFormattedPersonBirthDate(partyData);

    return {
      ...party,
      party_type: partyType,
      party_data: partyData,
    }
  });

  return formattedParties
}