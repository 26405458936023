import React, { createContext, useContext, useState } from 'react';
import { UndefinedContextError } from '../../../../../../errors/contextError/contextError';
import useFilesPendingSignatureTable from '../hooks/useFilesPendingSignatureTable';

const FilesPendingSignatureContext = createContext();
FilesPendingSignatureContext.displayName = 'FilesPendingSignatureContext';

const FilesPendingSignatureContextProvider = ({
  children,
  documentsPendingOfSignatureDataSource,
  view,
  onRowClick = () => { },
  shouldShowMessageIfThereAreNoDocuments = false,
  ...restOfProps
}) => {

  const [documentParameters, setDocumentParameters] = useState({});

  const filesPendingSignatureTableState = useFilesPendingSignatureTable({
    setDocumentParameters,
    expedientId: restOfProps.expedientId,
    documentsPendingOfSignatureDataSource,
    view,
    onRowClick,
  });

  const contextValue = {
    documentParameters,
    setDocumentParameters,
    view,
    shouldShowMessageIfThereAreNoDocuments,
    ...filesPendingSignatureTableState,
    ...restOfProps
  }

  return (
    <FilesPendingSignatureContext.Provider value={contextValue} >
      {children}
    </FilesPendingSignatureContext.Provider>
  )
}

export const useFilesPendingSignatureContext = () => {
  const context = useContext(FilesPendingSignatureContext);
  if (typeof context === 'undefined') {
    throw new UndefinedContextError('To use useFilesPendingSignatureContext you must wrap inside a FilesPendingSignatureContextProvider')
  }
  return context;
}

export default FilesPendingSignatureContextProvider
