import React, { useState } from 'react';
import styles from "./BulletinResults.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Card, Container, NavLink } from 'react-bootstrap';
import InfoModal from '../../modals/InfoModal';
// import { deleteExpedient, findExpedient } from '../api/expedients';

export function BulletinResults({ records, expedient, expedientId }) {
  const areRecordsAvailable = Array.isArray(records)
  const [show, setShow] = useState(false);

  const handleDetailExpedient = (e) => {
    e.preventDefault();
    setShow(true);
  }

  // const handleDeleteExpedient = async (e, id) => {
  //   e.preventDefault();

  //   const confirmation = window.confirm('¿Estas seguro que deseas eliminar este expediente?');
  //   if (confirmation) {
  //     await deleteExpedient(id);
  //     window.location.reload();
  //   }
  // }

  return (
    <>
      {areRecordsAvailable &&
        <Container className={styles.container}>
          <Card>
            <Card.Header className="text-center">
              <h5>{`Resultados para el expediente ${expedient}`}</h5>
            </Card.Header>
            <Container>

              {records && records.length === 0 ?
                <div className={styles.containerNotResultsArea}>
                  <FontAwesomeIcon icon={faXmark} className={styles.calendarIcon} />
                  <span>El número de expediente proporcionado no cuenta con registros en el boletín judicial.</span>
                </div>
                :
                <div className={styles.containerResultsArea}>
                  <ul className={styles.resultsSearch}>
                    {records.map((record) => {
                      // const { id } = record;
                      const { expedientNumber } = record.attributes;

                      return (
                        <li key={record.id}>
                          <div className={styles.actionElements}>
                            <NavLink onClick={handleDetailExpedient}>
                              {expedientNumber}
                            </NavLink>
                            {/* <NavLink>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={styles.deleteIconStyles}
                                onClick={(e) => handleDeleteExpedient(e, record.id)}
                              />
                            </NavLink> */}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              }
            </Container>
          </Card>
        </Container>
      }

      {/* <ConfirmationModal show={confirmShow} setShow={setConfirmShow} executeAction={deleteExpedient} /> */}

      <InfoModal show={show} setShow={setShow} expedientId={expedientId} />
    </>
  );
}
