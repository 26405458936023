import React from 'react'
import ModalComponent from '../../../../helper_components/ModalComponent'
import BodyModal from './components/BodyModal'
import FooterModal from './components/FooterModal'
import useBodyModalState from './hooks/useBodyModalState'
import IndicatorsContextProvider from './context/indicatorsContext/IndicatorsContextProvider'

const IndicatorsModal = (props) => {

  if (props.isModalShowing)
    return (
      <IndicatorsModalMainComponent {...props} />
    )

  return <></>
}

const IndicatorsModalMainComponent = ({
  toggleShowinModal,
  isModalShowing,
  expedientId,
}) => {

  const indicatorsContextState = useBodyModalState({ toggleShowinModal, expedientId });

  const contesxtValue = {
    ...indicatorsContextState,
    toggleShowinModal,
    isModalShowing,
    expedientId,
  }

  return (
    <IndicatorsContextProvider value={contesxtValue}>
      <ModalComponent
        header="Indicadores"
        body={<BodyModal />}
        footer={<FooterModal />}
        show={isModalShowing}
        size={'xl'}
      />
    </IndicatorsContextProvider>
  )
}

export default IndicatorsModal