import React, { createContext, useContext, useRef } from 'react';
import { UndefinedContextError } from '../../../../errors/contextError/contextError';

const ExpedientDetailsContext = createContext();

const ExpedientDetailsProvider = (props) => {

  const onUpdateExpedientDetailsComponetRef = useRef(() => { });

  const contextValue = {
    onUpdateExpedientDetailsComponetRef,
    onUpdateExpedientDetailsComponet: onUpdateExpedientDetailsComponetRef.current,
  }

  return (
    <ExpedientDetailsContext.Provider {...props} value={contextValue} />
  )
}

export const useExpedientDetailsContext = () => {
  const context = useContext(ExpedientDetailsContext);
  if (context === undefined) {
    throw new UndefinedContextError('To use useExpedientDetailsContext you must wrap inside a ExpedientDetailsProvider');
  }
  return context;
}

export default ExpedientDetailsProvider