import React, { createContext, useContext } from 'react';
import useSessionManager from './hooks/useSessionManager';
import SessionManagerModal from '../../components/helper_components/modals/sessionManagerModal/SessionManagerModal';

const SessionManagerContext = createContext();
SessionManagerContext.displayName = 'SessionManagerContext';

const SessionManagerContextProvider = ({ children }) => {

	const sessionManagerContext = useSessionManager();

	return (
		<SessionManagerContext.Provider value={sessionManagerContext}>
			<SessionManagerModal />
			<div onClick={sessionManagerContext.onScreenClick}>
				{children}
			</div>
		</SessionManagerContext.Provider>
	)
}

export const useSessionManagerContext = () => {
	const context = useContext(SessionManagerContext);

	if (typeof context === 'undefined')
		throw new Error('useSessionManagerContext must be wrapped in a SessionManagerProvider');

	return context;
}

export default SessionManagerContextProvider