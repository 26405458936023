import _ from "lodash";
import { NotificationManager } from "react-notifications";

export const getPdfTemplate = async (
  urlRequest,
  pdfTemplateName = 'document',
  pdfTemplateType,
) => {
  try {
    const response = await fetch(urlRequest);
    const responseCode = _.get(response, 'status');
    const isASuccessfulResponse = responseCode === 200;

    if (!isASuccessfulResponse) {
      const errorMessage = 'Ocurrió un error al intentar obtener el archivo de plantilla';
      NotificationManager.error(errorMessage);
      return
    }

    const pdfTemplateBlob = await response.blob();

    const pdfTemplateFileSettings = {
      type: _.defaultTo(pdfTemplateType, pdfTemplateBlob.type),
    }

    const pdfTemplateFile = new File([pdfTemplateBlob], pdfTemplateName, pdfTemplateFileSettings);
    return pdfTemplateFile;

  } catch (error) {
    throw error;
  }
};