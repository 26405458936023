import React from 'react'
import ModalComponent from '../../../../../../../../helper_components/ModalComponent'
import BodyModal from './components/bodyModal/BodyModal'
import FooterModal from './components/footerModal/FooterModal'

const SignatureConfirmationModal = ({
  onCloseModalClick = () => { },
  onConfirmClick = () => { },
  isModalShowing = false,
}) => {

  if (!isModalShowing) {
    return <></>
  }

  return (
    <ModalComponent
      header='Alerta'
      body={<BodyModal />}
      footer={<FooterModal onCancelClick={onCloseModalClick} onConfirmClick={onConfirmClick} />}
      show={isModalShowing}
      canClose={true}
      onHide={onCloseModalClick}
      scroll={true}
      dialogClassName="electronic-signature-modal"
    />
  )
}

export default SignatureConfirmationModal