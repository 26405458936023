import { getNodeToInsertCodeTag } from "./getNodeToInsertCodeTag";

export const setupEditorHandler = (editor) => {
  editor.ui.registry.addButton('codeButton', {
    icon: 'sourcecode',
    onAction: function (_) {
      const nodeToinsert = getNodeToInsertCodeTag(editor);
      editor.insertContent(nodeToinsert);
    }
  });
}