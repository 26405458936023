import React from 'react'
import { Table } from 'react-bootstrap'
import TextTooltip from '../../../../tootip/TextTooltip'
import _ from 'lodash'
import Cookies from 'js-cookie';

const dataNotAvailableToDisplay = 'Valor no disponible';

const ElectronicSignatureTable = ({
  electronicSignaturesList = [],
  singleElectronicSignature,
  isMultipleSignature,
  onDelteElectronicSignatureById,
  electronicSignaturesRecentlyAddedList = [],
}) => {

  const electronicSignaturesRecentlyAddedIdList = electronicSignaturesRecentlyAddedList.map(electronicSignature => _.get(electronicSignature, 'id'));

  return (
    <Table style={{ width: 'auto', fontSize: '11px', height: '1px' }}>
      <thead className='text-white'>
        <tr>
          <th className='bg-success'>Nombre del firmante</th>
          <th className='bg-success'>CURP</th>
          {
            isMultipleSignature
            &&
            <th className='bg-success'>Acción</th>
          }
        </tr>
      </thead>
      <tbody>
        {
          Boolean(electronicSignaturesList.length && isMultipleSignature)
          &&
          electronicSignaturesList.map((electronicSignatureItem) => {
            const isCurrentUserTheOwner = String(Cookies.get('userId_03')) === String(_.get(electronicSignatureItem, 'user_id'));
            const isRecentlyAdded = electronicSignaturesRecentlyAddedIdList.includes(_.get(electronicSignatureItem, 'id'));
            const canDeleteSignature = isRecentlyAdded && isCurrentUserTheOwner;

            return (
              <tr
                style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}
                key={electronicSignatureItem.id}
              >
                <TableItem>
                  {_.get(electronicSignatureItem, 'signatureInfo.CommonName', dataNotAvailableToDisplay).toUpperCase()}
                </TableItem>
                <TableItem>
                  {_.get(electronicSignatureItem, 'signatureInfo.CURPFirel', dataNotAvailableToDisplay).toUpperCase()}
                </TableItem>
                <TableItem noBackground>
                  {
                    canDeleteSignature
                    &&
                    <TextTooltip text={'Eliminar firma electrónica'}>
                      <button
                        onClick={() => onDelteElectronicSignatureById(_.get(electronicSignatureItem, 'id', ''))}
                        style={{
                          fontSize: '20px',
                          color: 'red',
                          backgroundColor: 'white',
                          border: 'none'
                        }}>
                        <i className="fas fa-trash-alt fa-xs"></i>
                      </button>
                    </TextTooltip>
                  }
                </TableItem>
              </tr>
            )
          })
        }
        {
          (!isMultipleSignature && Boolean(singleElectronicSignature))
          &&
          <tr>
            <TableItem>
              {_.get(singleElectronicSignature, 'signatureInfo.CommonName', dataNotAvailableToDisplay).toUpperCase()}
            </TableItem>
            <TableItem>
              {_.get(singleElectronicSignature, 'signatureInfo.CURPFirel', dataNotAvailableToDisplay).toUpperCase()}
            </TableItem>
          </tr>
        }
      </tbody>
    </Table>
  )
}

const TableItem = ({ noBackground = false, children }) => {
  return (
    <td style={{ height: '100%', padding: 0 }} >
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: noBackground ? 'unset' : 'white',
          padding: '10px 20px'
        }} >
        <div>
          {children}
        </div>
      </div>
    </td>
  )
}

export default ElectronicSignatureTable