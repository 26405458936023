import _ from "lodash";
import useFormValues from "../../../../../../hooks/useFormValues";
import { checkFields } from "../services/selectOptions/checkFieldsOptions";
import { representationAdmissibleProceedingDefaultFormValues } from "../constants/defaultValues";

const useRepresentationAdmissibleProceeding = () => {

  const actorFormMethods = useFormValues(representationAdmissibleProceedingDefaultFormValues);
  const defendantFormMethods = useFormValues(representationAdmissibleProceedingDefaultFormValues);
  const thirdFormMethods = useFormValues(representationAdmissibleProceedingDefaultFormValues);

  const formValues = {
    actor: actorFormMethods.formValues,
    defendant: defendantFormMethods.formValues,
    third: thirdFormMethods.formValues,
  }

  const fieldGroups = [
    {
      value: 'actor',
      label: 'Actor',
      formMethods: actorFormMethods,
      checkFields,
    },
    {
      value: 'defendant',
      label: 'Defendant',
      formMethods: defendantFormMethods,
      checkFields,
    },
    {
      value: 'third',
      label: 'Third',
      formMethods: thirdFormMethods,
      checkFields,
    },
  ]

  const setInitialState = (initialState) => {
    const doesInitialStateExist = _.isObject(initialState);
    if (!doesInitialStateExist) {
      return
    }

    const {
      actor = {},
      defendant = {},
      third = {},
    } = initialState;

    actorFormMethods.setFormValues({ ...actorFormMethods.formValues, ...actor });
    defendantFormMethods.setFormValues({ ...defendantFormMethods.formValues, ...defendant });
    thirdFormMethods.setFormValues({ ...thirdFormMethods.formValues, ...third });
  }

  return {
    actorFormMethods,
    defendantFormMethods,
    thirdFormMethods,
    formValues,
    fieldGroups,
    setInitialState,
  }
}

export default useRepresentationAdmissibleProceeding