import { useMemo, useState } from 'react';
import uuidv4 from 'uuid/dist/v4';
import { strCerPem } from '../../../../../services/eFirma';
import { getIsAValidEFirmaPassword, SeleccionarCer, SeleccionarKey, SeleccionarPfx } from '../../../../../services/electronicSignature';
import { getDetailsCer } from '../../../../../services/multifirma';
import { getMultiSignatureItem, SeleccionarPfxMultiSignature } from '../../../../../services/multipleElectronicSignature';
import { electronicSignatureSettingsDefaultValue } from '../constants/electronicSignatureSettingsDefaultValue';
import { getElectronicSignatureSettings } from '../constants/getElectronicSignatureSettings';
import Cookies from 'js-cookie';

const getInitialFormElectronicSignatureValues = (electronicSignatureSettings, isMultipleSignature) => {
  return {
    signaturetype: getElectronicSignatureSettings(electronicSignatureSettings).getDefaultType(isMultipleSignature),
    password: '',
    id: uuidv4(),
    cer: null,
    key: null,
    pfx: null,
  }
}

const useElectronicSignatureManager = ({ isMultipleSignature, electronicSignatureSettings = electronicSignatureSettingsDefaultValue }) => {

  const [formElectronicSignatureValues, setFormElectronicSignatureValues] = useState(getInitialFormElectronicSignatureValues(electronicSignatureSettings, isMultipleSignature));
  const isAnElectronicSignatureFirelType = useMemo(() => formElectronicSignatureValues.signaturetype === 'firel', [formElectronicSignatureValues.signaturetype]);

  const onFormElectronicSignatureChange = ({ target: { name, dataset, value } }) => {
    let inputValue = value;
    let lastFormValues = formElectronicSignatureValues;
    if (name === 'signaturetype' && dataset.rbEventKey) {
      inputValue = dataset.rbEventKey;
      if (inputValue === formElectronicSignatureValues.signaturetype) {
        return
      }
    }
    setFormElectronicSignatureValues({ ...lastFormValues, [name]: inputValue });
  }

  const onSetElectronicSignatureKeyFile = (file, formName) => {
    switch (formName) {
      case 'cer':
        SeleccionarCer(file)
        break;
      case 'key':
        SeleccionarKey(file)
        break;
      case 'pfx':
        SeleccionarPfx(file)
        SeleccionarPfxMultiSignature(file);
        break;
    }
    setOnElectronicSignatureValues(formName, file);
  }

  const setOnElectronicSignatureValues = (name, value) => {
    onFormElectronicSignatureChange({ target: { name, value } })
  }

  const resetElectronicSignatureValues = () => {
    setFormElectronicSignatureValues(getInitialFormElectronicSignatureValues(electronicSignatureSettings, isMultipleSignature));
  }

  const onAddElectronicSignature = async (addElectronicSignatureHandle) => {
    let signatureItem = {};
    let multiSign = null;
    let singleSign = null;
    let signatureInfo = {};

    if (isAnElectronicSignatureFirelType) {
      signatureItem = await getMultiSignatureItem(formElectronicSignatureValues.password);
      if (!signatureItem) {
        throw new Error('Invalid credentials');
      }
      signatureInfo = { ...getDetailsCer(signatureItem.firma.CertPEM) };
      multiSign = {
        id: formElectronicSignatureValues.id,
        signatureInfo,
        user_id: Cookies.get('userId_03'),
        ...signatureItem,
      }
    } else {
      const isAValidSatPassword = await getIsAValidEFirmaPassword(formElectronicSignatureValues.password);
      if (!isAValidSatPassword) {
        throw new Error('Invalid credentials');
      }
      signatureInfo = { ...getDetailsCer(strCerPem) };
    }
    singleSign = { ...formElectronicSignatureValues, signatureInfo };
    addElectronicSignatureHandle({ multiSign, singleSign });
  }

  return ({
    formElectronicSignatureValues,
    isAnElectronicSignatureFirelType,
    onSetElectronicSignatureKeyFile,
    setOnElectronicSignatureValues,
    resetElectronicSignatureValues,
    onAddElectronicSignature,
    onFormElectronicSignatureChange,
  })
}

export default useElectronicSignatureManager