import React from 'react';
import styles from './Separator.module.css';

export function Separator() {
  return (
    <div className={styles.separator}>
      <hr />
    </div>
  )
}
