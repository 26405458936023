import React from 'react'

const commentBackgroundColor = '#E9E9E9';
const commentGap = '5px';

const Comment = ({ userName, comment, date, isOwnComment = false }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: commentGap,
          flexDirection: isOwnComment ? 'row-reverse' : 'row',
        }}
      >
        <div
          style={{
            height: '25px',
            lineHeight: '25px',
            width: '25px',
            borderRadius: '100%',
            backgroundColor: commentBackgroundColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i className="fas fa-user fa-sm"></i>
        </div>

        <div
          style={{
            height: '25px',
            borderRadius: '25px',
            backgroundColor: commentBackgroundColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px 10px',
            fontWeight: 'bold',
          }}
        >
          {userName}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: isOwnComment ? 'row-reverse' : 'row',
        }}
      >
        <div
          style={{
            width: 'fit-content',
            borderRadius: isOwnComment ? '25px 0 25px 25px' : '0 25px 25px 25px',
            backgroundColor: commentBackgroundColor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            marginTop: commentGap,
          }}
        >
          <div>
            {comment}
          </div>
          <div
            style={{
              alignSelf: 'end',
              fontSize: '10px',
              color: '#AFAFAF',
            }}
          >
            {date}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comment