import React, { useEffect, useState } from 'react';
import { getLocaleDateString } from '../../../../../../services/dataFormatting/getLocaleDateString';
import { useLoaderContext } from '../../../../../layout/shared/loader_context';
import { usePreviewPdfModalContext } from '../../../context/previewPdfModalContext';
import ActionsRow from '../componets/actionsRow/ActionsRow';
import { useCommentsModalContext } from '../context/CommentsModalContext';
import { getDynamicColumns } from '../services/tableData';
import { getDisplayNameOfType } from '../../../services/getDisplayNameOfType';
import { useDeletePendingDocumentToBeSignedContext } from '../context/DeletePendingDocumentToBeSignedContext';
import { Col, Row } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { RequestNewVersion } from '../../../../../classes/RequestNewVersion';
import _ from 'lodash';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { getDocumentSubtype } from '../../../services/getDocumentSubtype';
import { getLabelFromDictionary } from '../../../../../../config/dictionary';

const useFilesPendingSignatureTable = ({
  setDocumentParameters,
  documentsPendingOfSignatureDataSource,
  view,
  expedientId,
  onRowClick = () => { },
}) => {

  const [dataDocuments, setDataDocuments] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [tableRowData, setTableRowData] = useState([]);
  const [isElectronicSignatureModalOpen, setIsElectronicSignatureModalOpen] = useState(false);
  const [isNotifyEmailModalShowing, setIsNotifyEmailModalShowing] = useState(false);
  const { setPdfDataToPreviewAndShowPreviewPdfModal } = usePreviewPdfModalContext();
  const [currentExpedientParts, setCurrentExpedientParts] = useState([]);
  const [receiveExpedientData, setReceiveExpedientData] = useState(null);
  const [showReceiveExpedientModal, setShowReceiveExpedientModal] = useState(false);
  const [receiveExpedientChecked, setReceiveExpedientChecked] = useState(false);
  const [receiveExpedientShowCheckedError, setReceiveExpedientShowCheckedError] = useState(false);

  const columns = getDynamicColumns(view);
  const currentExpedientPartsColumns = currentExpedientParts.map((part = {}) => part.party_data);

  const loader = useLoaderContext();

  const handleOpenElectronicSignatureModal = (currentDocumentParametersArgument) => {
    setDocumentParameters(currentDocumentParametersArgument);
    setIsElectronicSignatureModalOpen(true);
  }

  const handleCloseElectronicSignatureModal = () => {
    setIsElectronicSignatureModalOpen(false);
  }

  const handleRowClick = (documentaData, expedientParts = []) => {
    setCurrentExpedientParts(expedientParts);
    onRowClick(documentaData);
    setDocumentParameters(documentaData);
  }

  const { toggleIsDeleteDocumentModalOpen } = useDeletePendingDocumentToBeSignedContext();

  const {
    toggleCommentsModal,
    isATurnedExpedient,
    setIsATurnedExpedient
  } = useCommentsModalContext()

  useEffect(() => {
    getTableDataAndSetInTable()
  }, [page, sizePerPage, isATurnedExpedient])

  useEffect(() => {
    if (tableRowData.length > 0) {
      const tableDataFormatted = getDataToTableRows(tableRowData);      
      setDataDocuments(tableDataFormatted);
    }
  }, [isATurnedExpedient])

  const getActionsRow = (row, index, documentaData) => {
    const expedientParts = _.get(row, 'expedientParts', []);

    if (row.governmentBook.received) {
      return (
        <ActionsRow
          onClick={() => handleRowClick(documentaData, expedientParts)}
          onSignClick={() => handleOpenElectronicSignatureModal(documentaData)}
          onCommentsClick={toggleCommentsModal}
          onPreviewDocument={() => setPdfDataToPreviewAndShowPreviewPdfModal(documentaData)}
          documentLink={row.file_path}
          onDeleteClick={toggleIsDeleteDocumentModalOpen}
          isSignedButtonDisabled={isATurnedExpedient}
          isDeleteButtonDisabled={isATurnedExpedient}
          fileType={documentaData.fileType}
        />
      );
    }

    const governmentBook = {
      ...row.governmentBook,
      sender: row.governmentBook.sender.name,
      expedientId: row.expedient_id,
    }
    return (
      <Row key={index} center="xs" around="xs">
        <Col xs={12} className="procedures-actions">
          <div id={index + "button-receive"} className="btn btn-primary" onClick={() => { handleReceiveExpedientShowModal(governmentBook, expedientParts) }}>
            Recibir
          </div>
        </Col>
      </Row>
    );
  }

  const handleReceiveExpedientShowModal = (governmentBook, expedientParts = []) => {
    setReceiveExpedientData(governmentBook);
    setCurrentExpedientParts(expedientParts);
    setShowReceiveExpedientModal(true);
  }

  const handleCloseReceiveExpedientModal = () => {
    setReceiveExpedientChecked(false);
    setShowReceiveExpedientModal(false);
  }

  const handleReceiveExpedient = async () => {
    if (!receiveExpedientChecked) {
      setReceiveExpedientShowCheckedError(true);
      return;
    }

    loader.show(true);
    receiveExpedient(receiveExpedientData)
      .then(async (response) => {
        setReceiveExpedientChecked(false);
        setShowReceiveExpedientModal(false);
        await getTableDataAndSetInTable();
        loader.show(false);
      })
      .catch((error) => {
        loader.show(false);
      });
  };

  const receiveExpedient = async (expedient) => {

    return new Promise(async (resolve, reject) => {
      const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/receive/${expedient.expedientId}`,
        "post",
        null,
        {
          sign: true,
          observation: expedient.observation || null
        }
      );

      const response = await request.executeRequest();

      if (response.code !== 200) {
        NotificationManager.error(`Algo sucedió y no fue posible traer los ${getLabelFromDictionary('global', 'lowerMainName')}, inténtalo nuevamente`);
        return reject(`Algo sucedió y no fue posible traer los ${getLabelFromDictionary('global', 'lowerMainName')}, inténtalo nuevamente`);
      }

      if (
        response.response.error &&
        response.response.message &&
        typeof response.response.message === "string"
      ) {
        NotificationManager.error(response.response.message);
        return reject(response.response.message);
      }

      NotificationManager.success(response.response.message);
      return resolve(response.response.message);
    });
  }

  const getDataToTableRows = (rowData) => {
    const getUpdatedRowData = rowData.map((row, index) => {
      const {
        id: documentId,
        file_path: filePath,
        file_type: fileType,
        filename: fileName,
        ...restOfRowProperties
      } = row;

      const documentaData = {
        documentId,
        filePath,
        fileType,
        fileName,
        ...restOfRowProperties
      }

      const documentSubtype = getDocumentSubtype(row);

      let electronicExpedientLink = '';
      if (_.has(row.governmentBook, 'expedient_number')) {
        electronicExpedientLink = getLink(row.governmentBook.expedient_number, row.expedient_id);
      }      return {
        ...row,
        electronic_expedient_link: electronicExpedientLink,
        document_type: getDisplayNameOfType(row.document_type, documentSubtype),
        created_date: (row.created_at && row.created_at.date) ? getLocaleDateString(row.created_at.date, true) : '',
        update: (row.updated_at && row.updated_at.date) ? getLocaleDateString(row.updated_at.date, true) : '',
        actions: getActionsRow(row, index, documentaData)
      }
    })
    return getUpdatedRowData;
  }

  const getLink = (expedientNumber, expedientId) => {
    return (
      <Link 
        to={{
          pathname: `/expedient_details/${expedientId}`, 
          state: { 
            labelPath: 'Mis expedientes',
            prevPath: window.location.pathname 
          }
        }}
      >
        {expedientNumber}
      </Link>
    );
  };

  const getTableDataAndSetInTable = async () => {
    loader.show(true);
    const tableDataResponse = await documentsPendingOfSignatureDataSource(expedientId, page, sizePerPage);
    if (
      tableDataResponse &&
      tableDataResponse.data &&
      tableDataResponse.data.data &&
      tableDataResponse.data.data.data &&
      tableDataResponse.data.data.data.signatureDocuments &&
      tableDataResponse.data.data.data.tota
    ) {
      const tableDataResponseToArray = Object.values(tableDataResponse.data.data.data.signatureDocuments);
      const filterNonObjectValues = tableDataResponseToArray.filter((row) => typeof row === 'object');
      const tableDataFormatted = getDataToTableRows(filterNonObjectValues);
      setTableRowData(filterNonObjectValues);
      setDataDocuments(tableDataFormatted);
      setTotalDocuments(tableDataResponse.data.data.data.tota);
      loader.show(false);
      return
    }
    setDataDocuments([]);
    setTotalDocuments(0)
    loader.show(false);
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
  }

  const handleShowNotifyEmailModal = () => {
    setIsNotifyEmailModalShowing(true);
  }

  const handleHideNotifyEmailModal = () => {
    setIsNotifyEmailModalShowing(false);
  }

  return (
    {
      dataDocuments,
      page,
      sizePerPage,
      totalDocuments,
      handleTableChange,
      columns,
      handleOpenElectronicSignatureModal,
      handleCloseElectronicSignatureModal,
      isElectronicSignatureModalOpen,
      getTableDataAndSetInTable,
      receiveExpedientData,
      setReceiveExpedientData,
      showReceiveExpedientModal,
      handleCloseReceiveExpedientModal,
      receiveExpedientChecked,
      setReceiveExpedientChecked,
      receiveExpedientShowCheckedError,
      handleReceiveExpedient,
      isNotifyEmailModalShowing,
      handleShowNotifyEmailModal,
      handleHideNotifyEmailModal,
      isATurnedExpedient,
      setIsATurnedExpedient,
      currentExpedientPartsColumns,
    }
  )
}

export default useFilesPendingSignatureTable
