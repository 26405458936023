import React from 'react';
import { PartiesContext } from './PartiesContext';
import { PropTypes } from 'prop-types';

export const PartiesContextProvider = ( props ) => {
    return (
        <PartiesContext.Provider { ...props }/>
    );
}

PartiesContextProvider.propTypes = {
    value: PropTypes.object
}