import React from 'react'
import { Button } from 'react-bootstrap';
import ModalComponent from '../../../../../../helper_components/ModalComponent';
import useDeleteFileModal from '../../hooks/useDeleteFileModal';

const DeletePendingDocumentToBeSignedModal = ({
  onDeleteDocumentPendingToBeSigned = () => { }
}) => {

  const {
    isDeleteDocumentModalOpen,
    toggleIsDeleteDocumentModalOpen,
    handleDeleteDocumentPendingToBeSigned,
  } = useDeleteFileModal({onDeleteDocumentPendingToBeSigned});

  const footerModal = (
    <>
      <Button
        variant="secondary"
        onClick={toggleIsDeleteDocumentModalOpen}
      >
        Cancelar
      </Button>
      <Button
        variant="danger"
        onClick={handleDeleteDocumentPendingToBeSigned}
      >
        <i className="fas fa-trash-alt"></i> Eliminar
      </Button>
    </>
  )

  return (
    <ModalComponent
      header='Eliminar documento'
      body={'¿Está seguro que desea eliminar el documento pendiente por firmar?'}
      footer={footerModal}
      show={isDeleteDocumentModalOpen}
      canClose={true}
      onHide={toggleIsDeleteDocumentModalOpen}
      scroll={true}
      dialogClassName="electronic-signature-modal"
    />
  )
}

export default DeletePendingDocumentToBeSignedModal