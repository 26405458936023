import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";


export const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  selectRow,
  rowStyle,
}) => {

  const sizePerPageOptionRenderer = ({
    text,
    page,
    onSizePerPageChange
  }) => (
    <li
      key={ text }
      role="presentation"
      className="dropdown-item"
      onMouseDown={ (e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      <a
        href="/"
        style={{pointerEvents:"none"}}
        tabIndex="-1"
        role="menuitem"
        data-page={ page }
      >
        { text }
      </a>
    </li>
  );

  return (

    <PaginationProvider
      pagination={ paginationFactory({ page, sizePerPage, totalSize, custom:true, sizePerPageOptionRenderer }) }
    >
      {({
        paginationProps,
        paginationTableProps
      }) => (
        <div>
          <div className="clearFloat" />
          <SizePerPageDropdownStandalone
            { ...paginationProps }
          />
          <PaginationListStandalone
            { ...paginationProps }
          />
          <div className="special-border-top table-responsive">
            <BootstrapTable
              remote
              keyField="id"
              data={data}
              columns={columns}
              {...paginationTableProps}
              onTableChange={onTableChange}
              selectRow={selectRow}
              rowStyle={rowStyle}
            />
          </div>
        </div>
      )}
    </PaginationProvider>
)};
