export const typeParties = [
    {
        codigo: 'actor',
        etiqueta: 'Actor',
        type: 'primary',
        permittedSubjects: ['Familiar', 'Civil', 'Mercantil Oral', 'Mercantil Tradicional', 'Laboral']
    },
    {
        codigo: 'defendant',
        etiqueta: 'Demandado',
        type: 'primary',
        permittedSubjects: ['Familiar', 'Civil', 'Mercantil Oral', 'Mercantil Tradicional', 'Laboral']
    },
    {
        codigo: 'interested_third_party',
        etiqueta: 'Tercero interesado', 
        type: 'primary', 
        permittedSubjects: ['Familiar', 'Civil', 'Mercantil Oral', 'Mercantil Tradicional', 'Laboral', 'Penal']
    },
    {
        codigo: 'dea',
        etiqueta: 'Abogado patrono del demandado', 
        type: 'secondary', 
        key_relation: ['defendant'],
        permittedSubjects: ['Familiar', 'Civil', 'Mercantil Oral', 'Mercantil Tradicional', 'Laboral']
    },
    {
        codigo: 'lawyer_actor',
        etiqueta: 'Abogado patrono del actor', 
        type: 'secondary', 
        key_relation: ['actor'],
        permittedSubjects: ['Familiar', 'Civil', 'Mercantil Oral', 'Mercantil Tradicional', 'Laboral']
    },
    {
        codigo: 'litp',
        etiqueta: 'Abogado del tercero interesado', 
        type: 'secondary', 
        key_relation: ['interested_third_party'],
        permittedSubjects: ['Familiar', 'Civil', 'Mercantil Oral', 'Mercantil Tradicional', 'Laboral', 'Penal']
    },
    {
        codigo: 'public_ministry',
        etiqueta: 'Ministerio público', 
        type: 'primary', 
        permittedSubjects: ['Penal']
    },
    {
        codigo: 'victim',
        etiqueta: 'Victima u ofendido', 
        type: 'primary', 
        permittedSubjects: ['Penal']
    },
    {
        codigo: 'imp',
        etiqueta: 'Imputado', 
        type: 'primary', 
        permittedSubjects: ['Penal']
    },
    {
        codigo: 'accused',
        etiqueta: 'Acusado', 
        type: 'primary', 
        permittedSubjects: ['Penal']
    },
    {
        codigo: 'sentenced',
        etiqueta: 'Sentenciado', 
        type: 'primary', 
        permittedSubjects: ['Penal']
    },
    {
        codigo: 'legal_adviser',
        etiqueta: 'Asesor jurídico', 
        type: 'secondary', 
        key_relation: ['victim'],
        permittedSubjects: ['Penal']
    },
    {
        codigo: 'advocate',
        etiqueta: 'Defensor', 
        type: 'secondary', 
        key_relation: ['imp', 'accused', 'sentenced'],
        permittedSubjects: ['Penal']
    }
];
