import React, { useState } from 'react'

const defaultToggleButtonStyles = {
  backgroundColor: 'var(--secondary)',
  display: 'block',
  width: '100px',
  height: '45px',
  borderRadius: '45px',
  position: 'relative',
  transition: 'background-color .5s',
  margin: 0,
  cursor: 'pointer',
}

const checkedToggleButtonStyles = {
  ...defaultToggleButtonStyles,
  backgroundColor: 'var(--success)',
}

const defaultKnobStyles = {
  width: '50px',
  height: '50px',
  position: 'absolute',
  top: 0,
  bottom: 0,
  margin: 'auto 0',
  backgroundColor: 'white',
  borderRadius: '50%',
  border: '1px solid var(--secondary)',
  transition: 'transform .5s'
}

const checkedKnobStyles = {
  ...defaultKnobStyles,
  transform: 'translate(100%)',
}


const ToggleButton = ({
  id = 'toggleButton',
  children,
  onChange = () => { },
  initialState = false,
  size = 'medium',
  isCheckedControlled,
  ...restOfProps
}) => {
  const isAControlledComponent = (isCheckedControlled !== undefined);
  const [isChecked, setIsChecked] = useState(isAControlledComponent ? isCheckedControlled : initialState)

  const onInputChange = (event) => {
    onChange(event)
    setIsChecked(event.target.checked)
  }

  const inputCheckValue = isAControlledComponent ? isCheckedControlled : isChecked;

  return (
    <div style={{
      width: '100%',
      margin: 0,
    }}>
      <label htmlFor={id}>
        {children}
      </label>
      <label
        htmlFor={id}
        style={inputCheckValue ? checkedToggleButtonStyles : defaultToggleButtonStyles}
      >
        <div
          style={inputCheckValue ? checkedKnobStyles : defaultKnobStyles}
        >
        </div>
      </label >
      <input
        checked={inputCheckValue}
        onChange={onInputChange}
        type="checkbox"
        id={id}
        {...restOfProps}
        style={{
          display: 'none'
        }}
      />
    </div >
  )
}

export default ToggleButton