import React from 'react'

const BodyModal = ({
  actionText,
}) => {

  return (
    <h4 className={'text-center'}>
      ¿Deseas <b>{actionText}</b> la resolución al Juzgado de Primera Instancia?
    </h4>
  )
}

export default BodyModal
