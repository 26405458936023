import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { renderErrorsByInputName } from '../../government_book/my_expedients/expedients_list_logic';
import RenderDocumentPDF from './RenderDocumentPDF';

export const selectStylesDefault = {
  placeholder: (provided, state) => ({
    ...provided,
    color: '#c1c1c1'
  }),
  control: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057',
        backgroundColor: '#e9ecef',
        border: '1px solid #c1c1c1'
      };
    }
    else return {...provided}
  },
  singleValue: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057'
      };
    }
    else return {...provided}  }
};

export const selectStylesError = {
  control: (provided, state) => {
    return {
      ...provided,
      color: 'red',
      backgroundColor: 'transparent',
      border: '1px solid red'
    };
  }
};

export const optionsDocument = [
  {
    value: 'Copia simple',
    label: 'Copia simple'
  },
  {
    value: 'Copia certificada',
    label: 'Copia certificada'
  },
  {
    value: 'Original',
    label: 'Original'
  }
];

export const bodyMultiFile = (
  setFileState, fileState, getRootProps,
  errors, setErrors, getInputProps
) => {

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>* Tipo de documento:</Form.Label>
          <Select
            options={optionsDocument}
            onChange={async (selected) => {

              setFileState({ ...fileState, file_type: selected["value"] });
              delete errors['file_type'];
            }}
            placeholder="Selecciona el tipo de documento"
            styles={errors["file_type"] ? selectStylesError : selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "file_type")}
        </Form.Group>

        <Form.Group>
          <Form.Label>* Etiqueta:</Form.Label>
          <Form.Control
            onChange={(e) => {
              delete errors['alias'];
              setFileState({ ...fileState, alias: e.target.value });
            }}
            placeholder="Agrega una etiqueta para identificar este documento"
            className={errors["alias"] ? "field-error" : ""}
          />
          {renderErrorsByInputName(errors, "alias")}
        </Form.Group>

        <Form.Group>
          <Form.Label>* Anexo:</Form.Label>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Button style={{ fontWeight: "bold", fontSize: "14px" }} variant="primary" size="md" block>
                * Selecciona el archivo a subir
              </Button>
            </div>
          </section>
          {renderErrorsByInputName(errors, "file")}
          {
            fileState["file"] && fileState["file"]["preview"] ?
              <div className="mT-5">
                <div
                  className={fileState["file"] && fileState["file"]["preview"] ? "container-evidence-file" : "d-none"}
                >
                  <img
                    src={fileState["file"]["preview"]}
                    alt="Imagen"
                    style={{
                      width:'auto',
                      maxWidth:'80%'
                    }}
                  />
                </div>
                <small style={{ float: "right" }}>{fileState["file"]["name"]}</small>
                <br />
              </div> :
              ""
          }
        </Form.Group>
      </Form>
    </div>
  );
};

export const footerMultiFile = (handleClose, addFileToTable, fileState) => {
  return (
    <>
      <Button 
        variant="secondary" 
        onClick={() => handleClose()}
      >
        Cancelar
      </Button>
      <Button 
        variant="primary" 
        onClick={() => addFileToTable(fileState)}
      >
        Agregar
      </Button>
    </>
  );
};

export const bodySeeFile = (file) => {
  if (file.type === 'imagen') return (
    <div className='ta-c'>
      <img 
        src={file.url} 
        style={{ width:'auto', maxWidth:'70vw' }} 
      />
    </div>
  )
  else return (
    <div className='ta-c document-pdf-expedients' style={{ minHeight:'70vh', height: 'auto' }}>
      <RenderDocumentPDF
        doc={file.url}
      />
    </div>
  );
};

export const footerSeeFile = (handleClose) => {
  return (
    <>
      <Button 
        variant="secondary" 
        onClick={() => handleClose()}
      >
        Cerrar
      </Button>
    </>
  );
};