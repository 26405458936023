import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLoaderContext } from '../../../../../../../layout/shared/loader_context';
import { useCommentsModalContext } from '../../../context/CommentsModalContext'
import { addComment, getComments, getLocalDateFromComments } from '../services/commentsModalData'
import { useFilesPendingSignatureContext } from '../../../context/FilesPendingSignatureContext';

const useCommentsModal = () => {

  const [textComment, setTextComment] = useState('');
  const [comments, setComments] = useState([]);
  const loader = useLoaderContext();

  const {
    isCommentsModalOpen,
    toggleCommentsModal,
    isATurnedExpedient,
  } = useCommentsModalContext()

  const { documentParameters: { documentId } } = useFilesPendingSignatureContext();

  useEffect(() => {
    if (isCommentsModalOpen) {
      handleGetComments()
    }
  }, [isCommentsModalOpen])

  useEffect(() => {
    setComments([])
  }, [documentId])

  const handleAddComment = async () => {
    if (!textComment) {
      return
    }
    try {
      loader.show(true);
      const addCommentResponse = await addComment(documentId, textComment);
      if (!addCommentResponse) {
        NotificationManager.error('Ocurrió un error al intentar publicar comentario');
        return
      }
      NotificationManager.success('Comentario agregado satisfactoriamente');
      setTextComment('')
      loader.show(false);
      const getCommentsResponse = await handleGetComments();
      if (!getCommentsResponse) {
        return
      }
    } catch (error) {
      console.error(error)
    }
    finally {
      loader.show(false);
    }
  }

  const handleGetComments = async () => {
    loader.show(true);
    try {
      const comments = await getComments(documentId);
      if (!comments) {
        setComments([])
        return false
      }
      const commentsWithLocalDate = getLocalDateFromComments(comments);
      const reverseComments = commentsWithLocalDate.reverse()
      setComments(reverseComments)
    }
    catch (error) {
      setComments([])
      NotificationManager.error('Ocurrió un error al intertar recuperar los comentarios');
      console.error(error)
    }
    finally {
      loader.show(false);
    }
  }

  const handleOnFormChange = ({ target: { value } }) => {
    setTextComment(value)
  }

  return (
    {
      isCommentsModalOpen,
      toggleCommentsModal,
      comments,
      handleOnFormChange,
      textComment,
      handleAddComment,
      isATurnedExpedient,
    }
  )
}

export default useCommentsModal