
import KJUR from 'jsrsasign';
import forge from 'node-forge';

import { File, InfoCer, Signers, Content, ReqSignedData } from './clasesFirma';

export let _Keys = [];
export let _filesToSign = [];
export let PKCS7creado = "";
export let extFileUpload = "";
var filesIn = '';
var filePFX = '';
var nombrePFX = '';

function resetMainVariables() {
    _Keys = [];
    _filesToSign = [];
    extFileUpload = "";
    filesIn = '';
    filePFX = '';
    nombrePFX = '';
}

export function handleUploadFile(ctrlFile) {
    filesIn = ctrlFile;
    try {
        for (var i = 0; i < filesIn.target.files.length; i++) {
            //obteniendo la extension del documento
            extFileUpload = filesIn.target.files[i].name.split('.')[1];

            var fileReader = new FileReader();
            fileReader.readAsArrayBuffer(filesIn.target.files[i]);

            return new Promise((resolve, reject) => {

                fileReader.onload = (e) => {
                    var bufferFile = e.target.result;
                    AddFile(ConvertToHex(bufferFile), extFileUpload)
                    if (_filesToSign.length > 0) {
                        resolve(true);
                    }
                    resolve(false);
                };

            });
        }
    } catch (e) {
        console.error(e);
    }

};

function AddFile(hexFile, fileName) {
    let file = File;
    file.HexFile = hexFile;
    file.FileName = fileName;
    _filesToSign[_filesToSign.length] = file;
}

export function handleUpload(pfxFile) {

    filePFX = pfxFile;
    nombrePFX = pfxFile.name;

    try {

        var fileReader = new FileReader();
        fileReader.readAsArrayBuffer(pfxFile);

        return new Promise((resolve, reject) => {

            fileReader.onload = (e) => {
                var pfx = e.target.result;
                AddKey(pfx, nombrePFX);

                if (_Keys.length > 0) {
                    resolve(true);
                }
                resolve(false);
            };

        });

    } catch (e) {
        console.error(e);
    }
}

export function AddKey(pfx, fileName) {

    let key = {
        Pfx: '',
        FileName: '',
        PrivatePEM: '',
        CertPEM: '',
        Status: '',
        Error: ''
    };
    key.Pfx = pfx;
    key.FileName = fileName;
    _Keys[_Keys.length] = key;
}

export function AddSigner(password) {
    if (_Keys.length === 0)
        return false

    let key = _Keys[_Keys.length - 1];
    // let key = _Keys[0];

    var infoCer = ReadPfx(key.Pfx, password);
    return new Promise((resolve, reject) => {

        if (infoCer.Status) {

            key.PrivatePEM = infoCer.PrivatePEM;
            key.CertPEM = infoCer.CertPEM;
            key.Status = infoCer.Status;
            key.Error = null;
            _Keys[_Keys.length - 1] = key;
            resolve(true);

        } else {
            key.Error = infoCer.Error;
            // _Keys.splice(_Keys.length - 1, 1);
            resolve(false);
        }

    });
}

export function ReadPfx(pfx, password) {

    var infoCer = InfoCer;

    infoCer.Status = false;

    if ((password == 'undefined') || (password == '')) {
        infoCer.Error = "No se proporcionó la contraseña";
        return infoCer;
    }
    try {
        var pkcs12Der = forge.util.binary.raw.encode(new Uint8Array(pfx));
        var p12Asn1 = forge.asn1.fromDer(pkcs12Der);
        var contentFileP12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);

        //Se Obtienen La llave Privada y el Certificado del Archivo pfx
        var certBags = contentFileP12.getBags({ bagType: forge.pki.oids.certBag });
        var pkeyBags = contentFileP12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
        var certBag = certBags[forge.pki.oids.certBag][0];
        var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
        var privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
        var certPEM = forge.pki.certificateToPem(certBag.cert);

        infoCer = GetDetailsCer(certPEM);

        privateKeyPem = privateKeyPem.replace(/\r\n/g, '\n');
        certPEM = certPEM.replace(/\r\n/g, '\n');

        infoCer.PrivatePEM = privateKeyPem;
        infoCer.CertPEM = certPEM;
        infoCer.Status = true;

    } catch (error) {
        infoCer.Error = error;
    }

    return infoCer;
}

export function getDetailsCer(pemCertificate) {

    let infoCer = InfoCer;

    var cert = forge.pki.certificateFromPem(pemCertificate);
    infoCer.SerialNumber = cert.serialNumber;
    var cerAttributes = cert.subject.attributes;

    var attribute = cerAttributes.filter(cerAttributes => cerAttributes.type == "2.5.4.5")
    if (attribute.length == 1)
        infoCer.CURPFirel = attribute[0]["value"];

    attribute = cerAttributes.filter(cerAttributes => cerAttributes.type == "2.5.4.3")
    if (attribute.length == 1)
        infoCer.CommonName = DecodeUTF(attribute[0]["value"]);

    return infoCer;
}

export function GetDetailsCer(pemCertificate) {

    let infoCer = InfoCer;

    var cert = forge.pki.certificateFromPem(pemCertificate);
    infoCer.SerialNumber = cert.serialNumber;
    var cerAttributes = cert.subject.attributes;

    var attribute = cerAttributes.filter(cerAttributes => cerAttributes.type == "2.5.4.5")
    if (attribute.length == 1)
        infoCer.CURPFirel = attribute[0]["value"];

    attribute = cerAttributes.filter(cerAttributes => cerAttributes.type == "2.5.4.3")
    if (attribute.length == 1)
        infoCer.CommonName = DecodeUTF(attribute[0]["value"]);

    return infoCer;
}

export function Sign(listaFirmas) {
    if (listaFirmas.length > 0 && _filesToSign.length > 0) {

        return new Promise((resolve, reject) => {
            var signers = GetInfoSigner();
            for (let i = 0; i < _filesToSign.length; i++) {
                var req = CreateReqSignedData(_filesToSign[i].HexFile, signers);
                PKCS7creado = CreatePKCS7(req);
            }

            if (PKCS7creado != '') {
                resolve(true);
            }
            resolve(false);

        });

    } else {
        alert('Error, parametros no definidos.');
    }
}

export function SignMultifirma(_KeysSigners, bufferFile, realName) {
    _Keys = _KeysSigners;

    if (_Keys.length > 0 && _filesToSign.length > 0) {

        return new Promise((resolve, reject) => {
            var signers = GetInfoSigner();
            for (let i = 0; i < _filesToSign.length; i++) {
                var req = CreateReqSignedData(_filesToSign[i].HexFile, signers);
                PKCS7creado = CreatePKCS7(req);
            }
            resetMainVariables()
            if (PKCS7creado != '') {
                resolve(true);
            }
            resolve(false);
        });

    } else {
        alert('Error, parametros no definidos.');
    }
}

function GetInfoSigner() {
    var signers = Signers;

    var certs = new Array();
    var signerInfos = new Array();

    for (let i = 0; i < _Keys.length; i++) {
        certs.push(_Keys[i].CertPEM);

        var signerInfo = {
            hashAlg: '',
            sAttr: {
                SigningTime: {}
            },
            signerCert: '',
            sigAlg: '',
            signerPrvKey: ''
        };

        signerInfo.hashAlg = 'sha256';

        var sAttr = {
            SigningTime: {}
        };
        sAttr.SigningTime = new Object();
        signerInfo.sAttr = sAttr;

        signerInfo.signerCert = _Keys[i].CertPEM;
        signerInfo.sigAlg = 'SHA256withRSA';
        signerInfo.signerPrvKey = _Keys[i].PrivatePEM;

        signerInfos.push(signerInfo);
    }
    signers.certs = certs;
    signers.signerInfos = signerInfos;

    return signers;
};

function CreateReqSignedData(fileToSign, signers) {
    let content = Content;
    content.hex = fileToSign;

    let req = ReqSignedData;
    req.content = content;
    req.certs = signers.certs;
    req.signerInfos = signers.signerInfos;

    return req;
}

function CreatePKCS7(req) {
    try {

        var signedData = KJUR.asn1.cms.CMSUtil.newSignedData(req);
        var pkcs7 = RemoveCMSHeader(signedData.getPEM());

        return pkcs7;
    } catch (error) {
        console.error(error);
    }
};

function ConvertToHex(arrayBuffer) {
    const buff = new Uint8Array(arrayBuffer);

    const hexOctets = [];

    for (let i = 0; i < buff.length; ++i) {
        hexOctets.push(byteToHex[buff[i]]);

    }
    return hexOctets.join("");
}

const byteToHex = [];

for (let n = 0; n <= 0xff; ++n) {
    const hexOctet = n.toString(16).padStart(2, "0");
    byteToHex.push(hexOctet);
}

function DecodeUTF(s) {
    for (var a, b, i = -1, l = (s = s.split("")).length, o = String.fromCharCode, c = "charCodeAt"; ++i < l;
        ((a = s[i][c](0)) & 0x80) &&
        (s[i] = (a & 0xfc) == 0xc0 && ((b = s[i + 1][c](0)) & 0xc0) == 0x80 ?
            o(((a & 0x03) << 6) + (b & 0x3f)) : o(128), s[++i] = "")
    );
    return s.join("");
}
//
function RemoveCMSHeader(cms) {
    var pkcs7 = ""
    try {
        pkcs7 = cms.replace("-----END CMS-----", "").replace("-----BEGIN CMS-----", "").replace(/(\r\n|\n|\r)/gm, "");
    }
    catch (error) {
        console.error(error);
    }
    return pkcs7;
}
