import React from "react";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { getLabelFromDictionary } from "../../../config/dictionary";


export const columnsDetail = [
    {
      dataField: "expedient_number_link",
      text: `${getLabelFromDictionary( 'tableContent', 'label' )}`,
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "sender_name",
      text: "Emisor",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "receiver_name",
      text: "Receptor",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "created",
      text: "Transferencia",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "aceptation_date",
      text: "Aceptación",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "time",
      text: "Duración",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "effective_time",
      text: "Tiempo efectivo",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    }
];

export const searchHistoryExpedients = async (
    expedient_number,
    size_per_page,
    page,
    setData,
    setTotal,
    setMessageNotFound
) => {
    expedient_number = String(expedient_number).replace(/\//g,'-');

    const request = new RequestNewVersion(
        `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/history/number/${expedient_number}/${size_per_page}?page=${page}`,
        "get",
        null,
        {}
    );

    const responseSearch = await request.executeRequest();
    if ( responseSearch.code !== 200 ) {

        if ( 
            responseSearch.response.error &&
            responseSearch.response.message && 
            responseSearch.response.message !== "" &&
            typeof responseSearch.response.message === "string"
        ) {

            setMessageNotFound( responseSearch.response.message );
            return;
        }

        NotificationManager.error( "Algo ha sucedido, por favor inténtalo nuevamente" );
    }
    else {

        if ( responseSearch.response.history && responseSearch.response.history.length > 0 ) {
            const formattedData = await formattedResponseOfSearch(
                responseSearch.response.history
            );

            setData( formattedData );
            setTotal( responseSearch.response.total );
        }
        else if ( responseSearch.response.message && typeof responseSearch.response.message === "string" ) {

            setMessageNotFound(responseSearch.response.message);
        }
    }
};


const formattedResponseOfSearch = (
    historyResponseExpedients
) => {
    historyResponseExpedients = getTimeAcumulated(historyResponseExpedients.reverse());
    historyResponseExpedients.map( (expedient) => {

        expedient["expedient_number_link"] = (
            <Link 
                to={{
                    pathname: `/expedient_details/${expedient.expedient_id}`,
                    state: { 
                      labelPath: 'Buscar expediente',
                      prevPath: window.location.pathname 
                    }
                }}
            >
                {expedient["expedient_number"]}
            </Link>
        );
        expedient["sender_name"] = expedient["sender"]["name"];
        expedient["receiver_name"] = receiverUserOrDependence(expedient);
        expedient["created"] = getTextOfDate( expedient["created_at"].replace(/-/g, "/") );
        expedient["aceptation_date"] = expedient["reception_date"] ? getTextOfDate( expedient["reception_date"].replace(/-/g, "/") ) : "Por confirmar";
        
        expedient["time"] = expedient["created_at"] && expedient["reception_date"] ? 
                            getTimeTranscurred( new Date(expedient["created_at"].replace(/-/g, "/")), new Date(expedient["reception_date"].replace(/-/g, "/")) ) : 
                            !expedient["reception_date"] ? getTimeTranscurred( new Date(expedient["created_at"].replace(/-/g, "/")), new Date() ) : "N/A";

        return expedient;
    });

    return historyResponseExpedients;
};


const getTimeAcumulated = (expedientsOfSearch) => {

    if (expedientsOfSearch.length > 0) {
        for (let i = 0; i < expedientsOfSearch.length; i++) {
            if (i === 0) {
                expedientsOfSearch[i]['effective_time'] = 'N/A';
            }
            else if (i > 0) {
                expedientsOfSearch[i]['effective_time'] = getTimeTranscurred(
                    new Date(expedientsOfSearch[i - 1]['reception_date'].replace(/-/g, "/")),
                    new Date(expedientsOfSearch[i]['created_at'].replace(/-/g, "/"))
                );
            }
        }
    }

    return expedientsOfSearch.reverse();
};


const getTextOfDate = ( initialDate ) => {

    const date = new Date(initialDate).toLocaleDateString('es-MX', {
        day:'numeric',
        month:'long',
        year:'numeric'
    });

    return date;
};

const getTimeTranscurred = ( initialDate, finalDate ) => {

    const dateCreated = new Date(initialDate.getTime());
    const dateReception = new Date(finalDate.getTime());
    
    return Math.round((dateReception - dateCreated) / 86400000) + ( 
        Math.round(
            (dateReception - dateCreated) / 86400000) > 1 ||
            Math.round(
                (dateReception - dateCreated) / 86400000) === 0 ? 
                " días" : " día");
};


const receiverUserOrDependence = (expedient) => {

    if (expedient['external'] && expedient['dependence'] !== null) {
        return expedient['dependence'];
    }
    else {
       return expedient["receiver"]["name"];
    }
};