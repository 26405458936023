import { idFormatter, initialAgeFormatter, representationFormatter } from './columnsFormatter';
import { tableStyles } from './tableStyles';

export const columns = [
  {
    dataField: "id",
    text: "No",
    ...tableStyles,
    formatter: idFormatter,
  },
  {
    dataField: "initial_age",
    text: "Edad al inicio del juicio",
    formatter: initialAgeFormatter,
    ...tableStyles,
  },
  {
    dataField: "nationality",
    text: "Nacionalidad",
    ...tableStyles
  },
  {
    dataField: "gender",
    text: "Genero",
    ...tableStyles
  },
  {
    dataField: "representation",
    text: "Representación",
    ...tableStyles,
    formatter: representationFormatter,
  },
];