import React from 'react'
import ModalComponent from '../helper_components/ModalComponent'
import { BodyTransfer, footerTransfer } from '../government_book/details_expedient/expedient_details_logic'
import SupremeCourtOfJusticeReceiverConfirmationModal from '../government_book/details_expedient/modals/supremeCourtOfJusticeReceiverConfirmationModal/SupremeCourtOfJusticeReceiverConfirmationModal'
import { getLabelFromDictionary } from '../../config/dictionary'

const TransferModal = ({
  isSupremeCourtOfJusticeReceiverConfirmationModalShowing,
  setIsSupremeCourtOfJusticeReceiverConfirmationModalShowing,
  onAcceptSupremeCourtOfJusticeReceiverConfirmationModal,
  handleSubmitTransfer,
  dataTransfer,
  setDataTransfer,
  isAnExternalTransfer,
  transferErrors,
  showTransferModal,
  transferUsers,
  transferDependences,
  handleCloseTransferModal,
  canTurnForeign,
  isASupremeCourtOfJusticeReceiver,
  expedientParts = [],
  setTransferErrors,
  canCreateToca,
  isATransferOfTypeCreateToca,
  isAnAppealTypeProcedure,
}) => {
  
  return (
    <>
      <ModalComponent
        header={getLabelFromDictionary('global', 'mainName')}
        body={<BodyTransfer
          expedientParts={expedientParts}
          dataTransfer={dataTransfer}
          setDataTransfer={setDataTransfer}
          usersOptions={transferUsers}
          dependencesOptions={transferDependences}
          errors={transferErrors}
          canTurnForeign={canTurnForeign}
          isAnExternalTransfer={isAnExternalTransfer}
          isASupremeCourtOfJusticeReceiver={isASupremeCourtOfJusticeReceiver}
          setErrors={setTransferErrors}
          canCreateToca={canCreateToca}
          isATransferOfTypeCreateToca={isATransferOfTypeCreateToca}
          isAnAppealTypeProcedure={isAnAppealTypeProcedure}
        />}
        footer={footerTransfer(handleCloseTransferModal, handleSubmitTransfer)}
        show={showTransferModal}
        canClose={true}
        onHide={handleCloseTransferModal}
        size={'xl'}
      />

      <SupremeCourtOfJusticeReceiverConfirmationModal
        isShowing={isSupremeCourtOfJusticeReceiverConfirmationModalShowing}
        onConfirmClick={onAcceptSupremeCourtOfJusticeReceiverConfirmationModal}
        onCancelClick={() => setIsSupremeCourtOfJusticeReceiverConfirmationModalShowing(false)}
      />
    </>
  )
}

export default TransferModal