import React from 'react'

const FormTitle = (props) => {
  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: '33.6px',
        fontWeight: '700',
        lineHeight: '50.4px',
        letterSpacing: '0.5px',
        padding: '40px 0px',
      }}
      {...props}
    />
  )
}

export default FormTitle