import React from 'react'
import { Button, Form, ListGroup } from 'react-bootstrap';
import ModalComponent from '../../../helper_components/ModalComponent'
import GrowLoader from '../../../layout/shared/GrowLoader';
import useUsersAndPermissionsListModal from '../customHooks/useUsersAndPermissionsListModal';

const UsersAndPermissionsListModal = ({ isModalShowing, setModalShowing, document = {}, expedientId }) => {
  const {
    onPermissionToggled,
    isLoadingUsersPermissions,
    usersPermissions,
    onPostUsersPermissions,
    onUsersPermissionsToSearchInputChange,
    usersPermissionsToSearch,
    onSearchUserPermision,
  } = useUsersAndPermissionsListModal({ setModalShowing, documentExpedientId: document.id, expedientId });

  const bodyModal = (
    <>

      <div>
        <Form
          onSubmit={onSearchUserPermision} className='d-flex justify-content-center align-items-center pb-3'
          style={{ borderBottom: '1px solid #e7e7e7', gap: '10px' }}
        >
          <Form.Group controlId="formBasicEmail" className='m-0'>
            <Form.Control
              type="text"
              placeholder="Buscar usuario"
              style={{ borderRadius: '50px' }}
              onChange={onUsersPermissionsToSearchInputChange}
              value={usersPermissionsToSearch}
            />
          </Form.Group>
          <button
            type="submit"
            className="btn btn-outline-secondary rounded-circle"
          >
            <i className="fas fa-search text-black-50"></i>
          </button>
        </Form>
        {
          isLoadingUsersPermissions
            ?
            <div className='pt-3'>
              <GrowLoader />
            </div>
            :
            usersPermissions.length > 0
              ?
              <>
                <ListGroup
                  variant="flush"
                >
                  <ListGroup.Item
                    className='d-flex justify-content-between align-items-center'
                    style={{ gap: '20px' }}
                  >
                    <div className='d-flex justify-content-center flex-grow-1'>
                      Usuario
                    </div>
                    <span className='m-0 text-center' style={{ width: 'min-content' }}>
                      Acceso al documento
                    </span>
                  </ListGroup.Item>

                  {
                    usersPermissions.map((userPermissions) => (
                      <ListGroup.Item
                        key={userPermissions.id}
                        className='d-flex justify-content-between align-items-center'
                        style={{ borderTop: '1px solid #e7e7e7', gap: '20px' }}
                      >
                        <div>
                          <p className='m-0' style={{ fontSize: '14px' }}>
                            {userPermissions.fullname}
                          </p>
                          <p className='m-0' style={{ fontSize: '11px' }}>
                            {userPermissions.email}
                          </p>
                        </div>
                        <div className='pr-3'>
                          <Form >
                            <Form.Check
                              type='switch'
                              id={`custom-switch-${userPermissions.id}`}
                              defaultChecked={userPermissions.isPermissionEnabled}
                              value={userPermissions.id}
                              onClick={onPermissionToggled}
                            />
                          </Form>
                        </div>
                      </ListGroup.Item>
                    ))
                  }
                </ListGroup>
              </>
              :
              <div className='text-center pt-4' >Sin Resultados</div>
        }
      </div>
    </>
  );

  const footerModal = (
    <>
      <Button
        onClick={() => setModalShowing(false)}
        variant="secondary"
      >
        Cerrar
      </Button>
      <Button
        onClick={onPostUsersPermissions}
        disabled={isLoadingUsersPermissions}
      >
        Guardar
      </Button>
    </>
  );

  return (
    <div>
      <ModalComponent
        size="lg"
        header={`Gestionar usuarios con acceso al documento ${document.filename ? document.filename : ''}`}
        body={bodyModal}
        footer={footerModal}
        show={isModalShowing}
        canClose
        onHide={setModalShowing}
        scroll={true}
        dialogClassName="users-and-permissions-list-modal"
      />
    </div>
  )
}

export default UsersAndPermissionsListModal