import React, { createContext, useContext } from 'react';

export const PartiesContext = createContext();
PartiesContext.displayName = 'PartiesContext';

export const usePartiesContext = () => {
    const context = useContext(PartiesContext);

    if( typeof context === 'undefined' ) throw new Error('To use usePartiesContext you have to wrap your component inside a PartiesContextProvider component.');

    return context;
}