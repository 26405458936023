export const electronicSignatureSettingsFirel = [
  {
    id: 2,
    type: 'firel',
    isEnabled: true,
    displayName: 'Firel',
    isMultiSign: true,
    default: true,
    isMultiSignDisplayName: true,
    multiSignDisplayName: 'PFX',
  }
];