import React from 'react'

const NavItem = ({
  children,
  navItemName = '',
  currentSelectedNav = '',
  ...restOfProps
}) => {

  const isTheSelectedNav = (navItemName === currentSelectedNav);

  const selectedNavStyle = {
    backgroundColor: '#1D78FF',
    color: '#FFFFFF',
    cursor: 'unset',
  }

  const navStyle = {
    padding: '16px',
    backgroundColor: '#AFAFAF',
    color: '#404040',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    cursor: 'pointer',
    ...(isTheSelectedNav ? selectedNavStyle : {})
  }

  return (
    <div
      data-nav-item-name={navItemName}
      style={navStyle}
      {...restOfProps}
    >
      {children}
    </div>
  )
}

export default NavItem