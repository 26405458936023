import _ from "lodash"

export const getPartyDataWithFormattedPersonNames = (partyData) => {
  if (_.isEmpty(partyData)) {
    return partyData
  }

  let {
    names,
    name,
    paternal_surname,
    firstname,
    firstName,
    maternal_surname,
    secondname,
    secondName,
    ...restOfPartyData
  } = partyData;

  const formattedNames = names || name;
  const formattedPaternalSurname = paternal_surname || firstname || firstName;
  const formattedMaternalSurname = maternal_surname || secondname || secondName;

  const formattedPartyData = {
    ...restOfPartyData,
    names: formattedNames,
    paternal_surname: formattedPaternalSurname,
    maternal_surname: formattedMaternalSurname,
  }

  return formattedPartyData
}