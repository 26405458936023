import _ from "lodash";
import useFormValues from "../../../../../../hooks/useFormValues";
import { interlocutoryJudgmentDefaultFormValues } from "../constants/defaultValues";

const useInterlocutoryJudgment = () => {

  const interlocutoryJudgmentFormMethods = useFormValues(interlocutoryJudgmentDefaultFormValues);

  const formValues = {
    ...interlocutoryJudgmentFormMethods.formValues,
  }

  const assertions = {
    isAProtocol: String(interlocutoryJudgmentFormMethods.formValues.protocol) === 'true',
    isNotAProtocol: String(interlocutoryJudgmentFormMethods.formValues.protocol) === 'false',

    isASenseResolutionpProceeded: interlocutoryJudgmentFormMethods.formValues.sense_resolution === 'proceed',
    isNotASenseResolutionpProceeded: interlocutoryJudgmentFormMethods.formValues.sense_resolution === 'no_proceed',
  }

  const setInitialState = (initialState) => {
    const doesInitialStateExist = _.isObject(initialState);
    if (!doesInitialStateExist) {
      return
    }

    interlocutoryJudgmentFormMethods.setFormValues({ ...interlocutoryJudgmentFormMethods.formValues, ...initialState });
  }

  return {
    interlocutoryJudgmentFormMethods,
    formValues,
    assertions,
    setInitialState,
  }
}

export default useInterlocutoryJudgment