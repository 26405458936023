import React from "react";
import FilesPendingSignatureTable from "../../details_expedient/components/filesPendingSignatureTable/FilesPendingSignatureTable";
import Loader from "../../../layout/shared/Loader";
import TransferModalWrapper from "./TransferModalWrapper";
import { fetchPendingSignatureDocuments } from "./signature_pending_list_logic";
import FilesPendingSignatureContextProvider from "../../details_expedient/components/filesPendingSignatureTable/context/FilesPendingSignatureContext";
import _ from 'lodash';
import useSignaturePendingListState from "../hooks/useSignaturePendingListState";

const SignaturePendingList = ({ history }) => {

  const {
    transferState,
    currentExpedientId,
    handleRowClick,
  } = useSignaturePendingListState({ history });

  return (
    <FilesPendingSignatureContextProvider
      onRowClick={handleRowClick}
      documentsPendingOfSignatureDataSource={fetchPendingSignatureDocuments}
      view={'control_panel'}
      shouldShowMessageIfThereAreNoDocuments
    >
      <FilesPendingSignatureTable handleOpenTransfer={transferState.handleOpenTransferModal} />
      <TransferModalWrapper transferState={transferState} currentExpedientId={currentExpedientId} />
      <Loader />
    </FilesPendingSignatureContextProvider>
  );
}

export default SignaturePendingList;