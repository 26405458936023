import React, { useState, useEffect, useMemo } from 'react';
import ModalComponent from '../../../helper_components/ModalComponent';
import { Button, Form } from 'react-bootstrap';
import { Row, Col } from 'react-flexbox-grid';
import { getExpedientStakeHolders } from './notify_email_modal_logic';

const NotifyEmailModal = ({
  expedientId,
  showModal,
  handleOnCancel,
  onAcceptClick
}) => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    email: '',
    fullname: '',
    id: null,
    canDelete: true
  });
  const canNotify = useMemo(() => users.length > 0, [users]);

  useEffect(() => {
    (async () => {
      await getExpedientStakeHolders(expedientId, setUsers);
    })();
  }, []);

  const onCancel = async () => {
    await handleOnCancel();
  }

  return (
    <ModalComponent
      header='Notificar vía correo electrónico:'
      body={bodyNotifyEmail(
        users, setUsers, newUser, setNewUser
      )}
      footer={footerNotifyEmail(
        onCancel,
        onAcceptClick,
        canNotify,
        users
      )}
      show={showModal}
      canClose={true}
      onHide={onCancel}
      scroll={true}
      dialogClassName="modal-notify-email"
    />
  );
}

const footerNotifyEmail = (handleClose, handleRequestInsert, canNotify, users) => {
  return (
    <>
      <Button
        variant="secondary"
        className="mR-10"
        onClick={() => handleClose()}
      >
        Cancelar
      </Button>
      <Button
        variant="primary"
        onClick={() => handleRequestInsert({ users })}
        disabled={!canNotify}
      >
        Notificar
      </Button>
    </>
  );
};

const bodyNotifyEmail = (users, setUsers, newUser, setNewUser) => {
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmailValid = regexEmail.test(newUser.email);
  const isNameValid = newUser.fullname.trimStart() !== '';
  const canAddUser = isNameValid && isEmailValid;

  const addNewUser = () => {
    if (canAddUser) {
      let currentUsers = [...users];
      currentUsers.unshift(newUser);
      setUsers(currentUsers);
      setNewUser({
        fullname: '',
        email: '',
        id: null,
        canDelete: true
      });
    }
  };

  const handleDeletItem = (indexToDelete) => {
    let currentUsers = [...users];
    currentUsers.splice(indexToDelete, 1);
    setUsers(currentUsers);
  };

  return (
    <div>
      <section className="container">
        <h4>
          <b>
            Aquí puedes agregar algún contacto manualmente:
          </b>
        </h4>
        <div style={{ float: 'right', marginTop: '-20px' }}>
          <Button
            style={{ fontWeight: "bold", fontSize: "14px", backgroundColor: "#02cc98" }}
            size="md"
            variant="outline-light"
            disabled={!canAddUser}
            onClick={() => addNewUser()}
          >
            <strong>
              <i className="fas fa-plus" />{'  '}
              Agregar
            </strong>
          </Button>
        </div>
        <div className="clearFloat" />
        <div className="mT-10" style={{ marginBottom: '-20px' }}>
          <Form>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    <b>Nombre Completo:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Agrega el nombre completo"
                    onChange={(e) => setNewUser({ ...newUser, fullname: e.target.value })}
                    value={newUser.fullname}
                  />
                  <Form.Text
                    style={newUser.fullname !== '' && !isNameValid ? { color: 'red' } : null}
                  >
                    {newUser.fullname !== '' && !isNameValid ?
                      'Aún no has escrito nada...' :
                      'Agrega el nombre completo de quien se notificará'}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    <b>Correo Electrónico:</b>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Agrega un correo electrónico"
                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                    value={newUser.email}
                  />
                  <Form.Text
                    style={newUser.email !== '' && !isEmailValid ? { color: 'red' } : null}
                  >
                    {newUser.email !== '' && !isEmailValid ?
                      'Ingresa un correo electrónico válido...' :
                      'Agrega el correo electrónico de quien se notificará'}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div style={{ overflowX: 'scroll' }}>
          <hr />
          <table
            className="table table-collapsed"
            style={{
              border: '1px solid #a2a2a2'
            }}>
            <tbody>
              <tr style={{ backgroundColor: '#02cc98' }}>
                <th className="text-center button-see-evidence-documents">Nombre:</th>
                <th className="text-center button-see-evidence-documents">Correo:</th>
                <th className="text-center button-see-evidence-documents">Acciones:</th>
              </tr>
              {users.map((u, i) => (
                <tr key={i}>
                  <td className="text-center">
                    {u.fullname}
                  </td>
                  <td className="text-center">
                    {u.email}
                  </td>
                  <td className="text-center">
                      <Button
                        size="sm"
                        variant="danger"
                        title="Borrar elemento"
                        onClick={() => handleDeletItem(i)}
                      >
                        <i className="ti-trash" />
                      </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default NotifyEmailModal;
