import React from 'react'
import { Button } from 'react-bootstrap'

const FooterModal = ({
  onCancelClick = () => { },
  onConfirmClick = () => { },
}) => {
  return (
    <>
      <Button variant='secondary' onClick={onCancelClick}>
        Cancelar
      </Button>
      <Button onClick={onConfirmClick}>
        Confirmar
      </Button>
    </>
  )
}

export default FooterModal