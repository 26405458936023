import React from 'react'
import ModalComponent from '../../../../helper_components/ModalComponent'
import { Button } from 'react-bootstrap';
import BodyModal from './components/bodyModal/BodyModal';
import FooterModal from './components/footerModal/FooterModal';
import useReplicasManagementModal from './hooks/useReplicasManagementModal';

const ReplicasManagementModal = ({
  document,
}) => {

  const {
    isModalShowing,
    handleMainButtonClick,
    handleCloseModal,
    handleManageReplicas,
    actionText,
  } = useReplicasManagementModal({
    document,
  });

  return (
    <>
      <Button
        type={'button'}
        variant={'info'}
        className={'text-white'}
        title={'Remisión de resolución a Primer Instancia'}
        onClick={handleMainButtonClick}
      >
        <i className="fas fa-clone"></i>
      </Button>

      {
        isModalShowing
        &&
        <ModalComponent
          header={'Remisión de resolución a Primera Instancia'}
          body={<BodyModal actionText={actionText} />}
          footer={(
            <FooterModal
              actionText={actionText}
              onReplicasButtonClick={handleManageReplicas}
              onCloseButtonClick={handleCloseModal}
            />
          )}
          show={isModalShowing}
          canClose={true}
          onHide={handleCloseModal}
          size={'lg'}
        />
      }
    </>
  )
}

export default ReplicasManagementModal
