export const representationOptions = [
  {
    value: '1',
    label: "Defensa publica ( gratuita)",
    id: 'check_representation_1',
  },
  {
    value: '2',
    label: "Defensa privada",
    id: 'check_representation_2',
  },
  {
    value: '3',
    label: "Defensa publica y privada",
    id: 'check_representation_3',
  },
  {
    value: '4',
    label: "Representacion social",
    id: 'check_representation_4',
  },
  {
    value: '5',
    label: "No contaron con representacion legal",
    id: 'check_representation_5',
  },
  {
    value: '6',
    label: "No identificado",
    id: 'check_representation_6',
  }
]