import React, { useState, useEffect } from "react";
import { getAvaliableProcesses, startProcess } from "./process_list_logic";
import { Col, Row } from "react-flexbox-grid";

import OneColumn from "../layout/containers/OneColumn";
import { Card, Button } from "react-bootstrap";
import Dotdotdot from "react-dotdotdot";
import { useLoaderContext } from "../layout/shared/loader_context";

const ProcessList = props => {
  const [avaliableProcesses, setAvaliableProcesses] = useState([]);
  const loader = useLoaderContext();

  let pageConfig = {
    title: "Tramites disponibles",
    active: "my_formalities"
  };

  useEffect(() => {
    (async function() {
      await getAvaliableProcesses(props, setAvaliableProcesses);
    })();
  }, []);

  return (
    <OneColumn {...props} pageConfig={pageConfig}>
      <h4 className="mB-20 mT-30 w-100 text-center">Trámites disponibles</h4>

      <Row className="mT-30">
        {avaliableProcesses.map((el, index) => {
          return (
            <Col key={index} xs={12} sm={6} lg={6} xl={4}>
              <Card key={index} className="w-100 procedure-card mB-20">
                <Card.Header>
                  <div className="text-container">
                    <Dotdotdot clamp={2} className="font-weight-bold">
                      {el.name}
                    </Dotdotdot>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="text-container">
                    <Dotdotdot clamp={7} className="text-justify">
                      {el.description}
                    </Dotdotdot>
                  </div>
                </Card.Body>
                <Card.Footer className="p-0">
                  <Button
                    variant="outline-primary"
                    className="w-100 pY-10 font-weight-bolder"
                    onClick={() => startProcess(el.id, props, loader)}
                  >
                    Ir al trámite
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>
    </OneColumn>
  );
};

export default ProcessList;
