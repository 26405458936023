import { electronicSignatureSettingsDefaultValue } from "./electronicSignatureSettingsDefaultValue";

export const getElectronicSignatureSettings = (electronicSignatureSettings = electronicSignatureSettingsDefaultValue) => {

  return {
    getDefaultType(isMultiSign) {
      let electroniSignaturesListToFind = this.getTypes(isMultiSign);

      let faultType = electroniSignaturesListToFind.find((signature) => signature.default);
      if (faultType) {
        return faultType.type
      }

      faultType = electroniSignaturesListToFind[0];
      if (faultType) {
        return faultType.type
      }

      console.error('electronicSignatureSettings is empy')
    },

    getTypes(isMultiSign) {
      const enabledSignatures = electronicSignatureSettings.filter(signature => signature.isEnabled);
      if (isMultiSign) {
        const signaturesWithCustomDisplayName = enabledSignatures.map((signature) => {
          if (signature.isMultiSignDisplayName)
            return { ...signature, displayName: signature.multiSignDisplayName }
          return signature
        });
        return signaturesWithCustomDisplayName.filter(signature => signature.isMultiSign);
      }
      return enabledSignatures;
    },
  }
}