import React, { useEffect, useMemo, useState } from 'react'
import Cookies from "js-cookie";
import { checkIfStringOnlyIncludesLettersNumbersOrSpaces } from '../../../services/dataFormatting/getLocaleDateString';
import { getFileNameWithoutExtension } from '../../../services/dataFormatting/getFileNameWithoutExtension';
import { NotificationManager } from 'react-notifications';
import ElectronicSignatureProcess from './ElectronicSignatureProcess/ElectronicSignatureProcess';
import { useElectronicSignatureProcessContext } from './ElectronicSignatureProcess/context/ElectronicSignatureProcessContext';
import TextTooltip from '../../tootip/TextTooltip';
import ModalComponent from '../../helper_components/ModalComponent';
import { Button } from 'react-bootstrap';

const documentNotEnabledToEditText = 'Esperando a que finalice el proceso de firma electrónica en el formulario habilitado';
const documentReadyToSignText = 'Documento listo para firmar';
const editModalText = 'Si procedes a editar tendrás que volver a ingresar las credenciales del documento a firmar';
const addElectronicSignatureNowText = 'Si deseas agregar las credenciales ahora tendrás que volver a ingresar las credenciales del documento que se está editando actualmente';

function DynamicFormElectronicSignatureType({
  stateDynamicFormFileType,
  setStateDynamicFormFileType,
  dynamicElementName,
  onAddFiles,
  showErrorTextIfIsNotSigned,
  isEditingEnabled,
  setCurrentFormElectronicSignatureToEdit,
  enableNextFormElectronicSignature = { enableNextFormElectronicSignature }
}) {
  const [isDocumentReadyToSign, setIsDocumentReadyToSign] = useState(false);
  const [isEditSignatureWarningModalShowing, setIsEditSignatureWarningModalShowing] = useState(false);

  const dynamicElementAccessKey = useMemo(() => {
    if (stateDynamicFormFileType[dynamicElementName] && stateDynamicFormFileType[dynamicElementName].access_key) {
      return stateDynamicFormFileType[dynamicElementName].access_key;
    }
    return stateDynamicFormFileType[dynamicElementName];
  }, [stateDynamicFormFileType[dynamicElementName]])

  const {
    documentToSign,
    getPkcs7,
    isReadyToCreatePkcs7,
    isMultipleSignature,
    onResetSignatures,
  } = useElectronicSignatureProcessContext();

  useEffect(() => {
    resetSignaturesIfEditingIsEnabled();
  }, [isEditingEnabled]);

  const resetSignaturesIfEditingIsEnabled = () => {
    if (isEditingEnabled){
      onResetSignatures();
    }
  }

  const onCreatePkcs7 = async () => {
    if (!isReadyToCreatePkcs7) {
      return
    }

    let pkcs7 = '';
    try {
      pkcs7 = await getPkcs7();
      if (!Boolean(pkcs7)) {
        return
      }
    } catch (error) {
      NotificationManager.error(error.message);
    }

    const { names_03: userName, paternal_surname_03: userPaternalSurname } = Cookies.get();

    let multiSignParams = {};

    if (isMultipleSignature) {
      multiSignParams = {
        isMultipleSignature,
      }
    }

    setStateDynamicFormFileType((previousState) => ({
      ...previousState,
      [dynamicElementName]: {
        access_key: dynamicElementAccessKey,
        Pkcs7Original: pkcs7,
        CertificateType: 3,
        FileName: documentToSign.name,
        UserName: `${userName} ${userPaternalSurname}`,
        ...multiSignParams,
      }
    }))
    setIsDocumentReadyToSign(true);
    enableNextFormElectronicSignature();
  }

  const removeElectronicSignatureFromStateDynamicFormFileType = () => {
    setStateDynamicFormFileType((previousState) => ({
      ...previousState,
      [dynamicElementName]: dynamicElementAccessKey,
    }))
  }

  const onSetCurrentFormElectronicSignatureToEdit = () => {
    setCurrentFormElectronicSignatureToEdit(dynamicElementName);
  }

  const uploadFileToSign = async (files) => {
    const fileNameWithoutExtension = getFileNameWithoutExtension(files[0].name);
    const isAValidFilename = checkIfStringOnlyIncludesLettersNumbersOrSpaces(fileNameWithoutExtension);
    if (!isAValidFilename) {
      NotificationManager.warning('El nombre del documento sólo debe incluir números, espacios o letras sin acentos');
      return false
    }
    await onAddFiles(files);
    setIsDocumentReadyToSign(false);
    return true
  }

  const onCloseEditSignatureWarningModal = () => {
    setIsEditSignatureWarningModalShowing(false)
  }

  const onEditSignatureClick = () => {
    onCloseEditSignatureWarningModal();
    onResetSignatures();
    removeElectronicSignatureFromStateDynamicFormFileType();
    setIsDocumentReadyToSign(false);
    onSetCurrentFormElectronicSignatureToEdit();
  }

  const editSignatureWarningFooterModal = (
    <>
      <Button variant="secondary" onClick={onCloseEditSignatureWarningModal}>
        Cancelar
      </Button>
      <Button onClick={onEditSignatureClick}>
        {isDocumentReadyToSign ? 'Editar' : 'Agregar credenciales'}
      </Button>
    </>
  )

  return (
    <div>
      <div style={{ position: 'relative', padding: '10px' }}>
        {
          (isDocumentReadyToSign || !isEditingEnabled)
          &&
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(175,175,175,0.5)',
              borderRadius: '20px',
              opacity: '1',
              zIndex: '10',
              padding: '10px'
            }}
          >
            <div className={isDocumentReadyToSign ? 'text-success' : ''}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'rgba(255,255,255,1)',
                padding: '10px',
                borderRadius: '20px',
                padding: '10px'
              }}
            >
              <i className={`fas fa-${isDocumentReadyToSign ? 'check-circle' : 'hourglass-half'} fa-2x`}></i>
              <p style={{ fontSize: '10px', margin: '0' }}>
                {isDocumentReadyToSign ? documentReadyToSignText : documentNotEnabledToEditText}
              </p>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
              }}
            >
              <TextTooltip text={isDocumentReadyToSign ? 'Editar firma electrónica' : 'Agregar credenciales'}>
                <div
                  onClick={() => setIsEditSignatureWarningModalShowing(true)}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255,255,255,1)',
                    padding: '10px 7px 10px 10px',
                    borderRadius: '15px',
                  }}
                >
                  <i className="fas fa-edit"></i>
                </div>
              </TextTooltip>
            </div>
          </div>
        }
        <div style={(isDocumentReadyToSign || !isEditingEnabled) ? { filter: 'blur(1px)' } : {}}  >
          <ElectronicSignatureProcess
            onPreviousToAddFileToSign={uploadFileToSign}
            onCreatePkcs7={onCreatePkcs7}
          />
        </div>
      </div>
      {
        (showErrorTextIfIsNotSigned && !isDocumentReadyToSign)
        &&
        <p className='text-danger'>Este elemento debe ser firmado</p>
      }

      <ModalComponent
        header="Advertencia"
        body={isDocumentReadyToSign ? editModalText : addElectronicSignatureNowText}
        footer={editSignatureWarningFooterModal}
        show={isEditSignatureWarningModalShowing}
        canClose
        onHide={onCloseEditSignatureWarningModal}
      />
    </div>
  )
}

export default DynamicFormElectronicSignatureType;