import { Request } from "../../../classes/Request";

export const fetchPendingSignatureDocuments = async (expedientId, page, sizePerPage) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/get_documents_pending_signature_by_user/${sizePerPage}?page=${page}`,
    "get",
    null,
    null
  );

  try {
    const tableDataResponse = await request.executeRequest();
    if (!tableDataResponse || tableDataResponse.code !== 200) {
      return false
    }
    return tableDataResponse
  } catch (error) {
    console.error("From MultiSignature error de guardar firma", error)
    return false
  }
};