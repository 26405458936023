import _ from "lodash";
import { RequestNewVersion } from "../../../../../classes/RequestNewVersion";
import { useLoaderContext } from "../../../../../layout/shared/loader_context";

const useSubmitFormValues = ({
  indicatorstFormValues,
  expedientId,
  onSuccessfulRequest = () => { },
}) => {

  const { show: setIsLoaderShowing } = useLoaderContext();

  const handleSubmitFormValues = async () => {
    const submitIndicatorsUrl = `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/indicators`;
    const submitIndicatorsBody = {
      indicatorstFormValues,
      expedient_id: expedientId,
    };
    const indicatorsRequest = new RequestNewVersion(submitIndicatorsUrl, "post", null, submitIndicatorsBody);

    try {
      setIsLoaderShowing(true);
      const indicatorsResponse = await indicatorsRequest.executeRequest();
      const indicatorsResponseCode = _.get(indicatorsResponse, 'code');
      const isASuccessfulRequest = indicatorsResponseCode === 200;

      if (!isASuccessfulRequest) {
        return
      }

      onSuccessfulRequest();

    } catch (error) {
      throw error
    } finally {
      setIsLoaderShowing(false);
    }
  }

  return handleSubmitFormValues
}

export default useSubmitFormValues