import Cookies from "js-cookie";
import _ from "lodash";
export class UserEntity {
    constructor() {};


    static loadPermissions() {
        const {special_permissions_03} = Cookies.get();
        const special_permissions_parsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
        this.permissions = special_permissions_03.length > 0 ? special_permissions_parsed.at(0) : [];
    }

    static validatePermissions(permissionName = '') {
        this.loadPermissions();
        if(_.isEmpty(this.permissions)) {
            return false;
        }

        if(Object.keys(this.permissions).includes(permissionName)) {
            return true;
        }

        return false;
    }
}