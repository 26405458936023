import _ from "lodash";
import { getPartyDataWithFormattedPersonNames } from "../utils/getPartyDataWithFormattedPersonNames";

export const getFormattedPartyTypeList = (partyTypeList = []) => {
  if (!Array.isArray(partyTypeList)) {
    return [];
  }

  const formattedParties = partyTypeList.map((party) => {

    let partyData = _.get(party, 'party_data', {});
    partyData = getPartyDataWithFormattedPersonNames(partyData);

    return {
      ...party,
      party_data: partyData,
    }
  });

  return formattedParties
}