import { removeAccentsFromAString } from "../../../../services/dataFormatting/removeAccentsFromAString";
import { postData } from "../../../../services/multipleElectronicSignature";

export const onGetTemplateFile = async (
  expedientTemplateId,
  htmlOfTemplate,
  documentToSignName,
) => {
  try {
    const dataToGetPdfFromTamplate = {
      expedient_template_id: expedientTemplateId,
      html: htmlOfTemplate
    }
    const pathToGetPdfFromTamplate = `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/documento_to_signed`;

    const rawDocumentToSign = await postData(pathToGetPdfFromTamplate, dataToGetPdfFromTamplate);
    if (Number(rawDocumentToSign.status) === 200) {
      const documentToSignBlob = await rawDocumentToSign.blob();
      documentToSignBlob.name = `${removeAccentsFromAString(documentToSignName)}.pdf`;
      documentToSignBlob.lastModified = new Date();
      const documentToSignFile = new File([documentToSignBlob], documentToSignBlob.name, {
        type: documentToSignBlob.type,
      });
      return documentToSignFile;
    }

    throw new Error('No se pudo obtener el archivo de plantilla');
  } catch (error) {
    throw error
  }
}