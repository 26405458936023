import React from 'react'
import ModalComponent from '../../../helper_components/ModalComponent'
import BodyModal from './components/bodyModal/BodyModal'
import FooterModal from './components/footerModal/FooterModal'

export const TemplateImagesWithCorsErrorModal = ({
  isModalShowing = false,
  onCloseModal = () => { },
  imageWithCorsErrorList = [],
}) => {
  return (
    <>
      {
        isModalShowing
        &&
        <ModalComponent
          header={'Imágenes con conflictos'}
          body={<BodyModal imageWithCorsErrorList={imageWithCorsErrorList} />}
          footer={(
            <FooterModal onCloseButtonClick={onCloseModal} />
          )}
          show={isModalShowing}
          onHide={onCloseModal}
          size={'md'}
        />
      }
    </>
  )
}

export default TemplateImagesWithCorsErrorModal