import React from 'react'
import Login from '../../components/login/Login';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import { controlPanelTabNames } from '../../components/government_book/control_panel/constants/controlPanelTabNameList';

const RootRoute = (props) => {

  const { auth_03, special_permissions_03 } = Cookies.get();
  const special_permissions_parsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
  const canGoToProcedures = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['procedure_section'] === '1';
  const canGoToExpedients = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['expedient_section'] === '1';
  const canGoToExpirationPanel = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['expiration_panel'] === '1';
  const canGoToPendingSignaturesPanel = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['pending_signatures_panel'] === '1';
  const canGoToBulletin = special_permissions_parsed.length > 0 && special_permissions_parsed[0]['bulletin_section'] === '1';

  if (auth_03 && canGoToExpirationPanel) {
    return (
      <Redirect to={`/control_panel/${controlPanelTabNames.expirationTabName}`} />
    )
  }

  if (auth_03 && canGoToPendingSignaturesPanel) {
    return (
      <Redirect to={`/control_panel/${controlPanelTabNames.pendingSignatureTabName}`} />
    )
  }

  if (auth_03 && canGoToExpedients) {
    return (
      <Redirect to="/my_expedients" />
    )
  }

  if (auth_03 && canGoToProcedures) {
    return (
      <Redirect to="/home" />
    )
  }

  if (auth_03 && canGoToBulletin) {
    return (
      <Redirect to="/juditial_bulletin" />
    )
  }

  return (
    <Login {...props} />
  )
}

export default RootRoute