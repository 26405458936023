import React from 'react'

const BodyModal = ({
  imageWithCorsErrorList = [],
}) => {

  return (
    <div style={{ display: 'grid', gap: '30px' }}>
      <div>
        <p style={{ margin: 0, lineHeight: 'unset', fontSize: '14px' }}>
          Las siguientes imágenes están alojadas en una fuente externa, pero el servidor que las aloja no permite el acceso a ellas desde un origen desconocido.
        </p>
        <ul
          style={{
            listStyleType: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          {
            imageWithCorsErrorList.map((imageSrc, index) => (
              <li key={index} >
                <div style={{ height: '200px' }}>
                  <img
                    src={imageSrc}
                    alt={'Image with cors error'}
                    style={{ display: 'block', height: '100%', objectFit: 'contain' }}
                  />
                </div>
              </li>
            ))
          }
        </ul>
      </div>

      <div>
        <h5>¿Cuál es el problema con eso?</h5>
        <p style={{ margin: 0, lineHeight: 'unset', fontSize: '14px' }}>
          Cuando se genere el documento final (en formato PDF), las imágenes no se mostrarán.
        </p>
      </div>

      <div>
        <h5>¿Cómo lo soluciono?</h5>
        <p style={{ lineHeight: 'unset', fontSize: '14px' }}>
          A continuación se listarán las posibles soluciones para el problema:
        </p>
        <ul>
          <li>
            <p style={{ lineHeight: 'unset', fontSize: '14px' }}>
              Opción 1 (recomendada): Intenta con una imagen alojada en una fuente diferente.
            </p>
          </li>
          <li>
            <p style={{ margin: 0, lineHeight: 'unset', fontSize: '14px' }}>
              Opción 2: Descargar la imagen y después cargarla en el editor.
            </p>
          </li>
        </ul>
      </div>

      <div>
        <h5>Ejemplo de cómo cargar la imagen al editor después de haberla descargado:</h5>
        <div >
          <img
            style={{ display: 'block', width: '100%', height: 'auto' }}
            src={`${process.env.PUBLIC_URL}/img/user-help/upload-image-in-rich-text.webp`}
            alt='Upload image in rich text'
          />
        </div>
      </div>
    </div>
  )
}

export default BodyModal