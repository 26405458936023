import React from "react";
import NavBar from "../../layout/shared/NavBar";
import { Grid } from "react-flexbox-grid";
import TabMenu from "./components/TabMenu";
import Footer from "../../layout/shared/Footer";
import ControlPanelTabs from "./components/controlPanelTabs/ControlPanelTabs";
import _ from "lodash";

const ControlPanel = (props) => {
  const routeParams = _.get(props, 'match.params', {});
  const { panelTab } = routeParams;

  return (
    <div className="one_column">
      <NavBar {...props} />
      <h3 className="mT-20 w-100 text-center">Tablero de control</h3>
      <Grid fluid={true} className="no-padding" style={{ minHeight: '76vh' }}>
        <TabMenu {...props} activeTabName={panelTab} />
        <ControlPanelTabs {...props} activeTabName={panelTab} />
      </Grid>
      <Footer />
    </div>
  );
};

export default ControlPanel;