import React, { useContext } from 'react';
import { PartiesContext } from '../../context/PartiesContext';
import { Button } from 'react-bootstrap';

export const PatyFormDelete = () => {
    const { toggleDeleteParty, currentPartyItem, handleDelete, values, setValues } = useContext(PartiesContext);
    const { id, party_data = {}, party_type } = currentPartyItem;
    
    return (
        <>
            <div className=''>
                <h5>¿Estás seguro de eliminar la parte?</h5>
                <p>
                    { `${ party_data.names } ${ party_data.paternal_surname || '' } ${ party_data.maternal_surname || '' }` }   
                </p>
            </div>
            <div className='mt-2 d-flex justify-content-between'>
                <Button variant='dark' onClick={toggleDeleteParty}>
                    Cancelar
                </Button>

                <Button variant='danger' onClick={handleDelete}>
                    Eliminar
                </Button>
            </div>
        </>
    );
}
