import React from "react";
import { Modal } from "react-bootstrap";


const ModalComponent = (props) => {

    const {
        header,
        body,
        footer,
        show,
        canClose,
        onHide = () => { },
        scroll,
        dialogClassName,
        ...restOfProps
    } = props;


    return (
        <>
            <Modal dialogClassName={dialogClassName || ""} style={ scroll ? {display: 'block'} : null} show={show} onHide={ canClose ? () => onHide(false) : () => onHide(true) } {...restOfProps}>
                <Modal.Header className={"bg-complement-3"}>
                    <Modal.Title style={{ width: '100%' }}>{header}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={ scroll ? {height: 'auto', maxHeight:'60vh', overflowY: 'auto'} : null}>{body}</Modal.Body>
                <Modal.Footer>
                    {footer}
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default ModalComponent;