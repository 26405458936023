import React, { useEffect } from 'react';
import { Grid } from "react-flexbox-grid";
import { RemotePagination } from '../../../../helper_components/remote_pagination/RemotePagination'
import PreviewPdfModal from '../../modals/PreviewPdfModal';
import ElectronicSignatureModal from './componets/electronicSignatureModal/ElectronicSignatureModal';
import DeletePendingDocumentToBeSignedModal from './componets/deleteFileModal/DeleteFileModal';
import CommentsDocumentsPendingSignatureModal from './componets/commentsModal/CommentsModal';
import { useFilesPendingSignatureContext } from './context/FilesPendingSignatureContext';
import ReceiveElectronicExpedientModal from '../../../shared/ReceiveElectronicExpedientModal';
import { useLoaderContext } from '../../../../layout/shared/loader_context';

const FilesPendingSignatureTable = ({
  handleOpenTransfer = () => { },
  getDocumentsByExpedient = () => { },
}) => {

  const {
    dataDocuments,
    page,
    sizePerPage,
    totalDocuments,
    handleTableChange,
    columns,
    handleCloseElectronicSignatureModal,
    isElectronicSignatureModalOpen,
    getTableDataAndSetInTable,
    receiveExpedientData,
    setReceiveExpedientData,
    showReceiveExpedientModal,
    handleCloseReceiveExpedientModal,
    receiveExpedientChecked,
    setReceiveExpedientChecked,
    receiveExpedientShowCheckedError,
    handleReceiveExpedient,
    shouldShowMessageIfThereAreNoDocuments,
    setIsATurnedExpedient,
    currentExpedientPartsColumns = [],
  } = useFilesPendingSignatureContext();

  const loader = useLoaderContext();
  const isLoaderShowing = loader.status;
  const areThereDocumentsToShow = Boolean(totalDocuments);
  const shouldShowMessageOfNoDocumentsFoundPendingToBeSigned = shouldShowMessageIfThereAreNoDocuments && !areThereDocumentsToShow && !isLoaderShowing;

  useEffect(() => {
    setIsATurnedExpedient(false);
  },[])

  return (
    <>
      {
        dataDocuments.length > 0
        &&
        <>
          <h4 className={"text-center pt-5 mt-5 mb-0"}>
            Documentos pendientes por firmar
          </h4>
          <Grid fluid={true} className={"no-padding"} style={{ width: "65%", margin: "0px auto" }}>
            <section className="procedures-table-container tableFixHead mB-50 mT-10">
              <RemotePagination
                data={dataDocuments}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalDocuments}
                onTableChange={handleTableChange}
                columns={columns}
              />
            </section>
          </Grid>

          <PreviewPdfModal />

          <ElectronicSignatureModal
            onCloseModalClick={handleCloseElectronicSignatureModal}
            handleOpenTransfer={handleOpenTransfer}
            isShowing={isElectronicSignatureModalOpen}
            getDocumentsByExpedient={getDocumentsByExpedient}
          />

          <DeletePendingDocumentToBeSignedModal onDeleteDocumentPendingToBeSigned={getTableDataAndSetInTable} />

          <CommentsDocumentsPendingSignatureModal />

          <ReceiveElectronicExpedientModal
            showModal={showReceiveExpedientModal}
            modalData={receiveExpedientData}
            setModalData={setReceiveExpedientData}
            checked={receiveExpedientChecked}
            setChecked={setReceiveExpedientChecked}
            handleClose={handleCloseReceiveExpedientModal}
            handleReceive={handleReceiveExpedient}
            showCheckedError={receiveExpedientShowCheckedError}
            expedientParts={currentExpedientPartsColumns}
          />
        </>
      }

      {
        shouldShowMessageOfNoDocumentsFoundPendingToBeSigned
        &&
        <h4 className={'text-center mt-3'}>
          No se encontraron documentos pendientes por firmar
        </h4>
      }
    </>
  )
}

export default FilesPendingSignatureTable