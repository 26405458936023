import React from 'react'
import { Spinner } from 'react-bootstrap'

const GrowLoader = () => {
  return (
    <div className='w-100 d-flex justify-content-center' style={{ gap: '0.7rem' }}>
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="warning" />
      <Spinner animation="grow" variant="info" />
    </div>
  )
}

export default GrowLoader