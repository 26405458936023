import React from 'react'
import ModalComponent from '../../../../../../../../../../helper_components/ModalComponent'
import BodyModal from './components/BodyModal'
import FooterModal from './components/FooterModal'
import { useIndicatorsContext } from '../../../../../../context/indicatorsContext/IndicatorsContext'
import useAddMinor from './hooks/useAddMinor'

const AddMinorModal = ({ ...restOfProps }) => {

  const { initialDemand } = useIndicatorsContext();
  const addMinorProps = useAddMinor();

  return (
    <ModalComponent
      header="Agregar un menor"
      body={<BodyModal {...addMinorProps} />}
      footer={<FooterModal handleAddMinor={addMinorProps.handleAddMinor} />}
      show={initialDemand.isAddMinorModalShowing}
      canClose={true}
      onHide={initialDemand.toggleisAddMinorModalShowing}
      {...restOfProps}
    />
  )
}

export default AddMinorModal