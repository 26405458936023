export const procedureTypeOptions = [
  {
    "label": "Apelación",
    "value": 1
  },
  {
    "label": "Queja",
    "value": 2
  },
  {
    "label": "Recusación",
    "value": 3
  },
  {
    "label": "Incompetencia",
    "value": 4
  },
  {
    "label": "Excusa",
    "value": 5
  }
];