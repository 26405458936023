import React from 'react';
import Cookies from 'js-cookie';
import { RequestNewVersion } from '../../classes/RequestNewVersion';
import { NotificationManager } from 'react-notifications';
import {Link} from 'react-router-dom';
import { getLabelFromDictionary } from '../../../config/dictionary';

export const columns = [
  {
    dataField: "expedient_number_link",
    text: getLabelFromDictionary( 'tableContent', 'label' ),
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "filename",
    text: "Nombre de archivo",
    sort: false,
    style: { textAlign: "center", maxWidth:"200px" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "alias",
    text: "Etiqueta",
    sort: false,
    style: { textAlign: "center", maxWidth:"200px" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "created_at",
    text: "Fecha de creación",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "comment",
    text: "Comentarios",
    sort: false,
    style: { textAlign: "center", maxWidth: "200px" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "actions",
    text: "Acciones",
    sort: false,
    style: { textAlign: "center", maxWidth: "200px" },
    headerStyle: { textAlign: "center" }
  }
];

export const getPromotions = async (
  page,
  sizePerPage,
  setPromotions,
  setTotalPromotions,
  setTextNotData,
  setCommentModal,
  setShowCommentModal,
  setShowConfirmModal,
  setCurrentAttending
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/active_promotions/${sizePerPage}?page=${page}`,
    'get',
    null,
    {}
  );

  const response = await request.executeRequest();

  if (response.code === 403) {
    window.location.href = "/";
  } else if (response.code !== 200) {
    NotificationManager.error(`Algo sucedió y no fue posible traer los ${getLabelFromDictionary('global', 'lowerMainName')}, inténtalo nuevamente`);
    setTextNotData("No hay resultados por mostrar");
  } else {
    if (
      response.response.error &&
      response.response.message &&
      typeof response.response.message === "string"
    ) {
      setTextNotData(response.response.message);
      NotificationManager.error(response.response.message);
    }
    else if (response.response.documents && response.response.documents.length === 0) {
      setPromotions([]);
      setTotalPromotions(0);
      setTextNotData("No se han encontrado resultados");
    }
    else {
      const promotions = await formatPromotions(
        response.response.documents,
        setCommentModal,
        setShowCommentModal,
        setShowConfirmModal,
        setCurrentAttending
      );
      setTotalPromotions(response.response.total);
      setPromotions(promotions);
    }
  }
};

const formatPromotions = async (
  promotions,
  setCommentModal,
  setShowCommentModal,
  setShowConfirmModal,
  setCurrentAttending
) => {
  return promotions.map(p => ({
    ...p,
    expedient_number_link: (
      <Link to={`/expedient_details/${p.expedient_id}`}>{p.expedient_number}</Link>
    ),
    actions: p.attended
      ? <></>
      : <button onClick={ e => {setShowConfirmModal(true); setCurrentAttending(p)}} className="btn btn-primary"><i className="fas fa-check"></i></button>,
    comment: ((comment) => {
      if (!comment) return '';

      if (comment.length <= 110) return p.comment;

      setCommentModal(comment);
      return (
        <span>
          {comment.slice(0, 110)}... <strong style={{ cursor: 'pointer' }} onClick={() => {
            setCommentModal(comment);
            setShowCommentModal(true);
          }}>Ver más</strong>
        </span>
      );
    })(p.comment)
  }));
}

export const markAsAttended = async (id, setCurrentAttending, setShowConfirmModal) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/active_promotions`,
    'post',
    null,
    {
      document_expedient_id: id
    }
  );

  const response = await request.executeRequest();

  if (response.code !== 200) {
    NotificationManager.error("Algo sucedió y no fue posible completar esta acción, inténtalo nuevamente");
  } else {
    if (
      response.response.error &&
      response.response.message &&
      typeof response.response.message === "string"
    ) {
      NotificationManager.error(response.response.message);
    }
    else {
      NotificationManager.success("Se ha marcado la promoción como atendida");
      setCurrentAttending({});
      setShowConfirmModal(false);
    }
  }
}
