
export const getTimeTranscurred = (date) => {
    const time = new Date(date.getTime());
    const today = new Date();
    return "Hace " + Math.round((today - time) / 86400000) + ( Math.round((today - time) / 86400000) > 1 || Math.round((today - time) / 86400000) === 0 ? " días" : " día");
};

export const getTextOfDate = ( initialDate ) => {
    const date = new Date(initialDate).toLocaleDateString('es-MX', {
        day:'numeric',
        month:'long',
        year:'numeric'
    });
    const time = new Date(initialDate).toLocaleTimeString('es-MX', {
        hour:'2-digit',
        minute:'2-digit'
    });

    return date + " a las " + time;
};