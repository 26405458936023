import _ from "lodash";

export const getFormattedSelectCurtUsersOptions = (options) => {
  if (!Array.isArray(options)) {
    return [];
  }

  const formattedOptions = options.map((optionElement) => ({
    label: _.get(optionElement, 'name'),
    value: _.get(optionElement, 'id'),
  }));

  return formattedOptions;
}