import { useEffect, useState } from "react";
import { handleRequestSendDataNotificationEmail } from "../modals/notify_email_modal_logic";
import { useElectronicSignatureProcessContext } from "../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext";
import { onGetTemplateFile } from "../services/onGetTemplateFile";
import { NotificationManager } from "react-notifications";
import { useLoaderContext } from "../../../layout/shared/loader_context";
import { getElectronicSignatureProperties } from "../../../../services/electronicSignature/getElectronicSignatureProperties";
import _ from "lodash";
import { getFormData } from "../../../../services/dataFormatting/getFormData";
import { onSaveSignatureToTheListOfSignatures } from "../../../../services/electronicSignature/multiSignature/onSaveSignatureToTheListOfSignatures";
import { handleRequest } from "../../../../services/requests/handleRequest";
import { useFilesPendingSignatureContext } from "../components/filesPendingSignatureTable/context/FilesPendingSignatureContext";
import { useTemplatesPresavedContextContext } from "../context/templatesPresavedContext";
import { templateTypesWithMultiSignatureEnabledByDefault, templateTypesWithoutMultisignature } from "../constants/defaultValues";

const getIsMultisignatureEnabledFromTemplateType = (templateType) => {
  return !templateTypesWithoutMultisignature.includes(templateType);
}

const useElectronicSignatureTemplatesModal = ({
  htmlOfDocumentToSign,
  expedientTemplateId,
  documentToSignName = 'document',
  userId,
  onCloseElectronicSignatureModal,
  templateData,
  reloadExpedientDetailsComponent,
  setReloadTemplatesData,
  onCloseTemplateModal,
  electronicNotificationState,
  setShowModalTiny,
  dataExpedient,
  emailTemplateId,
}) => {
  const [isNotificationModalShowing, setIsNotificationModalShowing] = useState(false);
  const [isSimpleSignatureAlertShowing, setIsSimpleSignatureAlertShowing] = useState(false);
  const loader = useLoaderContext();
  const isMultisignatureEnabled = getIsMultisignatureEnabledFromTemplateType(templateData.type);

  const onCloseNotifyEmailModal = () => {
    setIsNotificationModalShowing(false);
  }

  const { handleGetDocumentsByExpedientNumber = () => { } } = useTemplatesPresavedContextContext();

  const { getTableDataAndSetInTable: getNewRecordsFromFilesPendingSignatureTable } = useFilesPendingSignatureContext();

  const {
    electronicSignaturesList,
    isMultipleSignature,
    getPkcs7,
    isReadyToCreatePkcs7,
    documentToSign,
    onAddDocumentToSign,
    onResetSignatures,
    setIsMultipleSignature,
  } = useElectronicSignatureProcessContext();

  const onToggleButtonClick = (event) => {
    const isToggleButtonChecked = _.get(event, 'target.checked');
    if (!isToggleButtonChecked) {
      setIsSimpleSignatureAlertShowing(true);
      return
    }

    setIsMultipleSignature(isToggleButtonChecked);
  }

  const onContinueSimpleSignatureAlertClick = () => {
    setIsMultipleSignature(false);
    setIsSimpleSignatureAlertShowing(false);
  }

  const onCancelSimpleSignatureAlertClick = () => {
    setIsSimpleSignatureAlertShowing(false);
  }

  const isElectronicSignatureTableShowing = Boolean(electronicSignaturesList.length);

  const handleGetFileTosign = async () => {
    try {
      loader.show(true);
      const templateFile = await onGetTemplateFile(expedientTemplateId, htmlOfDocumentToSign, documentToSignName,);
      onAddDocumentToSign([templateFile]);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      loader.show(false);
    }
  }

  useEffect(() => {
    handleGetFileTosign();
    handleEnableMultiSignatureByTemplateType();
  }, [])

  let dataToSendForElectronicSignature = {
    ...templateData,
    expedient_template_id: expedientTemplateId,
    user_id: userId,
    html: htmlOfDocumentToSign,
    file: documentToSign,
  }

  if (electronicNotificationState.isAnElectronicNotification && templateData.type === 'notification') {
    dataToSendForElectronicSignature = {
      ...dataToSendForElectronicSignature,
      checked_judgment: electronicNotificationState.isAnJudgmentType,
      checked_agreement: electronicNotificationState.isAnAgreementType
    }
  }

  const handleSingTemplate = async () => {
    const argumentsForGetElectronicSignatureProperties = {
      isMultipleSignature,
      isReadyToCreatePkcs7,
      getPkcs7,
      documentToSignName: documentToSign.name,
      shouldPkcs7BeGenerated: !isMultipleSignature,
    };

    const electronicSignatureProperties = await getElectronicSignatureProperties(argumentsForGetElectronicSignatureProperties);
    if (!_.isObject(electronicSignatureProperties)) {
      throw new Error('Errores en firma electronica');
    }

    const saveDocumentToSignBodyRequest = {
      ...dataToSendForElectronicSignature,
      ...electronicSignatureProperties,
    }

    const saveDocumentToSignBodyRequestFormData = getFormData(saveDocumentToSignBodyRequest);

    const singleElectronicSignatureUrlRequest = `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/template_pdf`;
    const multipleElectronicSignatureUrlRequest = `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/multisign_template`;

    if (isMultipleSignature) {
      const multipleElectronicSignatureRequestArgs = [multipleElectronicSignatureUrlRequest, "post", null, saveDocumentToSignBodyRequestFormData];
      const multipleElectronicSignatureResponse = await handleRequest(...multipleElectronicSignatureRequestArgs);

      const documentId = _.get(multipleElectronicSignatureResponse, 'response.signatureDocument.id');
      if (!documentId) {
        return
      }

      const signatureToSave = electronicSignaturesList[0];
      const saveSignatureToTheListOfSignaturesResponse = await onSaveSignatureToTheListOfSignatures(signatureToSave, documentId);
      return saveSignatureToTheListOfSignaturesResponse;

    } else {
      const singleElectronicSignatureRequestArgs = [singleElectronicSignatureUrlRequest, "post", null, saveDocumentToSignBodyRequestFormData];
      const singleElectronicSignatureResponse = await handleRequest(...singleElectronicSignatureRequestArgs);
      return singleElectronicSignatureResponse
    }
  }

  const onSingTemplate = async ({ signTemplateFromNotificationModal = false, users = [] } = {}) => {
    try {
      loader.show(true);

      const isANotificationTypeTemplate = electronicNotificationState.isAnElectronicNotification;
      const shouldTemplateBeNotified = (isANotificationTypeTemplate && !isMultipleSignature);
      const shouldNotificationModalBeShown = (shouldTemplateBeNotified && !signTemplateFromNotificationModal);

      if (shouldNotificationModalBeShown) {
        setIsNotificationModalShowing(true);
        return
      }

      const singTemplateStatus = await handleSingTemplate();
      const hasSignBeenSuccessful = _.isObject(singTemplateStatus);

      if (!hasSignBeenSuccessful) {
        NotificationManager.error('Ocurrió un error al intentar firmar el documento');
        return
      }

      if (isMultipleSignature) {
        getNewRecordsFromFilesPendingSignatureTable();
      } else {
        handleGetDocumentsByExpedientNumber();
      }

      if (!shouldTemplateBeNotified) {
        onFinalizeElectronicSignatureTemplate();
        setShowModalTiny(false);
        return
      }

      const documentId = !isMultipleSignature ? _.get(singTemplateStatus, 'response.id') : null;

      const sendDataNotificationEmailResponse = await handleRequestSendDataNotificationEmail({
        expedient_id: dataExpedient.id,
        notification_template_id: emailTemplateId,
        emails: users,
        using_document_expedient: 'si',
        document_expedient_id: documentId,
        checked_judgment: electronicNotificationState.isAnJudgmentType,
        checked_agreement: electronicNotificationState.isAnAgreementType
      });

      if (sendDataNotificationEmailResponse) {
        onFinalizeElectronicSignatureTemplate();
        setShowModalTiny(false);
        setIsNotificationModalShowing(false);
      }

    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      loader.show(false);
    }
  }

  const onFinalizeElectronicSignatureTemplate = () => {
    onResetSignatures();
    reloadExpedientDetailsComponent();
    setReloadTemplatesData((previousState) => (!previousState));
    onCloseElectronicSignatureModal();
    onCloseTemplateModal();
  }

  const handleEnableMultiSignatureByTemplateType = () => {
    const isATemplateWithMultiSignatureEnabledByDefault = templateTypesWithMultiSignatureEnabledByDefault.includes(templateData.type);
    if (isATemplateWithMultiSignatureEnabledByDefault) {
      setIsMultipleSignature(true);
    }
  }

  return (
    {
      onSingTemplate,
      isNotificationModalShowing,
      onCloseNotifyEmailModal,
      isMultisignatureEnabled,
      isElectronicSignatureTableShowing,
      onToggleButtonClick,
      isSimpleSignatureAlertShowing,
      setIsSimpleSignatureAlertShowing,
      onCancelSimpleSignatureAlertClick,
      onContinueSimpleSignatureAlertClick,
    }
  )
}

export default useElectronicSignatureTemplatesModal
