import React, { useEffect } from 'react';
import { usePartiesContext } from '../../context/PartiesContext';
import { PartiesItem } from './PartiesItem';

export const PartiesList = () => {
    const { listParties, handleGetListParties, values, setValues } = usePartiesContext();

    useEffect( () => {
        async function getData() {
	    setValues({ ...values, loading: true });
            await handleGetListParties();
	    setValues({ ...values, loading: false });
        }
        getData();
    }, [] );

    if(listParties.length === 0)  {
        return (
            <div className='d-flex justify-content-center'>
                <p className='h5 user-select-none'>No hay partes registradas</p>
            </div>
        );
    }
   
    return (
        <>
            {
                listParties.map( (party, index) => (
                    <PartiesItem key={index} party={party} />
                ))
            }
        </>
    );
}
