export const genders = [
    {
        'codigo': 'bigenero',
        'etiqueta': 'Bigénero'
    },
    {
        'codigo': 'cisgenero',
        'etiqueta': 'Cisgénero'
    },
    {
        'codigo': 'generoflu',
        'etiqueta': 'Género fluido'
    },
    {
        'codigo': 'heteronormatividad',
        'etiqueta': 'Heteronormatividad'
    },
    {
        'codigo': 'nobinario',
        'etiqueta': 'No binario'
    }
];