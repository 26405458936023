import { useEffect, useRef, useState } from 'react'
import { getJudicialPartiesList } from '../services/getJudicialPartiesList';
import _ from 'lodash';
import { getFormattedSelectCourtsOptions } from '../services/getFormattedSelectCourtsOptions';
import { useLoaderContext } from '../../../layout/shared/loader_context';
import { getCourtUsersByCourtId } from '../services/getCourtUsersByCourtId';
import { getFormattedSelectCurtUsersOptions } from '../services/getFormattedSelectCurtUsersOptions';

const useCreateTocaState = ({
  isCreateTocaTabSelected = false,
}) => {

  const [selectCourtsOptions, setSelectCourtsOptions] = useState([]);
  const [selectCurtUsersOptions, setSelectCurtUsersOptions] = useState([]);
  const lastCourtId = useRef(null);
  const loader = useLoaderContext();

  const handleGetJudicialPartiesList = async () => {
    const doesSelectCourtsOptionsExist = Boolean(_.get(selectCourtsOptions, 'length', 0));
    if (doesSelectCourtsOptionsExist) {
      return
    }

    loader.show(true);
    const judicialPartiesList = await getJudicialPartiesList();
    loader.show(false);
    const formattedSelectCourtsOptions = getFormattedSelectCourtsOptions(judicialPartiesList);
    setSelectCourtsOptions(formattedSelectCourtsOptions);
  }

  const handleGetSelectCurtUsersOptions = async (courtId) => {
    const hasCourtIdChanged = lastCourtId.current !== courtId;

    if (!hasCourtIdChanged) {
      return
    }

    lastCourtId.current = courtId;

    loader.show(true);
    const courtUsers = await getCourtUsersByCourtId(courtId);
    loader.show(false);
    const formattedSelectCurtUsersOptions = getFormattedSelectCurtUsersOptions(courtUsers);
    setSelectCurtUsersOptions(formattedSelectCurtUsersOptions);
  }

  useEffect(() => {
    if (!isCreateTocaTabSelected) {
      return
    }

    handleGetJudicialPartiesList();
  }, [isCreateTocaTabSelected]);

  return {
    selectCourtsOptions,
    selectCurtUsersOptions,
    handleGetSelectCurtUsersOptions,
  }
}

export default useCreateTocaState