import Cookies from "js-cookie";

export const getElectronicSignatureProperties = async ({
  isMultipleSignature,
  isReadyToCreatePkcs7,
  getPkcs7 = () => { },
  documentToSignName,
  shouldPkcs7BeGenerated = true,
}) => {
  let pkcs7 = '';

  if (!isReadyToCreatePkcs7) {
    return
  }

  if (shouldPkcs7BeGenerated) {
    try {
      pkcs7 = await getPkcs7();
      if (!Boolean(pkcs7)) {
        return
      }
    } catch (error) {
      throw error
    }
  }

  const { names_03: userName, paternal_surname_03: userPaternalSurname } = Cookies.get();
  const userFullName = `${userName} ${userPaternalSurname}`;

  const electronicSignatureProperties = {
    Pkcs7Original: pkcs7,
    CertificateType: 3,
    FileName: documentToSignName,
    UserName: userFullName,
    isMultipleSignature,
  }

  return electronicSignatureProperties
}