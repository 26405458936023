export const checkFields = [
  {
    label: 'Defensa publica ( gratuita)',
    value: 'defensa_publica',
  },
  {
    label: 'Defensa privada',
    value: 'defensa_privada',
  },
  {
    label: 'defensa publica y privada',
    value: 'defensa_publica_privada',
  },
  {
    label: 'Representacion social',
    value: 'representacion',
  },
  {
    label: 'No contaron con representacion legal',
    value: 'no_representacion',
  },
  {
    label: 'No identificado',
    value: 'no_identificado',
  },
]