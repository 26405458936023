export const imagesUploadHandler = (blobInfo, success, failure) => {
  const { base64, blob } = blobInfo;
  const imageMimeType = blob().type;
  const imageBase64 = base64();

  if (!imageMimeType || !imageBase64) {
    failure('Ocurrió un error al intentar cargar la imagen');
  }

  const base64Url = `data:${imageMimeType};base64,${imageBase64}`;
  success(base64Url);
}