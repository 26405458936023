import React from 'react';
import FormTitle from '../../FormTitle';
import { Form } from 'react-bootstrap';
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext';
import { getElementFromOptionsList } from '../../../../../../../../services/dataFormatting/getElementFromOptionsList';
import Select from 'react-select';
import _ from 'lodash';
import { interlocutoryJudgmentDefaultFormValues } from '../../../constants/defaultValues';

const InterlocutoryJudgment = () => {

  const { interlocutoryJudgment } = useIndicatorsContext();

  const {
    interlocutoryJudgmentFormMethods: {
      onFormChange,
      onFormRadioChange,
      onSelectChange,
      formValues,
    },
    assertions,
  } = interlocutoryJudgment;

  const {
    isAProtocol,
    isNotAProtocol,
    isASenseResolutionpProceeded,
    isNotASenseResolutionpProceeded,
  } = assertions;

  return (
    <div>
      <FormTitle>
        Sentencia interlocutoria
      </FormTitle>

      <Form>
        <Form.Group controlId="precautionary_measures_decreed">
          <Form.Label>
            Medidas precautorias decretadas
          </Form.Label>
          <Form.Control
            type="text"
            name='precautionary_measures_decreed'
            onChange={onFormChange}
            value={_.defaultTo(formValues.precautionary_measures_decreed, interlocutoryJudgmentDefaultFormValues.precautionary_measures_decreed)}
          />
        </Form.Group>

        <Form.Group controlId="precautionary_measures_requested">
          <Form.Label>
            Medidas precautorias solicitadas
          </Form.Label>
          <Form.Control
            type="text"
            name='precautionary_measures_requested'
            onChange={onFormChange}
            value={_.defaultTo(formValues.precautionary_measures_requested, interlocutoryJudgmentDefaultFormValues.precautionary_measures_requested)}
          />
        </Form.Group>

        <Form.Group controlId="principal_action">
          <Form.Label>
            Accion principal
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name='principal_action'
            onChange={onFormChange}
            value={_.defaultTo(formValues.principal_action, interlocutoryJudgmentDefaultFormValues.principal_action)}
          />
        </Form.Group>

        <Form.Group controlId="sense_resolution">
          <Form.Text className="text-muted">
            Sentido de la resolución
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Procedente"
            name="sense_resolution"
            id='sense_resolution_proceed'
            value={'proceed'}
            checked={isASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
          <Form.Check
            inline
            type="radio"
            label="Improcedente"
            name="sense_resolution"
            id='sense_resolution_no_proceed'
            value={'no_proceed'}
            checked={isNotASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
        </Form.Group>

        <Form.Group controlId="protocol">
          <Form.Text className="text-muted">
            Protocolo
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Si"
            name="protocol"
            id='protocol_si'
            value={true}
            checked={isAProtocol}
            onChange={onFormRadioChange}
          />
          <Form.Check
            inline
            type="radio"
            label="No"
            name="protocol"
            id='protocol_no'
            value={false}
            checked={isNotAProtocol}
            onChange={onFormRadioChange}
          />
        </Form.Group>

        {
          isAProtocol
          &&
          <Form.Group controlId="which_protocol">
            <Form.Label>
              ¿Cuál?
            </Form.Label>
            <Select
              name='which_protocol'
              placeholder="Selecciona un protocolo"
              options={[
                {value: 1,label:"Sentencia dictada con perspectiva de género"}, 
                {value: 2,label:"Sentencia dictada con perspectiva de infancia y adolescencia"},
                {value: 3,label:"Sentencia dictada con perspectiva de violencia contra las mujeres"}
              ]}
              onChange={onSelectChange}
              value={getElementFromOptionsList(formValues.which_protocol, [
                {value: 1,label:"Sentencia dictada con perspectiva de género"}, 
                {value: 2,label:"Sentencia dictada con perspectiva de infancia y adolescencia"},
                {value: 3,label:"Sentencia dictada con perspectiva de violencia contra las mujeres"}
              ])}            />
          </Form.Group>
        }
      </Form>
    </div>
  )
}

export default InterlocutoryJudgment