import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { usePartiesContext } from '../../context/PartiesContext';
import { ErrorsByKeyName } from '../shared/ErrorsByKeyName';
import { isError } from '../../helpers/logicDataParties';

export const PartiesFormContactInformation = () => {
    const { values, handleChageForm } = usePartiesContext();

    return (
        <>
            <Form style={{ width: '100%' }} className='border p-5 rounded mb-2'>
                <Row>
                    <Col xs={12} md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Correo electrónico</Form.Label>
                            <Form.Control 
                                type='email' 
                                placeholder='Ejemplo: john@doe.com' 
                                onChange={(e) => { handleChageForm(e.target.value, 'email') }}
                                value={values.formValues.email}
                                isInvalid={ isError(values.errors, 'email')}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='email' />
                        </Form.Group>
                    </Col>
                    
                    <Col xs={12} md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label> Teléfono</Form.Label>
                            <Form.Control 
                                type='text' 
                                placeholder='Ejemplo: 55 5555 55 55' 
                                onChange={(e) => { handleChageForm(e.target.value, 'phoneNumber') }}
                                value={values.formValues.phoneNumber}
                                isInvalid={ isError(values.errors, 'phoneNumber')}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='phoneNumber' />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>* Lugar de residencia</Form.Label>
                            <Form.Control 
                                type='text' 
                                placeholder='Ejemplo: Guadalajara' 
                                onChange={(e) => { handleChageForm(e.target.value, 'home') }}
                                value={values.formValues.home}
                                isInvalid={ isError(values.errors, 'home')}
                            />
                            <ErrorsByKeyName listErrors={values.errors} value='home' />
                        </Form.Group>
                    </Col>
                    

                    
                </Row>
            </Form>
        </>
    );
}