import React from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';

const WrapperFormikField = ({ children, name = '', ...restOfProp }) => {

  const { values, errors } = useFormikContext();
  const currentValue = _.get(values, name);
  const errorField = _.get(errors, name);
  const childrenProps = {
    ...restOfProp, currentValue, errorField, name
  };

  if (typeof children === 'function') {
    return children(childrenProps);
  }

  return (
    <>
      {React.Children.map(children, child => {
        return React.cloneElement(child, childrenProps);
      })}
    </>
  );
}

export default WrapperFormikField;