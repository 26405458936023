export const mergeFormData = (formData1, formData2) => {
  const mergedFormData = new FormData();

  for (const [key, value] of formData1.entries()) {
    mergedFormData.append(key, value);
  }

  for (const [key, value] of formData2.entries()) {
    mergedFormData.append(key, value);
  }

  return mergedFormData;
}