import React, { useEffect, useState, useMemo } from "react";
import IframeFunctional from "./IframeFunctional";
import {
  getTemplatesByType, getAgreementTypes, getPromotionsWithoutAgreement,
  requestTemplatePresave, getEmailsTemplates, getIsFieldShowingInFile
} from "./details_expedient/expedient_details_logic";
import { Button, Form } from "react-bootstrap";
import { selectStylesDefault, fieldsValidation } from "./my_expedients/expedients_list_logic";
import Select from "react-select";
import Cookies from "js-cookie";
import { renderErrorsList, renderClassesToErrors } from "../edit_profile/edit_profile_logic";
import { NotificationManager } from "react-notifications";
import ModalComponentPortal from "../helper_components/modals/ModalComponentPortal";
import AgreementWithoutPromotionModal from "./details_expedient/modals/AgreementWithoutPromotionModal";
import { templatesTypesToAskIfIsAPublishBulletin } from "../../services/dataFormatting/templatesTypesToAskIfIsAPublishBulletin";
import RichTextEditor from "../richTextEditor/RichTextEditor";
import useImagesWithCorsErrorState from "../richTextEditor/hooks/imagesWithCorsErrorState/useImagesWithCorsErrorState";
import _ from "lodash";
import { useLoaderContext } from "../layout/shared/loader_context";

const stylesDiv = {
  paddingLeft: '10px',
  paddingRight: '15px',
  paddingTop: '15px',
  paddingBottom: '15px',
  fontSize: '5px',
  cursor: 'pointer'
};

const stylesDiv2 = {
  width: '160px',
  height: '240px',
  fontSize: '5px',
  backgroundColor: 'white',
  pointerEvents: 'none'
};

const stylesDiv3 = {
  paddingBottom: '20px',
  cursor: 'pointer'
};

const stylesFormatted = {
  ...selectStylesDefault,
  menu: (provided, state) => ({
    ...provided,
    zIndex: 100
  })
};

export const selectStylesError = {
  control: (provided, state) => {
    return {
      ...provided,
      border: '1px solid red'
    };
  }
};

const PreviewTemplates = ({ type, expedientNumber, setReloadDataTemplates, reloadDataTemplates, setShowModalTemplates, expedientId }) => {
  const { userId_03 } = Cookies.get();
  const [templates, setTemplates] = useState([]);
  const [showModalTiny, setShowModalTiny] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [templateTiny, setTemplateTiny] = useState({});
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [errors, setErrors] = useState({});
  const [emailsOptions, setEmailsOptions] = useState([]);
  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const [selectedAgreementTypes, setSelectedAgreementTypes] = useState([]);
  const shouldAskIfIsAPublishBulletin = useMemo(() => templatesTypesToAskIfIsAPublishBulletin.includes(type), [type]);
  const imagesWithCorsErrorState = useImagesWithCorsErrorState();
  const loader = useLoaderContext();

  const onIsAPublishBulletinChange = ({ target: { value } }) => {
    const isIncomingValueTrue = value === 'true';
    setErrors(({ ifexcerpt, ...restOfProperties }) => ({ ...restOfProperties }));
    setTemplateTiny({ ...templateTiny, ifexcerpt: isIncomingValueTrue, isAPublic: isIncomingValueTrue });
  }

  useEffect(() => {
    if (type !== '') {
      (async function () {
        await getTemplatesByType(
          type,
          setTemplates,
          expedientId,
        );
        await getAgreementTypes(
          setAgreementTypes,
          hydre
        );

        const hydre = (formattedPromotions) => {
          setAgreementTypes(formattedPromotions);
          setSelectedAgreementTypes(formattedPromotions);
        }

        const hydratePromotions = (formattedPromotions) => {
          setPromotions(formattedPromotions);
          setSelectedPromotions(formattedPromotions);
        }
        await getPromotionsWithoutAgreement(
          expedientId,
          hydratePromotions
        );
        if (type === 'notification') await getEmailsTemplates(expedientId, setEmailsOptions);
      })()
    }
  }, [])

  const onSelectRubroChange = (selected) => {
    setSelectedAgreementTypes(selected);
    const agreementsFormatted = selected ? selected.map((p) => p.value) : [];
    const agreementsFormattedLabel = selected ? selected.map((p) => p.label) : [];
    setTemplateTiny({
      ...templateTiny,
      alias: agreementsFormattedLabel.toString(),
      headings: agreementsFormatted
    });
  }

  const openModalTinyTemplate = (template) => {
    if (template) {
      setSelectedPromotions(promotions);
      setErrors({});
      const promotionsFormatted = selectedPromotions ? selectedPromotions.map((p) => p.value) : [];
      const agreementsFormatted = agreementTypes ? agreementTypes.map((p) => p.value) : [];
      const agreementsFormattedLabel = agreementTypes ? agreementTypes.map((p) => p.label) : [];
      setTemplateTiny({
        content: template['content'],
        document_id: template['id'],
        name: template['name'],
        user_id: userId_03,
        expedient: expedientNumber.replace(/-/g, '/'),
        expedient_id: expedientId,
        type: type,
        comments: '',
        comment: '',
        alias: type !== 'agreement' ? template['name'] : agreementsFormattedLabel.toString(),
        answer_promotion: true,
        all_promotion: true,
        promotions_ids: promotionsFormatted.toString(),
        headings: type == 'agreement' ? agreementsFormatted : [],
        isAPublic: false
      });
      setShowModalTiny(true);
    }
  };

  const handleEditorChange = (e) => {
    delete errors['content'];
    delete errors['html'];

    setTemplateTiny({
      ...templateTiny,
      content: e.content
    });
  };
  
  const [validateType, setStyle] = useState("cont");
  const [cssValidate, setCssValidate] = useState({});
  const isFieldShowing = getIsFieldShowingInFile(templateTiny.type);

  const tinyEditor = (
    <div style={{ margin: '0 auto', width: 'auto' }}>
      <div style={{ margin: '0 auto', width: '80%' }}>
        <Form>
          <Form.Group>
            <Form.Label>{templateTiny['type'] === 'agreement' ? '* Rubro' : '* Nombre'}</Form.Label>
            {templateTiny['type'] === 'agreement' ?
              <div id='validateSelect' className={validateType}  style={cssValidate}>
                <Select  id='selectRubro'
                  defaultValue={selectedAgreementTypes}
                  onChange={onSelectRubroChange}
                  value={selectedAgreementTypes}
                  isClearable={false}
                  isMulti
                  options={agreementTypes}
                  placeholder="Selecciona rubros"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  noOptionsMessage={agreementTypes.length > 0 ? () => "Sin más opciones disponibles" : () => "Sin opciones disponibles"}
                  styles={stylesFormatted}
                />
              </div>
              :
              <Form.Control
                onChange={(e) => {
                  delete errors['alias'];
                  setTemplateTiny({ ...templateTiny, alias: e.target.value });
                }}
                className={renderClassesToErrors(errors, 'alias')}
                placeholder='Agrega un nombre'
                value={templateTiny['alias']}
              />
            }
            {renderErrorsList(errors, 'alias')}
          </Form.Group>
          <hr />

          {
            isFieldShowing
            &&
            <>
              <Form.Group>
                <Form.Label>Observaciones internas sobre el documento:</Form.Label>
                <Form.Control
                  onChange={(e) => setTemplateTiny({ ...templateTiny, comments: e.target.value })}
                  as="textarea"
                  rows="5"
                  aria-label="Observaciones"
                  className={renderClassesToErrors(errors, 'comments')}
                />
                <Form.Text>
                  Escribe aquí las observaciones para corrección del documento previo al firmado electrónico del mismo.
                </Form.Text>
                {renderErrorsList(errors, 'comments')}
              </Form.Group>
              <hr />
            </>
          }

          {
            isFieldShowing
            &&
            <>
              <Form.Group>
                <Form.Label>Comentarios sobre el resumen del documento:</Form.Label>
                <Form.Control
                  onChange={({ target: { value } }) => setTemplateTiny({ ...templateTiny, comment: value })}
                  as="textarea"
                  rows="5"
                  aria-label="Comentarios"
                />
                <Form.Text>
                  Escribe aquí los comentarios que desees que se visualicen como resumen del documento.
                </Form.Text>
              </Form.Group>
              <hr />
            </>
          }

          {
            shouldAskIfIsAPublishBulletin
            &&
            <>
              <Form.Group >
                <Form.Label>* ¿Desea publicar en el boletín?</Form.Label>
                {renderErrorsList(errors, 'ifexcerpt')}
                <section>
                  <Form.Check
                    inline
                    label="Si"
                    name="publishBulletin"
                    type={'radio'}
                    id={'publish-bulletin'}
                    value={true}
                    onChange={onIsAPublishBulletinChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="publishBulletin"
                    type={'radio'}
                    id={'do-not-publish-bulletin'}
                    value={false}
                    onChange={onIsAPublishBulletinChange}
                  />
                </section>
              </Form.Group>
              {
                (templateTiny.ifexcerpt)
                &&
                <>
                  <Form.Group>
                    <Form.Label>* Extracto para boletín:</Form.Label>
                    {renderErrorsList(errors, 'excerpt')}
                    <Form.Control
                      onChange={(e) => {
                        setErrors(({ excerpt, ...restOfProperties }) => ({ ...restOfProperties }))
                        setTemplateTiny({ ...templateTiny, excerpt: e.target.value })
                      }}
                      as="textarea"
                      rows="5"
                      aria-label="Extracto para boletín"
                      className={renderClassesToErrors(errors, 'excerpt')}
                    />
                  </Form.Group>
                </>
              }
              <hr />
            </>
          }

          {type === 'agreement' ?
            <Form.Group >
              <Form.Label>¿Deseas responder promociones?</Form.Label>
              <Form.Check
                type="checkbox"
                label={"Si"}
                checked={templateTiny['answer_promotion'] && promotions.length > 0 ? true : false}
                id={"si"}
                value={templateTiny['answer_promotion'] ? false : true}
                onChange={(e) => {
                  if (e.target.value === 'true') {
                    if (promotions.length === 0) NotificationManager.error('No existen promociones disponibles para responder')
                    const promotionsFormatted = selectedPromotions ? selectedPromotions.map((p) => p.value) : [];
                    setTemplateTiny({
                      ...templateTiny,
                      answer_promotion: true,
                      promotions_ids: promotionsFormatted.toString()
                    });
                  } else {
                    delete templateTiny['promotions_ids'];
                    setTemplateTiny({
                      ...templateTiny,
                      answer_promotion: false
                    });
                  }
                }}
                className={renderClassesToErrors(errors, 'answer_promotion')}
              />
              {renderErrorsList(errors, 'answer_promotion')}
              <hr />
            </Form.Group> : ''}

          {
            templateTiny['answer_promotion'] === true && type === 'agreement' ?
              <>
                <Form.Group>
                  <Form.Label>Respuesta a promociones:</Form.Label>
                  <Select
                    defaultValue={selectedPromotions}
                    onChange={(selected) => {
                      setSelectedPromotions(selected);
                      const promotionsFormatted = selected ? selected.map((p) => p.value) : [];
                      setTemplateTiny({
                        ...templateTiny,
                        answer_promotion: true,
                        promotions_ids: promotionsFormatted.toString(),
                        all_promotion: promotions.length === promotionsFormatted.length
                      });
                    }}
                    value={selectedPromotions}
                    isClearable={false}
                    isMulti
                    options={promotions}
                    placeholder="Selecciona las promociones de este acuerdo"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    noOptionsMessage={promotions.length > 0 ? () => "Sin más opciones disponibles" : () => "Sin opciones disponibles"}
                    styles={stylesFormatted}
                  />
                  {renderErrorsList(errors, 'promotions_ids')}
                </Form.Group>
                <hr />
              </> : ""
          }

          <Form.Group>
            <Form.Label>* Documento:</Form.Label>
            {renderErrorsList(errors, 'content')}
          </Form.Group>
        </Form>
      </div>
      <div className={"container-preview-templates" + (errors['content'] ? '-error' : '')}>
        <RichTextEditor
          value={templateTiny.content}
          onChange={handleEditorChange}
          imagesWithCorsErrorState={imagesWithCorsErrorState}
        />
      </div>
    </div>
  );

  const onSaveTemplate = async () => {
    const saveTemplateResponse = await handleRequestSaveTemplate(templateTiny);
    if (!saveTemplateResponse) {
      return
    }

    setShowModalTemplates(false);
    setShowModalTiny(false);
  }

  const handleRequestSaveTemplate = async () => {
    const requiredFields = [
      'alias',
      'content',
      'user_id',
    ];

    if (shouldAskIfIsAPublishBulletin && templateTiny.ifexcerpt === undefined) {
      requiredFields.push('ifexcerpt');
    }

    if (templateTiny.ifexcerpt) {
      requiredFields.push('excerpt');
    }

    const responseValidation = fieldsValidation(requiredFields, templateTiny, selectedAgreementTypes);

    if (Array.isArray(selectedAgreementTypes)) {
      if (selectedAgreementTypes.length == 0) {
        setStyle("is-invalid");
        setCssValidate({borderStyle:'solid', borderWidth: 'thin', borderRadius: '4px'})
      } else {
        setStyle("");
      setCssValidate({});
      }
    } else {
      if (selectedAgreementTypes  == null) {
        setStyle("is-invalid");
        setCssValidate({borderStyle:'solid', borderWidth: 'thin', borderRadius: '4px'})
      } else {
        setStyle("");
        setCssValidate({});
      }
    }
    
    if (typeof responseValidation === 'object') {
      NotificationManager.error('Los campos marcados en rojo son requeridos, revísalos');
      setErrors(responseValidation);
    } else {
      if (typeof templateTiny['alias'] === 'object')
        templateTiny['alias'] = templateTiny['alias']['value'];

      const {
        isAPublishBulletin,
        ...templateTinyToSend
      } = templateTiny;

      const templatePresaveResponse = await requestTemplatePresave(
        templateTinyToSend,
        setErrors,
        setReloadDataTemplates,
        reloadDataTemplates,
      );
      return templatePresaveResponse;
    }
  };

  const handleSaveTemplate = async ({ isACallFromAgreementWithoutPromotionModal } = {}) => {
    loader.show(true);
    const areThereTemplateImagesWithCorsError = await imagesWithCorsErrorState.getAreThereTemplateImagesWithCorsError(templateTiny.content);
    loader.show(false);

    if (areThereTemplateImagesWithCorsError) {
      return
    };

    if (isACallFromAgreementWithoutPromotionModal) {
      handleRequestSaveTemplate(templateTiny);
      setShowModalConfirm(false);
      return
    }

    const doAllPromotionsExist = Boolean(_.get(templateTiny, 'all_promotion'));
    if (doAllPromotionsExist) {
      onSaveTemplate();
      return
    }

    setShowModalConfirm(true);
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <div className="row">
        {
          templates.map((template, index) => (
            <div
              key={index}
              className="col-sm"
              style={stylesDiv3}
              onClick={() => openModalTinyTemplate(template)}
            >
              <IframeFunctional title={index} >
                <div
                  style={stylesDiv}
                  onClick={() => openModalTinyTemplate(template)}
                >
                  <div
                    style={stylesDiv2}
                    dangerouslySetInnerHTML={{
                      __html: template['html']
                    }}
                  />
                </div>
              </IframeFunctional>
              <br />
              <span>{template['name']}</span>
            </div>
          ))
        }
      </div>
      <ModalComponentPortal
        header={`${templateTiny['name']}:`}
        body={tinyEditor}
        footer={(
          <>
            <Button
              variant="secondary"
              onClick={() => setShowModalTiny(false)}
            >
              Cerrar
            </Button>
            <Button
              variant="success"
              onClick={handleSaveTemplate}
            >
              <i className="fas fa-save" /> {'  '}
              Guardar
            </Button>
          </>
        )}
        isModalShowing={showModalTiny}
        onHide={() => {
          setSelectedPromotions([]);
          setShowModalTiny(false);
        }}
      />

      <AgreementWithoutPromotionModal
        onCancelClick={() => setShowModalConfirm(false)}
        onAcceptClick={() => handleSaveTemplate({ isACallFromAgreementWithoutPromotionModal: true })}
        onOutsideClick={() => {
          setSelectedPromotions([]);
          setShowModalConfirm(false);
        }}
        isModalShowing={showModalConfirm}
      />
    </div>
  );
};

export default PreviewTemplates;
