import React, { useEffect, useState } from "react"
import { Modal, Button, Container } from "react-bootstrap";
import { Separator } from "../shared/Separator";
import Agreements from "../juditial_bulletin/expedient_form/sections/info_data/Agreements";
import Sentences from "../juditial_bulletin/expedient_form/sections/info_data/Sentences";
import { getAgreementsByExpedient } from "../juditial_bulletin/api/agreements";
import { getSentencesByExpedient } from "../juditial_bulletin/api/sentences";
import { findExpedient } from "../juditial_bulletin/api/expedients";
import InfoExistentExpedient from "../juditial_bulletin/info_expedient/InfoExistentExpedient";

const InfoModal = ({ show, setShow, expedientId }) => {
  const [expedientInfo, setExpedientInfo] = useState({});
  const [agreements, setAgreements] = useState([]);
  const [sentences, setSentences] = useState([]);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (expedientId) {
      (async () => {
        const expedient = await findExpedient(expedientId);
        setExpedientInfo([expedient]);
      })();

      (async () => {
        const agreements = await getAgreementsByExpedient(expedientId);
        setAgreements(agreements);
      })();

      (async () => {
        const sentences = await getSentencesByExpedient(expedientId);
        setSentences(sentences);
      })();
    }

  }, [expedientId]);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header>
        <Modal.Title>Detalle del expediente</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container>
          <InfoExistentExpedient records={expedientInfo} />
          <Agreements agreements={agreements} />
          <Separator />
          <Sentences sentences={sentences} />
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal >
  );
}

export default InfoModal;