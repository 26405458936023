import React, { useContext, useEffect } from 'react';
import { PartiesItem } from '../parties/PartiesItem';
import { PartiesContext } from '../../context/PartiesContext';
import { PartiesForm } from '../forms/PartiesForm';
import { PartiesList } from '../parties/PartiesList';
import { PatyFormDelete } from '../forms/PatyFormDelete';
import LoaderContext from "../../../../../../layout/shared/loader_context.js";
import Loader from "../../../../../../layout/shared/Loader.js";

export const ModalPartiesBody = () => {
    const { toggleShowAddForm, showForm, isDeleteParty, isOpenEditForm, values } = useContext(PartiesContext);
    const loader = useContext(LoaderContext);
    
    useEffect(() => {
	loader.show(values.loading);
    }, [values.loading]);


    if (showForm || isOpenEditForm) return (<PartiesForm />);
    if ( isDeleteParty ) return <PatyFormDelete />;
    
    return (
        <div>
            <div className='d-flex justify-content-start'>
                <p className='h4 user-select-none'>Partes</p>

                <button
                    style={{
                        width: '25px',
                        height: '25px',
                        padding: '2px 0',
                        borderRadius: '50%',
			
                        textAlign: 'center',
                        fontSize: '20px',
                        borderColor: 'green',
                        fontWeight: 'bold',
                        marginLeft: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    className='btn'
                    onClick={toggleShowAddForm}
                >
                    <span>+</span>
                </button>
            </div>
            <PartiesList />

	    <Loader />
        </div>
    )
    
}
