
import React from 'react';
import Cookies from 'js-cookie';
import { RequestNewVersion } from '../../classes/RequestNewVersion';
import { NotificationManager } from 'react-notifications';
import { getDecodedJwt } from '../../../services/dataFormatting/getDecodedJwt';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { getLabelFromDictionary } from '../../../config/dictionary';

let userPerms = null;
const { authentication_token_03 } = Cookies.get();
if (authentication_token_03) {
  const { user } = getDecodedJwt(authentication_token_03);
  userPerms = user;
}

export const initColumnsTableReceivedHistory = [
  {
    dataField: "index",
    text: "#",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "expedient_number_link",
    text: `No. ${getLabelFromDictionary('global', 'singleLowerMainName')}`,
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "sender",
    text: "Emisor",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "group",
    text: "Puesto",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "reception_date",
    text: "Fecha de recepción",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "actions",
    text: "Acciones",
    sort: false,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  }
];
if (userPerms) {
  if (userPerms.special_permissions[0].general_secretariat &&
    userPerms.special_permissions[0].general_secretariat === '1') {
    const generalSecretariatColmns = [
      {
        dataField: "party",
        text: "Partido",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" }
      },
      {
        dataField: "court",
        text: "Juzgado",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" }
      },
      {
        dataField: "actions",
        text: "Acciones",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" }
      }
    ]
    generalSecretariatColmns.map((colm, index) => {
      initColumnsTableReceivedHistory[index + 5] = colm;
    });
  }
}



export const getReceivedHistory = async (
  setReceivedHistory,
  setTotalExpedients,
  page,
  sizePerPage,
  handleShowModal,
  setTextNotData
) => {

  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/history/user/${userId_03}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const response = await request.executeRequest();

  if (response.code !== 200) {
    NotificationManager.error(`Algo sucedió y no fue posible traer los ${getLabelFromDictionary('global', 'lowerMainName')}, inténtalo nuevamente`);
  } else {
    if (
      response.response.error &&
      response.response.message &&
      typeof response.response.message === "string"
    ) {
      NotificationManager.error(response.response.message);
    }
    else if (response.response.history && response.response.history.length === 0) {

      setTextNotData("No existen resultados por mostrar");
      setTotalExpedients(0);
      setReceivedHistory([]);
    }
    else {
      const expedients = await formattedExpedients(
        response.response.history,
        handleShowModal
      );
      setTotalExpedients(response.response.total);
      setReceivedHistory(expedients);
    }
  }
};

const formattedExpedients = (expedients, handleShowModal) => {

  return expedients.map((expedient, index) => {

    expedient["actions"] = (
      <Row key={expedient['id']} center="xs" around="xs">
        <Col xs={12} className="procedures-actions">
          <div className="continue" onClick={(e) => { handleShowModal(expedient) }}>
            <i className="ti-eye" title="Ver detalle" />
          </div>
        </Col>
      </Row>
    );
    expedient["expedient_number_link"] = (
      <Link
        to={{
          pathname: `/expedient_details/${expedient.expedient_id}`,
          state: {
            labelPath: 'Expedientes recibidos',
            prevPath: window.location.pathname
          }
        }}
      >
        {expedient.expedient_number}
      </Link>
    );

    expedient['index'] = expedient.id;
    expedient['group'] = (expedient.sender.group).toUpperCase();
    expedient['sender'] = expedient.sender.name;
    expedient["reception_date"] = expedient['reception_date'] ? getFormatedDate(expedient['reception_date']) : '';
    expedient['party'] = expedient['party'] ? expedient['party'] : 'N/A';
    expedient['court'] = expedient['court'] ? expedient['court'] : 'N/A';

    return expedient;
  });
};

const getFormatedDate = (date) => {
  let year = date.substr(0, 4);
  let month = date.substr(5, 2);
  let day = date.substr(8, 2);

  return `${day}-${month}-${year}`;
}

export const sendSearchExpedients = async (
  search,
  setTextNotData,
  sizePerPage,
  page,
  setData,
  setTotalProcedures,
  handleShowModal
) => {

  const { userId_03 } = Cookies.get();
  search = String(search).replace(/\//g, '-');

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/government_books/history/search/user/${userId_03}/${search}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseSearch = await request.executeRequest();

  if (responseSearch.code === 200) {

    if (responseSearch.response.history && responseSearch.response.history.length > 0) {

      const expedients = await formattedExpedients(
        responseSearch.response.history,
        handleShowModal
      );

      setData(expedients);
      setTotalProcedures(responseSearch.response.total);
    }
    else {

      setTotalProcedures(0);
      setTextNotData("No se han encontrado resultados");
      setData([]);
    }
  }
  else if (
    responseSearch.response.error &&
    typeof responseSearch.response.message === "string"
    && responseSearch.response.message !== ""
  ) {

    setTextNotData(responseSearch.response.message);
    setData([]);
    setTotalProcedures(0);
  }
};
