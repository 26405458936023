import React from 'react'
import { pdf } from '../../../../../../../services/fileTypes/CommonMimeTypes'

const ActionsRow = ({
  onSignClick,
  documentLink,
  onCommentsClick,
  onDeleteClick,
  onPreviewDocument,
  onClick,
  isSignedButtonDisabled = false,
  isDeleteButtonDisabled = false,
  fileType = '',
}) => {

  const isAPdfFile = fileType === pdf;

  return (
    <div className='d-flex justify-content-around ' style={{ fontSize: '20px', gap: '5px' }} onClick={onClick}>
      <a href={documentLink} download>
        <button
          type="button"
          className="btn btn-info px-3 py-1"
          data-toggle="tooltip"
          title="Descargar documento"
        >
          <i className="fas fa-file-alt px-1"></i>
        </button>
      </a>
      {
        isAPdfFile
        &&
        <button
          style={{ color: 'white' }}
          type="button"
          className="btn btn-secondary px-2 py-1"
          data-toggle="tooltip"
          title="Visualizar documento"
          onClick={onPreviewDocument}
        >
          <i className="fas fa-eye px-2"></i>
        </button>
      }
      <button
        type="button"
        className="btn btn-success px-2 py-1"
        onClick={onSignClick}
        data-toggle="tooltip"
        title="Firmar documento"
        disabled={isSignedButtonDisabled}
      >
        <i className="fas fa-pen px-2"></i>
      </button>
      <button
        type="button"
        className="btn btn-primary px-2 py-1"
        data-toggle="tooltip"
        title="Comentarios"
        onClick={onCommentsClick}
      >
        <i className="fas fa-comments px-2"></i>
      </button>
      <button
        type="button"
        className="btn btn-danger px-2 py-1"
        data-toggle="tooltip"
        title="Eliminar documento"
        onClick={onDeleteClick}
        disabled={isDeleteButtonDisabled}
      >
        <i className="fas fa-trash-alt px-2"></i>
      </button>
    </div>
  )
}
export default ActionsRow