import React, { createContext, useContext, useState } from 'react'

const DeletePendingDocumentToBeSignedContext = createContext();
DeletePendingDocumentToBeSignedContext.displayName = 'DeletePendingDocumentToBeSignedContext';

const DeletePendingDocumentToBeSignedContextProvider = (props) => {

  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(false);
  const [isDocumentDeletedSuccessfully, setIsDocumentDeletedSuccessfully] = useState(false);

  const toggleIsDeleteDocumentModalOpen = () => {
    setIsDeleteDocumentModalOpen((previosState) => !previosState)
  }

  const contextValueFromProps = props.value;

  const contextValue = {
    isDeleteDocumentModalOpen,
    setIsDeleteDocumentModalOpen,
    isDocumentDeletedSuccessfully,
    setIsDocumentDeletedSuccessfully,
    toggleIsDeleteDocumentModalOpen,
    ...contextValueFromProps
  }

  return (
    <DeletePendingDocumentToBeSignedContext.Provider {...props} value={contextValue} />
  )
}

export const useDeletePendingDocumentToBeSignedContext = () => {
  const context = useContext(DeletePendingDocumentToBeSignedContext);
  if (typeof context === 'undefined') {
    throw new Error('To use useDeletePendingDocumentToBeSignedContext you must wrap inside a DeletePendingDocumentToBeSignedContextProvider')
  }
  return context;
}

export default DeletePendingDocumentToBeSignedContextProvider