import _ from "lodash";
import { RequestNewVersion } from "../../../components/classes/RequestNewVersion";

export const onSignMultisignatureFileRequest = async (bodyRequest = {}, documentId) => {
  const signMultisignatureRequestURL = `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/signature_end/${documentId}`;
  const signMultisignatureRequest = new RequestNewVersion(signMultisignatureRequestURL, "post", null, bodyRequest);

  try {
    const signMultisignatureResponse = await signMultisignatureRequest.executeRequest();
    const signMultisignatureResponseCode = _.get(signMultisignatureResponse, 'code');
    const signMultisignatureResponseSuccessful = signMultisignatureResponseCode === 200;

    if (!signMultisignatureResponseSuccessful) {
      throw new Error('Ocurrió un error al intentar firmar el archivo con múltiples firmas');
    }

    return signMultisignatureResponse

  } catch (error) {
    throw error;
  }
}