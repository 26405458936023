import React, { useEffect, useMemo } from 'react'
import ReactDOM from "react-dom";

const modalRoot = document.getElementById('modal-root');

const ModalComponentPortal = ({ header, body, footer, isModalShowing, onHide = () => { } }) => {

  const onCloseModal = ({ target: { classList } }) => {
    if ([...classList].includes('click-to-close-modal-root')) {
      onHide();
    }
  }

  return (
    <>
      {
        isModalShowing
        &&
        ReactDOM.createPortal((
          <div
            className='modal-root click-to-close-modal-root'
            onClick={onCloseModal}
          >
            <div className='modal-root-main-modal'>
              <header
              >
                {header}
              </header>
              <main
              >
                {body}
              </main>
              <div className='footer-modal-root'>
                {footer}
              </div>
              <div style={{ height: '1.75rem' }}>
              </div>
            </div>
          </div>
        ), modalRoot)
      }
    </>
  )
}

export default ModalComponentPortal;