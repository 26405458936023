import { useEffect } from 'react';
import _ from 'lodash';
import { RequestNewVersion } from '../../../../../classes/RequestNewVersion';
import { useLoaderContext } from '../../../../../layout/shared/loader_context';

const useHandleIndicatorsInitialValues = ({
  expedientId,
  initialDemand,
  finalJudgment,
  interlocutoryJudgment,
  representationAdmissibleProceeding,
  testSection,
}) => {

  const { show: setIsLoaderShowing } = useLoaderContext();

  const getIndicatorsInitialValues = async () => {
    const indicatorsInitialValuesPathRequest = `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/indicators/${expedientId}`;
    const indicatorsInitialValuesRequest = new RequestNewVersion(indicatorsInitialValuesPathRequest, "get", null, {});

    let indicatorsInitialValuesResponse;

    try {
      setIsLoaderShowing(true);
      indicatorsInitialValuesResponse = await indicatorsInitialValuesRequest.executeRequest();
    } catch (error) {
      throw error
    } finally {
      setIsLoaderShowing(false);
    }

    const indicatorsInitialValuesResponseCode = _.get(indicatorsInitialValuesResponse, 'code', undefined)
    const isASuccessfulResponse = indicatorsInitialValuesResponseCode === 200;

    if (!isASuccessfulResponse) {
      return
    }

    const indicatorsInitialValues = _.get(indicatorsInitialValuesResponse, 'response.electronic_expedient.indicators', {});
    return indicatorsInitialValues
  }

  const setIndicatorsInitialValues = (indicatorsInitialValues) => {
    initialDemand.setInitialState(indicatorsInitialValues.initial_demands);
    finalJudgment.setInitialState(indicatorsInitialValues.final_judgments);
    interlocutoryJudgment.setInitialState(indicatorsInitialValues.interlocutory_judgments);
    representationAdmissibleProceeding.setInitialState(indicatorsInitialValues.representation_admissible_proceedings);
    testSection.setInitialState(indicatorsInitialValues.test);
  }

  const handleIndicatorsInitialValues = async () => {
    const indicatorsInitialValues = await getIndicatorsInitialValues();
    const doesIndicatorsInitialValuesExist = _.isObject(indicatorsInitialValues);

    if (!doesIndicatorsInitialValuesExist) {
      return
    }

    setIndicatorsInitialValues(indicatorsInitialValues);
  }

  useEffect(() => {
    handleIndicatorsInitialValues();
  }, [])

}

export default useHandleIndicatorsInitialValues