import '../../styles/styles.css';
import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ErrorsByKeyName } from '../shared/ErrorsByKeyName';
import { usePartiesContext } from '../../context/PartiesContext';
import { isError } from '../../helpers/logicDataParties';
import { findData, mappingData, mappingDataOne } from '../../helpers/parseAndFilterDataToForm';
import { typeParties } from '../../assets/typeParties';
import _ from 'lodash';
const getOptionsByMattersOfUser = ( mattersByUser ) => {
    let namesOfMatter = mattersByUser.map(item => `${item.name}`.toLowerCase());
    let res = typeParties.filter(party =>
	party.permittedSubjects.some(permitted => namesOfMatter.includes(`${permitted}`.toLowerCase()))
    );
    return res || [];
}

export const PartiesFormType = () => {
    const [ partySelected, setPartySelected ] = useState(null);
    const { values, handleChageForm, randomVal, currentPartyItem, mattersByUser } = usePartiesContext();
    const handleChangeTypePartie = (val) => {
        if (val) {
            setPartySelected(val);
            handleChageForm(val.value, 'typeParts')
        } else {
            setPartySelected(null);
            handleChageForm('', 'typeParts');
        }
    }
    
    useEffect( () => {
        // LOAD VALUES TO STATES
        if(values.formValues.typeParts) setPartySelected(mappingDataOne(findData(typeParties, values.formValues.typeParts)));
    }, [randomVal, currentPartyItem] );
    
    return (
        <Form style={{ width: '100%', zIndex: 100 }} className='border p-5 rounded mb-2'>
            <Row>
                <Col xs={12} md={12}>
                    <Form.Group className='mb-3'>
                        <Form.Label>* Tipo de parte</Form.Label>
                        <Select
                            options={mappingData(getOptionsByMattersOfUser(mattersByUser))}
                            placeholder='Selecciona una opción'
                            classNamePrefix="my-select"
                            className={isError(values.errors, 'typeParts') ? 'select-error class' : 'class'}
                            onChange={val => handleChangeTypePartie(val)}
                            value={partySelected}
                            isDisabled={_.isEmpty(currentPartyItem) ? false : true}
                            style={{
                                zIndex: 100,
                            }}
                        />
                        <ErrorsByKeyName listErrors={values.errors} value='typeParts' />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}
