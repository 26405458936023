import { useState } from "react";
import _ from 'lodash';
import { useMultipleElectronicSignatureModalContext } from "../../details_expedient/components/filesPendingSignatureTable/componets/electronicSignatureModal/context/MultipleElectronicSignatureModalContext";
import useTransferState from "../../../transferModal/hooks/useTransferState";

const useSignaturePendingListState = ({ history = { push: () => { } } }) => {

  const [currentExpedientData, setCurrentExpedientData] = useState(null);
  const currentExpedientId = _.get(currentExpedientData, 'id');

  const handleRowClick = (rowData) => {
    setCurrentExpedientData(rowData.expedient);
  }

  const {
    handleSaveSignatures,
    handleRemoveSavedSignatures,
  } = useMultipleElectronicSignatureModalContext();

  const transferState = useTransferState({
    handleSaveSignatures,
    handleRemoveSavedSignatures,
    isInternalTurningByDefault: true,
    history,
    expedientData: currentExpedientData,
    onCloseTransferModalClick: () => { },
  });

  return (
    {
      transferState,
      currentExpedientId,
      handleRowClick,
    }
  )
}

export default useSignaturePendingListState