import Cookies from "js-cookie";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

export const getSpecialPermissions = () => {

  const { special_permissions_03 } = Cookies.get();
  let specialPermissions = {};

  try {
    if (typeof special_permissions_03 === 'string') {
      const specialPermissionsparsed = JSON.parse(special_permissions_03);
      specialPermissions = _.get(specialPermissionsparsed, '[0]', {});
    }
  } catch {
    NotificationManager.error('Ocurrió un error al obtener los permisos especiales');
  }

  const specialPermissionsKeys = Object.keys(specialPermissions);

  for (const permissionKey of specialPermissionsKeys) {
    specialPermissions[permissionKey] = specialPermissions[permissionKey] === '1';
  }

  return specialPermissions;
}