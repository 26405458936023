import { Request } from "../../../../../../../classes/Request";
import moment from 'moment';
import 'moment/locale/es';

export const getComments = async (documentId) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/get_comments/${documentId}`,
    "get",
    null,
    null
  );

  try {
    const response = await request.executeRequest();
    if (!response || response.code !== 200) {
      return false
    }
    if (response.data && response.data.data && response.data.data.data && response.data.data.data.signatureDocuments)
      return response.data.data.data.signatureDocuments
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export const addComment = async (documentId, comment) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/create_comment`,
    "post",
    null,
    {
      'signature_document_id': documentId,
      comment,
    }
  );

  try {
    const response = await request.executeRequest();
    if (!response || response.code !== 200) {
      return false
    }
    if (response.data && response.data.data && response.data.data.status)
      return response.data.data
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export const getLocalDateFromComments = (arrayDataToSetDate) => {
  const localDateComments = arrayDataToSetDate.map((element) => {
    const elementDate = new Date(element.created_at.date);
    moment.locale('es');
    const localDate = moment(elementDate).calendar();

    return {
      ...element,
      localDate,
    }
  })

  return localDateComments
}