import React from 'react'
import { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { getTemplateFileName } from '../../../../services/multipleElectronicSignature'
import ModalComponent from '../../../helper_components/ModalComponent'
import useElectronicSignatureTemplatesModal from '../customHooks/useElectronicSignatureTemplatesModal'
import NotifyEmailModal from './NotifyEmailModal'
import CredentialsModal from '../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/credentialsModal/CredentialsModal'
import ToggleButton from '../../../layout/shared/ToggleButton'
import ElectronicSignatureTable from '../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/componets/ElectronicSignatureTable'
import { useElectronicSignatureProcessContext } from '../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext'
import { useTemplatesPresavedContextContext } from '../context/templatesPresavedContext'
import { electronicSignatureSettingsFirel } from '../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/constants/electronicSignatureSettingsFirel'
import SimpleSignatureAlert from './SimpleSignatureAlert'

const ElectronicSignatureTemplatesModal = ({
  htmlOfDocumentToSign,
  expedientTemplateId,
  templateName,
  ExpedientId,
  userId,
  templateData,
  reloadExpedientDetailsComponent,
  setReloadTemplatesData,
  onCloseTemplateModal,
  electronicNotificationState,
  setShowModalTiny,
  dataExpedient,
  emailTemplateId,
}) => {

  const {
    isElectronicSignatureModalSowing,
    setIsElectronicSignatureModalSowing,
    electronicSignaturesList,
    onCloseModal: onCloseCredentialsModal,
    onAddElectronicSignature,
    isMultipleSignature,
    onDelteElectronicSignatureById,
    singleElectronicSignature,
    isThereASignature,
  } = useElectronicSignatureProcessContext();

  const {
    isElectronicSignatureModalOpen,
    onCloseElectronicSignatureModal,
  } = useTemplatesPresavedContextContext();

  const documentToSignName = useMemo(() => getTemplateFileName(templateName, expedientTemplateId, ExpedientId), [templateName, expedientTemplateId, ExpedientId])

  const {
    onSingTemplate,
    isNotificationModalShowing,
    onCloseNotifyEmailModal,
    isMultisignatureEnabled,
    isElectronicSignatureTableShowing,
    onToggleButtonClick,
    isSimpleSignatureAlertShowing,
    setIsSimpleSignatureAlertShowing,
    onCancelSimpleSignatureAlertClick,
    onContinueSimpleSignatureAlertClick,
  } = useElectronicSignatureTemplatesModal({
    htmlOfDocumentToSign,
    expedientTemplateId,
    documentToSignName,
    userId,
    onCloseElectronicSignatureModal,
    templateData,
    reloadExpedientDetailsComponent,
    setReloadTemplatesData,
    onCloseTemplateModal,
    electronicNotificationState,
    setShowModalTiny,
    dataExpedient,
    emailTemplateId,
  });

  const footerModal = (
    <Button
      disabled={!isElectronicSignatureTableShowing}
      variant="success"
      onClick={onSingTemplate}
    >
      <i className="fas fa-user-edit"></i> Firmar
    </Button>
  )

  const bodyModal = (
    <>
      {
        isElectronicSignatureModalSowing
        &&
        <CredentialsModal
          isModalSowing={isElectronicSignatureModalSowing}
          onCloseModal={onCloseCredentialsModal}
          addElectronicSignatureHandle={onAddElectronicSignature}
          isMultipleSignature={isMultipleSignature}
          electronicSignatureSettings={electronicSignatureSettingsFirel}
          isOnlyOneSignaturePerUserActivated
        />
      }

      <Button
        variant="success"
        onClick={() => setIsElectronicSignatureModalSowing(true)}
        className={'mb-3'}
        style={{ marginTop: '20px' }}
      >
        {isThereASignature ? 'Cambiar Firma' : 'Agregar Firma'}
      </Button>

      {
        isMultisignatureEnabled
        &&
        <section className="text-muted mt-3 mb-3">
          <ToggleButton
            name='ToggleButtonName'
            onChange={onToggleButtonClick}
            id='isMultipleSignatureToggleButtonTemplatePresaved'
            isCheckedControlled={isMultipleSignature}
          >
            Multifirma
          </ToggleButton>
          <div className="text-muted mt-1 mb-2" style={{ fontSize: '11.2px', lineHeight: '1.5' }}>
            Activa esta sección si deseas que el documento sea firmado por más de un usuario.
          </div>
        </section>
      }

      {
        isElectronicSignatureTableShowing
        &&
        <>
          <p className='m-0'>
            {isMultipleSignature ? 'Lista de Firmantes' : 'Firmante'}
          </p>
          <ElectronicSignatureTable
            electronicSignaturesList={electronicSignaturesList}
            singleElectronicSignature={singleElectronicSignature}
            isMultipleSignature={isMultipleSignature}
            onDelteElectronicSignatureById={onDelteElectronicSignatureById}
          />
        </>
      }
    </>
  )

  return (
    <>
      {
        isNotificationModalShowing
        &&
        <NotifyEmailModal
          showModal={isNotificationModalShowing}
          expedientId={ExpedientId}
          handleOnCancel={onCloseNotifyEmailModal}
          onAcceptClick={(restOfArguments) => { onSingTemplate({ signTemplateFromNotificationModal: true, ...restOfArguments }) }}
        />
      }
      <ModalComponent
        header='Firmar Plantilla'
        body={bodyModal}
        footer={footerModal}
        show={isElectronicSignatureModalOpen}
        canClose={true}
        onHide={onCloseElectronicSignatureModal}
        scroll={true}
        dialogClassName="electronic-signature-template-modal"
      />
      <SimpleSignatureAlert
        isModalShowing={isSimpleSignatureAlertShowing}
        setIsModalShowing={setIsSimpleSignatureAlertShowing}
        onCancel={onCancelSimpleSignatureAlertClick}
        onContinue={onContinueSimpleSignatureAlertClick}
      />
    </>
  )
}

export default ElectronicSignatureTemplatesModal