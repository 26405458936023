import {RequestNewVersion} from "../../../classes/RequestNewVersion";
import {NotificationManager} from "react-notifications";

export const getExpedientStakeHolders = async (expedientId, setUsers) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedient/stakeholders/${expedientId}`,
    "get",
    null,
    {}
  );
  const responseUsers = await request.executeRequest();
  if (
    responseUsers &&
    responseUsers.code === 200 && responseUsers.response &&
    responseUsers.response.users && responseUsers.response.users.length > 0
  ) {
    setUsers(responseUsers.response.users.map((u) => ({
      ...u,
      canDelete: true
    })));
  }
};

export const handleRequestSendDataNotificationEmail = async (
  data
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/expedient_notifications/send`,
    "post",
    null,
    {...data}
  );

  const responseSendEmail = await request.executeRequest();
  if (responseSendEmail && responseSendEmail.code === 200) {
    return true;
  } else {
    NotificationManager.error(
      'Ha ocurrido un error inesperado, es probable que haya errores en la configuración del correo electrónico, por favor revísala.', 
      '', 
      5000
    );
  }
};
