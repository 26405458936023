export const personTypeOptions = [
  {
    value: 1,
    label: 'Persona Fisica',
  },
  {
    value: 2,
    label: 'Persona Moral',
  },
  {
    value: 3,
    label: 'Dependencia pública',
  },
];