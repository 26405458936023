import React, { useState } from 'react';
import Part from './Part';
import PartsFormModal from '../../modals/parts/PartsFormModal';
import { deleteSpecificPart, getAllPartsByExpedient } from '../api/parts';

const PartsList = ({ parts, setParts, expedientId = null }) => {
  const [show, setShow] = useState(false);
  const [partId, setPartId] = useState(null);

  const editPart = (id) => {
    setShow(true);
    setPartId(id);
  }

  const deletePart = async (id) => {
    if (window.confirm('¿Estás seguro de eliminar la parte?')) {
      console.log("Eliminar parte ", id);

      if (!expedientId) {
        setParts(parts.filter((part) => {
          if (part.id !== id) {
            return part;
          }
          return;
        }));
      } else {
        await deleteSpecificPart(id);

        setParts(
          await getAllPartsByExpedient(expedientId)
        );
      }
    }
  }

  return (
    <div>
      {parts.length > 0 ?
        parts.map((part) => (
          <Part
            key={part.id}
            part={part}
            editPart={editPart}
            deletePart={deletePart}
            expedientId={expedientId}
          />
        ))
        :
        <div>
          No hay partes agregadas
        </div>
      }

      <PartsFormModal
        show={show}
        setShow={setShow}
        parts={parts}
        setParts={setParts}
        id={partId}
        expedientId={expedientId}
        key={partId}
      />
    </div>
  );
}

export default PartsList;