import React from 'react'
import { Button } from 'react-bootstrap'
import { useIndicatorsContext } from '../../../../../../../context/indicatorsContext/IndicatorsContext';

const FooterModal = ({ handleAddMinor }) => {

  const { initialDemand } = useIndicatorsContext();

  return (
    <>
      <Button onClick={initialDemand.toggleisAddMinorModalShowing}>
        Cancelar
      </Button>
      <Button onClick={handleAddMinor}>
        Guardar
      </Button>
    </>
  )
}

export default FooterModal