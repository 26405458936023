export const downloadFileFromUrl = async (url, fileExtension = 'pdf') => {
  try {
    const downloadResponse = await fetch(url);
    console.log({ downloadResponse });

    const blobFile = await downloadResponse.blob();
    console.log({ blobFile });

    const headerList = downloadResponse.headers.entries();

    for (const header of headerList) {
      console.log(`${header[0]}: ${header[1]}`);
    }

    const objectURL = URL.createObjectURL(blobFile);
    const anchorElement = document.createElement('a');
    anchorElement.href = objectURL;
    anchorElement.download = `deleteme.${fileExtension}`;
    anchorElement.click();
  } catch (error) {
    console.error(error);
  }
}