import useFormValues from "../../../../../../../../../../../../hooks/useFormValues";
import { useIndicatorsContext } from "../../../../../../../context/indicatorsContext/IndicatorsContext";

const defaultAddMinorFormValues = {
  initial_age: null,
  nationality: null,
  gender: null,
  representation: [],
}

const useAddMinor = () => {

  const formMethods = useFormValues(defaultAddMinorFormValues);

  const { initialDemand } = useIndicatorsContext();

  const handleAddMinor = () => {
    initialDemand.handleAddMinor(formMethods.formValues);
    initialDemand.toggleisAddMinorModalShowing();
  }

  return {
    ...formMethods,
    handleAddMinor,
  }
}

export default useAddMinor