const getSentencesByExpedient = async (expedientId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    }

    const allSentencesByExpedientResponse =
      await fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}` +
        `/api/sentences?filters[expedientId][id][$eq]=${expedientId}&populate=documentSentence`,
        requestOptions);

    const sentences = await allSentencesByExpedientResponse.json();
    return sentences.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const deleteSentence = async (sentenceId) => {
  try {
    const sentenceResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}` + `/api/sentences/${sentenceId}`,
      { method: 'DELETE' }
    );

    return await sentenceResponse.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

const editSentence = async (sentenceId, sentence) => {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          loadDateSentence: sentence.sentence_date,
          opinionDateSentence: sentence.opinion_sentence_date,
          crimeActionSentence: sentence.crime,
          genderPerspectiveSentence: sentence.gender_perspective_sentence,
          womenPerspectiveSentence: sentence.women_perspective_sentence,
          infantilPerspectiveSentence: sentence.infantil_perspective_sentence
        }
      }),
    }

    const editResponse = await fetch(
      `${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/sentences/${sentenceId}`, requestOptions
    );
    const updatedSentence = await editResponse.json();
    return updatedSentence;
  } catch (error) {
    console.error(error);
    console.log("Ocurrio un error al editar el acuerdo");
  }
}

const addSentenceToExpedient = (sentence, expedientId, documentType, setRecords) => {
  return fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/sentences`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
    },
    body: JSON.stringify({
      data: {
        loadDateSentence: sentence.sentence_date,
        opinionDateSentence: sentence.opinion_sentence_date,
        crimeActionSentence: sentence.crime,
        expedientId: expedientId,
        genderPerspectiveSentence: sentence.gender_perspective_sentence,
        womenPerspectiveSentence: sentence.women_perspective_sentence,
        infantilPerspectiveSentence: sentence.infantil_perspective_sentence,
        documentType: documentType
      }
    }),
  })
    .then(response => response.json())
    .then(async addedSentence => {
      const requestBody = new FormData();
      requestBody.append('files', sentence.file);
      requestBody.append('field', 'documentSentence');
      requestBody.append('ref', 'api::sentence.sentence');
      requestBody.append('refId', addedSentence.data.id);

      await fetch(`${process.env.REACT_APP_STRAPI_BACKEND_PATH}/api/upload`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
        },
        method: 'POST',
        body: requestBody
      });

      setRecords(null);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

export {
  getSentencesByExpedient,
  // deleteSentence,
  editSentence,
  addSentenceToExpedient
};