import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from './Part.module.css';
import { NavLink } from 'react-bootstrap';

const Part = ({ part, editPart, deletePart, expedientId }) => {
  const partId = part.id;

  if (expedientId) {
    part = part.attributes;
  }

  return (
    <div className={styles.partContainer}>
      <div className={styles.generalPartInfo}>
        <span className={styles.fullName}>
          {part.partNames + ' ' + part.paternalSurname + ' ' + part.maternalSurname}
        </span>
        <span className={styles.partType}>{part.partType.label}</span>
        <span className={styles.partClasification}>{part.partClasification.label}</span>

        <NavLink>
          <FontAwesomeIcon
            icon={faPencil}
            className={styles.editIconStyles}
            onClick={() => editPart(partId)}
          />
        </NavLink>

        <NavLink>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => deletePart(partId)}
            className={styles.deleteIconStyles}
          />
        </NavLink>
      </div>
    </div >
  );
}

export default Part;