import _ from "lodash";
import { documentTypes } from "../expedient_details_logic";

export const getDisplayNameOfType = (type, subtypes) => {
  let documentType = documentTypes.find(documentTypeElement => documentTypeElement.value === type);

  if (!documentType) {
    documentType = documentTypes.find(documentTypeElement => documentTypeElement.valueAlias === type);
  }

  if (!subtypes) {
    const displayNameOfType = _.get(documentType, 'label', '');
    return displayNameOfType;
  }

  const displayNameBySubtypes = _.invoke(documentType, 'getLabelBySubtype', subtypes);
  return displayNameBySubtypes
}