import React, {useState, useEffect, useContext} from 'react';
import NavBar from '../../layout/shared/NavBar';
import {Grid} from 'react-flexbox-grid';
import Loader from "../../layout/shared/Loader";
import {RemotePagination} from '../../helper_components/remote_pagination/RemotePagination';
import { columns, getPromotions, markAsAttended } from './promotions_table_logic';
import Footer from '../../layout/shared/Footer';
import FullCommentModal from '../../helper_components/full_comment/FullCommentModal';
import LoaderContext from '../../layout/shared/loader_context';
import ConfirmModal from '../../helper_components/confirm_modal/ConfirmModal';
import { getLabelFromDictionary } from '../../../config/dictionary';

const PromotionsTable = (props) => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalPromotions, setTotalPromotions] = useState(3);
  const [textNotData,setTextNotData] = useState("");
  const [promotions, setPromotions] = useState([]);
  const [commentModal, setCommentModal] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentAttending, setCurrentAttending] = useState(null);

  const loader = useContext(LoaderContext);

  useEffect(() => {
    (async () => {
      await retrievePromotions(page, sizePerPage);
    })();
  }, []);

  const retrievePromotions = async (page, sizePerPage) => {
    loader.show(true);
    await getPromotions(
      page,
      sizePerPage,
      setPromotions,
      setTotalPromotions,
      setTextNotData,
      setCommentModal,
      setShowCommentModal,
      setShowConfirmModal,
      setCurrentAttending
      );
    loader.show(false);
  }

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    await retrievePromotions(page, sizePerPage);
  }

  const handleConfirmAttendance = async () => {
    loader.show(true);
    await markAsAttended(
      currentAttending.id,
      setCurrentAttending,
      setShowConfirmModal
    );
    await retrievePromotions(page, sizePerPage);
    loader.show(false);
  }

  return (
    <>
      <NavBar {...props}/>
      <h3 className="mT-20 w-100 text-center">{ getLabelFromDictionary( 'promitions', 'label' ) }</h3>

      { promotions.length > 0
        ? (
          <Grid fluid={true} className="no-padding" style={{ minHeight: '76vh', width: "85%", margin: "0px auto" }}>
            <section className="procedures-table-container mT-30">
              <RemotePagination
                data={promotions}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalPromotions}
                onTableChange={handleTableChange}
                columns={columns}
              />
            </section>
          </Grid>
      ) : (
        <h4 className="text-center clearFloat padding-footer-not-data">{textNotData}</h4>
      )}

      <Footer />

      <FullCommentModal
        comment={commentModal}
        showModal={showCommentModal}
        setShowModal={setShowCommentModal}
      />

      <ConfirmModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        title="Marcar como atendida"
        bodyText="¿Desea marcar esta promoción como atendida?"
        cancelCallback={() => {}}
        confirmCallback={handleConfirmAttendance}
      />

      <Loader />
    </>
  );

}

export default PromotionsTable;
