import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import "./file64.css";

const File64 = props => {
  const { setFile, label, id, defaultFileName = '', ...restOfProps } = props;
  const [fileName, setFileName] = useState(defaultFileName);
  const onDrop = (files) => {
    let file = files[0];
    setFileName(file.name);
    setFile(file);
  };

  return (
    <div className="margin-top-2em">
      <Form.Group controlId="formBasicFile">
        <Form.Label >
          {label}
        </Form.Label>
        <div className={'d-flex align-items-center'}>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input
                    id={id}
                    name={id}
                    {...getInputProps()}
                    {...restOfProps}
                  />
                  <Button
                    variant="secondary"
                    size="sm"
                    className={'text-nowrap text-white'}
                  >
                    {fileName ? 'Modificar Archivo' : ' Agregar Archivo'}
                  </Button>
                </div>
              </section>
            )}
          </Dropzone>
          {
            fileName
            &&
            <div className='ml-2 p-3 bg-success text-white rounded text-break'>
              {fileName}
            </div>
          }
        </div>
      </Form.Group>
    </div>
  );
};
export default File64;



