import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { getFooterData, initStateFooter } from "./footer_logic";
import _ from "lodash";
import { controlPanelTabNames } from "../../government_book/control_panel/constants/controlPanelTabNameList";
import { getLabelFromDictionary } from "../../../config/dictionary";

const SideBar = (props) => {
    const { app_config_03, special_permissions_03 } = Cookies.get();
    const {
        open,
        onHide
    } = props;
    const [currentDropDownElement, setCurrentDropDownElement] = useState(null);
    const [initForm, setInitForm] = useState(initStateFooter);
    const permissions_parsed = special_permissions_03 ? JSON.parse(special_permissions_03) : [];
    const canTurnForeign = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['foreign'] === '1';
    const canModifyExpedients = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['modify_expedient'] === '1';
    const canGoToProcedures = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['procedure_section'] === '1';
    const canGoToExpedients = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['expedient_section'] === '1';
    const canGoToPromotions = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['promotion_section'] === '1';
    const canGoToExpirationPanel = _.get(permissions_parsed, '[0]expiration_panel', '0') === '1';
    const canGoToPendingSignaturesPanel = _.get(permissions_parsed, '[0]pending_signatures_panel', '0') === '1';
    const canGoToControlPanel = canGoToExpirationPanel || canGoToPendingSignaturesPanel;
    const routeParams = _.get(props, 'match.params', {});
    const { panelTab } = routeParams;
    const lastPanelTab = useRef(panelTab);
    const canShowJuditialBulletin = permissions_parsed && permissions_parsed[0] && permissions_parsed[0]['bulletin_capture'] === '1';

    const hideSidebar = () => {
        onHide(false);
        setCurrentDropDownElement(null);
    };

    if (lastPanelTab.current !== panelTab) {
        lastPanelTab.current = panelTab;
        hideSidebar()
    }

    const handleDropDownElementClick = (event) => {
        const newCurrentDropDownElement = _.get(event, 'currentTarget.dataset.elementName');
        setCurrentDropDownElement(newCurrentDropDownElement);
    }

    useEffect(() => {
        (async function () {
            if (!app_config_03) {
                await getFooterData(setInitForm);
            }
            else {
                setInitForm(JSON.parse(app_config_03));
            }
        })();
    }, []);

    return (
        <>
            <div
                className={open ? "sidebarActiveContainer" : "sidebarInactiveContainer"}
                onClick={() => hideSidebar()}
            />
            <div
                className={open ? "sidebarActive" : "sidebarInactive"}
            >
                <div
                    style={{ width: "100%", marginLeft: "10%", marginTop: "18px" }}
                >
                    <img
                        width={"50%"}
                        src={initForm && initForm.app_functionary_header_logo_url_image ? initForm.app_functionary_header_logo_url_image : `${process.env.REACT_APP_URL_BASE_LARAVEL}img/nilo_completo_01.svg`}
                        alt="application logo"
                    />
                </div>
                {canGoToProcedures &&
                    <Link
                        style={{
                            textDecoration: "none"
                        }}
                        to={'/home'}
                    >
                        <div
                            className="optionSidebar"
                            onClick={() => onHide(false)}
                            title="Sección de trámites"
                        >
                            <i
                                className="fas fa-atlas mR-10"
                            />{'  '}
                            Trámites
                        </div>
                    </Link>
                }
                {
                    canGoToExpedients
                    &&
                    <div
                        className="optionSidebar"
                        onClick={handleDropDownElementClick}
                        data-element-name={'expedients'}
                    >
                        <i
                            className="fas fa-book mR-10"
                        />{'  '}
                        { getLabelFromDictionary( 'lateralMenu','expiedients' ) }
                        {currentDropDownElement === 'expedients' && (
                            <ul className="itemsDropdownGovernmentBook">
                                <Link to="/my_expedients"><li>{ getLabelFromDictionary( 'expedientsMenu', 'myExpedients' ) }</li></Link>
                                <Link to="/pending_transfers"><li>{ getLabelFromDictionary( 'expedientsMenu', 'sendedExpedients' ) }</li></Link>
                                <Link to="/expedient_detail"><li>{ getLabelFromDictionary( 'expedientsMenu', 'searchExpedients' ) }</li></Link>
                                <Link to="/received_history"><li>{ getLabelFromDictionary( 'expedientsMenu', 'resivedExpedients' ) }</li></Link>
                                <Link to="/pending_expedients_to_receive"><li>{ getLabelFromDictionary( 'expedientsMenu', 'pendingExpedients' ) }</li></Link>
                                {canGoToPromotions && (
                                    <Link to="/promotions"><li>{ getLabelFromDictionary( 'expedientsMenu', 'promitions' ) }</li></Link>
                                )}
                            </ul>
                        )}
                    </div>
                }
                {canTurnForeign &&
                    <Link
                        style={{
                            textDecoration: "none"
                        }}
                        to={'/turn_foreign'}
                    >
                        <div
                            className="optionSidebar"
                            onClick={() => onHide(false)}
                            title="Turnado externo de expedientes"
                        >
                            <i
                                className="fas fa-university mR-10"
                            />{'  '}
                            Turnado externo de {getLabelFromDictionary('global', 'lowerMainName')}
                        </div>
                    </Link>
                }
                {canModifyExpedients &&
                    <Link
                        style={{
                            textDecoration: "none"
                        }}
                        to={'/modify_expedients'}
                    >
                        <div
                            className="optionSidebar"
                            onClick={() => onHide(false)}
                            title="Modificar expedientes"
                        >
                            <i
                                className="fas fa-book mR-10"
                            />{'  '}
                            { getLabelFromDictionary( 'lateralMenu', 'updateExpedient' ) }
                        </div>
                    </Link>
                }
                {
                    canGoToControlPanel
                    &&
                    <div
                        className="optionSidebar"
                        onClick={handleDropDownElementClick}
                        data-element-name={'controlPanel'}
                    >
                        <i className="ti-panel" />
                        <span className="ml-2 pl-1">
                            Panel de control
                        </span>
                        {
                            currentDropDownElement === 'controlPanel'
                            &&
                            <ul className="itemsDropdownGovernmentBook itemsDropdownGovernmentBook__controlPanel">
                                {
                                    canGoToExpirationPanel
                                    &&
                                    <Link to={`/control_panel/${controlPanelTabNames.expirationTabName}`}>
                                        <li>
                                            Vencimiento
                                        </li>
                                    </Link>
                                }
                                {
                                    canGoToPendingSignaturesPanel
                                    &&
                                    <Link to={`/control_panel/${controlPanelTabNames.pendingSignatureTabName}`}>
                                        <li>
                                            Pendiente de firma
                                        </li>
                                    </Link>
                                }
                            </ul>
                        }

                    </div>
                }
                {
                    canShowJuditialBulletin &&
                    <Link
                        style={{
                            textDecoration: "none"
                        }}
                        to={'/juditial_bulletin'}
                    >
                        <div
                            className="optionSidebar"
                            onClick={() => onHide(false)}
                            title="Boletín Judicial"
                        >
                            <i
                                className="fas fa-gavel mR-10"
                            />{'  '}
                            Boletín Judicial Tradicional
                        </div>
                    </Link>
                }
            </div >
        </>

    );
};

export default SideBar;
