import { Request } from "../../classes/Request";

/**********************************************************SAVE AVATAR************************************************************************/
export const getOptionsByUrl = async (url, dependentValues, urlValues) => {
  let urlParams = urlValues.substring(0, urlValues.length - 1);
  const request = new Request(
    `${url}?token=${process.env.REACT_APP_TOKEN}&${urlParams}`,
    "get",
    null,
    {
      dependentValues
    }
  );
  const getOptionsByUrlData = await request.executeRequest();
  if (getOptionsByUrlData) {
    return getOptionsByUrlData;
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
  } else {
  }
};
/***********************************************************************************************************************************************/
