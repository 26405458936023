import _ from "lodash";
import { RequestNewVersion } from "../../../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";

export const getCourtUsersByCourtId = async (courtId) => {
  const courtUsersRequest = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/courts/get_users/${courtId}`,
    "get",
    null,
    {},
  );

  const { code, response } = _.defaultTo(await courtUsersRequest.executeRequest(), {});
  if (code !== 200) {
    NotificationManager.error('Ocurrió un problema al tratar de obtener el listado de usuarios receptores');
    return;
  }

  return _.get(response, 'users', []);
}