import React, { useState } from "react";
import { RemotePagination } from "../../helper_components/remote_pagination/RemotePagination";
import { Grid } from "react-flexbox-grid";
import NavBar from "../../layout/shared/NavBar";
import ExpedientsSearcher from "../../helper_components/expedients_searcher/ExpedientSearcher";
import {
    columnsDetail,
    searchHistoryExpedients
} from "./expedient_detail_logic";
import Footer from "../../layout/shared/Footer";
import { getLabelFromDictionary } from "../../../config/dictionary";


const ExpedientDetail = props => {
  const [columns] = useState(columnsDetail);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalProcedures, setTotalProcedures] = useState(0);
  const [searching,setSearching] = useState(false);
  // eslint-disable-next-line
  const [cleaning,setCleaning] = useState(false);
  const [search,setSearch] = useState("");
  const [messageNotFound,setMessageNotFound] = useState("");


  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    await searchHistoryExpedients(
        search,
        sizePerPage,
        page,
        setData,
        setTotalProcedures,
        setMessageNotFound
    );
  };

  const searchExpedients = async () => {
    setData([]);
    setTotalProcedures(0);
    setMessageNotFound("");
    if ( search.length > 0 && /^$|.*\S+.*/.test(search) ) {

        setSearching(true);
        await searchHistoryExpedients(
            search.replace(/^\s+|\s+$/gm,''),
            sizePerPage,
            page,
            setData,
            setTotalProcedures,
            setMessageNotFound
        );
        setSearching(false);
    }

  };

  const cleanSearch = () => {

    setMessageNotFound("");
    setSearch("");
    setData([]);
    setTotalProcedures(0);
  };

  return (
    <>
        <NavBar {...props} />
        
        <h3 className="mT-20 w-100 text-center">{getLabelFromDictionary('searchOfExpedient','searchOfExpedientLabel')}</h3>
        
        <div style={{width:"85%",margin:"0px auto",display:"block"}}>
            <ExpedientsSearcher
                placeholderSearch={`Escribe el número de ${getLabelFromDictionary('global', 'lowerMainName')}`}
                placeholderSearchButton={`Buscar ${getLabelFromDictionary('global', 'lowerMainName')}`}
                placeholderCleanButton={`Nueva búsqueda`}
                helperContextual={`Escribe el número de ${getLabelFromDictionary('global', 'lowerMainName')} y presiona el botón Buscar ${getLabelFromDictionary('global', 'lowerMainName')}.`}
                executeSearch={ searchExpedients }
                cleanSearch={ cleanSearch }
                isSearching={searching}
                isCleaning={cleaning}
                setSearch={setSearch}
                search={search}
                hasRulesToSearch={true}
                rulesToSearch={/^[a-zA-Z0-9/]*$/}
            />
        </div>
        <Grid fluid={true} className={ data.length === 0 ? "d-n" : "no-padding"} style={{minHeight: '76vh', width:"85%", margin:"0px auto"}}>
            <section className="procedures-table-container mT-30">

                <h4 className="text-center clearFloat">Resultados de la búsqueda</h4>
                <RemotePagination
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={totalProcedures}
                    onTableChange={handleTableChange}
                    columns={columns}
                />

            </section>
        </Grid>

        <h4 className={ data.length === 0 ? "text-center clearFloat padding-footer-not-data" : "d-n"}>{messageNotFound}</h4>

        <Footer/>
    </>
  );
};
export default ExpedientDetail;
