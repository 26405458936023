import { useState } from "react";
import { RequestNewVersion } from "../../../../../classes/RequestNewVersion";
import { manageReplicasRequestUrl } from "../constants/urls";
import { NotificationManager } from "react-notifications";
import { useLoaderContext } from "../../../../../layout/shared/loader_context";
import _ from "lodash";
import { useExpedientDetailsContext } from "../../../context/expedientDetailsContext";

const useReplicasManagementModal = ({
  document = {},
}) => {

  const [isModalShowing, setIsModalShowing] = useState(false);
  const loader = useLoaderContext();

  const {
    onUpdateExpedientDetailsComponet = () => { },
  } = useExpedientDetailsContext();

  const handleMainButtonClick = () => {
    handleOpenModal();
  }

  const handleCloseModal = () => {
    setIsModalShowing(false);
  }

  const handleOpenModal = () => {
    setIsModalShowing(true);
  }

  const handleManageReplicas = async () => {
    const manageReplicasBodyRequest = {
      document_expedient_id: document.id,
      status: !document.areReplicasVisible,
    };

    try {
      loader.show(true);

      const manageReplicasRequest = new RequestNewVersion(manageReplicasRequestUrl, "post", null, manageReplicasBodyRequest);
      const manageReplicasResponse = await manageReplicasRequest.executeRequest();

      const manageReplicasSuccessfulResponse = _.get(manageReplicasResponse, 'code') === 200;

      if (manageReplicasSuccessfulResponse) {
        NotificationManager.success('El estatus de las réplicas de este documento ha sido actualizado');
        onUpdateExpedientDetailsComponet();
        return
      }

      NotificationManager.error('No se pudo actualizar es estatus de las réplicas de este documento');

    } catch (error) {

      NotificationManager.error(error.message);

    } finally {

      handleCloseModal();
      loader.show(false);

    }
  }

    const actionText = document.areReplicasVisible ? 'cancelar la remisión de' : 'remitir';

  return {
    isModalShowing,
    handleMainButtonClick,
    handleCloseModal,
    handleOpenModal,
    handleManageReplicas,
    actionText,
  }
}

export default useReplicasManagementModal
