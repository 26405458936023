export function getDecodedJwt(jwt) {
	let decodedToken;

	try {
		decodedToken = JSON.parse(atob(jwt.split('.')[1]));
		return decodedToken
	} catch (error) {
		console.error(error)
		return null;
	}
}