export const getFileNameWithoutExtensionFromName = (fileName) => {
  if (typeof fileName !== 'string') {
    throw new Error('File name must be of type string');
  }

  const fineNameSplitted = fileName.split('.');
  const isThereAnExtension = fineNameSplitted.length > 1;

  if (!isThereAnExtension) {
    return fineNameSplitted;
  }

  fineNameSplitted.pop();
  const fileNameWithoutExtension = fineNameSplitted.join('.');
  return fileNameWithoutExtension;
}