import React, { useContext } from "react";

/**
 * Context loader
 * @type {React.Context<{show: LoaderContext.show, status: boolean}>}
 */
const LoaderContext = React.createContext({
    status: false,
    show: () => { }
});

export const useLoaderContext = () => {
    const context = useContext(LoaderContext);
    if (typeof context === 'undefined') {
        throw new Error('To use LoaderContext you must wrap inside a LoaderContext.Provider')
    }
    return context
}

export default LoaderContext;
