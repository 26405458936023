import React from 'react'
import { Button } from 'react-bootstrap'
import ModalComponent from '../../../helper_components/ModalComponent'

const AlertModal = ({ isShowingModal, title, content, onAccept, onCancel, onCloseModal, restOfProps }) => {
  return (
    <>
      {
        isShowingModal
        &&
        <ModalComponent
          header={title}
          body={content}
          footer={<FooterModal onAccept={onAccept} onCancel={onCancel} />}
          show={isShowingModal}
          canClose={true}
          onHide={onCloseModal}
          scroll={true}
          {...restOfProps}
        />
      }
    </>
  )
}

const FooterModal = ({ onAccept, onCancel }) => {
  return (
    <>
      {
        onCancel
        &&
        <Button
          variant="secondary"
          className="mR-10"
          onClick={onCancel}
        >
          Cancelar
        </Button>
      }
      <Button
        variant="primary"
        onClick={onAccept}
      >
        Aceptar
      </Button>
    </>
  )
}

export default AlertModal