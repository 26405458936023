import { useState } from 'react';
import { getTemplateImagesWithCorsError } from '../../../../services/tinymce/getTemplateImagesWithCorsError';

const useImagesWithCorsErrorState = () => {
  const [isTemplateImagesWithCorsErrorModalShowing, setIsTemplateImagesWithCorsErrorModalShowing] = useState(false);
  const [templateImageWithCorsErrorList, setTemplateImageWithCorsErrorList] = useState([]);

  const handleCloseTemplateImagesWithCorsErrorModal = () => {
    setIsTemplateImagesWithCorsErrorModalShowing(false);
  };

  const getAreThereTemplateImagesWithCorsError = async (template) => {
    const newTmplateImageWithCorsErrorList = await getTemplateImagesWithCorsError(template);
    setTemplateImageWithCorsErrorList(newTmplateImageWithCorsErrorList);

    if (newTmplateImageWithCorsErrorList.length) {
      setIsTemplateImagesWithCorsErrorModalShowing(true);
      return true
    }

    return false
  };

  return {
    isTemplateImagesWithCorsErrorModalShowing,
    templateImageWithCorsErrorList,
    handleCloseTemplateImagesWithCorsErrorModal,
    getAreThereTemplateImagesWithCorsError,
  }
};

export default useImagesWithCorsErrorState;