import React from 'react';
import { Card, Container, Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { deleteSentence } from '../../../api/sentences';
// import EditSentenceModal from '../../../../modals/sentences/EditSentenceModal';
import moment from 'moment';
// import styles from "../../../bulletin_results/BulletinResults.module.css";

const Sentences = ({ sentences }) => {
  // const [show, setShow] = useState(false);
  // const [sentenceInfo, setSentenceInfo] = useState(null);

  // const handleEditSentence = async (e, sentence) => {
  //   e.preventDefault();
  //   setSentenceInfo(sentence);
  //   setShow(true);
  // }

  // const handleDeleteSentence = async (e, id) => {
  //   e.preventDefault();

  //   const confirmation = window.confirm('¿Estas seguro que deseas eliminar esta sentencia?');
  //   if (confirmation) {
  //     await deleteSentence(id);
  //     window.location.reload();
  //   }
  // }

  const formatDate = (expedientDate) => {
    return moment(expedientDate).locale('es').format('LL');
  }

  return (
    <>
      <Card>
        <Card.Header>
          <h5>Sentencias</h5>
        </Card.Header>
        <Card.Body>
          <Container>
            <table>
              <thead>
                <tr>
                  <th>Fecha de la Sentencia</th>
                  <th>Tipo</th>
                  <th>Acción o Delito</th>
                  <th>Descargar</th>
                  {/* <th className='text-center'>Acciones</th> */}
                </tr>
              </thead>
              <tbody>
                {sentences.map((sentence) => {
                  const { documentSentence } = sentence.attributes;

                  return (
                    <tr key={sentence.id}>
                      <td>{formatDate(sentence.attributes.loadDateSentence)}</td>
                      <td>{sentence.attributes.documentType ? sentence.attributes.documentType.label : 'Sin Tipo'}</td>
                      <td>{sentence.attributes.crimeActionSentence.label}</td>
                      <td>
                        <Button
                          variant="primary"
                          href={documentSentence ? documentSentence.data.attributes.url : '#'}
                          target="_blank"
                        >
                          Descargar
                        </Button>
                      </td>
                      {/* <td>
                        <div className={styles.actionElements}>
                          <NavLink>
                            <FontAwesomeIcon
                              icon={faPencil}
                              className={styles.editIconStyles}
                              onClick={(e) => handleEditSentence(e, sentence)}
                            />
                          </NavLink>

                          <NavLink>
                            <FontAwesomeIcon
                              icon={faTrash}
                              className={styles.deleteIconStyles}
                              onClick={(e) => handleDeleteSentence(e, sentence.id)}
                            />
                          </NavLink>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        </Card.Body>
      </Card >

      {/* <EditSentenceModal show={show} setShow={setShow} sentence={sentenceInfo} /> */}
    </>
  );
}

export default Sentences;