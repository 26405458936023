import React, { useEffect } from 'react'
import { Col, Form } from 'react-bootstrap'
import ReactSelect from 'react-select'
import FormTitle from '../../../../../../FormTitle'
import { initialAgeOptions } from '../../../../../../../services/selectOptions/initialAgeOptions'
import { representationOptions } from '../../../../../../../services/selectOptions/representationOptions'
import { getElementFromOptionsList } from '../../../../../../../../../../../../services/dataFormatting/getElementFromOptionsList'

const BodyModal = ({
  formValues,
  onSelectChange,
  onFormCheckListChange,
}) => {

  return (
    <Col>
      <FormTitle>
        Agregar un menor
      </FormTitle>
      <Form>
        <Form.Group controlId="initial_age">
          <Form.Label>
            Edad al inicio del juicio
          </Form.Label>
          <ReactSelect
            options={initialAgeOptions}
            onChange={onSelectChange}
            name='initial_age'
            value={getElementFromOptionsList(formValues.initial_age, initialAgeOptions)}
            placeholder="Selecciona una edad"
          />
        </Form.Group>

        <Form.Group controlId="nationality">
          <Form.Label>
            Nacionalidad
          </Form.Label>
          <ReactSelect
            options={[
              { "value": 1, "label": "MEXICANA" },
              { "value": 2, "label": "NAMIBIANA" },
              { "value": 3, "label": "ANGOLESA" },
              { "value": 4, "label": "ARGELIANA" },
              { "value": 5, "label": "DE BENNIN" },
              { "value": 6, "label": "BOTSWANESA" },
              { "value": 7, "label": "BURUNDESA" },
              { "value": 8, "label": "DE CABO VERDE" },
              { "value": 9, "label": "COMORENSE" },
              { "value": 10, "label": "CONGOLESA" },
              { "value": 11, "label": "MARFILEÑA" },
              { "value": 12, "label": "CHADIANA" },
              { "value": 13, "label": "DE DJIBOUTI" },
              { "value": 14, "label": "EGIPCIA" },
              { "value": 15, "label": "ETIOPE" },
              { "value": 16, "label": "GABONESA" },
              { "value": 17, "label": "GAMBIANA" },
              { "value": 18, "label": "GHANATA" },
              { "value": 19, "label": "GUINEA" },
              { "value": 20, "label": "GUINEA" },
              { "value": 21, "label": "GUINEA ECUATORIANA" },
              { "value": 22, "label": "LIBIA" },
              { "value": 23, "label": "KENIANA" },
              { "value": 24, "label": "LESOTHENSE" },
              { "value": 25, "label": "LIBERIANA" },
              { "value": 26, "label": "MALAWIANA" },
              { "value": 27, "label": "MALIENSE" },
              { "value": 28, "label": "MARROQUI" },
              { "value": 29, "label": "MAURICIANA" },
              { "value": 30, "label": "MAURITANA" },
              { "value": 31, "label": "MOZAMBIQUEÑA" },
              { "value": 32, "label": "NIGERINA" },
              { "value": 33, "label": "NIGERIANA" },
              { "value": 34, "label": "CENTRO AFRICANA" },
              { "value": 35, "label": "CAMERUNESA" },
              { "value": 36, "label": "TANZANIANA" },
              { "value": 37, "label": "RWANDESA" },
              { "value": 38, "label": "DEL SAHARA" },
              { "value": 39, "label": "DE SANTO TOME" },
              { "value": 40, "label": "SENEGALESA" },
              { "value": 41, "label": "DE SEYCHELLES" },
              { "value": 42, "label": "SIERRA LEONESA" },
              { "value": 43, "label": "SOMALI" },
              { "value": 44, "label": "SUDAFRICANA" },
              { "value": 45, "label": "SUDANESA" },
              { "value": 46, "label": "SWAZI" },
              { "value": 47, "label": "TOGOLESA" },
              { "value": 48, "label": "TUNECINA" },
              { "value": 49, "label": "UGANDESA" },
              { "value": 50, "label": "ZAIRANA" },
              { "value": 51, "label": "ZAMBIANA" },
              { "value": 52, "label": "DE ZIMBAWI" },
              { "value": 53, "label": "ARGENTINA" },
              { "value": 54, "label": "BAHAMEÑA" },
              { "value": 55, "label": "DE BARBADOS" },
              { "value": 56, "label": "BELICEÑA" },
              { "value": 57, "label": "BOLIVIANA" },
              { "value": 58, "label": "BRASILEÑA" },
              { "value": 59, "label": "CANADIENSE" },
              { "value": 60, "label": "COLOMBIANA" },
              { "value": 61, "label": "COSTARRICENSE" },
              { "value": 62, "label": "CUBANA" },
              { "value": 63, "label": "CHILENA" },
              { "value": 64, "label": "DOMINICA" },
              { "value": 65, "label": "SALVADOREÑA" },
              { "value": 66, "label": "ESTADOUNIDENSE" },
              { "value": 67, "label": "GRANADINA" },
              { "value": 68, "label": "GUATEMALTECA" },
              { "value": 69, "label": "BRITANICA" },
              { "value": 70, "label": "GUYANESA" },
              { "value": 71, "label": "HAITIANA" },
              { "value": 72, "label": "HONDUREÑA" },
              { "value": 73, "label": "JAMAIQUINA" },
              { "value": 74, "label": "NICARAGUENSE" },
              { "value": 75, "label": "PANAMEÑA" },
              { "value": 76, "label": "PARAGUAYA" },
              { "value": 77, "label": "PERUANA" },
              { "value": 78, "label": "PUERTORRIQUEÑA" },
              { "value": 79, "label": "DOMINICANA" },
              { "value": 80, "label": "SANTA LUCIENSE" },
              { "value": 81, "label": "SURINAMENSE" },
              { "value": 82, "label": "TRINITARIA" },
              { "value": 83, "label": "URUGUAYA" },
              { "value": 84, "label": "VENEZOLANA" },
              { "value": 85, "label": "AMERICANA" },
              { "value": 86, "label": "AFGANA" },
              { "value": 87, "label": "DE BAHREIN" },
              { "value": 88, "label": "BHUTANESA" },
              { "value": 89, "label": "BIRMANA" },
              { "value": 90, "label": "NORCOREANA" },
              { "value": 91, "label": "SUDCOREANA" },
              { "value": 92, "label": "CHINA" },
              { "value": 93, "label": "CHIPRIOTA" },
              { "value": 94, "label": "ARABE" },
              { "value": 95, "label": "FILIPINA" },
              { "value": 96, "label": "HINDU" },
              { "value": 97, "label": "INDONESA" },
              { "value": 98, "label": "IRAQUI" },
              { "value": 99, "label": "IRANI" },
              { "value": 100, "label": "ISRAELI" },
              { "value": 101, "label": "JAPONESA" },
              { "value": 102, "label": "JORDANA" },
              { "value": 103, "label": "CAMBOYANA" },
              { "value": 104, "label": "KUWAITI" },
              { "value": 105, "label": "LIBANESA" },
              { "value": 106, "label": "MALASIA" },
              { "value": 107, "label": "MALDIVA" },
              { "value": 108, "label": "MONGOLESA" },
              { "value": 109, "label": "NEPALESA" },
              { "value": 110, "label": "OMANESA" },
              { "value": 111, "label": "PAKISTANI" },
              { "value": 112, "label": "DEL QATAR" },
              { "value": 113, "label": "SIRIA" },
              { "value": 114, "label": "LAOSIANA" },
              { "value": 115, "label": "SINGAPORENSE" },
              { "value": 116, "label": "TAILANDESA" },
              { "value": 117, "label": "TAIWANESA" },
              { "value": 118, "label": "TURCA" },
              { "value": 119, "label": "NORVIETNAMITA" },
              { "value": 120, "label": "YEMENI" },
              { "value": 121, "label": "ALBANESA" },
              { "value": 122, "label": "ALEMANA" },
              { "value": 123, "label": "ANDORRANA" },
              { "value": 124, "label": "AUSTRIACA" },
              { "value": 125, "label": "BELGA" },
              { "value": 126, "label": "BULGARA" },
              { "value": 127, "label": "CHECOSLOVACA" },
              { "value": 128, "label": "DANESA" },
              { "value": 129, "label": "VATICANA" },
              { "value": 130, "label": "ESPAÑOLA" },
              { "value": 131, "label": "FINLANDESA" },
              { "value": 132, "label": "FRANCESA" },
              { "value": 133, "label": "GRIEGA" },
              { "value": 134, "label": "HUNGARA" },
              { "value": 135, "label": "IRLANDESA" },
              { "value": 136, "label": "ISLANDESA" },
              { "value": 137, "label": "ITALIANA" },
              { "value": 138, "label": "LIECHTENSTENSE" },
              { "value": 139, "label": "LUXEMBURGUESA" },
              { "value": 140, "label": "MALTESA" },
              { "value": 141, "label": "MONEGASCA" },
              { "value": 142, "label": "NORUEGA" },
              { "value": 143, "label": "HOLANDESA" },
              { "value": 144, "label": "PORTUGUESA" },
              { "value": 145, "label": "BRITANICA" },
              { "value": 146, "label": "SOVIETICA BIELORRUSA" },
              { "value": 147, "label": "SOVIETICA UCRANIANA" },
              { "value": 148, "label": "RUMANA" },
              { "value": 149, "label": "SAN MARINENSE" },
              { "value": 150, "label": "SUECA" },
              { "value": 151, "label": "SUIZA" },
              { "value": 152, "label": "YUGOSLAVA" },
              { "value": 153, "label": "AUSTRALIANA" },
              { "value": 154, "label": "FIJIANA" },
              { "value": 155, "label": "SALOMONESA" },
              { "value": 156, "label": "NAURUANA" },
              { "value": 157, "label": "PAPUENSE" },
              { "value": 158, "label": "SAMOANA" },
              { "value": 159, "label": "ESLOVACA" },
              { "value": 160, "label": "BURKINA FASO" },
              { "value": 161, "label": "ESTONIA" },
              { "value": 162, "label": "MICRONECIA" },
              { "value": 163, "label": "REINO UNIDO(DEPEN. TET. BRIT.)" },
              { "value": 164, "label": "REINO UNIDO(BRIT. DEL EXT.)" },
              { "value": 165, "label": "REINO UNIDO(C. BRIT. DEL EXT.)" },
              { "value": 166, "label": "REINO UNIDO" },
              { "value": 167, "label": "KIRGUISTAN" },
              { "value": 168, "label": "LITUANIA" },
              { "value": 169, "label": "MOLDOVIA, REPUBLICA DE" },
              { "value": 170, "label": "MACEDONIA" },
              { "value": 171, "label": "ESLOVENIA" },
              { "value": 172, "label": "ESLOVAQUIA" }
            ]}
            onChange={onSelectChange}
            name='nationality'
            placeholder="Selecciona una nacionalidad"
          />
        </Form.Group>

        <Form.Group controlId="gender">
          <Form.Label>
            Sexo
          </Form.Label>
          <ReactSelect
            options={[
              {
                value:"female", label: "Femenino",
              }, 
              {
                value: "male", label: "Masculino"
              }
            ]}
            onChange={onSelectChange}
            name='gender'
            placeholder="Seleccionar un sexo"
          />
        </Form.Group>

        <Form.Group controlId="representation">
          <Form.Label>
            Agregar representación:
          </Form.Label>
          {
            representationOptions.map((representationItem) => (
              <Form.Check
                key={representationItem.id}
                value={representationItem.value}
                onChange={onFormCheckListChange}
                checked={formValues.representation.includes(representationItem.value)}
                type={"checkbox"}
                label={representationItem.label}
                id={representationItem.id}
                name='representation'
              />
            ))
          }
        </Form.Group>

      </Form>
    </Col>
  )
}

export default BodyModal