import { getLabelFromDictionary } from "../../../../../../config/dictionary";
import { Request } from "../../../../../classes/Request";

export const fetchTableData = async (expedientId, page, sizePerPage) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/get_documents_pending_signature/${expedientId}/${sizePerPage}?page=${page}`,
    "get",
    null,
    null
  );

  try {
    const tableDataResponse = await request.executeRequest();
    if (!tableDataResponse || tableDataResponse.code !== 200) {
      return false
    }
    return tableDataResponse
  } catch (error) {
    console.error("From MultiSignature error de guardar firma", error)
    return false
  }
}

export const getDynamicColumns = (view) => {
  if (view === 'control_panel') {
    return columns.filter(c => c.dataField !== 'update')
      .filter(c => c.dataField !== 'comment');
  }

  return columns.filter(c => c.dataField !== 'electronic_expedient_link');
}

export const columns = [
  {
    dataField: "actions",
    text: "Documento",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
    classes: 'fixed-cell'
  },
  {
    dataField: "electronic_expedient_link",
    text: `No. ${getLabelFromDictionary('global', 'lowerMainName')}`,
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
    escape: false
  },
  {
    dataField: "filename",
    text: "Nombre",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
    escape: false
  },
  {
    dataField: "alias",
    text: "Etiqueta",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "document_type",
    text: "Tipo",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "created_date",
    text: "Creación",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "update",
    text: "Actualización ",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "comment",
    text: "Comentarios",
    sort: true,
    style: { textAlign: "justify" },
    headerStyle: { textAlign: "center" }
  }
];