import React from 'react'
import { Button } from 'react-bootstrap'
import { useIndicatorsContext } from '../context/indicatorsContext/IndicatorsContext';

const FooterModal = () => {

  const { toggleShowinModal, handleSubmitFormValues } = useIndicatorsContext();

  return (
    <>
      <Button variant='dark' onClick={toggleShowinModal}>
        Cancelar
      </Button>
      <Button onClick={handleSubmitFormValues}>
        Guardar
      </Button>
    </>
  )
}

export default FooterModal