import { useEffect, useState } from 'react';
import { onGetSignatureList } from '../../../../../../../../services/electronicSignature/multiSignature/onGetSignatureList';
import { useElectronicSignatureProcessContext } from '../../../../../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext';
import { useLoaderContext } from '../../../../../../../layout/shared/loader_context';
import { useFilesPendingSignatureContext } from '../../../context/FilesPendingSignatureContext';
import { getPdfTemplate } from '../services/getPdfTemplate';
import { NotificationManager } from 'react-notifications';
import { getElectronicSignatureProperties } from '../../../../../../../../services/electronicSignature/getElectronicSignatureProperties';
import { onSignMultisignatureFileRequest } from '../../../../../../../../services/electronicSignature/multiSignature/onSignMultisignatureFileRequest';
import _ from 'lodash';
import { useMultipleElectronicSignatureModalContext } from '../context/MultipleElectronicSignatureModalContext';
import { handleSendDataNotificationEmail } from '../services/handleSendDataNotificationEmail';

const useElectronicSignatureModalState = ({
  onCloseModalClick = () => { },
  handleOpenTransfer = () => { },
  getDocumentsByExpedient = () => { },
}) => {

  const [isSignatureConfirmationModalShowing, setIsSignatureConfirmationModalShowing] = useState(false);

  const loader = useLoaderContext();
  const {
    documentParameters,
    getTableDataAndSetInTable: getNewRecordsFromFilesPendingSignatureTable = () => { },
    expedientId,
    handleShowNotifyEmailModal,
    handleHideNotifyEmailModal,
  } = useFilesPendingSignatureContext();

  const {
    electronicSignaturesList,
    isMultipleSignature,
    setIsMultipleSignature,
    getPkcs7,
    isReadyToCreatePkcs7,
    documentToSign,
    setDocumentToSign,
    singleElectronicSignature,
    handleSetInitialElectronicSignaturesList,
    electronicSignaturesRecentlyAddedList,
    setElectronicSignaturesList,
  } = useElectronicSignatureProcessContext();

  const {
    setElectronicSignaturesPendingToSave,
    setCurrentDocumentIdToSaveSignatures,
    getElectronicSignaturesPendingToSaveListByDocumentId,
  } = useMultipleElectronicSignatureModalContext();

  const isAnEmailNotification = documentParameters.document_type === 'notification' && documentParameters.electronic_notification;
  const isElectronicSignatureTableShowing = (Boolean(electronicSignaturesList.length && isMultipleSignature) || (!isMultipleSignature && Boolean(singleElectronicSignature)));

  const handlePdfTemplateFile = async () => {
    loader.show(true);
    const pdfTemplate = await getPdfTemplate(
      documentParameters.filePath,
      documentParameters.fileName,
      documentParameters.file_type,
    );
    setDocumentToSign(pdfTemplate);
    loader.show(false);
  }

  const handleCloseSignatureConfirmationModal = () => {
    setIsSignatureConfirmationModalShowing(false);
  }

  const handleOpenSignatureConfirmationModal = () => {
    setIsSignatureConfirmationModalShowing(true);
  }

  const handleGetSignatureList = async () => {
    try {
      loader.show(true);
      const { documentId } = documentParameters;
      const signatureListResponse = await onGetSignatureList(documentId);
      const signatureListResponseCode = _.get(signatureListResponse, 'code');
      const signatureListResponseSuccessful = signatureListResponseCode === 200;
      if (!signatureListResponseSuccessful) {
        NotificationManager.error('No se pudo obtener el listado de firmantes');
      }
      const electronicSignaturesListSaved = _.get(signatureListResponse, 'response', {});

      const electronicSignaturesListArray = Array.from(electronicSignaturesListSaved);
      handleSetInitialElectronicSignaturesList(electronicSignaturesListArray);

    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      loader.show(false);
    }
  }

  const handleSaveSignatureAndTransfer = async () => {
    try {
      loader.show(true);

      if (Boolean(electronicSignaturesRecentlyAddedList.length)) {
        setElectronicSignaturesPendingToSave((previouState) => {
          return {
            ...previouState,
            [documentParameters.documentId]: electronicSignaturesRecentlyAddedList
          }
        });
      }
      
      onCloseModalClick();
      handleOpenTransfer();
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      loader.show(false);
    }
  }

  const handleSignDocumentClick = async () => {
    if (isAnEmailNotification) {
      handleShowNotifyEmailModal();
      return
    }

    const documentSigned = await handleSignDocument();
    const isASuccessfullySignedDocument = Boolean(documentSigned);
    if (!isASuccessfullySignedDocument) {
      return
    }

    handleRefreshView();
  }

  const handleSendNotifyEmailButtonClick = async ({ users }) => {
    const documentSigned = await handleSignDocument();
    const signedDocumentId = _.get(documentSigned, 'response.documentExpedient.id');
    const isASuccessfullySignedDocument = Boolean(signedDocumentId);
    if (!isASuccessfullySignedDocument) {
      handleHideNotifyEmailModal();
      return
    }

    const handleSendDataNotificationEmailArguments = {
      expedientId,
      documentParameters,
      documentSigned,
      users,
      signedDocumentId,
    }
    const isASuccessfulEmailNotification = await handleSendDataNotificationEmail(handleSendDataNotificationEmailArguments);
    if (!isASuccessfulEmailNotification) {
      return
    }

    handleRefreshView();
  }
  
  const handleSignDocument = async () => {
    const argumentsToGetElectronicSignatureProperties = {
      isMultipleSignature,
      isReadyToCreatePkcs7,
      getPkcs7,
      documentToSignName: documentToSign.name,
    };
    
    try {
      loader.show(true);
      handleCloseSignatureConfirmationModal();
      const electronicSignatureProperties = await getElectronicSignatureProperties(argumentsToGetElectronicSignatureProperties);
      if (!_.isObject(electronicSignatureProperties)) {
        NotificationManager.error('Errores en firma electronica');
        return
      }

      const signMultisignatureResponse = await onSignMultisignatureFileRequest(electronicSignatureProperties, documentParameters.documentId);
      NotificationManager.success('Documento firmado con éxito');
      return signMultisignatureResponse

    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      loader.show(false);
    }
  }

  const handleRefreshView = async () => {
    try {
      loader.show(true);
      handleHideNotifyEmailModal();
      await getNewRecordsFromFilesPendingSignatureTable();
      getDocumentsByExpedient();
      onCloseModalClick();
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      loader.show(false);
    }
  }

  const electronicSignaturesPendingToSaveList = getElectronicSignaturesPendingToSaveListByDocumentId(documentParameters.documentId);

  const handleFirstSetUp = async () => {
    setIsMultipleSignature(true);
    handlePdfTemplateFile();
    setCurrentDocumentIdToSaveSignatures(documentParameters.documentId);
    await handleGetSignatureList();
    setElectronicSignaturesList((previousSate) => [...previousSate, ...electronicSignaturesPendingToSaveList]);
  }

  useEffect(() => {
    handleFirstSetUp();
  }, []);

  const isOnlyOneSignaturePerUserActivated = !Boolean(electronicSignaturesPendingToSaveList.length);

  return {
    isElectronicSignatureTableShowing,
    handleSaveSignatureAndTransfer,
    handleSendNotifyEmailButtonClick,
    handleSignDocumentClick,
    isOnlyOneSignaturePerUserActivated,
    handleCloseSignatureConfirmationModal,
    handleOpenSignatureConfirmationModal,
    isSignatureConfirmationModalShowing,
  }
}

export default useElectronicSignatureModalState