import React from 'react'

const BodyModal = () => {
  return (
    <h4>
      ¿Estás seguro de finalizar la multifirma?
    </h4>
  )
}

export default BodyModal