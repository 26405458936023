import { RequestNewVersion } from "../../../components/classes/RequestNewVersion";

export const getMattersListRequest = async () => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/matters/get_list`,
    "get",
    null,
    {}
  );

  const mattersListResponse = await request.executeRequest();
  if (
    typeof mattersListResponse === 'object' &&
    Number(mattersListResponse.code) === 200 &&
    typeof mattersListResponse.response === 'object' &&
    Array.isArray(mattersListResponse.response.matters)
  ) {
    return mattersListResponse.response.matters;
  }
  return [];
} 