export const getElementFromOptionsList = (optionValue, optionsList, isEmptyElementEnabled = false) => {
  if (!Array.isArray(optionsList))
    return;

  let optionElement = optionsList.find((optionItem) => {
    if (typeof optionItem !== 'object')
      return false;

    return String(optionItem.value) === String(optionValue);
  })

  if (typeof optionElement === 'object') {
    return optionElement;
  }

  if (isEmptyElementEnabled) {
    const emptyElement = { value: '', label: '' };
    optionElement = emptyElement;
  }

  return optionElement;

}