import React from 'react'
import TransferModal from '../../../transferModal/TransferModal'
import { useFilesPendingSignatureContext } from '../../details_expedient/components/filesPendingSignatureTable/context/FilesPendingSignatureContext';

const TransferModalWrapper = ({
  transferState = {},
  currentExpedientId,
}) => {

  const {
    currentExpedientPartsColumns = [],
  } = useFilesPendingSignatureContext();

  return (
    <TransferModal
      {...transferState}
      key={currentExpedientId}
      expedientParts={currentExpedientPartsColumns}
    />
  )
}

export default TransferModalWrapper