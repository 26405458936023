import React, { createContext, useContext, useEffect, useState } from 'react'

const CommentsModalContext = createContext();
CommentsModalContext.displayName = 'CommentsModalContext';

const CommentsModalProvider = ({ children }) => {
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [isATurnedExpedient, setIsATurnedExpedient] = useState(false);

  const toggleCommentsModal = () => {
    setIsCommentsModalOpen((previousState) => !previousState)
  }

  const contextValue = {
    isCommentsModalOpen,
    toggleCommentsModal,
    isATurnedExpedient,
    setIsATurnedExpedient,
  };

  return (
    <CommentsModalContext.Provider value={contextValue}>
      {children}
    </CommentsModalContext.Provider>
  )
}

export const useCommentsModalContext = () => {
  const context = useContext(CommentsModalContext);
  if (typeof context === 'undefined') {
    throw new Error('To use useCommentsModalContext you must wrap inside a CommentsModalProvider')
  }
  return context;
}

export default CommentsModalProvider