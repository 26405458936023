import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { RemotePagination } from "../helper_components/remote_pagination/RemotePagination";

import {
  getListNoAssignProcessData,
  initColumns,
  toAssignProcess,
  executeSearchToProceduresNoAssign
} from "./table_process_logic";
import LoaderContext from "../layout/shared/loader_context";
import Loader from "../layout/shared/Loader";
import Searcher from "../helper_components/searcher_component/Searcher";

const TableProcessNoAssign = props => {
  const [columns] = useState(initColumns);
  const [showModal, setShowModal] = useState(false);
  const [stageState, setStageState] = useState("");
  const [responseError] = useState("");
  const [hasModalErrors, setHasModalErrors] = useState(false);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const [isSearching, setIsSearching] = useState(false);
  const [isCleaning, setIsCleaning] = useState(false);
  const [search, setSearch] = useState("");
  const loader = useContext(LoaderContext);
  const [textNotData, setTextNotData] = useState("");

  function setShowModalHandler(stage_id) {
    setShowModal(true);
    setStageState(stage_id);
  }

  function onCancelModalHandler() {
    setShowModal(false);
    setHasModalErrors(false);
  }

  const requestAssignHandler = async () => {
    await toAssignProcess(stageState, props);
  };

  useEffect(() => {
    (async function() {
      await getListNoAssignProcessData(
        setShowModalHandler,
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page
      );
    })();
  }, []);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);

    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {
      loader.show(true);
      await executeSearchToProceduresNoAssign(
        search,
        setShowModalHandler,
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        setTextNotData
      );
      loader.show(false);
    } else {
      loader.show(true);
      await getListNoAssignProcessData(
        setShowModalHandler,
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page
      );
      loader.show(false);
    }
  };

  const getProceduresToSearch = async () => {
    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {
      setIsSearching(true);
      loader.show(true);
      setTextNotData("");

      await executeSearchToProceduresNoAssign(
        search,
        setShowModalHandler,
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        1,
        setTextNotData
      );

      setIsSearching(false);
      loader.show(false);
    }
  };

  const cleanProceduresToSearch = async () => {
    setSearch("");
    setIsCleaning(true);
    loader.show(true);

    await getListNoAssignProcessData(
      setShowModalHandler,
      props,
      setData,
      setTotalProcedures,
      sizePerPage,
      1
    );

    setTextNotData("");
    setIsCleaning(false);
    loader.show(false);  
  };

  const bodyModalMessage = () => {
    return (
      <>
        {hasModalErrors ? (
          <Modal.Body>
            <Alert variant="danger">
              <Alert.Heading>
                Oh lo sentimos! Algo ah ocurrido mal!
              </Alert.Heading>
              <p>{responseError}</p>
              <hr />
            </Alert>
          </Modal.Body>
        ) : (
          <Modal.Body>
            ¿Estás seguro que deseas asignarte este trámite?
          </Modal.Body>
        )}
      </>
    );
  };

  return (
    <>
      <Modal show={showModal} size="lg">
        <Modal.Header>
          <Modal.Title>Asignación de trámite</Modal.Title>
        </Modal.Header>
        {bodyModalMessage()}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onCancelModalHandler()}>
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={() => requestAssignHandler()}
            style={!hasModalErrors ? { display: "block" } : { display: "none" }}
          >
            Asignarme este trámite
          </Button>
        </Modal.Footer>
      </Modal>

      <h4 className="mB-20 mt-0 w-100 text-center">Trámites no asignados</h4>

      <Searcher
        placeholderSearch="Ingresa el texto a buscar"
        placeholderSearchButton="Buscar "
        placeholderCleanButton="Limpiar"
        helperContextual="Ingresa el texto deseado y haz clic en el botón 'Buscar'"
        executeSearch={getProceduresToSearch}
        cleanSearch={cleanProceduresToSearch}
        isSearching={isSearching}
        isCleaning={isCleaning}
        setSearch={setSearch}
        search={search}
        classContainer="classContainer"
        classHelperContextual="classHelperContextual"
        classButtonsSearch="classButtonsSearch"
      />

      { data.length > 0 && textNotData === "" ?
        <RemotePagination
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalProcedures}
          onTableChange={handleTableChange}
          columns={columns}
        />
      :
        <h4 className="clearFloat text-center mT-10">{textNotData}</h4>
      }
      <Loader/>
    </>
  );
};
export default TableProcessNoAssign;
