import React, { useState, useEffect, useContext } from "react";
import NavBar from "../../layout/shared/NavBar";
import { RemotePagination } from '../../helper_components/remote_pagination/RemotePagination';
import { Grid } from 'react-flexbox-grid';
import { 
  initColumnsTableReceivedHistory,
  getReceivedHistory,
  sendSearchExpedients
} from './table_received_history_logic';
import ExpedientSearcher from '../../helper_components/expedients_searcher/ExpedientSearcher';
import { Button, Container } from 'react-bootstrap';
import ModalComponent from "../../helper_components/ModalComponent";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import Footer from "../../layout/shared/Footer";
import { getLabelFromDictionary } from "../../../config/dictionary";


const TableReceivedHistory = (props) => {
  const [columns] = useState(initColumnsTableReceivedHistory);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const [searching, setSearching] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [textNotData, setTextNotData] = useState("");
  const loader = useContext(LoaderContext);


  useEffect(() => {
    (async function () {
      await getReceivedHistory(
        setData,
        setTotalProcedures,
        page,
        sizePerPage,
        handleShowModal,
        setTextNotData
      );
    })();
  }, []);

  const handleShowModal = (governmentBook) => {

    const dataToShow = {
      emisorName: governmentBook.sender,
      emisorGroup: governmentBook.group,
      expedient_number: governmentBook.expedient_number,
      reception_date: new Date( governmentBook.updated_at.replace(/-/g, "/") ).toLocaleDateString('es-MX', {
                        day:'numeric',
                        month:'long',
                        year:'numeric'
                      }),
      receiver_observations: governmentBook.receiver_observations,
      sender_observations: governmentBook.sender_observations
    };
    setModalData(dataToShow);
    setShowModal(true);
  };

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);

    await getReceivedHistory(
      setData,
      setTotalProcedures,
      page,
      sizePerPage,
      handleShowModal,
      setTextNotData
    );
  };

  const searchExpedients = async () => {
    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {

      setSearching(true);
      loader.show(true);
      await sendSearchExpedients(
        search.replace(/^\s+|\s+$/gm,''),
        setTextNotData,
        10,
        1,
        setData,
        setTotalProcedures,
        handleShowModal
      );
      setSearching(false);
      loader.show(false);
    }
  };

  const cleanSearch = async () => {
    setSearch("");

    setCleaning(true);
    loader.show(true);
    await getReceivedHistory(
      setData,
      setTotalProcedures,
      page,
      sizePerPage,
      handleShowModal,
      setTextNotData
    );
    loader.show(false);
    setCleaning(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const bodyModal = (
    <>
      <Container>
          <div className="table-responsive text-center">
            <table className="table border border-secondary">
              <thead>
                <tr
                  style={{backgroundColor:"#9f9f9f",color:"white"}}
                >
                      <th>Dato:</th>
                      <th>Valor:</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                      <th className="text-right">{getLabelFromDictionary('global', 'sigleMainName')}:</th>
                      <td className="text-left"><h6>{modalData['expedient_number']}</h6></td>
                  </tr>

                  <tr>
                      <th className="text-right">Fecha de recepción:</th>
                      <td className="text-left"><h6>{modalData["reception_date"]}</h6></td>
                  </tr>

                  <tr>
                      <th className="text-right">Emisor:</th>
                      <td className="text-left"><h6>{modalData["emisorName"]}</h6></td>
                  </tr>

                  <tr>
                      <th className="text-right">Puesto:</th>
                      <td className="text-left"><h6>{modalData["emisorGroup"]}</h6></td>
                  </tr>

                  <tr>
                      <th className="text-right">Observaciones del emisor:</th>
                      <td style={{textAlign:"justify"}}><h6>{modalData["receiver_observations"]}</h6></td>
                  </tr>

                  <tr>
                      <th className="text-right">Observaciones del remitente:</th>
                      <td style={{textAlign:"justify"}}><h6>{modalData["sender_observations"]}</h6></td>
                  </tr>
              </tbody>
            </table>
          </div>
      </Container>
    </>
  );

  const footerModal = (
    <>
      <Button 
        variant="secondary" 
        onClick={() => handleClose()}
      >
        Cerrar
      </Button>
    </>
  );

  return (
    <>
      <NavBar {...props} />

      <h3 className="mT-20 w-100 text-center">{ getLabelFromDictionary('expedientsReceived', 'label') }</h3>
      
      <div style={{ width: "85%", margin: "0px auto", display: "block" }}>
        <ExpedientSearcher
          placeholderSearch={`Escribe el número de ${getLabelFromDictionary('global', 'singleLowerMainName')}`}
          placeholderSearchButton="Buscar"
          placeholderCleanButton="Limpiar"
          helperContextual={`Escribe el número de ${getLabelFromDictionary('global', 'singleLowerMainName')} y haz clic en el botón buscar.`}
          executeSearch={searchExpedients}
          cleanSearch={cleanSearch}
          isSearching={searching}
          isCleaning={cleaning}
          setSearch={setSearch}
          search={search}
        />
      </div>
      
      <Grid fluid={true} className={ data.length === 0 ? "d-n" : "no-padding"} style={{ minHeight: '76vh', width: "85%", margin: "0px auto" }}>
        <section className="procedures-table-container mT-30">
          <RemotePagination
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalProcedures}
            onTableChange={handleTableChange}
            columns={columns}
          />

        </section>
      </Grid>

      <h4 className={ data.length === 0 ? "text-center clearFloat padding-footer-not-data" : "d-n"}>{textNotData}</h4>
      
      <Footer/>
      
      <ModalComponent
        show={showModal}
        onHide={setShowModal}
        header={""}
        body={bodyModal}
        footer={footerModal}
        canClose={true}
      />

      <Loader/>
    </>
  );
}

export default TableReceivedHistory;