import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import ExpedientFormFields from '../juditial_bulletin/expedient_form/sections/ExpedientFormFields';
import { Formik } from 'formik';
import { initialValues, validateEditExpedientSchema } from '../juditial_bulletin/expedient_form/ExpedientForm.form';
import _ from 'lodash';
import styles from '../juditial_bulletin/expedient_form/ExpedientForm.module.css';
import { editExpedient } from '../juditial_bulletin/api/expedients';

const formikInitialValues = initialValues();
const EditExpedientModal = ({ show, setShow, expedient, setExpedientInfo }) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header>
        <Modal.Title>Edición del expediente {_.get(expedient, 'attributes.expedientNumber', '')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {expedient &&
          <Formik
            initialValues={
              {
                expedient: {
                  record_date: new Date(_.get(expedient, 'attributes.recordDate', formikInitialValues.expedient.record_date)),
                  expedient_number: _.get(expedient, 'attributes.expedientNumber', formikInitialValues.expedient.expedient_number),
                  expedient_type: _.get(expedient, 'attributes.expedientType', formikInitialValues.expedient.expedient_type),
                  judgment: _.get(expedient, 'attributes.judgment', formikInitialValues.expedient.judgment),
                  origin: _.get(expedient, 'attributes.origin', formikInitialValues.expedient.origin),
                  external_expedient: _.get(expedient, 'attributes.externalExpedient', formikInitialValues.expedient.external_expedient),
                  external_dependence: _.get(expedient, 'attributes.externalDependence', formikInitialValues.expedient.external_dependence)
                }
              }
            }
            validationSchema={validateEditExpedientSchema()}
            validateOnChange={false}
            onSubmit={async (values) => {
              setLoading(true);
              const updatedExpedient = await editExpedient(expedient.id, values.expedient);
              setExpedientInfo(updatedExpedient);
              setLoading(false);
              setShow(false);
            }}
          >
            {
              props => (
                <form onSubmit={props.handleSubmit}>
                  <ExpedientFormFields />

                  <div className={styles.actionButtonsContainer}>
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className={styles.button}
                    >
                      Cancelar
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      className={styles.button}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={
                          loading ? { display: "inline-block" } : { display: "none" }
                        }
                      />
                      Guardar cambios
                    </Button>
                  </div>
                </form>
              )
            }
          </Formik>
        }

      </Modal.Body>
    </Modal >
  );
}

export default EditExpedientModal;