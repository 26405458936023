import React from 'react'
import SignaturePendingList from '../SignaturePendingList';
import ExpirationList from '../ExpirationList';
import { controlPanelTabNames } from '../../constants/controlPanelTabNameList';

const ControlPanelTabs = ({ activeTabName, history }) => {

  const {
    expirationTabName,
    pendingSignatureTabName,
  } = controlPanelTabNames;

  if (activeTabName === pendingSignatureTabName) {
    return <SignaturePendingList history={history} />
  }

  if (activeTabName === expirationTabName) {
    return <ExpirationList />;
  }

  return <></>;
}

export default ControlPanelTabs