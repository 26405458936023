import React from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { RequestNewVersion } from "../../../classes/RequestNewVersion";
import { getTimeTranscurred } from "../../../../utils/date";
import _ from "lodash";
import { getLabelFromDictionary } from "../../../../config/dictionary";

export const initColumns = [
  {
    dataField: "expedient_number_link",
    text: `No. ${getLabelFromDictionary('global', 'lowerMainName')}`,
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "responsible",
    text: "Responsable",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "time_elapsed",
    text: `Tiempo de recepción del ${getLabelFromDictionary('global', 'lowerMainName')}`,
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "time_indicator",
    text: "Indicador",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
];

export const getExpedientsList = async (
  setExpedients,
  setTotalExpedients,
  page,
  sizePerPage,
  setShowExpiration,
  setDocumentsData,
) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/find_by_court/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseMyExpedients = _.defaultTo(await request.executeRequest(), {});

  if ( responseMyExpedients.code !== 200 ) {

      NotificationManager.error(`Algo sucedió y no fue posible traer los ${getLabelFromDictionary('global', 'lowerMainName')}, inténtalo nuevamente`);
  } else {
    if (
        responseMyExpedients.response.error &&
        responseMyExpedients.response.message &&
        typeof responseMyExpedients.response.message === "string"
    ) {
        NotificationManager.error( responseMyExpedients.response.message );
    }
    else {
      const formatedExpedients = formatExpedients(
        responseMyExpedients.response.electronicExpedients,
        setShowExpiration,
        setDocumentsData
      );
      setExpedients(formatedExpedients);
      setTotalExpedients(responseMyExpedients.response.total);
    }
  }
};

const formatExpedients = (
  expedients,
  setShowModal,
  setDocumentsData
) => {
  return expedients.map((expedient, index) => {
    return {
      id: expedient.id,
      expedient_number_link: expedientNumberLink(expedient.expedient_number, expedient.id),
      responsible: expedient.responsible,
      time_elapsed: getTimeTranscurred(new Date( expedient.updated_at.replace(/-/g, "/"))),
      time_indicator: timeIndicator(
        expedient.warning_flag,
        expedient.expirations,
        setShowModal,
        setDocumentsData
      )
    };
  });
};

const expedientNumberLink = (expedientNumber, expedientId) => {
  return (
    <Link to={{
      pathname: `/expedient_details/${expedientId}`,
      state: {
        labelPath: 'Mis expedientes',
        prevPath: window.location.pathname
      }
    }}>
      {expedientNumber}
    </Link>
  );
}

const timeIndicator = (indicator, expirations, setShowModal, setDocumentsData) => {
  return (
    <a onClick={() => {
      setDocumentsData(expirations);
      setShowModal(true)
    }}><span className={"dot-" + indicator} /></a>
  );
}
