import React, { useContext, useEffect, useState } from 'react';
import ModalComponent from '../../../helper_components/ModalComponent';
import { Button, Form } from 'react-bootstrap';
import { copyBase64QrToClipboard, fieldsValidation, getDocuments } from './generate_qr_modal_logic';
import LoaderContext from '../../../layout/shared/loader_context';
import _ from 'lodash';
import { RequestNewVersion } from '../../../classes/RequestNewVersion';
import { NotificationManager } from 'react-notifications';

const GenerateQRModal = ({ electronicExpedientId, isShowingModal, onCloseModal }) => {
  const loader = useContext(LoaderContext);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [allDocumentsSelected, setAllDocumentsSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [qrBase64, setQrBase64] = useState(null);

  useEffect(() => {
    if (isShowingModal) {
      (async function () {
        loader.show(true);
        await getDocuments(electronicExpedientId, setDocuments);
        loader.show(false);
      }());
    }
  }, []);

  const onGenerateQr = async () => {
    const requiredFields = [];
    if (!allDocumentsSelected) {
      requiredFields.push('selectedDocuments');
    }

    const body = {
      allDocumentsSelected,
      selectedDocuments: selectedDocuments.map(sd => sd.id)
    };

    const validationResponse = fieldsValidation(requiredFields, body);
    if (typeof validationResponse === 'object') {
      setErrors(validationResponse);
      return;
    }

    loader.show(true);
    const url = process.env.REACT_APP_URL_API_LARAVEL + `/electronic_expedients/${electronicExpedientId}/qr`;
    const request = new RequestNewVersion(url, 'POST', null, body);
    const response = await request.executeRequest();

    if (response.code === 200) {
      const { qr } = response.response;
      setQrBase64(qr);
      try {
        await copyBase64QrToClipboard(qr);
      } catch (err) {
        console.error("Unable to copy to clipboard.");
      }
    } else {
      NotificationManager.error('Ocurrió un error al generar el QR');
    }

    loader.show(false);
  }

  return (
    <>
      <ModalComponent 
        header='Generar código QR'
        body={
          <BodyModal
            allDocumentsSelected={allDocumentsSelected}
            setAllDocumentsSelected={setAllDocumentsSelected}
            documents={documents}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            errors={errors}
            setErrors={setErrors}
            qrBase64={qrBase64}
          />
        }
        footer={<FooterModal onAccept={onGenerateQr} onCancel={onCloseModal} />}
        show={isShowingModal}
        canClose={true}
        onHide={onCloseModal}
        scroll={true}
      />
    </>
  );
};

const BodyModal = ({ allDocumentsSelected, setAllDocumentsSelected, documents, selectedDocuments, setSelectedDocuments, errors, setErrors, qrBase64 }) => {
  const handleAllDocumentsOnChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedDocuments([]);
    }
    setAllDocumentsSelected(checked);
    setErrors({});
  };

  const handleSelectDocumentsOnChange = (e, checkedDocument) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedDocuments([...selectedDocuments, checkedDocument]);
    } else {
      setSelectedDocuments(selectedDocuments.filter(sd => sd !== checkedDocument));
    }
    setErrors({});
  };

  return (
    <>
      <p>* Selecciona los documentos que podrá descargar el ciudadano al leer el QR</p>
      {
        !_.isEmpty(errors)
        &&
        <p style={{ color: 'red' }}>Debes elegir al menos un documento.</p>
      }
      <Form.Group>
        <Form.Check
          id='checkAllDocuments'
          type='checkbox'
          checked={allDocumentsSelected}
          onChange={(e) => handleAllDocumentsOnChange(e)}
          label='Todos los documentos'
        />
      </Form.Group>
      <hr />
      {documents.map(d => {
        return (
          <Form.Group key={d.id}>
            <Form.Check
              id={d.id}
              disabled={allDocumentsSelected}
              type='checkbox'
              checked={selectedDocuments.includes(d)}
              onChange={e => handleSelectDocumentsOnChange(e, d)}
              label={`${d.document_type} - ${d.filename}`}
            />
          </Form.Group>
        );
      })}
      <hr />
      <QR qrBase64={qrBase64}/>
    </>
  );
};

const QR = ({ qrBase64 }) => {
  const handleCopyClick = async () => {
		try {
      await copyBase64QrToClipboard(qrBase64);
		} catch (err) {
			console.error("Unable to copy to clipboard.");
		}
	};

  return (
    <div className="d-flex justify-content-center align-items-center">
      {
        qrBase64
        && (
          <>
            <img src={`data:image/png;base64,${qrBase64}`} alt="QR" />
            <Button title="Copiar al portapapeles" variant='primary' onClick={handleCopyClick}><i className='fas fa-clipboard'/></Button>
          </>
        )
      }
    </div>
  );
}

const FooterModal = ({ onAccept, onCancel }) => {
  return (
    <>
      {
        onCancel
        &&
        <Button
          variant="secondary"
          className="mR-10"
          onClick={onCancel}
        >
          Cancelar
        </Button>
      }
      <Button
        variant="primary"
        onClick={onAccept}
      >
        Generar QR
      </Button>
    </>
  )
}

export default GenerateQRModal;