import Cookies from "js-cookie";
import { getDecodedJwt } from "../services/dataFormatting/getDecodedJwt";


const original = {
    lateralMenu: {
        expiedients: 'Expedientes',
        updateExpedient: 'Modificar expedientes',
        myExpedients: 'Mis expedientes',
    },
    expedientsMenu: {
        myExpedients: 'Mis expedientes',
        sendedExpedients: 'Expedientes enviados',
        searchExpedients: 'Buscar expediente',
        resivedExpedients: 'Expedientes recibidos',
        pendingExpedients: 'Expedientes por recibir',
        promitions: 'Promociones',
        detailExpedient: 'Detalles de expediente',
        "Turnado externo de expedientes": "Turnado externo de expedientes"
    },
    myExpedientsPage: {
        myExpedients: 'Mis expedientes',
    },
    addExpediente: {
        add: 'Agregar expediente',
        numberExpedient: "Número de expediente",
        regimeActor: "Régimen del actor",
        firstLastNameActor: "Primer apellido del actor",
        secondLastNameActor: "Segundo apellido del actor",
        electronicNotificationsActor: "Notificaciones electrónicas del actor",
        lawyerNameActor: "Nombre(s) del abogado patrono del actor",
        lawyerFirstLastNameActor: "Primer apellido del abogado patrono del actor",
        lawyerSecondLastNameActor: "Segundo apellido del abogado patrono del actor",
        electronicNotificationsLawyerActor: "Notificaciones electrónicas del abogado patrono del actor",
        regimeDefendant: "Régimen del demandado",
        defendantName: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        lawyerNameDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia",
        selected: "Turnar expedientes seleccionados"
    },


    searchExpedient: {
        writeNoExpedient: "Escribe el número de expediente",
        helperText: "Escribe el número  de expediente y haz clic en el botón buscar"
    },

    tableExpedients: {
        header: 'No. de Expediente',
    },

    detailExpedient: {
        header: 'Documentos del Expediente'
    },

    turned: {
        turnExpedient: "Turnar expediente",
        numberExpedient: "Número de expediente",
        actorNames: "Nombre(s) del actor",
        actorFirstLastName: "Primer apellido del actor",
        actorSecondLastName: "Segundo apellido del actor",
        actorElectronicNotifications: "Notificaciones electrónicas del actor",
        lawyerNameActor: "Nombre(s) del abogado patrono del actor",
        lawyerFirstLastNameActor: "Primer apellido del abogado patrono del actor",
        lawyerSecondLastNameActor: "Segundo apellido del abogado patrono del actor",
        defendantNames: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        lawyerNamesDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia"
    },

    coverExpedient: {
        expedient: "Expediente: L1/2024",
        generalData: "Datos generales del expediente",
        trialType: "Tipo de Juicio",
        via: "Vía",
        judicialParty: "Partido Judicial",
        court: "Juzgado",
        subject: "Materia",
        actor: "Actor",
        patronLawyer: "Abogado oatrono",
        defendant: "Demandado"
    },

    showCompleteExpedient: {
        viewFullExpedient: "Ver expediente completo",
        expedientDocuments: "Documentos del Expediente",
        downloadExpedient: "Descarga expediente"
    },

    sendExpedients: {
        label: 'Expedientes enviados'
    },

    search: {
        writeExpedientNumber: "Escribe el número de expediente",
        writeExpedientNumberAndClickSearch: "Escribe el número de expediente y haz clic en el botón buscar"
    },

    tableContent: {
        label: 'No. expediente'
    },

    expedientsReceived: {
        label: 'Turnos recibidos'
    },

    expedientsToReceived: {
        label: 'Expedientes pendientes de recepción'
    },

    searchOfExpedient:{
        searchOfExpedientLabel: "Búsqueda de expediente"
    },

    promitions: {
        label: 'Promociones'
    },

    modifyExpedient: {
        label: 'Listado de expedientes del juzgado'
    },

    editExpedient: {
        editExpedientLabel: "Edición del expediente",
        numberExpedient: "Número de expediente",
        actorRegime: "Régimen del actor",
        actorNames: "Nombre(s) del actor",
        actorFirstLastName: "Primer apellido del actor",
        actorSecondLastName: "Segundo apellido del actor",
        actorEmail: "Correo electrónico del actor",
        actorPatronLawyerNames: "Nombre(s) del abogado patrono del actor",
        actorPatronLawyerFirstLastName: "Primer apellido del abogado patrono del actor",
        actorPatronLawyerSecondLastName: "Segundo apellido del abogado patrono del actor",
        defendantRegime: "Régimen del demandado",
        defendantNames: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        defendantEmail: "Correo electrónico del demandado",
        lawyerNamesDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia"
    },

    global: {
        mainName: 'Expedientes',
        lowerMainName: 'expedientes',
        sigleMainName: 'Expediente',
        singleLowerMainName: 'expediente'
    }
}

const secondInstance = {
    lateralMenu: {
        expiedients: 'TOCA',
        updateExpedient: 'Modificar TOCA',
        myExpedients: 'Mis TOCA',
    },
    expedientsMenu: {
        myExpedients: 'Mis TOCA',
        sendedExpedients: 'TOCA enviados',
        searchExpedients: 'Buscar TOCA',
        resivedExpedients: 'TOCA recibidos',
        pendingExpedients: 'TOCA por recibir',
        promitions: 'Promociones',
        detailExpedient: 'Detalles de TOCA',
        "Turnado externo de expedientes": "Turnado externo de TOCA"
    },


    myExpedientsPage: {
        myExpedients: 'Mis TOCA',
    },

    addExpediente: {
        add: 'Mis TOCA',
        numberExpedient: "Número de TOCA",
        regimeActor: "Régimen del actor",
        actorName: "Nombre(s) del actor",
        actorFirstLastName: "Primer apellido del actor",
        actorSecondLastName: "Segundo apellido del actor",
        electronicNotificationsActor: "Notificaciones electrónicas del actor",
        lawyerNameActor: "Nombre(s) del abogado patrono del actor",
        lawyerFirstLastNameActor: "Primer apellido del abogado patrono del actor",
        lawyerSecondLastNameActor: "Segundo apellido del abogado patrono del actor",
        electronicNotificationsLawyerActor: "Notificaciones electrónicas del abogado patrono del actor",
        regimeDefendant: "Régimen del demandado",
        defendantName: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        lawyerNameDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia",
        selected: "Turnar TOCA seleccionados"
    },

    searchExpedient: {
        writeNoExpedient: "Escribe el número de TOCA",
        helperText: "Escribe el número  de TOCA y haz clic en el botón buscar"
    },

    tableExpedients: {
        header: 'No. TOCA',
    },

    detailExpedient: {
        header: 'Dcoumentos del TOCA'
    },

    turned: {
        turnExpedient: "Turnar TOCA",
        numberExpedient: "Número de TOCA",
        actorNames: "Nombre(s) del actor",
        actorFirstLastName: "Primer apellido del actor",
        actorSecondLastName: "Segundo apellido del actor",
        actorElectronicNotifications: "Notificaciones electrónicas del actor",
        lawyerNameActor: "Nombre(s) del abogado patrono del actor",
        lawyerFirstLastNameActor: "Primer apellido del abogado patrono del actor",
        lawyerSecondLastNameActor: "Segundo apellido del abogado patrono del actor",
        defendantNames: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        lawyerNamesDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia"
    },

    coverExpedient: {
        expedient: "TOCA: L1/2024",
        generalData: "Datos generales del TOCA",
        trialType: "Tipo de Juicio",
        via: "Vía",
        judicialParty: "Partido",
        court: "Sala",
        subject: "Materia",
        actor: "Actor",
        patronLawyer: "Abogado patrono",
        defendant: "Demandado"
    },

    showCompleteExpedient: {
        viewFullExpedient: "Ver TOCA completo",
        expedientDocuments: "Documentos del TOCA",
        downloadExpedient: "Descarga TOCA"
    },

    sendExpedients: {
        label: 'TOCA enviados'
    },

    search: {
        writeExpedientNumber: "Escribe el número de TOCA",
        writeExpedientNumberAndClickSearch: "Escribe el número de TOCA y haz clic en el botón buscar"
    },

    tableContent: {
        label: 'No. TOCA'
    },

    expedientsReceived: {
        label: 'Turnos recibidos'
    },

    searchOfExpedient:{
        searchOfExpedientLabel: "Búsqueda de TOCA"
    },


    expedientsToReceived: {
        label: 'TOCA pendientes de recepción'
    },

    promitions: {
        label: 'Promociones'
    },

    modifyExpedient: {
        label: 'Listado de TOCA de la sala'
    },

    editExpedient: {
        editExpedientLabel: "Edición del TOCA",
        numberExpedient: "Número de TOCA",
        actorRegime: "Régimen del actor",
        actorNames: "Nombre(s) del actor",
        actorFirstLastName: "Primer apellido del actor",
        actorSecondLastName: "Segundo apellido del actor",
        actorEmail: "Correo electrónico del actor",
        actorPatronLawyerNames: "Nombre(s) del abogado patrono del actor",
        actorPatronLawyerFirstLastName: "Primer apellido del abogado patrono del actor",
        actorPatronLawyerSecondLastName: "Segundo apellido del abogado patrono del actor",
        defendantRegime: "Régimen del demandado",
        defendantNames: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        defendantEmail: "Correo electrónico del demandado",
        lawyerNamesDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia"
    },

    global: {
        mainName: 'TOCA',
        lowerMainName: 'toca',
        sigleMainName: 'Toca',
        singleLowerMainName: 'toca'
    }
}

const penal = {
    lateralMenu: {
        expiedients: 'Carpetas',
        updateExpedient: 'Modificar carpetas',
        myExpedients: 'Mis carpetas',
    },
    expedientsMenu: {
        myExpedients: 'Mis carpetas',
        sendedExpedients: 'Carpetas enviadas',
        searchExpedients: 'Buscar carpeta',
        resivedExpedients: 'Carpetas recibidas',
        pendingExpedients: 'Carpetas por recibir',
        promitions: 'Promociones',
        detailExpedient: 'Detalles de carpeta',
        "Turnado externo de expedientes": "Turnado externo de carpeta"
    },

    myExpedientsPage: {
        myExpedients: 'Mis carpetas',
    },

    addExpediente: {
        add: "Mis carpetas",
        numberExpedient: "Número de carpeta",
        regimeActor: "Régimen del imputado",
        firstLastNameActor: "",
        secondLastNameActor: "",
        electronicNotificationsActor: "",
        lawyerNameActor: "",
        lawyerFirstLastNameActor: "",
        lawyerSecondLastNameActor: "",
        electronicNotificationsLawyerActor: "",
        regimeDefendant: "",
        defendantName: "",
        defendantFirstLastName: "",
        defendantSecondLastName: "",
        lawyerNameDefendant: "",
        lawyerFirstLastNameDefendant: "",
        lawyerSecondLastNameDefendant: "",
        trialType: "Tipo de trámite",
        via: "Vía",
        subject: "Materia",
        selected: "Turnar carpetas seleccionadas"
    },


    searchExpedient: {
        writeNoExpedient: "Escribe el número de carpeta",
        helperText: "Escribe el número  de carpeta y haz clic en el botón buscar"
    },

    tableExpedients: {
        header: 'No. carpeta',
    },

    detailExpedient: {
        header: 'Documentos de la carpeta'
    },

    turned: {
        turnExpedient: "Turnar carpeta",
        numberExpedient: "Número de carpeta",
        actorNames: "Nombre(s) del imputado",
        actorFirstLastName: "",
        actorSecondLastName: "",
        actorElectronicNotifications: "",
        lawyerNameActor: "",
        lawyerFirstLastNameActor: "",
        lawyerSecondLastNameActor: "",
        defendantNames: "",
        defendantFirstLastName: "",
        defendantSecondLastName: "",
        lawyerNamesDefendant: "",
        lawyerFirstLastNameDefendant: "",
        lawyerSecondLastNameDefendant: "",
        trialType: "No aplica",
        via: "Tipo de trámite",
        subject: "Materia"
    },

    coverExpedient: {
        expedient: "Carpeta: L1/2024",
        generalData: "Datos generales de la carpeta",
        trialType: "No aplica",
        via: "Tipo de trámite",
        judicialParty: "Distrito",
        court: "Juzgado",
        subject: "Materia",
        actor: "Imputado",
        patronLawyer: "",
        defendant: ""
    },

    showCompleteExpedient: {
        viewFullExpedient: "Ver carpeta completa",
        expedientDocuments: "Documentos de la carpeta",
        downloadExpedient: "Descarga carpeta"
    },

    sendExpedients: {
        label: 'Carpetas enviadas'
    },

    search: {
        writeExpedientNumber: "Escribe el número de carpeta",
        writeExpedientNumberAndClickSearch: "Escribe el número  de carpeta y haz clic en el botón buscar"
    },

    tableContent: {
        label: 'No. carpeta'
    },
    expedientsReceived: {
        label: 'Turnos recibidos'
    },

    expedientsToReceived: {
        label: 'Carpetas pendientes de recepción'
    },

    searchOfExpedient:{
        searchOfExpedientLabel: "Búsqueda de carpeta"
    },


    promitions: {
        label: 'Promociones'
    },

    modifyExpedient: {
        label: 'Listado de carpetas del juzgado'
    },

    editExpedient: {
        editExpedientLabel: "Edición de la carpeta",
        numberExpedient: "Número de carpeta",
        actorRegime: "Régimen del imputado",
        actorNames: "",
        actorFirstLastName: "",
        actorSecondLastName: "",
        actorEmail: "",
        actorPatronLawyerNames: "",
        actorPatronLawyerFirstLastName: "",
        actorPatronLawyerSecondLastName: "",
        defendantRegime: "",
        defendantNames: "",
        defendantFirstLastName: "",
        defendantSecondLastName: "",
        defendantEmail: "",
        lawyerNamesDefendant: "",
        lawyerFirstLastNameDefendant: "",
        lawyerSecondLastNameDefendant: "",
        trialType: "Tipo de trámite",
        via: "Vía",
        subject: "Materia"
    },

    global: {
        mainName: 'Carpetas',
        lowerMainName: 'carpetas',
        sigleMainName: 'Carpeta',
        singleLowerMainName: 'carpeta'
    }
}

const laboral = {
    lateralMenu: {
        expiedients: 'Expedientes',
        updateExpedient: 'Modificar expedientes',
        myExpedients: 'Mis expedientes',
    },
    expedientsMenu: {
        myExpedients: 'Mis expedientes',
        sendedExpedients: 'Expedientes enviados',
        searchExpedients: 'Buscar expediente',
        resivedExpedients: 'Expedientes recibidos',
        pendingExpedients: 'Expedientes por recibir',
        promitions: 'Promociones',
        detailExpedient: 'Detalles de expediente',
        "Turnado externo de expedientes": "Turnado externo de expedientes"
    },

    myExpedientsPage: {
        myExpedients: 'Mis expedientes',
    },

    addExpediente: {
        add: "Agregar expediente",
        numberExpedient: "Número de expediente",
        regimeActor: "Régimen del actor",
        firstLastNameActor: "Primer apellido del actor",
        secondLastNameActor: "Segundo apellido del actor",
        electronicNotificationsActor: "Notificaciones electrónicas del actor",
        lawyerNameActor: "Nombre(s) del abogado patrono del actor",
        lawyerFirstLastNameActor: "Primer apellido del abogado patrono del actor",
        lawyerSecondLastNameActor: "Segundo apellido del abogado patrono del actor",
        electronicNotificationsLawyerActor: "Notificaciones electrónicas del abogado patrono del actor",
        regimeDefendant: "Régimen del demandado",
        defendantName: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        lawyerNameDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia",
        selected: "Turnar expedientes seleccionados"
    },

    searchExpedient: {
        writeNoExpedient: "Escribe el número de expediente",
        helperText: "Escribe el número  de expediente y haz clic en el botón buscar"
    },

    tableExpedients: {
        header: 'No. de Expediente',
    },

    detailExpedient: {
        header: 'Documentos del Expediente'
    },

    turned: {
        turnExpedient: "Turnar expediente",
        numberExpedient: "Número de expediente",
        actorNames: "Nombre(s) del actor",
        actorFirstLastName: "Primer apellido del actor",
        actorSecondLastName: "Segundo apellido del actor",
        actorElectronicNotifications: "Notificaciones electrónicas del actor",
        lawyerNameActor: "Nombre(s) del abogado patrono del actor",
        lawyerFirstLastNameActor: "Primer apellido del abogado patrono del actor",
        lawyerSecondLastNameActor: "Segundo apellido del abogado patrono del actor",
        defendantNames: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        lawyerNamesDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia"
    },

    coverExpedient: {
        expedient: "Expediente: L1/2024",
        generalData: "Datos generales del expediente",
        trialType: "Tipo de Juicio",
        via: "Vía",
        judicialParty: "Región",
        court: "Juzgado",
        subject: "Materia",
        actor: "Actor",
        patronLawyer: "Abogado oatrono",
        defendant: "Demandado"
    },

    showCompleteExpedient: {
        viewFullExpedient: "Ver expediente completo",
        expedientDocuments: "Documentos del Expediente",
        downloadExpedient: "Descarga expediente"
    },

    sendExpedients: {
        label: 'Expedientes enviados'
    },

    search: {
        writeExpedientNumber: "Escribe el número de expediente",
        writeExpedientNumberAndClickSearch: "Escribe el número de expediente y haz clic en el botón buscar"
    },

    tableContent: {
        label: 'No. expediente'
    },

    expedientsReceived: {
        label: 'Turnos recibidos'
    },

    expedientsToReceived: {
        label: 'Expedientes pendientes de recepción'
    },

    promitions: {
        label: 'Promociones'
    },

    modifyExpedient: {
        label: 'Listado de expedientes del juzgado'
    },

    editExpedient: {
        ditExpedientL1_2024: "Edición del expediente L1/2024",
        numberExpedient: "Número de expediente",
        actorRegime: "Régimen del actor",
        actorNames: "Nombre(s) del actor",
        actorFirstLastName: "Primer apellido del actor",
        actorSecondLastName: "Segundo apellido del actor",
        actorEmail: "Correo electrónico del actor",
        actorPatronLawyerNames: "Nombre(s) del abogado patrono del actor",
        actorPatronLawyerFirstLastName: "Primer apellido del abogado patrono del actor",
        actorPatronLawyerSecondLastName: "Segundo apellido del abogado patrono del actor",
        defendantRegime: "Régimen del demandado",
        defendantNames: "Nombre(s) del demandado",
        defendantFirstLastName: "Primer apellido del demandado",
        defendantSecondLastName: "Segundo apellido del demandado",
        defendantEmail: "Correo electrónico del demandado",
        lawyerNamesDefendant: "Nombre(s) del abogado patrono",
        lawyerFirstLastNameDefendant: "Primer apellido del abogado patrono",
        lawyerSecondLastNameDefendant: "Segundo apellido del abogado patrono",
        trialType: "Tipo de Juicio",
        via: "Vía",
        subject: "Materia"
    },

    global: {
        mainName: 'Expedientes',
        lowerMainName: 'expedientes',
        sigleMainName: 'Expediente',
        singleLowerMainName: 'expediente'
    }
}

export const getLabelFromDictionary = (types, original_name, forced, label) => {
    const { authentication_token_03 } = Cookies.get();
    if( !authentication_token_03 ) return '';
   
    let { user } = getDecodedJwt(authentication_token_03);

    if( !user ) return '';

    let id_app = user.account_id || 2;
    switch (id_app) {
        case 2:
            if (Object.keys(original).includes(types)) {
                return original[`${types}`][`${original_name}`] ? original[`${types}`][`${original_name}`] : (forced ? label : '');
            }
            return '';
        case 3:
            if (Object.keys(secondInstance).includes(types)) {
                return secondInstance[`${types}`][`${original_name}`] ? secondInstance[`${types}`][`${original_name}`] : (forced ? label : '');
            }
            return '';
        case 4:
            if (Object.keys(penal).includes(types)) {
                return penal[`${types}`][`${original_name}`] ? penal[`${types}`][`${original_name}`] : (forced ? label : '');
            }
            return '';
        case 5:
            if (Object.keys(laboral).includes(types)) {
                return laboral[`${types}`][`${original_name}`] ? laboral[`${types}`][`${original_name}`] : (forced ? label : '');
            }
            return '';
        default:
            if (Object.keys(original).includes(types)) {
                return original[`${types}`][`${original_name}`] ? original[`${types}`][`${original_name}`] : (forced ? label : '');
            }
            return '';
    }
}
