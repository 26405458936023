import _ from "lodash";

export const getFormattedSelectCourtsOptions = (options) => {
  if (!Array.isArray(options)) {
    return [];
  }

  const formattedOptions = options.map((optionElement) => ({
    label: _.get(optionElement, 'court_name'),
    value: _.get(optionElement, 'court_id'),
  }));

  return formattedOptions;
}