
import InitialDemand from '../components/forms/initialDemand/InitialDemand';
import FinalJudgment from '../components/forms/initialDemand/FinalJudgment';
import InterlocutoryJudgment from '../components/forms/interlocutoryJudgment/InterlocutoryJudgment';
import RepresentationAdmissibleProceeding from '../components/forms/representationAdmissibleProceeding/RepresentationAdmissibleProceeding';
import TestComponent from '../components/forms/testComponent/TestComponent';

export const navItems = [
  {
    name: 'initialDemand',
    displayName: 'Demanda inicial',
    body: InitialDemand,
  }, {
    name: 'finalJudgment',
    displayName: 'Sentencia definitiva',
    body: FinalJudgment,
  }, {
    name: 'interlocutoryJudgment',
    displayName: 'Sentencia interlocutoria',
    body: InterlocutoryJudgment,
  }, {
    name: 'representationAdmissibleProceeding',
    displayName: 'Representacion de las partes en los procedimentos admitidos',
    body: RepresentationAdmissibleProceeding,
  }, {
    name: 'test',
    displayName: 'Pruebas',
    body: TestComponent,
  },
];