import { useEffect } from "react";

const useTawkTo = (isTawkToEnabled = true) => {

  useEffect(() => {
    if (isTawkToEnabled) {
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = `https://embed.tawk.to/${process.env.REACT_APP_TAWK_KEY}/${process.env.REACT_APP_TAWK_ID}`;
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    } else if (window.Tawk_API) {
      window.Tawk_API.minimize();
      window.Tawk_API.hideWidget();
    }
  }, [isTawkToEnabled])

  return ({});
}

export default useTawkTo;