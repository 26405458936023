import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { CrimesCatalog } from '../../../../utils/BulletinCatalogs';
import DateField from '../../../formikFields/DateField';
import SelectField from '../../../formikFields/SelectField';
import CheckField from '../../../formikFields/CheckField';
import DropzoneField from '../../../formikFields/DropzoneField';
import styles from '../ExpedientForm.module.css';

export default function SentenceFormFields() {
  const [crimeOptions, setCrimeOptions] = useState([]);

  async function getCrimeOptions() {
    let authenticationToken = Cookies.get("authentication_token_03");

    const crimesResponse = await fetch(
      `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedient/crimes`, {
      method: 'GET',
      headers: {
        'Authorization': authenticationToken
      }
    });

    const crimesData = await crimesResponse.json();
    const crimeOptions = crimesData.data.crimes;
    const options = crimeOptions.map((crime) => {
      return {
        value: crime.id,
        label: crime.name
      }
    });

    setCrimeOptions(options);
  }

  return (
    <>
      <div className={styles.containerRowFields}>
        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Fecha de carga de Sentencia
            </div>
            <div className={styles.calendarFieldApparience}>
              <div className={styles.datePickerElement}>
                <DateField
                  name="sentence.sentence_date"
                  id="sentence_date"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className={styles.inputDate}
                  placeholderText={"Clic para seleccionar fecha"}
                />
              </div>
              <div className={styles.iconPicker}>
                <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
              </div>
            </div>
            <small>Fecha en que se cargó la sentencia</small>
          </Form.Group>
        </div>

        <div className={styles.mediumSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Fecha de dictamen de la Sentencia
            </div>
            <div className={styles.calendarFieldApparience}>
              <div className={styles.datePickerElement}>
                <DateField
                  name="sentence.opinion_sentence_date"
                  id="opinion_sentence_date"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className={styles.inputDate}
                  placeholderText={"Clic para seleccionar fecha"}
                />
              </div>
              <div className={styles.iconPicker}>
                <FontAwesomeIcon icon={faCalendarDays} className={styles.calendarIcon} />
              </div>
            </div>
            <small>Fecha en que se dictaminó la sentencia</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <Form.Group>
            <div className={styles.fieldLabel}>
              * Acción o delito
            </div>
            <SelectField
              id="sentence.crime"
              name='sentence.crime'
              placeholder="Selecciona una opción"
              onFocus={() => getCrimeOptions()}
              options={crimeOptions}
            />
            <small>Selecciona la acción o delito especificado en la serntencia</small>
          </Form.Group>
        </div>
      </div>

      <div className={styles.instructionsText}>
        <span>A continuación, marca las casillas correspondientes de acuerdo a la naturaleza de la sentencia</span>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <div className={styles.checkGroupField}>
            <CheckField
              id="gender_perspective_sentence"
              name="sentence.gender_perspective_sentence"
              className={styles.checkAparience}
            />
            <div className={styles.fieldLabel}>
              <label htmlFor="gender_perspective_sentence" style={{ marginBottom: 0 }}>
                Sentencia dictada con perspectiva de género
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <div className={styles.checkGroupField}>
            <CheckField
              id="infantil_perspective_sentence"
              name="sentence.infantil_perspective_sentence"
              className={styles.checkAparience}
            />
            <div className={styles.fieldLabel}>
              <label htmlFor="infantil_perspective_sentence" style={{ marginBottom: 0 }}>
                Sentencia dictada con perspectiva de infancia y adolescencia
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize}>
          <div className={styles.checkGroupField}>
            <CheckField
              id="women_perspective_sentence"
              name="sentence.women_perspective_sentence"
              className={styles.checkAparience}
            />
            <div className={styles.fieldLabel}>
              <label htmlFor="women_perspective_sentence" style={{ marginBottom: 0 }}>
                Sentencia dictada con perspectiva de violencia contra las mujeres
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.containerRowFields}>
        <div className={styles.largeSize} style={{ marginTop: '2rem' }}>
          <div className={styles.fieldLabel}>
            * Adjunta el archivo de la sentencia
          </div>

          <DropzoneField
            name="sentence.file"
          />
        </div>
      </div>
    </>
  );
}
