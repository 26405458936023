import React from 'react'
import { Button } from 'react-bootstrap';
import { getLabelFromDictionary } from '../../../../../../../../../config/dictionary';

const FooterModal = ({
  onTransferExpedinetClick = () => { },
  onSignDocumentClick = () => { },
  isTransferExpedinetButtonDisabled = false,
  isSignDocumentButtonDisabled = false,
}) => {

  return (
    <div className={'d-flex justify-content-between flex-grow-1 flex-wrap'}>
      <Button
        variant={'secondary'}
        onClick={onTransferExpedinetClick}
        className={'text-white'}
        disabled={isTransferExpedinetButtonDisabled}
      >
        <i className={'fas fa-chevron-circle-right'}></i> Turnar {getLabelFromDictionary('global', 'lowerMainName')}
      </Button>
      <Button
        variant={'success'}
        onClick={onSignDocumentClick}
        className={'text-white'}
        disabled={isSignDocumentButtonDisabled}
      >
        <i className="fas fa-user-edit"></i> Finalizar multifirma
      </Button>
    </div>
  )
}

export default FooterModal