import React from 'react'
import { createContext, useContext } from "react";
import { UndefinedContextError } from '../../errors/contextError/contextError';
import useMattersList from '../../hooks/globalData/useMattersList';

const GlobalContext = createContext();
GlobalContext.displayName = 'GlobalContext';

const GlobalContextProvider = (props) => {

  const mattersList = useMattersList();

  const contextValue = {
    dataRequest: {
      mattersList,
    },
  }

  return (
    <GlobalContext.Provider {...props} value={contextValue} />
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (typeof context === 'undefined') {
    throw new UndefinedContextError('To use useGlobalContext you must wrap inside a GlobalContextProvider')
  }
  return context;
}

export default GlobalContextProvider