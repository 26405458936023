import React from 'react'
import NavItem from './components/NavItem'

const NavBar = ({
  currentNav = '',
  setCurrentNav,
  handleClickNav,
  navItems,
  ...restOfProps
}) => {

  const onClickNav = ({ target }) => {
    const _currentNav = target.dataset.navItemName;
    const currentNavItem = navItems.find(navItem => navItem.name === _currentNav);
    if (typeof setCurrentNav === 'function')
      setCurrentNav(currentNavItem);
    if (typeof handleClickNav === 'function')
      handleClickNav(currentNavItem);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      className={'flex-column flex-xl-row'}
      {...restOfProps}
    >
      {
        navItems.map((navElement, index) => (
          <NavItem
            key={index}
            navItemName={navElement.name}
            currentSelectedNav={currentNav.name}
            onClick={onClickNav}
          >
            {navElement.displayName}
          </NavItem>
        ))
      }
    </div >
  )
}

export default NavBar