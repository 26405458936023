import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { Request } from "../classes/Request";
import { NotificationManager } from "react-notifications";

/**********************************************************getAvaliableProcesses************************************************************************/
export const getAvaliableProcesses = async (props, setAvaliableProcesses) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/processes_by_account/${userId_03}?token=${process.env.REACT_APP_TOKEN}
    `,
    "get",
    null,
    {}
  );

  const getAvaliableProcessesData = await request.executeRequest();
  if (getAvaliableProcessesData.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    setAvaliableProcesses(
      await formatAvaliableProcesses(
        getAvaliableProcessesData.data.data.data.processes,
        props
      )
    );
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    NotificationManager.error(
      getAvaliableProcessesData.message,
      "Error " + getAvaliableProcessesData.code + ":"
    );
  }
};

export const formatAvaliableProcesses = async (data, props) => {
  data.forEach(el => {
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12}>
          <Button
            onClick={() => {
              startProcess(el.id, props);
            }}
          >
            Iniciar
          </Button>
        </Col>
      </Row>
    );
  });
  return data;
};

/***********************************************************************************************************************************************/
/******************************************************************START PROCESS****************************************************************/

export const startProcess = async (processId, props, loader) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/start_procedure`,
    "post",
    null,
    {
      user_id: userId_03,
      token: process.env.REACT_APP_TOKEN,
      process_id: processId
    }
  );
  loader.show(true);
  const startProcessData = await request.executeRequest();
  loader.show(false);
  if (startProcessData.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    let form = startProcessData.data.data.data.form;
    let stage = startProcessData.data.data.data.meta.stage_id;
    let sequence = startProcessData.data.data.data.meta.current_step;
    let title = startProcessData.data.data.data.meta.form_name;
    let mode = startProcessData.data.data.data.meta.mode;
    let applicant = startProcessData.data.data.data.meta.applicant_id;

    props.history.push({
      pathname: "/start_process",
      state: {
        params: {
          stage,
          sequence,
          form: form,
          formTitle: title,
          mode: mode,
          applicant: applicant
        }
      }
    });
  }
};
/***********************************************************************************************************************************************/
/***********************************************************************************************************************************************/
