import React from 'react';
import {range} from 'lodash';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
const years = range(1800, 2040);
const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
];

export const secondCustomHeaderDatePicker = (
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
) => {
    return (
        <div>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
                style={{ border: '2px solid #216ba5', borderRadius: '5px', backgroundColor: '#216ba5', fontSize: '10px' }}
            >
                <i className='fas fa-arrow-left' style={{ color: 'white', fontSize: '8px' }} title='Last year' />
            </button>
            <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    increaseMonth();
                }}
                disabled={nextMonthButtonDisabled}
                style={{ border: '2px solid #216ba5', borderRadius: '5px', backgroundColor: '#216ba5', fontSize: '10px' }}
            >
                <i className='fas fa-arrow-right' style={{ color: 'white', fontSize: '8px' }} title='Next year' />
            </button>
        </div>
    )
};