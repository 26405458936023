import { handleRequestSendDataNotificationEmail } from "../../../../../modals/notify_email_modal_logic";

export const handleSendDataNotificationEmail = async ({
  expedientId,
  documentParameters = {},
  users,
  signedDocumentId,
}) => {

  const isAJudgmentTypeNotification = documentParameters.notification_type === 'judgment';
  const isAnAgreementTypeNotification = documentParameters.notification_type === 'agreement';

  const isASuccessfulEmailNotification = await handleRequestSendDataNotificationEmail({
    expedient_id: expedientId,
    notification_template_id: documentParameters.email_template_id,
    emails: users,
    using_document_expedient: 'si',
    document_expedient_id: signedDocumentId,
    checked_judgment: isAJudgmentTypeNotification,
    checked_agreement: isAnAgreementTypeNotification,
  });

  return isASuccessfulEmailNotification;
}