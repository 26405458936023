import _ from "lodash";
import { RequestNewVersion } from "../../../components/classes/RequestNewVersion";

export const onUploadDocumentWithMultisignature = async (documentToUpload) => {
  const uploadRequestURL = `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/multisign`;
  const uploadRequest = new RequestNewVersion(uploadRequestURL, "post", null, documentToUpload);

  try {
    const uploadDocumentResponse = await uploadRequest.executeRequest();
    const uploadDocumentResponseCode = _.get(uploadDocumentResponse, 'code');
    const isUploadDocumentResponseSuccessful = uploadDocumentResponseCode === 200;

    if (!isUploadDocumentResponseSuccessful) {
      throw new Error('No se pudo guardar el documento');
    }

    return uploadDocumentResponse

  } catch (error) {
    throw error;
  }
}