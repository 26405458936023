import React from 'react';
import { Button } from 'react-bootstrap';

const FooterModal = ({
  onConfirmClick = () => { },
  onCancelClick = () => { },
}) => {
  return (
    <>
      <Button variant="secondary" onClick={onCancelClick}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={onConfirmClick} >
        Confirmar
      </Button>
    </>
  )
}

export default FooterModal