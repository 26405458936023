import React, {useState} from "react";
import { Button, Form, InputGroup, FormControl } from "react-bootstrap";
import ModalComponent from "../../../helper_components/ModalComponent";
import {Grid} from "react-flexbox-grid";
import BootstrapTable from "react-bootstrap-table-next";
import {removeUserToExpedientPermission, assignedUsersTableColumns, handleSubmitAssignUserSearch, addUserToExpedientPermission, searchUsersTableColumns} from "./assign_users_logic";
import { getLabelFromDictionary } from "../../../../config/dictionary";

const AssignUser = ({
  expedientId,
  usersWithPermission,
  setUsersWithPermission,
  usersFound,
  setUsersFound,
  assignUserSearch,
  setAssignUserSearch,
  loader,
  setShowModal,
  showModal
}) => {

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [modalText, setModalText] = useState('');
  const [currentUser, setCurrentUser] = useState({});

  const craftUsersTable = (
    title,
    users,
    columns,
    setCollection,
    onClick,
    buttonStyle
  ) => {
    return (
      <>
        <h5>{title}</h5>
        <div className="special-border-top table-responsive">
          <BootstrapTable
            remote
            keyField="idreact"
            data={users.map(user => ({
              ...user,
              actions: user.id
                ? <Button
                    className={`btn ${buttonStyle.className}`}
                    onClick={() => {onClick(user, expedientId, setCollection, loader)}}
                    ><i className={buttonStyle.icon}></i></Button>
                : <></>
            }))}
            columns={columns}
          />
        </div>
      </>
    );
  };

  const craftSearcher = () => {
    return (
      <div className="row assignable-users-search">
        <Form className="col-12" onSubmit={(e) => {handleSubmitAssignUserSearch(
          e,
          assignUserSearch,
          setUsersFound,
          loader
        )} }>
          <InputGroup style={{ padding: '6px' }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={{ height: '35px' }}><i className="fas fa-search"></i></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              name="assignUserSearch"
              placeholder="Buscar"
              value={assignUserSearch}
              onChange={(e) => {setAssignUserSearch(e.target.value)}}
            />
            <Button
              type="submit"
              className="btn"
              style={{height: '35px'}}
            >Buscar</Button>
          </InputGroup>
        </Form>
      </div>
    );
  }

  const bodyModalAssignUsers = () => {
    return (
      <>
        <Grid fluid={true} className="no-padding" >
          <section>
            <div className="procedures-table-container col-12 offset-md-2 col-md-8">
              {craftUsersTable(
                `Los usuarios actualmente mostrados tienen acceso a este ${getLabelFromDictionary('global', 'singleLowerMainName')}`,
                usersWithPermission,
                assignedUsersTableColumns,
                setUsersWithPermission,
                handleRemoveClick,
                { className: 'btn-danger', icon: 'fas fa-trash' }
              )}

              {craftSearcher()}

              <>
                {craftUsersTable(
                  'Usuarios por agregar',
                  usersFound,
                  searchUsersTableColumns,
                  setUsersWithPermission,
                  handleAddClick,
                  { className: 'btn-success', icon: 'fas fa-plus' }
                )}
                { usersFound.length === 0 &&
                <>
                  <p>Tu búsqueda no ha arrojado resultados</p>
                </>
                }
              </>
            </div>
          </section>
        </Grid>
      </>
    );
  };

  const handleRemoveClick = (user, expedientId, setCollection, loader) => {
    setModalText(`Al confirmar, el usuario ${user.username} ya no tendrá acceso a este ${getLabelFromDictionary('global', 'singleLowerMainName')}`);
    setCurrentUser({
      user,
      expedientId,
      setCollection,
      loader,
      callback: removeUserToExpedientPermission
    });
    setShowModalConfirm(true);
  };

  const handleAddClick = (user, expedientId, setCollection, loader) => {
    setModalText(`Al confirmar, el usuario ${user.username} podrá tener acceso a este ${getLabelFromDictionary('global', 'singleLowerMainName')}`);
    setCurrentUser({
      user,
      expedientId,
      setCollection,
      loader,
      callback: addUserToExpedientPermission
    });
    setShowModalConfirm(true);
  };

  const onConfirmClick = async () => {
    const { user, expedientId, setCollection, loader, callback } = currentUser;
    await callback(user.id, expedientId, setCollection, loader);
    setShowModalConfirm(false);
  };

  const confirmModalBody = () => {
    return (
      <section>
        <div className="container" style={{ height: '60vh' }}>
          <div className="row" style={{ padding: '10%' }}>
            <div className="col-12 text-center" style={{ padding:'6%', boxShadow: '2px 6px 13px gray' }}>
              <p>{modalText}</p>
              <button
                className="btn btn-success mr-2"
                onClick={async () => {
                  await onConfirmClick();
                  setCurrentUser({});
                }}>Confirmar
              </button>
              <button
                className="btn btn-info"
                onClick={() => {
                  setShowModalConfirm(false);
                  setCurrentUser({});
                }}>Cancelar
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <ModalComponent
        header={`Permisos de ${getLabelFromDictionary('global', 'singleLowerMainName')}:`}
        body={bodyModalAssignUsers()}
        footer={(
          <>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
          </>
        )}
        show={showModal}
        canClose={true}
        onHide={setShowModal}
        scroll={true}
        dialogClassName="modal-templates-preview"
      />

      <ModalComponent
        header="Confirmar acción"
        body={confirmModalBody()}
        footer={<></>}
        show={showModalConfirm}
        canClose={false}
        onHide={setShowModalConfirm}
        scroll={false}
        dialogClassName="modal-templates-preview"
      />
    </>
  );
};

export default AssignUser;

