import { useState } from 'react'
import { NotificationManager } from 'react-notifications';
import _ from "lodash";
import Cookies from "js-cookie";
import { fieldsValidation } from '../../government_book/my_expedients/expedients_list_logic';
import { useLoaderContext } from '../../layout/shared/loader_context';
import { getDependencesToTransfer, handleTransferExpedient } from '../../government_book/details_expedient/expedient_details_logic';
import { getUsersToTransfer } from '../../government_book/pending_transfers/pending_transfers_logic';

const useTransferState = ({
  handleSaveSignatures = () => { },
  handleRemoveSavedSignatures = () => { },
  isInternalTurningByDefault = false,
  history = { push: () => { } },
  expedientData = {},
  onCloseTransferModalClick = () => { },
}) => {
  const [isSupremeCourtOfJusticeReceiverConfirmationModalShowing, setIsSupremeCourtOfJusticeReceiverConfirmationModalShowing] = useState(false);
  const [dataTransfer, setDataTransfer] = useState({});
  const [transferErrors, setTransferErrors] = useState({});
  const [showTransferModal, setShowTransferModal] = useState(false);
  const loader = useLoaderContext();
  const [transferUsers, setTransferUsers] = useState([]);
  const [transferDependences, setTransferDependences] = useState([]);

  const specialPermissions = Cookies.getJSON('special_permissions_03');
  const canTurnForeign = _.get(specialPermissions, '[0].foreign') === '1';
  const canCreateToca = _.get(specialPermissions, '[0].create_toca') === '1';
  const isAnExternalTransfer = _.get(dataTransfer, 'type') === 'external';
  const doesReceiverHaveId2 = _.get(dataTransfer, 'receiver.value') === 2;
  const isASupremeCourtOfJusticeReceiver = isAnExternalTransfer && doesReceiverHaveId2;
  const isATransferOfTypeCreateToca = canCreateToca && (dataTransfer.type === 'create_toca');
  const isAnAppealTypeProcedure = _.get(dataTransfer, 'procedure_type.value', '') === 1;

  const submitTransfer = async () => {
    const requiredFields = [
      'type',
      ...(dataTransfer.type === 'internal' ? ['receiver'] : []),
      ...(dataTransfer.type === 'external' ? ['external'] : []),
      ...(isATransferOfTypeCreateToca ? ['room', 'receiverUser','observation'] : []),
      ...(isASupremeCourtOfJusticeReceiver ? ['procedure_type'] : []),
    ];
    
    if (isASupremeCourtOfJusticeReceiver) {
      requiredFields.push('procedure_type');
    }

    if (isAnAppealTypeProcedure) {
      requiredFields.push('appellant_full_name');
    }

    const frontValidation = fieldsValidation(requiredFields, dataTransfer);
    if (typeof frontValidation === "object") {
      setTransferErrors(frontValidation);
      NotificationManager.error(
        'Hace falta información requerida, por favor revisa los campos marcados en rojo...'
      );
    }
    else {
      try {
        loader.show(true);
        let data = {};
        if (dataTransfer.type === 'internal') {
          data = {
            ...dataTransfer,
            receiver_id: dataTransfer.receiver.value,
            kind_judgment: dataTransfer.kind_judgment.value,
            via: dataTransfer.via.value
          }
        } else if (dataTransfer.type === 'external') {
          data = {
            ...dataTransfer,
            dependence_id: dataTransfer.receiver.value,
            kind_judgment: dataTransfer.kind_judgment.value,
            via: dataTransfer.via.value
          }
        } else if (isATransferOfTypeCreateToca) {
          data = {
            ...dataTransfer,
            kind_judgment: dataTransfer.kind_judgment.value,
            via: dataTransfer.via.value
          }
        }

        const hasExpedientBeenTransferred = await handleTransferExpedient(data, setTransferErrors);

        if (!hasExpedientBeenTransferred) {
          return
        }

        await handleSaveSignatures();
        handleRemoveSavedSignatures();

        if (isInternalTurningByDefault) {
          history.push('/my_expedients');
        } else {
          history.push('/pending_transfers');
        }

      } catch (error) {
        NotificationManager.error(error.message);
      } finally {
        loader.show(false);
      }
    }
  };

  const onAcceptSupremeCourtOfJusticeReceiverConfirmationModal = () => {
    setIsSupremeCourtOfJusticeReceiverConfirmationModalShowing(false);
    submitTransfer();
  }

  const handleOpenTransferModal = async () => {
    loader.show(true);
    await getUsersToTransfer(setTransferUsers, expedientData.id);
    await getDependencesToTransfer(setTransferDependences);
    setDataTransfer({
      ...expedientData,
      via: {
        label: expedientData.via,
        value: expedientData.via_id,
      },
      kind_judgment: {
        label: expedientData.kind_judgment,
        value: expedientData.kind_judgment_id,
      },
      type: isInternalTurningByDefault ? 'internal' : canTurnForeign ? '' : 'internal',
    });
    setShowTransferModal(true);
    loader.show(false);
  };

  const handleSubmitTransfer = () => {
    if (isASupremeCourtOfJusticeReceiver) {
      setIsSupremeCourtOfJusticeReceiverConfirmationModalShowing(true);
      return
    }
    submitTransfer();
  }

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
    onCloseTransferModalClick()
  };

  return {
    submitTransfer,
    isSupremeCourtOfJusticeReceiverConfirmationModalShowing,
    setIsSupremeCourtOfJusticeReceiverConfirmationModalShowing,
    onAcceptSupremeCourtOfJusticeReceiverConfirmationModal,
    handleSubmitTransfer,
    dataTransfer,
    setDataTransfer,
    isAnExternalTransfer,
    transferErrors,
    setShowTransferModal,
    showTransferModal,
    handleOpenTransferModal,
    transferUsers,
    transferDependences,
    handleCloseTransferModal,
    canTurnForeign,
    isASupremeCourtOfJusticeReceiver,
    canCreateToca,
    isATransferOfTypeCreateToca,
    setTransferErrors,
    isAnAppealTypeProcedure,
  }
}

export default useTransferState