import * as Yup from 'yup';

export function initialValues() {
  return {
    expedient_number: "",
    expedient_type: ""
  };
}

export function validationSchema() {
  return Yup.object({
    expedient_number: Yup.string().required("El número de expediente es requerido"),
    expedient_type: Yup.object().required("Debes seleccionar una opción")
  });
}