export const DocumentTypesCatalog = [
  { value: 1, label: "Acuerdo" },
  { value: 2, label: "Sentencia Definitiva" },
  { value: 3, label: "Sentencia Interlocutoria" },
  { value: 4, label: "Sentencia Segunda Instancia" }
];

export const AgreementsClasificationCatalog = [
  { value: 1, label: "Boletinable" },
  { value: 2, label: "Personal" },
  { value: 4, label: "Reservado" }
];

export const ExpedientTypesCatalog = [
  { value: 80, label: "-A" },
  { value: 86, label: "-A-2" },
  { value: 89, label: "-A-M" },
  { value: 81, label: "-B" },
  { value: 87, label: "-B-2" },
  { value: 90, label: "-B-M" },
  { value: 82, label: "-C" },
  { value: 88, label: "-C-2" },
  { value: 91, label: "-C-M" },
  { value: 83, label: "-D" },
  { value: 84, label: "-E" },
  { value: 120, label: "-S" },
  { value: 51, label: "ADMINISTRATIVO" },
  { value: 32, label: "AMPARO" },
  { value: 52, label: "AMPARO II" },
  { value: 53, label: "AMPARO III" },
  { value: 54, label: "AMPARO IV" },
  { value: 55, label: "AMPARO V" },
  { value: 109, label: "AMPARO-A" },
  { value: 26, label: "BIS 1" },
  { value: 27, label: "BIS 2" },
  { value: 28, label: "BIS 3" },
  { value: 40, label: "BIS 4" },
  { value: 41, label: "BIS 5" },
  { value: 42, label: "BIS 6" },
  { value: 43, label: "BIS 7" },
  { value: 44, label: "BIS 8" },
  { value: 103, label: "CARPETA ADMINISTRATIVA" },
  { value: 117, label: "CARPETA DE EJECUCION" },
  { value: 102, label: "CARPETA DE INVESTIGACION" },
  { value: 107, label: "CARPETA PROCESAL" },
  { value: 56, label: "CUADERNILLO DE AMPARO" },
  { value: 110, label: "CUADERNILLO DE AMPARO-A" },
  { value: 57, label: "CUADERNILLO DE COMUNICACIONES" },
  { value: 25, label: "CUADERNILLO DE CONSTANCIA" },
  { value: 65, label: "CUADERNILLO DE CONSTANCIA BIS" },
  { value: 111, label: "CUADERNILLO DE CONSTANCIA-A" },
  { value: 48, label: "CUADERNILLO DE DESPACHO" },
  { value: 116, label: "CUADERNILLO DE DESPACHO-A" },
  { value: 30, label: "CUADERNILLO DE EXHORTO" },
  { value: 121, label: "CUADERNILLO DE REQUISITORIA" },
  { value: 104, label: "DE EJECUCION" },
  { value: 45, label: "DESPACHO" },
  { value: 112, label: "DESPACHO-A" },
  { value: 59, label: "EXCUSA" },
  { value: 31, label: "EXHORTO" },
  { value: 115, label: "EXHORTO BIS" },
  { value: 113, label: "EXHORTO-A" },
  { value: 92, label: "I" },
  { value: 93, label: "II" },
  { value: 94, label: "III" },
  { value: 108, label: "INCIDENTE" },
  { value: 118, label: "INCIDENTE DE TACHAS" },
  { value: 119, label: "INCIDENTE DE TACHAS BIS" },
  { value: 58, label: "INHIBITORIA" },
  { value: 95, label: "IV" },
  { value: 100, label: "IX" },
  { value: 106, label: "L.B." },
  { value: 66, label: "ORAL" },
  { value: 24, label: "PRINCIPAL" },
  { value: 114, label: "PRINCIPAL-A" },
  { value: 46, label: "REQUISITORIA" },
  { value: 105, label: "REQUISITORIA LOCAL" },
  { value: 50, label: "RESTITUCION DE MENORES" },
  { value: 49, label: "ROGATORIA" },
  { value: 47, label: "TERCERIA" },
  { value: 85, label: "TOMO" },
  { value: 29, label: "TOMO 1" },
  { value: 61, label: "TOMO 10" },
  { value: 62, label: "TOMO 11" },
  { value: 63, label: "TOMO 12" },
  { value: 64, label: "TOMO 13" },
  { value: 33, label: "TOMO 2" },
  { value: 34, label: "TOMO 3" },
  { value: 35, label: "TOMO 4" },
  { value: 36, label: "TOMO 5" },
  { value: 37, label: "TOMO 6" },
  { value: 38, label: "TOMO 7" },
  { value: 39, label: "TOMO 8" },
  { value: 60, label: "TOMO 9" },
  { value: 96, label: "V" },
  { value: 97, label: "VI" },
  { value: 98, label: "VII" },
  { value: 99, label: "VIII" },
  { value: 101, label: "X" }
];

export const OriginsCatalog = [
  { value: 1, label: "DEMANDA" },
  { value: 2, label: "EXCUSA" },
  { value: 3, label: "RECUSACION" },
  { value: 4, label: "INCOMPETENCIA POR DECLINATORIA" },
  { value: 5, label: "INCOMPETENCIA POR INHIBITORIA" },
  { value: 6, label: "PRORROGA DE LA JURISDICCION" },
  { value: 7, label: "ACUMULACION DE AUTOS" },
  { value: 8, label: "DESISTIMIENTO DE LA ACCION" },
  { value: 9, label: "DESISTIMIENTO DE LA INSTANCIA" },
  { value: 10, label: "CADUCIDAD" },
  { value: 11, label: "INCLUMPLIMIENTO A PREVENSION" },
  { value: 13, label: "DENUNCIA" },
  { value: 14, label: "AVERIGUACION PREVIA" },
  { value: 15, label: "REQUISITORIA" },
  { value: 16, label: "EXHORTO" },
  { value: 17, label: "DESPACHO" },
  { value: 18, label: "AMPARO" },
  { value: 19, label: "CARPETA DE INVESTIGACION" },
  { value: 20, label: "CAUSA PENAL" },
  { value: 21, label: "CARPETA ADMINISTRATIVA" },
  { value: 22, label: "CONDICIONES DE INTERNAMIENTO" },
  { value: 23, label: "EXPEDIENTE DE EJECUCION" },
];

export const CrimesCatalog = [
  { value: 1, matter: 1, label: "Conspiración" },
  { value: 2, matter: 1, label: "Rebelión" },
  { value: 3, matter: 1, label: "Sedición" },
  { value: 4, matter: 1, label: "Motín" },
  { value: 5, matter: 1, label: "Evasión de Presos" },
  { value: 6, matter: 1, label: "Quebrantamiento de Sanción" },
  { value: 7, matter: 1, label: "Armas y Objetos Prohibidos" },
  { value: 8, matter: 1, label: "Asociación Delictuosa" },
  { value: 9, matter: 1, label: "Pandillerismo" },
  { value: 10, matter: 1, label: "Delitos de Tránsito" },
  { value: 11, matter: 1, label: "De la Violencia en Espectáculos Públicos" },
  { value: 12, matter: 1, label: "Ataques a las Vías de Comunicación." },
  { value: 13, matter: 1, label: "Desobediencia o Resistencia de Particulares" },
  { value: 14, matter: 1, label: "Oposición a que se Ejecute Alguna Obra o Trabajo Público" },
  { value: 15, matter: 1, label: "Quebrantamiento de Sellos" },
  { value: 16, matter: 1, label: "Delitos Cometidos contra Representantes de la Autoridad" },
  { value: 17, matter: 1, label: "Ultrajes a la Moral o a las Buenas Costumbres e Incitación a la Prostitución" },
  { value: 18, matter: 1, label: "Lenocinio" },
  { value: 19, matter: 1, label: "Provocación de un Delito y Apología de éste o de Algún Vicio" },
  { value: 20, matter: 1, label: "Corrupción de Menores" },
  { value: 21, matter: 1, label: "Pornografía Infantil" },
  { value: 22, matter: 1, label: "Atentados al Pudor" },
  { value: 23, matter: 1, label: "Prostitución Infantil" },
  { value: 24, matter: 1, label: "Estupro" },
  { value: 25, matter: 1, label: "De la Gestión de la Adopción Ilegal" },
  { value: 26, matter: 1, label: "Abuso sexual infantil" },
  { value: 27, matter: 1, label: "Maltrato Infantil" },
  { value: 28, matter: 1, label: "Agravantes comunes a los delitos contra el desarrollo de la personalidad" },
  { value: 29, matter: 1, label: "Revelación de secretos" },
  { value: 30, matter: 1, label: "La Obtención Ilícita de Información Electrónica" },
  { value: 31, matter: 1, label: "Utilización Ilícita de información Confidencial" },
  { value: 32, matter: 1, label: "Suplantación de Identidad" },
  { value: 33, matter: 1, label: "Ejercicio Indebido y Abandono del Servicio Público" },
  { value: 34, matter: 1, label: "Abuso de Autoridad" },
  { value: 35, matter: 1, label: "Cohecho" },
  { value: 36, matter: 1, label: "Peculado" },
  { value: 37, matter: 1, label: "Concusión" },
  { value: 38, matter: 1, label: "Delitos Cometidos en la Custodia o Guarda de Documentos" },
  { value: 39, matter: 1, label: "Uso Ilícito de Atribuciones y Facultades" },
  { value: 40, matter: 1, label: "Enriquecimiento Ilícito" },
  { value: 41, matter: 1, label: "Delitos cometidos en la Administración de Justicia y en otros Ramos del Poder Público" },
  { value: 42, matter: 1, label: "De la Desaparición Forzada de Personas" },
  { value: 43, matter: 1, label: "De la Tortura" },
  { value: 44, matter: 1, label: "Del ocultamiento o falsificación de rendimientos y tráfico de influencias" },
  { value: 45, matter: 1, label: "Fraude procesal" },
  { value: 46, matter: 1, label: "Delito de Abogados, Patronos y Litigantes" },
  { value: 47, matter: 1, label: "Responsabilidad Médica" },
  { value: 48, matter: 1, label: "Responsabilidad Profesional y Técnica" },
  { value: 49, matter: 1, label: "Falsificación de Documentos expedidos por los Poderes del Estado, Organismos Autónomos, Ayuntamientos o de los Documentos de Crédito" },
  { value: 50, matter: 1, label: "Falsificación y Uso Indebido de Sellos, Marcas, Llaves y Troqueles" },
  { value: 51, matter: 1, label: "Falsificación de Documentos en General" },
  { value: 52, matter: 1, label: "Falsificación de Certificaciones" },
  { value: 53, matter: 1, label: "Falsedad en Declaraciones y en Informes dados a una Autoridad" },
  { value: 54, matter: 1, label: "Variación del Nombre o del Domicilio" },
  { value: 55, matter: 1, label: "Usurpación de Funciones Públicas o de Profesión y Uso indebido de Uniformes o Insignias" },
  { value: 56, matter: 1, label: "Falsificación de Medios Electrónicos o Magnéticos" },
  { value: 57, matter: 1, label: "Explotación e Inducción a la Mendicidad" },
  { value: 58, matter: 1, label: "Abuso Sexual" },
  { value: 59, matter: 1, label: "Estrupo y Prostotución Infantil" },
  { value: 60, matter: 1, label: "Violación" },
  { value: 61, matter: 1, label: "Hostigamiento y Acoso Sexual" },
  { value: 62, matter: 1, label: "Ciberacoso" },
  { value: 63, matter: 1, label: "Violación a la intimidad sexual" },
  { value: 64, matter: 1, label: "De la Violencia Familiar" },
  { value: 65, matter: 1, label: "De la Suposición y Supresión del Estado Civil" },
  { value: 66, matter: 1, label: "Exposición de Infantes" },
  { value: 67, matter: 1, label: "Substracción, Robo y Tráfico de Menores" },
  { value: 68, matter: 1, label: "Bigamia" },
  { value: 69, matter: 1, label: "Incesto" },
  { value: 70, matter: 1, label: "Adulterio" },
  { value: 71, matter: 1, label: "Abandono de Familiares" },
  { value: 72, matter: 1, label: "Delitos en Materia de Inhumaciones y Exhumaciones" },
  { value: 73, matter: 1, label: "Profanación de Sepulcros o Cadáveres" },
  { value: 74, matter: 1, label: "Amenazas" },
  { value: 75, matter: 1, label: "Extorsion" },
  { value: 76, matter: 1, label: "Chantaje" },
  { value: 77, matter: 1, label: "Allanamiento de Morada" },
  { value: 78, matter: 1, label: "Asalto" },
  { value: 79, matter: 1, label: "Privación Ilegal de la Libertad" },
  { value: 80, matter: 1, label: "Secuestro" },
  { value: 81, matter: 1, label: "Rapto" },
  { value: 82, matter: 1, label: "Golpes Simples" },
  { value: 83, matter: 1, label: "Injurias" },
  { value: 84, matter: 1, label: "Difamación" },
  { value: 85, matter: 1, label: "Calumnia" },
  { value: 86, matter: 1, label: "De los Delitos contra la Dignidad de las Personas" },
  { value: 87, matter: 1, label: "Disparo de arma de fuego sobre persona y ataque peligroso" },
  { value: 88, matter: 1, label: "Lesiones" },
  { value: 89, matter: 1, label: "Homicidio" },
  { value: 90, matter: 1, label: "Parricidio" },
  { value: 91, matter: 1, label: "Instigación o ayuda al Suicidio" },
  { value: 92, matter: 1, label: "Inducción o ayuda al suicidio feminista" },
  { value: 93, matter: 1, label: "Infanticidio" },
  { value: 94, matter: 1, label: "Aborto" },
  { value: 95, matter: 1, label: "Abandono de Personas" },
  { value: 96, matter: 1, label: "Feminicidio" },
  { value: 97, matter: 1, label: "Robo" },
  { value: 98, matter: 1, label: "Abigeato y Robo de Animales" },
  { value: 99, matter: 1, label: "Abuso de Confianza" },
  { value: 100, matter: 1, label: "Violación de Depósito" },
  { value: 101, matter: 1, label: "Fraude" },
  { value: 102, matter: 1, label: "Delitos contra el Desarrollo Urbano" },
  { value: 103, matter: 1, label: "Administración Fraudulenta" },
  { value: 104, matter: 1, label: "Delitos relacionados con la capacidad pecuniaria de las personas sujetas a concurso de acreedores" },
  { value: 105, matter: 1, label: "Usura" },
  { value: 106, matter: 1, label: "Daño en las Cosas" },
  { value: 107, matter: 1, label: "Daño al patrimonio urbano" },
  { value: 108, matter: 1, label: "Despojo de Inmuebles y Aguas" },
  { value: 109, matter: 1, label: "Del Pillaje" },
  { value: 110, matter: 1, label: "Encubrimiento" },
  { value: 111, matter: 1, label: "Adquisición Ilegítima de Bienes Materia de un Delito o de una infracción Penal" },
  { value: 112, matter: 1, label: "De las Definiciones" },
  { value: 113, matter: 1, label: "De los Delitos Cometidos por los Ciudadanos" },
  { value: 114, matter: 1, label: "Delitos cometidos por los Funcionarios Electorales" },
  { value: 115, matter: 1, label: "Delitos Cometidos por Representantes de Partido" },
  { value: 116, matter: 1, label: "Delitos cometidos por los Servidores Públicos" },
  { value: 117, matter: 1, label: "Disposiciones Comunes" },
  { value: 118, matter: 1, label: "Delitos Fiscales" },
  { value: 119, matter: 1, label: "Delitos Contra el Ambiente" },
  { value: 120, matter: 1, label: "Delitos en Materia de Información" },
  { value: 121, matter: 1, label: "Delitos en Materia de Seguridad Social de los Servidores Públicos" },
  { value: 122, matter: 1, label: "Crueldad Contra Los Animales" },
  { value: 123, matter: 1, label: "Delitos Contra la Libertad de Expresión" },
  { value: 124, matter: 1, label: "Delitos de Operaciones con Recursos de Procedencia Ilícita" },
  { value: 125, matter: 2, label: "Reivindicatorias" },
  { value: 126, matter: 2, label: "Usucapion" },
  { value: 127, matter: 2, label: "Proforma" },
  { value: 128, matter: 2, label: "Nulidad de Escritura" },
  { value: 130, matter: 2, label: "Acciones de pago" },
  { value: 131, matter: 2, label: "Pago de coutas condominales" },
  { value: 132, matter: 2, label: "Contratos" },
  { value: 133, matter: 3, label: "Alimentos" },
  { value: 134, matter: 3, label: "Patria protestad" },
  { value: 135, matter: 3, label: "Denuncia de herencias" },
  { value: 136, matter: 3, label: "Interdital" },
  { value: 137, matter: 3, label: "De petición de herencia" },
  { value: 138, matter: 3, label: "Sucesoria reivindicatoria" },
  { value: 139, matter: 3, label: "Proforma" },
  { value: 140, matter: 4, label: "Acción Cambiaria Directa" },
  { value: 141, matter: 4, label: "Acción Ordinaria" },
  { value: 142, matter: 4, label: "Cumplimiento de Contratos" },
  { value: 143, matter: 4, label: "Vencimiento de contratos de crédito" },
  { value: 144, matter: 4, label: "Contratos Privados ante Notario" },
  { value: 145, matter: 4, label: "Trámites Incidentales" },
  { value: 146, matter: 2, label: "Incidente de Nulidad de Actuaciones" },
  { value: 147, matter: 2, label: "Incidente de Nulidad de Emplazamiento" },
  { value: 148, matter: 2, label: "Incidente de falta de personalidad" },
  { value: 149, matter: 2, label: "Incidente de preferencia de acreedor" },
  { value: 150, matter: 2, label: "Planilla de liquidación" },
  { value: 151, matter: 2, label: "Planilla de costas" },
  { value: 152, matter: 2, label: "Diligencias para mejor proveer" },
  { value: 153, matter: 2, label: "Diligencias de información testimonial" },
  { value: 154, matter: 2, label: "Admisión de Interdicto" },
  { value: 155, matter: 2, label: "No se admite Interdicto" },
  { value: 156, matter: 2, label: "Interdicto de retener la posición" },
  { value: 157, matter: 2, label: "Interdicto de obra peligrosa" },
  { value: 158, matter: 2, label: "Interdicto de recuperar la posesión" },
  { value: 159, matter: 2, label: "Responsabilidad civil" },
  { value: 160, matter: 2, label: "Daño moral" },
  { value: 161, matter: 2, label: "Responsabilidad civil médica" },
  { value: 162, matter: 2, label: "Compraventa" },
  { value: 163, matter: 2, label: "Hipotecario" },
  { value: 164, matter: 2, label: "Comodato" },
  { value: 165, matter: 2, label: "Arrendamiento" },
  { value: 166, matter: 2, label: "Honorarios" },
  { value: 167, matter: 2, label: "Plenaria de posesión" },
  { value: 168, matter: 2, label: "Mutuo" },
  { value: 169, matter: 2, label: "Daños y perjuicios" },
  { value: 170, matter: 2, label: "Reparación del daño" },
  { value: 171, matter: 2, label: "Copropiedad" },
  { value: 172, matter: 2, label: "Rectificación de Acta" },
  { value: 173, matter: 2, label: "Nulificación de Acta" },
  { value: 174, matter: 2, label: "Anotación de Acta" },
  { value: 175, matter: 2, label: "Reposición de Acta" },
  { value: 176, matter: 2, label: "Convalidación de Acta" },
  { value: 177, matter: 3, label: "Sucesorio Intestamentario" },
  { value: 178, matter: 3, label: "Sucesorio Testamentario" },
  { value: 179, matter: 3, label: "Divorcio Incausado" },
  { value: 180, matter: 4, label: "Mercantil Ejecutivo" },
  { value: 181, matter: 4, label: "Mercantil Oral" },
  { value: 182, matter: 4, label: "Vencimiento Anticipado de Contratos" },
  { value: 183, matter: 4, label: "Rescisión de Contratos" },
  { value: 184, matter: 4, label: "Terminación de Contratos" },
  { value: 185, matter: 4, label: "Prescripción Negativa" },
  { value: 186, matter: 4, label: "Nulidad Absoluta" },
  { value: 187, matter: 4, label: "Objeción de Pago" },
  { value: 188, matter: 4, label: "Causal" },
  { value: 189, matter: 4, label: "Pago" },
  { value: 190, matter: 3, label: "Separación de Personas" },
  { value: 191, matter: 3, label: "Medidas de Protección" },
  { value: 192, matter: 3, label: "Reconocimiento de Paternidad" },
  { value: 193, matter: 3, label: "Desconocimiento de Paternidad" },
  { value: 194, matter: 3, label: "Rectificación de Acta" },
  { value: 195, matter: 3, label: "Anotación de Acta" },
  { value: 196, matter: 3, label: "Nulidad de Acta" },
  { value: 197, matter: 3, label: "Custodia" },
  { value: 198, matter: 3, label: "Covivencia" },
  { value: 199, matter: 3, label: "Divorcio por Mutuo Consentimiento" },
  { value: 200, matter: 3, label: "Dependencia Económica" },
  { value: 201, matter: 3, label: "Divorcio Contencioso" },
  { value: 202, matter: 3, label: "Concubinato" },
  { value: 203, matter: 3, label: "Tutela" },
  { value: 204, matter: 3, label: "Estado de Interdicción" },
  { value: 205, matter: 3, label: "Autorización para salir del País" },
  { value: 206, matter: 3, label: "Autorización de Venta de Inmuebles de Menores" },
  { value: 207, matter: 3, label: "Convivencia" },
  { value: 208, matter: 4, label: "Responsabilidad de Administradores de Sociedades Mercantiles" },
  { value: 209, matter: 4, label: "Pago de Daños y Perjuicios" },
  { value: 210, matter: 4, label: "Prórroga" },
  { value: 211, matter: 4, label: "Convocatoria para Asamblea General de Accionistas" },
  { value: 212, matter: 4, label: "Nulidad" },
  { value: 213, matter: 4, label: "Oposición a Decisiones tomadas en la Asamblea General de Accionistas" },
  { value: 214, matter: 4, label: "Acción Cambiaria en Vía de Regreso" },
  { value: 215, matter: 4, label: "Cancelación de Títulos de Crédito" },
  { value: 216, matter: 4, label: "Derivada de la Confesión Judicial del Deudor" },
  { value: 217, matter: 4, label: "Derivadas de Testimonios Públicos y Copias Certificadas Expedidas por Fedatarios Públicos" },
  { value: 218, matter: 4, label: "Derecho de Preferencia" },
  { value: 219, matter: 4, label: "Reconocimiento de Firma, Monto y Causa del Adeudo" },
  { value: 220, matter: 4, label: "Designación de Árbitros" },
  { value: 221, matter: 4, label: "Ejecución de Fideicomiso Otorgado en garantía" },
  { value: 222, matter: 4, label: "Ejecución de Fideicomiso" },
  { value: 223, matter: 4, label: "Ejecución de Prenda" },
  { value: 224, matter: 4, label: "Cumplimiento del Fideicomiso" },
  { value: 225, matter: 4, label: "Pago de Fianza" },
  { value: 226, matter: 4, label: "Reconocimiento del Laudo Arbitral" },
  { value: 227, matter: 4, label: "Ejecución del Laudo Arbitral" },
  { value: 228, matter: 4, label: "Reposición de Póliza de Fianza" },
  { value: 229, matter: 4, label: "Recusación de Árbitros" },
  { value: 230, matter: 4, label: "Rendición de Cuentas" },
  { value: 231, matter: 4, label: "Reposición de Títulos de Crédito" },
  { value: 232, matter: 4, label: "Liquidación de Sociedades Mercantiles" },
  { value: 233, matter: 4, label: "Separación de Socios en Sociedades Mercantiles" },
  { value: 234, matter: 4, label: "Entrega de Garantía Prendaria" },
  { value: 235, matter: 4, label: "Venta de Prenda" },
  { value: 236, matter: 4, label: "Tercería Excluyente de Dominio y de Preferencia" },
  { value: 237, matter: 4, label: "Tercería Coadyuvante" },
  { value: 238, matter: 3, label: "Planilla de Liquidación de Sentencia" },
  { value: 239, matter: 3, label: "Incidentes" },
  { value: 240, matter: 3, label: "Adopción Plena" },
  { value: 241, matter: 3, label: "Adopción Simple" },
  { value: 242, matter: 3, label: "Homologación de Sentencia" },
  { value: 243, matter: 2, label: "Ejecutivo" },
  { value: 244, matter: 2, label: "Intestamentario" },
  { value: 245, matter: 2, label: "Divorcio" },
  { value: 246, matter: 4, label: "Ejecutivo" },
  { value: 247, matter: 4, label: "Mercantil Oral" },
  { value: 248, matter: 3, label: "Competencia" },
  { value: 249, matter: 3, label: "Declaración de Ausencia" },
  { value: 250, matter: 3, label: "Modificación de Convenio" },
  { value: 251, matter: 2, label: "Testamentario" },
  { value: 252, matter: 2, label: "Acumulación" },
  { value: 253, matter: 1, label: "Contra la Salud" },
  { value: 254, matter: 2, label: "Incidente de Acumulación" },
  { value: 255, matter: 2, label: "Pérdida de la patria protestad" },
  { value: 256, matter: 2, label: "Jurisdicción Voluntaria" },
  { value: 257, matter: 2, label: "Sucesorio Testamentario" },
  { value: 258, matter: 4, label: "Improcedencia a la vía" },
  { value: 259, matter: 4, label: "Falta de personalidad" },
  { value: 260, matter: 2, label: "Sucesorio intestamentario" },
  { value: 261, matter: 2, label: "Tramitación Especial" },
  { value: 262, matter: 2, label: "Civil Sumario" },
  { value: 263, matter: 2, label: "Civil Ordinario" },
  { value: 264, matter: 2, label: "Reducción de Pensión Alimenticia" },
  { value: 265, matter: 3, label: "Reducción de Pensión Alimenticia" },
  { value: 266, matter: 3, label: "Ilegitimidad por Ineficacia" },
  { value: 267, matter: 3, label: "Conexidad de Causa" },
  { value: 268, matter: 3, label: "Incidente de Reposición" },
  { value: 269, matter: 3, label: "Diversidad de Nombres" },
  { value: 270, matter: 3, label: "Patrimonio de Familia" },
  { value: 271, matter: 1, label: "Tentativa de Feminicidio y Abuso Sexual Infantil" },
  { value: 272, matter: 3, label: "Nulidad de actuaciones" },
  { value: 273, matter: 3, label: "Cambio de Régimen Matrimonial" },
  { value: 274, matter: 1, label: "Robo Calificado y Robo Equiparado" },
  { value: 275, matter: 1, label: "Robo Calificado" },
  { value: 276, matter: 1, label: "Tentativa de Robo Calificado" },
  { value: 277, matter: 1, label: "Homicidio Calificado" },
  { value: 278, matter: 1, label: "Maltrato Infantil y Lesiones Calificadas" },
  { value: 279, matter: 1, label: "Tentativa de Homicidio" },
  { value: 280, matter: 1, label: "Robo Equiparado" },
  { value: 281, matter: 1, label: "Tentativa de Parricidio" },
  { value: 282, matter: 1, label: "Tentativa de Feminicidio" },
  { value: 283, matter: 1, label: "Abuso Sexual Infantil Agravado" },
  { value: 284, matter: 1, label: "Acopio de armas de uso exclusivo" },
  { value: 285, matter: 1, label: "Robo de Infante" },
  { value: 286, matter: 3, label: "Abandono" },
  { value: 287, matter: 1, label: "Tentativa de Robo de Infante" },
  { value: 288, matter: 1, label: "Tentativa privación ilegal de la libertad, Usurpación de funciones públicas y Lesiones calificadas" },
  { value: 289, matter: 3, label: "Incidencia de Convivencia" },
  { value: 290, matter: 3, label: "Litispendencia" },
  { value: 291, matter: 1, label: "Abogado Patrono y Litigantes" },
  { value: 292, matter: 2, label: "Presunción de Muerte" },
  { value: 293, matter: 2, label: "Medidas Vigentes" },
  { value: 294, matter: 5, label: "Reinstalación" },
  { value: 295, matter: 5, label: "Pago de salarios caidos" },
  { value: 296, matter: 5, label: "Convenio" },
  { value: 297, matter: 5, label: "Indemnización" },
  { value: 298, matter: 5, label: "Resccisión de contrato" },
  { value: 299, matter: 5, label: "Salarios retenidos" },
  { value: 300, matter: 5, label: "Pago de prestaciones" },
  { value: 301, matter: 5, label: "Accidente de trabajo" },
  { value: 302, matter: 5, label: "Beneficiarios" },
  { value: 303, matter: 5, label: "Beneficiarios" },
  { value: 304, matter: 1, label: "En grado de Tentativa" },
  { value: 305, matter: 1, label: "Violencia familiar, maltrato infantil" },
  { value: 306, matter: 1, label: "Violencia familiar, amenzas, lesiones" },
  { value: 307, matter: 1, label: "Violencia familiar, delitos cometidos contra la dignidad de las personas" },
  { value: 308, matter: 1, label: "Violencia familiar, amenzas, violación a la intimidad sexual" },
  { value: 309, matter: 1, label: "Violencia familiar, lesiones, amenazas, daño en las cosas" },
  { value: 310, matter: 1, label: "Violencia familiar, disparos de arma de fuego, lesiones calificadas" },
  { value: 311, matter: 1, label: "Violencia familiar, lesiones simples, daños en las cosas" },
  { value: 312, matter: 1, label: "Violación familiar quiparada" },
  { value: 313, matter: 1, label: "Ultrajes a la moral" },
  { value: 314, matter: 1, label: "Violencia familiar equiparada" },
  { value: 315, matter: 4, label: "Medidas Precautorias" },
  { value: 316, matter: 1, label: "Desaparición Cometida por Particulares" },
  { value: 317, matter: 1, label: "Beneficios preliberacionales" },
  { value: 318, matter: 1, label: "Homicidio en Riña" },
  { value: 319, matter: 1, label: "Violación equiparada" },
  { value: 320, matter: 1, label: "Lesiones Calificadas" },
  { value: 321, matter: 5, label: "Cumplimiento de Convenio" },
  { value: 322, matter: 5, label: "Ejecución de Convenio" },
  { value: 323, matter: 5, label: "Notificación de aviso de resición" },
  { value: 324, matter: 1, label: "Delitos contra la salud" },
  { value: 325, matter: 1, label: "Lesiones Dolosas" },
  { value: 326, matter: 1, label: "Violencia familiar y lesiones dolosas" },
  { value: 327, matter: 1, label: "Homicidio calificado  en su variante de ventaja y brutal ferocidad" },
  { value: 328, matter: 1, label: "Violencia familiar y amenazas" },
  { value: 329, matter: 1, label: "Contra la salud en su modalidad de narcomenudeo, en su variante de posesión simple" },
  { value: 330, matter: 1, label: "Abuso sexual infantil, corrupción de menores ambos en modalidad de agravados" },
  { value: 331, matter: 1, label: "Abuso sexual infantil agravado, lesiones y violencia familiar" },
  { value: 332, matter: 1, label: "Lesiones calificadas en su variante de ventaja, maltrato infantil y violencia familiar equiparada" }
];

export const PartsTypeCatalog = [
  { value: 1, label: "Actor" },
  { value: 2, label: "Demandado" },
  { value: 3, label: "Abogado del Actor" },
  { value: 4, label: "Abogado del Demandado" },
  { value: 5, label: "Beneficiario" },
  { value: 6, label: "Tercero Llamado a Juicio" },
  { value: 7, label: "Acusado" },
  { value: 8, label: "Defensor Privado" },
  { value: 9, label: "Defensor Público" },
  { value: 10, label: "Imputado" },
  { value: 11, label: "Víctima u Ofendido" }
];

export const PartsClasificationCatalog = [
  { value: 'public', label: "Público" },
  { value: 'private', label: "Privado" },
];

export const PartsSexCatalog = [
  { value: 'male', label: "Masculino" },
  { value: 'female', label: "Femenino" }
];

export const PartsGenderCatalog = [
  { value: 'bigenero', label: "Bigénero" },
  { value: 'cisgenero', label: "Cisgénero" },
  { value: 'generoflu', label: "Género fluido" },
  { value: 'heteronormatividad', label: "Heteronormatividad" },
  { value: 'nobinario', label: "No binario" }
];

export const RegimesCatalog = [
  { value: "fisica", label: "Persona Física" },
  { value: "moral", label: "Persona Moral" }
];