export const imagesDataimgFilter = (img) => {
  const { currentSrc } = img;

  if (!currentSrc) {
    return false
  }

  const currentSrcSplitted = currentSrc.split(',');
  const srcFirstSection = currentSrcSplitted[0];

  const isAnBase64 = srcFirstSection.includes('base64');

  if (isAnBase64) {
    return false
  }

  return true
}