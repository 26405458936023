export const getNodeToInsertCodeTag = (editor) => {
  const currentNodeValue = editor.selection.getContent({ format: 'text' });
  const currentTagNameNode = editor.selection.getNode().tagName.toLocaleLowerCase();
  let nodeToinsert;
  if (currentTagNameNode === 'code') {
    nodeToinsert = currentNodeValue;
  } else {
    nodeToinsert = `<code>${currentNodeValue}</code>`;
  }
  return nodeToinsert;
}