import React from 'react'
import { getElementFromOptionsList } from "../../../../../../../../../../../../../services/dataFormatting/getElementFromOptionsList"
import { initialAgeOptions } from "../../../../../../../../services/selectOptions/initialAgeOptions"
import { representationOptions } from '../../../../../../../../services/selectOptions/representationOptions'

const isEmptyElementEnabled = true;

export const initialAgeFormatter = (cell, row) => {
  return getElementFromOptionsList(row.initial_age, initialAgeOptions, isEmptyElementEnabled).label
}

export const representationFormatter = (cell, row) => {
  if ((Array.isArray(row.representation)) && row.representation.length) {
    return (
      <ul>
        {
          row.representation.map((representationElement, index) => (
            <li style={{ textAlign: 'left' }} key={index}>
              {getElementFromOptionsList(representationElement, representationOptions, isEmptyElementEnabled).label}
            </li>
          ))
        }
      </ul>
    )
  }
  return <></>
}

export const idFormatter = (cell, row, index) => (index + 1);